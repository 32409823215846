import React from 'react';
import { colorFontDark } from '@app/src/theme';

const HelpSupportIcon = ({ color = colorFontDark, ...props }) => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18 19.1893H17.24C16.44 19.1893 15.68 19.4993 15.12 20.0593L13.41 21.7494C12.63 22.5194 11.36 22.5194 10.58 21.7494L8.87 20.0593C8.31 19.4993 7.54 19.1893 6.75 19.1893H6C4.34 19.1893 3 17.8594 3 16.2194V5.30933C3 3.66933 4.34 2.33936 6 2.33936H18C19.66 2.33936 21 3.66933 21 5.30933V16.2194C21 17.8494 19.66 19.1893 18 19.1893Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.22769 10.8397C10.5064 10.8397 11.5082 9.84237 11.5082 6.56295V6.32935H12.4918V6.56295C12.4918 9.84237 13.4936 10.8397 16.7723 10.8397H17V11.819H16.7723C13.4936 11.819 12.4918 12.8163 12.4918 16.0957V16.3293H11.5082V16.0957C11.5082 12.8163 10.5064 11.819 7.22769 11.819H7V10.8397H7.22769Z'
        fill={color}
      />
    </svg>
  );
};

export default HelpSupportIcon;
