// @ts-check

import React from 'react';
import TaxFlowBillStatus from '@app/src/Components/TaxFlow/Question/TaxFlowBillStatus';
import TaxFlowRefundStatus from '@app/src/Components/TaxFlow/Question/TaxFlowRefundStatus';

/**
 * @typedef {Object} TaxFlowSettlementStatusProps
 * @property {number} amount
 * @property {string} mask
 * @property {string} paymentMethod
 * @property {string} entity
 * @property {import('moment').Moment} submittedDate
 * @property {import('moment').Moment} acceptedDate
 * @property {import('moment').Moment} transactionFoundDate
 * @property {import('moment').Moment} withdrawalDate
 * @property {string} status
 */

/**
 * @type {React.FC<TaxFlowSettlementStatusProps>}
 */
const TaxFlowSettlementStatus = ({
  amount,
  mask,
  paymentMethod,
  entity,
  submittedDate,
  acceptedDate,
  transactionFoundDate,
  withdrawalDate,
  status
}) => {
  if (amount === 0) {
    return null;
  }

  if (amount < 0) {
    return (
      <TaxFlowRefundStatus
        amount={amount}
        submittedDate={submittedDate}
        acceptedDate={acceptedDate}
        entity={entity}
        mask={mask}
        refundDate={transactionFoundDate}
        paymentMethod={paymentMethod}
        status={status}
      />
    );
  }

  return (
    <TaxFlowBillStatus
      mask={mask}
      amount={amount}
      withdrawalDate={withdrawalDate}
      entity={entity}
      billDate={transactionFoundDate}
      paymentMethod={paymentMethod}
      status={status}
    />
  );
};

export default TaxFlowSettlementStatus;
