import React from 'react';
import _ from 'lodash';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import { ArrowDown2, ArrowRight2 } from 'iconsax-react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { useWindowSize } from 'react-use';
import { setHolisticOnboardingDrawerOpen } from '@app/src/actions/holisticOnboardingActions';
import { drawerOpenSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const InfoDrawer = ({ learnMoreInfo, open, setHolisticOnboardingDrawerOpen }) => {
  const title = _.get(learnMoreInfo, 'title', '');
  const contentType = _.get(learnMoreInfo, 'contentType', '');
  const accordionInfo = _.get(learnMoreInfo, 'accordionInfo', {});
  const isMobile = useWindowSize().width < 1024;

  const onClose = () => {
    setHolisticOnboardingDrawerOpen(false);
  };

  const Accordion = styled((props) => <MuiAccordion {...props} />)(() => ({
    boxShadow: 'none',
    border: 'none',
    '& .MuiExpanded': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '30px',
      marginBottom: '0px'
    }
  }));

  const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(() => ({
    boxShadow: 'none',
    border: 'none',
    paddingLeft: '0px',
    marginBottom: '16px',
    minHeight: '0px',
    '& .MuiAccordionSummary-content': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px'
    }
  }));

  const AccordionDetails = styled((props) => <MuiAccordionDetails {...props} />)(() => ({
    padding: '0px 16px 16px 0px'
  }));

  const content = (
    <>
      {contentType === 'accordion' && (
        <div>
          <ReactMarkdown
            source={_.get(accordionInfo, ['intro'], '')}
            className='taxflow-info-modal-window-accordion-intro'
          />
          {accordionInfo.data.map((info, i) => {
            return (
              <Accordion
                sx={{
                  '&:before': {
                    display: 'none'
                  }
                }}
                key={i + 1}
              >
                <AccordionSummary
                  expandIcon={<ArrowDown2 />}
                  aria-controls={`panel${i}a-content`}
                  id={`panel${i + 1}a-header`}
                >
                  <ReactMarkdown className='taxflow-info-modal-window-accordion-summary' source={info.summary} />
                </AccordionSummary>
                <AccordionDetails>
                  <ReactMarkdown className='taxflow-info-modal-window-accordion-details' source={info.details} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </>
  );

  return (
    accordionInfo && (
      <SwipeableDrawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={open}
        onOpen={() => {}}
        onClose={onClose}
        PaperProps={{ style: { maxWidth: '600px' } }}
      >
        <div style={{ padding: '16px', marginTop: '16px' }}>
          <IconButton onClick={onClose} style={{ position: 'absolute', right: '0px', top: '0px', margin: '8px' }}>
            {isMobile ? <ArrowDown2 /> : <ArrowRight2 />}
          </IconButton>
          <h1 className='taxflow-info-modal-window-panel-header'> {title}</h1>
          <div className='taxflow-info-modal-window-panel-text'>{content}</div>
          <div className='taxflow-info-modal-window-panel-assistance-box'>
            <h3 className='taxflow-info-modal-window-panel-assistance-box-header'>Need assistance? </h3>
            <p className='taxflow-info-modal-window-panel-assistance-box-caption'>
              Email support@keepertax.com and we'll answer your questions within 24 hours.
              <br />
              <br /> You can also browse our{' '}
              <a href='https://help.keepertax.com/hc/en-us' target='_blank' rel='noopener noreferrer'>
                help center
              </a>{' '}
              for instant advice on common problems.
            </p>
          </div>
        </div>
      </SwipeableDrawer>
    )
  );
};

const mapStateToProps = (state) => ({
  open: drawerOpenSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingDrawerOpen
};

const ConnectedInfoDrawer = connect(mapStateToProps, mapDispatchToProps)(InfoDrawer);

export default ConnectedInfoDrawer;
