import {
  Url_ONBOARDING_DURATION,
  Url_ONBOARDING_HOME,
  Url_ONBOARDING_INCOME_TYPE,
  Url_ONBOARDING_JOB_SELECT,
  Url_ONBOARDING_MEAL,
  Url_ONBOARDING_TRANSPORT,
  Url_ONBOARDING_TRAVEL,
  Url_SIGNUP,
  Url_SIGNUP_LINK,
  Url_SIGNUP_PHONE_ONLY
} from '@app/src/constants/onboardingConstants';

const getProgressMap = () => {
  const onboardingSteps = {
    [Url_ONBOARDING_INCOME_TYPE]: 1,
    [Url_ONBOARDING_JOB_SELECT]: 2,
    [Url_ONBOARDING_DURATION]: 3,
    [Url_ONBOARDING_TRANSPORT]: 4,
    [Url_ONBOARDING_HOME]: 5,
    [Url_ONBOARDING_MEAL]: 6,
    [Url_ONBOARDING_TRAVEL]: 7,
    [Url_SIGNUP]: 8,
    [Url_SIGNUP_PHONE_ONLY]: 9,
    [Url_SIGNUP_LINK]: 10,
    placeholder: 11
  };
  return onboardingSteps;
};

export default getProgressMap;
