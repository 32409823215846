import React from 'react';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { TickCircle } from 'iconsax-react';
import { colorSuccess } from '@app/src/theme';
import '@app/src/Components/HolisticOnboarding/components/AnimatedList/AnimatedList.scss';

const AnimatedList = ({ items }) => (
  <List className='animated-list'>
    {items.map((item, idx) => (
      <Fade key={idx} in={true} timeout={500} style={{ transitionDelay: `${idx * 1000}ms` }}>
        <ListItem key={item}>
          <ListItemText primary={item} />
          <TickCircle size='24' color={colorSuccess} />
        </ListItem>
      </Fade>
    ))}
  </List>
);

export default AnimatedList;
