import React from 'react';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { setBank } from '@app/src/actions/pricingActions';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import { bankSelector, chargeableAccountsSelector, updatingSelector } from '@app/src/selectors/pricingSelectors';
import { saveBankPaymentMethod } from '@app/src/services/pricingService';
import '@app/src/Components/Subscription/PaymentModal/PaymentBank.scss';

const PaymentBank = ({ className, history, bank, chargeableAccounts, updating, setBank, saveBankPaymentMethod }) => {
  const onToggleBank = ({ account_id }) => {
    if (updating) {
      return;
    }
    setBank({ bank: account_id === bank ? null : account_id });
    saveBankPaymentMethod({ history });
  };

  if (!chargeableAccounts.length) {
    return null;
  }

  return (
    <Stack className={classNames('payment-bank', className)} spacing={1}>
      <div className='payment-bank-text'>Select one of your connected bank accounts:</div>
      {chargeableAccounts.map((chargeableAccount) => (
        <div
          key={chargeableAccount.account_id}
          className={classNames('payment-bank-bank', {
            'payment-bank-bank-active': chargeableAccount.account_id === bank,
            'payment-bank-bank-disabled': updating
          })}
          onClick={() => onToggleBank({ account_id: chargeableAccount.account_id })}
          role='button'
        >
          {chargeableAccount.logo ? (
            <img
              className='payment-bank-bank-icon'
              src={`data:image/png;base64,${chargeableAccount.logo}`}
              alt={chargeableAccount.institution_name}
            />
          ) : (
            <BankIcon className='payment-bank-bank-icon' />
          )}
          <span>
            {chargeableAccount.name}
            {chargeableAccount.mask && <span> - {chargeableAccount.mask}</span>}
          </span>
        </div>
      ))}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  bank: bankSelector(state),
  chargeableAccounts: chargeableAccountsSelector(state),
  updating: updatingSelector(state)
});

const mapDispatchToProps = {
  setBank,
  saveBankPaymentMethod
};

const ConnectedPaymentBank = connect(mapStateToProps, mapDispatchToProps)(PaymentBank);

export default ConnectedPaymentBank;
