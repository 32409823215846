import React from 'react';
import TableRow from '@mui/material/TableRow';
import '@app/src/Components/ExpenseReview/Table/ExpenseTable/ExpenseTableRow.scss';

const ExpenseTableRow = ({ children, selected, ...props }) => (
  <TableRow
    className='expense-table-row'
    selected={selected}
    classes={{ selected: 'expense-table-row-selected' }}
    {...props}
  >
    {children}
  </TableRow>
);

export default ExpenseTableRow;
