import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Loader from '@app/src/Components/Common/Loader/Loader';
import LoadingBanner from '@app/src/Components/Onboarding/LoadingBanner';
import TickCircleIcon from '@app/src/assets/tick-circle.svg?react';
import VerifyIcon from '@app/src/assets/verify.svg?react';
import { BANK_LINK_LOADING_LIST_ITEMS, BANK_SKIP_LOADING_LIST_ITEMS } from '@app/src/constants/loadingPageConstants';
import { bankLinkSkippedSelector } from '@app/src/selectors/bankSelectors';
import { loadingSelector } from '@app/src/selectors/onboardingSelectors';
import { colorSuccess } from '@app/src/theme';
import getImageUrl from '@app/src/utils/getImageUrl';
import '@app/src/Components/Onboarding/OnboardingLoading.scss';

const OnboardingLoading = ({ history, loading, bankLinkSkipped }) => {
  const transitionTime = 1100;

  const bulletsText = useRef(bankLinkSkipped ? BANK_SKIP_LOADING_LIST_ITEMS : BANK_LINK_LOADING_LIST_ITEMS);

  useEffect(() => {
    setTimeout(() => history.push('/onboarding/app-download'), transitionTime * 1.2 * (bulletsText.current.length + 2));
  }, [transitionTime, bulletsText, history]);

  return (
    <div className='onboarding-2-loading'>
      {loading ? (
        <div className='onboarding-2-loading-wrapper'>
          <Loader type='page' />
        </div>
      ) : (
        <>
          <LoadingBanner
            bankLinkSkipped={bankLinkSkipped}
            topPadding={true}
            hideBottomBorder={true}
            className='onboarding-2-loading-title'
          />
          <div className='onboarding-2-loading-content'>
            <img className='onboarding-2-loading-gif' src={getImageUrl('bank-statement.webp')} alt='loading...' />
            <TransitionGroup className='onboarding-2-loading-list' appear={true}>
              {bulletsText.current.map((text, i) => (
                <CSSTransition classNames={'onboarding-2-loading-list-item'} timeout={(i + 1) * transitionTime} key={i}>
                  <div className='onboarding-2-loading-list-item-content'>
                    {text}
                    <TickCircleIcon className='onboarding-2-loading-list-item-content-check' fill={colorSuccess} />
                  </div>
                </CSSTransition>
              ))}
              <CSSTransition
                classNames={'onboarding-2-loading-list-success'}
                timeout={(bulletsText.current.length + 1) * transitionTime}
              >
                <div className='onboarding-2-loading-list-success-content'>
                  <div className='onboarding-2-loading-list-success-icon-container'>
                    <VerifyIcon width={16} height={16} />
                  </div>
                  <div>Success! We have results!</div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  bankLinkSkipped: bankLinkSkippedSelector(state)
});

const ConnectedOnboardingLoading = connect(mapStateToProps)(OnboardingLoading);

export default ConnectedOnboardingLoading;
