import React, { useEffect } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { connect } from 'react-redux';
import BankLinkPlaid from '@app/src/Components/BankLinkPlaid/BankLinkPlaid';
import { useTransactionsSyncInProgress } from '@app/src/hooks/useTransactionsSyncInProgress';
import { isInConfirmAmountsSelector } from '@app/src/selectors/taxFlowSelectors';
import { initPlaid } from '@app/src/services/bankLinkService';
import store from '@app/src/store/store';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';

const BankLink = ({
  className,
  fullWidth,
  variant,
  color,
  type,
  linkToken,
  icon,
  children,
  styles,
  initPlaid,
  setSelfUnlockModalOpen
}) => {
  const { syncInProgress } = useTransactionsSyncInProgress({ year: TAX_FILING_YEAR });

  useEffect(() => {
    initPlaid({ type });
  }, [initPlaid, type]);

  const isInConfirmAmounts = isInConfirmAmountsSelector(store.getState());

  if (_.isNil(linkToken)) {
    return (
      <Button
        size='large'
        fullWidth={!_.isNil(fullWidth) ? fullWidth : true}
        startIcon={icon}
        color={color}
        variant={variant ? variant : 'outlined'}
        className={classNames(className)}
        disabled
        sx={styles}
      >
        {children}
      </Button>
    );
  }

  const renderButton = ({ open, ready }) => {
    const openBankLink = () => {
      if (isInConfirmAmounts) {
        setSelfUnlockModalOpen(true);
      } else {
        open();
      }
    };
    return (
      <Button
        startIcon={icon}
        fullWidth={!_.isNil(fullWidth) ? fullWidth : true}
        size='large'
        color={color}
        variant={variant ? variant : 'outlined'}
        className={classNames(className)}
        disabled={!ready || syncInProgress}
        onClick={openBankLink}
        sx={styles}
      >
        {children}
      </Button>
    );
  };

  return <BankLinkPlaid type={type} renderButton={renderButton} />;
};

const mapStateToProps = (state) => ({
  linkToken: _.get(state, ['bank', 'linkToken'])
});

const mapDispatchToProps = {
  initPlaid,
  setSelfUnlockModalOpen
};

const ConnectedBankLink = connect(mapStateToProps, mapDispatchToProps)(BankLink);

export default ConnectedBankLink;
