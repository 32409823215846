import _ from 'lodash';
import moment from 'moment';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_TEXT,
  CATEGORY_TYPE_TAXFLOW_FORM_SSN,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_INITIAL,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_YEARS,
  CATEGORY_TYPE_PERCENT,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_EIN,
  CATEGORY_TYPE_SSN_OR_EIN,
  CATEGORY_TYPE_ZIP,
  CATEGORY_TYPE_INT,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TWELVE_A,
  CATEGORY_TYPE_BOX_14,
  CATEGORY_TYPE_TAXFLOW_ADDRESS,
  CATEGORY_TYPE_DROPDOWN_SEARCH,
  CATEGORY_TYPE_BUSINESS_CODE,
  CATEGORY_TYPE_EXPENSES_LINK
} from '@app/src/constants/constants';
import { safeJsonParse } from '@app/src/global/Helpers';
import { CAR_SLUGS } from '@app/src/taxflow/sections/car/carConstants';
import {
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_AMOUNT,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_INDUSTRY
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  SLUG__STATE_INCOME,
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  COLLECTION_TYPE__STATE_RESIDENCY,
  SLUG__STATE_EXPENSES
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { getExpenseSplitJobs } from '@app/src/taxflow/sections/state/utils/multistateUtils';
import { SLUG__PAST_RETURNS, TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';
import { isCalendarMonths, getQueryResultsByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';

export const serializeQuestionAnswer = ({ question, answer }) => {
  if (
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SLIDER ||
    question.slug === SLUG__INCOME_FREELANCE_1099K_EXPENSES_AMOUNT
  ) {
    return _.get(answer, ['value']);
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE) {
    if (_.isEmpty(_.get(answer, ['value']))) {
      return '';
    }
    return moment(_.get(answer, ['value']), 'DD/MMM/YYYY').format('YYYY-MM-DD');
  } else if (
    question.question_type === CATEGORY_TYPE_OPTIONS ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN ||
    question.question_type === CATEGORY_TYPE_STATE ||
    question.question_type === CATEGORY_TYPE_TEXT ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_NUMBER ||
    question.question_type === CATEGORY_TYPE_MONEY ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SSN ||
    question.question_type === CATEGORY_TYPE_INITIAL ||
    question.question_type === CATEGORY_TYPE_MILES ||
    question.question_type === CATEGORY_TYPE_YEARS ||
    question.question_type === CATEGORY_TYPE_PERCENT ||
    question.question_type === CATEGORY_TYPE_SQUARE_FOOTAGE ||
    question.question_type === CATEGORY_TYPE_EIN ||
    question.question_type === CATEGORY_TYPE_SSN_OR_EIN ||
    question.question_type === CATEGORY_TYPE_ZIP ||
    question.question_type === CATEGORY_TYPE_INT ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PHONE ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_ADDRESS ||
    question.question_type === CATEGORY_TYPE_DROPDOWN_SEARCH ||
    question.question_type === CATEGORY_TYPE_EXPENSES_LINK
  ) {
    if (_.isNil(_.get(answer, ['value']))) {
      return '';
    } else {
      return `${_.get(answer, ['value'])}`;
    }
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR) {
    if (question.collectionType === COLLECTION_TYPE__STATE_RESIDENCY) {
      return JSON.stringify({
        ...(!isCalendarMonths(question) ? { value: _.get(answer, ['value']) } : {}),
        state: _.get(answer, ['state'], null),
        months: _.get(answer, ['months'], [])
      });
    } else {
      return JSON.stringify({
        ...(!isCalendarMonths(question) ? { value: _.get(answer, ['value']) } : {}),
        months: _.get(answer, ['months'], [])
      });
    }
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION) {
    return JSON.stringify(_.get(answer, ['value']));
  } else if (question.question_type === CATEGORY_TYPE_TWELVE_A) {
    return JSON.stringify(_.get(answer, ['value']));
  } else if (question.question_type === CATEGORY_TYPE_BOX_14) {
    if (!answer.value) {
      return null;
    } else {
      return JSON.stringify(answer.value);
    }
  } else if (question.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
    return [
      {
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE,
        value: _.get(answer, ['value', SLUG__INCOME_FREELANCE_BUSINESS_CODE, 'value'])
      },
      {
        slug: SLUG__INCOME_FREELANCE_INDUSTRY,
        value: _.get(answer, ['value', SLUG__INCOME_FREELANCE_INDUSTRY, 'value'])
      }
    ];
  } else if (question.slug === SLUG__STATE_INCOME || question.slug === SLUG__STATE_EXPENSES) {
    if (!answer.value) {
      return null;
    } else {
      return JSON.stringify(_.get(answer, ['value']));
    }
  } else if (question.slug === SLUG__PAST_RETURNS) {
    return answer;
  } else if (question.slug === CAR_SLUGS.MILEAGE_ESTIMATION_WORK_MILES_ESTIMATED) {
    return _.get(answer, 'value', 0);
  } else {
    return null;
  }
};

export const deserializeQuestionAnswer = ({ question, value, queryResults }) => {
  if (
    question.question_type === CATEGORY_TYPE_OPTIONS ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN ||
    question.question_type === CATEGORY_TYPE_STATE
  ) {
    if (_.isEmpty(value)) {
      const defaultItem = question.question_meta.find && question.question_meta.find((item) => item.default === 1);
      if (defaultItem) {
        return { value: `${defaultItem.value}` };
      } else {
        return { value: '' };
      }
    } else {
      return { value };
    }
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SLIDER) {
    if (_.isEmpty(value)) {
      const defaultVal = question.question_meta.default;
      if (defaultVal) {
        return { value: defaultVal };
      } else {
        return { value: 50 };
      }
    } else {
      return { value: parseInt(value) };
    }
  } else if (question.slug === SLUG__INCOME_FREELANCE_1099K_EXPENSES_AMOUNT) {
    return { value };
  } else if (
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_NUMBER ||
    question.question_type === CATEGORY_TYPE_MONEY ||
    question.question_type === CATEGORY_TYPE_MILES ||
    question.question_type === CATEGORY_TYPE_YEARS ||
    question.question_type === CATEGORY_TYPE_SQUARE_FOOTAGE ||
    question.question_type === CATEGORY_TYPE_PERCENT
  ) {
    if (/^\d*(\.\d*)?$/.test(value)) {
      return { value };
    } else {
      const defaultVal = _.get(question, ['question_meta', 'defaultAnswer'], null);
      return { value: defaultVal };
    }
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      return { value: moment(value, 'YYYY-MM-DD').format('DD/MMM/YYYY') };
    }

    let defaultVal = question.question_meta.defaultAnswer;
    if (defaultVal) {
      if (/^\{taxFilingYear}-\d{2}-\d{2}$/.test(defaultVal)) {
        defaultVal = defaultVal.replace('{taxFilingYear}', TAX_FILING_YEAR);
      }

      if (/^\d{4}-\d{2}-\d{2}$/.test(defaultVal)) {
        return { value: moment(defaultVal, 'YYYY-MM-DD').format('DD/MMM/YYYY') };
      }
    }

    return { value: null };
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR) {
    let defaultVal;
    if (question.collectionType === COLLECTION_TYPE__STATE_RESIDENCY) {
      defaultVal = { value: null, state: null, months: [] };
    } else {
      const defaultItem = question.question_meta.find((item) => item.default === 1);
      if (defaultItem) {
        defaultVal = { value: `${defaultItem.value}`, months: `${defaultItem.value}` === '1' ? _.range(12) : [] };
      } else {
        defaultVal = { value: null, months: [] };
      }
    }
    const json = safeJsonParse({ jsonStr: value, defaultVal });
    let newVal = {};
    if (question.collectionType === COLLECTION_TYPE__STATE_RESIDENCY) {
      newVal = {
        ...defaultVal,
        ..._.pick(json, [...(!isCalendarMonths(question) ? ['value'] : []), 'state', 'months'])
      };
    } else {
      newVal = {
        ...defaultVal,
        ..._.pick(json, [...(!isCalendarMonths(question) ? ['value'] : []), 'months'])
      };
    }
    return newVal;
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION) {
    const defaultVal = question.question_meta.filter((item) => item.default === 1).map((item) => `${item.value}`);
    if (_.isNil(value)) {
      return { value: defaultVal };
    } else {
      const json = safeJsonParse({ jsonStr: value, defaultVal });
      return { value: json };
    }
  } else if (question.question_type === CATEGORY_TYPE_TWELVE_A) {
    const defaultOption = question.question_meta.options.find((item) => item.default === 1);
    const defaultVal = { partOne: _.get(defaultOption, 'value', ''), partTwo: null };
    const json = { ...defaultVal, ...safeJsonParse({ jsonStr: value, defaultVal: {} }) };
    return { value: json };
  } else if (question.question_type === CATEGORY_TYPE_BOX_14) {
    return { value: safeJsonParse({ jsonStr: value, defaultVal: { code: '', amount: '' } }) };
  } else if (question.slug === SLUG__STATE_INCOME) {
    const jobTypes = {
      '1099 / business income': 'income-freelance',
      'W-2 income': 'income-w2',
      'Unemployment income': 'income-1099g',
      'Interest income': 'income-interest',
      'Dividend income': 'income-div',
      'Investment income': 'income-invest',
      'Retirement income': 'income-retirement'
    };
    const states = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    });
    const newValue = Object.keys(jobTypes).reduce((result, jobType) => {
      const jobs = getQueryResultsByEndpointAttribute({
        queryResults,
        collectionType: jobTypes[jobType],
        slug: `${jobTypes[jobType]}-started`
      });

      const jobTypeValues = jobs.reduce((jobValues, job) => {
        const slug1 = `state-income-${_.get(states, [0, 'answer', 'value'])}-${jobTypes[jobType]}-${job.coll_id}`;
        const slug2 = `state-income-${_.get(states, [1, 'answer', 'value'])}-${jobTypes[jobType]}-${job.coll_id}`;
        return {
          ...jobValues,
          [slug1]: { value: '' },
          [slug2]: { value: '' }
        };
      }, {});

      return {
        ...result,
        ...jobTypeValues
      };
    }, {});
    return { value: newValue };
  } else if (question.slug === SLUG__STATE_EXPENSES) {
    const states = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    });
    const expenseSplitJobs = getExpenseSplitJobs(queryResults);
    const newValue = expenseSplitJobs.reduce((result, job) => {
      const slug1 = `state-expenses-${states[0].answer.value}-income-freelance-${job.coll_id}`;
      const slug2 = `state-expenses-${states[1].answer.value}-income-freelance-${job.coll_id}`;
      return {
        ...result,
        [slug1]: { value: '' },
        [slug2]: { value: '' }
      };
    }, {});

    return { value: newValue };
  } else {
    return { value };
  }
};
