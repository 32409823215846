import React from 'react';
import _ from 'lodash';
import SectionedProgressBar from '@app/src/Components/Common/SectionedProgressBar/SectionedProgressBar';
import { useGetCurrentQuestionnaireQuestionQuery, useGetQuestionnaireProgressQuery } from '@app/src/api/taxDataApi';
import ArrowRightIcon from '@app/src/assets/arrow-right.svg?react';
import '@app/src/Components/TaxFlow/Common/TaxFlowQuestionnaireProgressBar.scss';

function TaxFlowQuestionnaireProgressBar() {
  const { data: currentQuestionnaireQuestion, isLoading: isCurrentQuestionnaireQuestionLoading } =
    useGetCurrentQuestionnaireQuestionQuery();
  const { data: progress, isLoading: progressLoading } = useGetQuestionnaireProgressQuery();
  if (progressLoading || isCurrentQuestionnaireQuestionLoading) {
    return null;
  }
  const { totalSections, passedSections } = progress;
  if (_.isNil(totalSections) || _.isNil(passedSections) || progressLoading) {
    return null;
  }

  return (
    <>
      <SectionedProgressBar totalSections={totalSections} currentSection={passedSections + 1} className='mb-16' />
      <div className='questionnaire-progress-bar-breadcrumb'>
        <div className='questionnaire-progress-bar-breadcrumb-parent'>Questionnaire</div>
        <ArrowRightIcon width={16} height={16} />
        <div className='questionnaire-progress-bar-breadcrumb-child'>
          {currentQuestionnaireQuestion.type === 'form-review' ? 'Review forms' : 'Follow up questions'}
        </div>
      </div>
    </>
  );
}

export default TaxFlowQuestionnaireProgressBar;
