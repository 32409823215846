import _ from 'lodash';
import {
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__HOME,
  HOME_PATH_COMPONENTS,
  HOME_ENDPOINT_ATTRIBUTES,
  HOME_SLUGS
} from '@app/src/taxflow/sections/home/homeConstants';
import {
  COLLECTION_TYPE__HOME_ADDRESS,
  SLUG__HOME_ADDRESS_STATE,
  SLUG__HOME_ADDRESS_STATE_MULTISTATE,
  SLUG__HOME_ADDRESS_STREET,
  SLUG__HOME_ADDRESS_STREET_MULTISTATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextPathComponentMap = () => {
  return {
    [HOME_SLUGS.EXPENSES_MORTGAGE_INTEREST]: {
      nextPathComponent: HOME_PATH_COMPONENTS.DEPRECIATION,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.TYPE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.ACQUIRED,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.EXPENSES_RENT]: {
      nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.ACQUIRED]: {
      nextPathComponent: HOME_PATH_COMPONENTS.VALUE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.VALUE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.PAST_DEPRECIATION,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.DONE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.YEARS_DEPRECIATION]: {
      nextPathComponent: HOME_PATH_COMPONENTS.PRIOR_DEPRECIATION,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.PRIOR_DEPRECIATION]: {
      nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.OFFICE_SIZE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.NAV_START,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.ADDRESS]: {
      nextPathComponent: HOME_PATH_COMPONENTS.OPTIONS,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.EXIT_BOUNCE]: {
      questionnaireEarlyExit: true
    }
  };
};

export const getOptionPathComponentMap = () => {
  return {
    [HOME_SLUGS.OPTIONS]: {
      rent: {
        nextPathComponent: HOME_PATH_COMPONENTS.DATES,
        questionnaireEarlyExit: false
      },
      own_home: {
        nextPathComponent: HOME_PATH_COMPONENTS.DATES,
        questionnaireEarlyExit: false
      },
      free_housing: {
        nextPathComponent: HOME_PATH_COMPONENTS.NAV_START,
        questionnaireEarlyExit: false
      }
    },
    [HOME_SLUGS.DEPRECIATION]: {
      0: {
        nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: HOME_PATH_COMPONENTS.TYPE,
        questionnaireEarlyExit: false
      }
    },
    [HOME_SLUGS.PAST_DEPRECIATION]: {
      0: {
        nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: HOME_PATH_COMPONENTS.YEARS_DEPRECIATION,
        questionnaireEarlyExit: false
      }
    },
    [HOME_SLUGS.NAV_START]: {
      0: { nextPathComponent: HOME_PATH_COMPONENTS.EXIT_BOUNCE, questionnaireEarlyExit: false }
    },
    [HOME_SLUGS.PRESELECT_ADDRESS]: {
      0: { nextPathComponent: HOME_PATH_COMPONENTS.ADDRESS, questionnaireEarlyExit: false }
    }
  };
};

export const getNextPathComponent = ({ question, collectionId, queryResults }) => {
  if (question.slug === HOME_SLUGS.DATES) {
    const queryResult = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__HOME,
      collectionId,
      slug: HOME_ENDPOINT_ATTRIBUTES.OPTIONS
    });
    if (_.get(queryResult, ['answer', 'value']) === 'rent') {
      return { nextPathComponent: HOME_PATH_COMPONENTS.EXPENSES_RENT, questionnaireEarlyExit: false };
    } else if (_.get(queryResult, ['answer', 'value']) === 'own_home') {
      return { nextPathComponent: HOME_PATH_COMPONENTS.EXPENSES_MORTGAGE_INTEREST, questionnaireEarlyExit: false };
    } else {
      return { nextPathComponent: HOME_PATH_COMPONENTS.EXIT_BOUNCE, questionnaireEarlyExit: false };
    }
  } else if (question.slug === HOME_SLUGS.PRESELECT_ADDRESS) {
    return { nextPathComponent: HOME_PATH_COMPONENTS.OPTIONS, questionnaireEarlyExit: false };
  } else if (question.slug === HOME_SLUGS.NAV_START) {
    return { nextPathComponent: HOME_PATH_COMPONENTS.EXPENSES_TOTAL, questionnaireEarlyExit: false };
  }
  return { nextPathComponent: null };
};

export const getSlugMap = () => {
  return {
    [HOME_PATH_COMPONENTS.ACQUIRED]: HOME_SLUGS.ACQUIRED,
    [HOME_PATH_COMPONENTS.ADDRESS]: HOME_SLUGS.ADDRESS,
    [HOME_PATH_COMPONENTS.DEPRECIATION]: HOME_SLUGS.DEPRECIATION,
    [HOME_PATH_COMPONENTS.PRIOR_DEPRECIATION]: HOME_SLUGS.PRIOR_DEPRECIATION,
    [HOME_PATH_COMPONENTS.YEARS_DEPRECIATION]: HOME_SLUGS.YEARS_DEPRECIATION,
    [HOME_PATH_COMPONENTS.DATES]: HOME_SLUGS.DATES,
    [HOME_PATH_COMPONENTS.EXIT_BOUNCE]: HOME_SLUGS.EXIT_BOUNCE,
    [HOME_PATH_COMPONENTS.EXPENSES_MORTGAGE_INTEREST]: HOME_SLUGS.EXPENSES_MORTGAGE_INTEREST,
    [HOME_PATH_COMPONENTS.EXPENSES_RENT]: HOME_SLUGS.EXPENSES_RENT,
    [HOME_PATH_COMPONENTS.EXPENSES_TOTAL]: HOME_PATH_COMPONENTS.EXPENSES_TOTAL,
    [HOME_PATH_COMPONENTS.NAV_START]: HOME_SLUGS.NAV_START,
    [HOME_PATH_COMPONENTS.OFFICE_SIZE]: HOME_SLUGS.OFFICE_SIZE,
    [HOME_PATH_COMPONENTS.OPTIONS]: HOME_SLUGS.OPTIONS,
    [HOME_PATH_COMPONENTS.PAST_DEPRECIATION]: HOME_SLUGS.PAST_DEPRECIATION,
    [HOME_PATH_COMPONENTS.PRESELECT_ADDRESS]: HOME_SLUGS.PRESELECT_ADDRESS,
    [HOME_PATH_COMPONENTS.TYPE]: HOME_SLUGS.TYPE,
    [HOME_PATH_COMPONENTS.VALUE]: HOME_SLUGS.VALUE
  };
};

export const getQuestionQueries = ({ question, collectionId }) => {
  let queries = [
    {
      coll_type: COLLECTION_TYPE__HOME,
      coll_id: collectionId,
      slug: HOME_ENDPOINT_ATTRIBUTES.ADDRESS_STREET
    }
  ];

  if (question.slug === HOME_SLUGS.DATES) {
    queries = queries.concat([
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.OPTIONS
      }
    ]);
  } else if (question.slug === HOME_SLUGS.PRIOR_DEPRECIATION) {
    queries = queries.concat([
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_TOTAL
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_WORK_SPACE
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_PURCHASE_PRICE
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_IMPROVEMENTS
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_LAND
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.YEARS_DEPRECIATION
      }
    ]);
  } else if (question.slug === HOME_SLUGS.EXPENSES_RENT) {
    queries = queries.concat([
      { coll_type: COLLECTION_TYPE__HOME, coll_id: collectionId, slug: HOME_ENDPOINT_ATTRIBUTES.DATES }
    ]);
  } else if (question.slug === HOME_SLUGS.EXPENSES_MORTGAGE_INTEREST) {
    queries = queries.concat([
      { coll_type: COLLECTION_TYPE__HOME, coll_id: collectionId, slug: HOME_ENDPOINT_ATTRIBUTES.DATES },
      {
        coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
      }
    ]);
  } else if (question.slug === HOME_SLUGS.PRESELECT_ADDRESS) {
    queries = queries.concat([
      { coll_type: COLLECTION_TYPE__HOME_ADDRESS, coll_id: DEFAULT_COLLECTION_ID, slug: SLUG__HOME_ADDRESS_STATE },
      { coll_type: COLLECTION_TYPE__HOME_ADDRESS, coll_id: DEFAULT_COLLECTION_ID, slug: SLUG__HOME_ADDRESS_STREET },
      {
        coll_type: COLLECTION_TYPE__HOME_ADDRESS,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: SLUG__HOME_ADDRESS_STATE_MULTISTATE
      },
      {
        coll_type: COLLECTION_TYPE__HOME_ADDRESS,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: SLUG__HOME_ADDRESS_STREET_MULTISTATE
      }
    ]);
  }
  return queries;
};

// This is used for the questionnaire to set the next collectionId. If the next path component
// is the NAV_START, always set the collectionId to 0
export const getNextQuery = ({ nextPathComponent }) => {
  if (nextPathComponent === HOME_PATH_COMPONENTS.NAV_START) {
    return {
      collectionId: '0'
    };
  }
  return {};
};
