import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InfoDrawer from '@app/src/Components/HolisticOnboarding/components/InfoDrawer/InfoDrawer';
import LearnMoreButton from '@app/src/Components/HolisticOnboarding/components/LearnMoreButton/LearnMoreButton';
import OnboardingFooter from '@app/src/Components/HolisticOnboarding/components/OnboardingFooter';
import OnboardingSnackbar from '@app/src/Components/HolisticOnboarding/components/OnboardingSnackbar';
import PageTransition from '@app/src/Components/HolisticOnboarding/components/PageTransition';
import ProgressBarHeader from '@app/src/Components/HolisticOnboarding/components/ProgressBarHeader/ProgressBarHeader';
import QuestionBody from '@app/src/Components/HolisticOnboarding/components/QuestionBody';
import QuestionHeader from '@app/src/Components/HolisticOnboarding/components/QuestionHeader';
import {
  initialLoadSelector,
  isPreviousSelector,
  learnMoreSelector,
  progressSelector
} from '@app/src/selectors/holisticOnboardingSelectors';

const HolisticOnboardingLayout = ({ children, loading, learnMoreInfo }) => {
  const { pathname } = useLocation();

  return (
    <div className='h-onboarding-container-outer'>
      <div className='h-onboarding-container-inner'>
        {!['/h/onboarding/app-download', '/h/onboarding/loading-initial'].includes(pathname) && (
          <ProgressBarHeader pathname={pathname} loading={loading} />
        )}
        <PageTransition loading={loading}>
          <div className='h-onboarding-content'>
            <QuestionHeader />
            <LearnMoreButton learnMoreInfo={learnMoreInfo} />
            <QuestionBody>{children}</QuestionBody>
          </div>
        </PageTransition>
        <OnboardingSnackbar />
      </div>
      <OnboardingFooter pathname={pathname} />
      <InfoDrawer learnMoreInfo={learnMoreInfo} />
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  isPrevious: isPreviousSelector(state, props),
  learnMoreInfo: learnMoreSelector(state, props),
  loading: initialLoadSelector(state),
  progress: progressSelector(state)
});

const ConnectedHolisticOnboardingLayout = connect(mapStateToProps)(HolisticOnboardingLayout);

export default ConnectedHolisticOnboardingLayout;
