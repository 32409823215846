import React, { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { DocumentDownload } from 'iconsax-react';
import { useSignedUrl } from '@app/src/services/assistantService';
import { colorFontDark } from '@app/src/theme';
import '@app/src/Components/Assistant/AssistantMessageAttachments.scss';

const AssistantPdfFileAttachment = ({ attachment }) => {
  const { signedUrl } = useSignedUrl(attachment.key);

  if (!signedUrl) {
    return <Skeleton variant='rectangular' width={210} height={48} />;
  }

  return (
    <div className='assistant-pdf-link-container'>
      <a href={signedUrl} target='_blank' rel='noreferrer' className='assistant-pdf-link'>
        <div className='assistant-pdf-link-icon-container'>
          <DocumentDownload size={28} color={colorFontDark} />
        </div>
        <div className='assistant-pdf-link-description-container'>Download {attachment.filename}</div>
      </a>
    </div>
  );
};

const MemoizedAssistantPdfFileAttachment = memo(
  AssistantPdfFileAttachment,
  (prevProps, nextProps) =>
    prevProps.attachment.key === nextProps.attachment.key &&
    prevProps.attachment.filename === nextProps.attachment.filename
);

export default MemoizedAssistantPdfFileAttachment;
