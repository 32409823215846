import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import TaxFlowLearnMoreElement from '@app/src/Components/TaxFlow/Common/TaxFlowLearnMoreElement';
import TaxFlowQuestionnaireProgressBar from '@app/src/Components/TaxFlow/Common/TaxFlowQuestionnaireProgressBar';
import getImageUrl from '@app/src/utils/getImageUrl';

const TaxFlowHead = ({
  question,
  learnMoreTitle,
  title,
  summary,
  examples,
  loadingScreen,
  isQuestionnaireFlow,
  renderWideDesktopContent,
  ...props
}) => {
  const renderLearnMore = () => {
    const accordionInfo = _.get(props, ['learnMoreInfo', 'fields', 'accordionInfo']);
    const contentType = _.get(props, ['learnMoreInfo', 'fields', 'contentType']);
    const disableChatMessaging = _.get(props, ['learnMoreInfo', 'fields', 'disableChatMessaging']);
    const type = _.get(props, ['learnMoreInfo', 'fields', 'type']);
    if (type === 'footer') {
      return null;
    }

    const slug = _.get(props, ['learnMoreInfo', 'fields', 'slug']);
    const title = _.get(props, ['learnMoreInfo', 'fields', 'title']);
    const description = _.get(props, ['learnMoreInfo', 'fields', 'description']);
    const url = _.get(props, ['learnMoreInfo', 'fields', 'url']);

    return (
      <TaxFlowLearnMoreElement
        question={question}
        slug={slug}
        type={type}
        buttonText={learnMoreTitle}
        modalTitle={title}
        description={description}
        url={url}
        accordionInfo={accordionInfo}
        contentType={contentType}
        disableChatMessaging={disableChatMessaging}
      />
    );
  };

  const renderImage = ({ fileName, className, altText }) => {
    try {
      return <img className={className} src={getImageUrl(fileName)} alt={altText} />;
    } catch (e) {
      return null;
    }
  };

  const submitHeaderImg = question?.question_meta?.headerImg;
  return (
    <div
      className={classNames('steps-head', {
        'steps-head-loading-screen': loadingScreen,
        'hidden-title': _.isEmpty(title),
        'steps-head-large': renderWideDesktopContent
      })}
    >
      {isQuestionnaireFlow && <TaxFlowQuestionnaireProgressBar question={question} />}
      {submitHeaderImg &&
        renderImage({
          fileName: submitHeaderImg,
          className: 'submit-header-img',
          altText: 'submit header'
        })}

      <div className='taxflow-header'>
        <div>
          <h1>{title}</h1>
        </div>
      </div>
      <div
        className={classNames({
          'step-description': !_.isEmpty(summary)
        })}
      >
        {!_.isEmpty(summary) && <ReactMarkdown className='step-summary' source={summary} linkTarget='_blank' />}
        {examples && examples.length > 0 && (
          <ul className='list-type'>
            {examples.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>
        )}
        {renderLearnMore()}
      </div>
    </div>
  );
};

export default TaxFlowHead;
