/**
 * Await till a rtk-query initiate function returns data respective of a fulfilled request (ie the request has completed).
 * If a request has been issued prior and is in-flight (say a component hook triggered such just prior to dispatch), initiate calls may return pending request data.
 * This seems incorrect and might be an artifact of our redux configuration - this bug is reported in the following issue - https://github.com/reduxjs/redux-toolkit/issues/1915
 */
export const getFulfilledRequestData = async ({ initiateFunction, dispatch }) => {
  const data = await awaitFulfilled(() => dispatch(initiateFunction()));
  return data;
};

const awaitFulfilled = async (getDispatch, totalWaitTimeMs = 0) => {
  const waitTimeMs = 10;
  const timeoutMs = 30_000;
  if (totalWaitTimeMs > timeoutMs) {
    throw Error('Request timeout exceeded');
  }
  const dispatchData = getDispatch();
  const { status, data } = await dispatchData;
  if (status === 'pending') {
    await sleep(waitTimeMs);
    const data = await awaitFulfilled(getDispatch, totalWaitTimeMs + waitTimeMs);
    return data;
  }
  if (totalWaitTimeMs === 0) {
    // Prevent unnecessary background updates upon invalidation
    // If we don't do this, calls might be made even if no hooks are active or initiate calls are in progress
    dispatchData.unsubscribe();
  }
  return data;
};

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
