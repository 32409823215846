import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Url_ONBOARDING_INCOME_TYPE } from '@app/src/constants/onboardingConstants';
import { trackActivity } from '@app/src/services/analyticsService';
import getImageUrl from '@app/src/utils/getImageUrl';
import '@app/src/Components/Onboarding/Question/OnboardingListItem.scss';

const OnboardingListItem = ({ className, item, currentQuestion, currentAnswer, multiple, onChange }) => {
  const checked = multiple
    ? (_.get(currentAnswer, 'value') || []).includes(item.value)
    : `${item.value}` === `${_.get(currentAnswer, 'value')}`;
  const isIncomeType = _.get(currentQuestion, 'slug') === Url_ONBOARDING_INCOME_TYPE;

  const onChangeImpl = () => {
    if (isIncomeType) {
      trackActivity('onboarding: income type selected', {
        income_type: item.value
      });
    }

    if (multiple) {
      onChange(_.xor(_.get(currentAnswer, 'value') || [], [`${item.value}`]));
    } else {
      onChange(`${item.value}`);
    }
  };

  return (
    <div
      className={classNames(
        'onboarding-list-item',
        {
          'onboarding-list-item-checked': checked,
          'onboarding-list-item-income-type': isIncomeType
        },
        className
      )}
    >
      <div className='onboarding-list-item-content' onClick={onChangeImpl} role='button'>
        {isIncomeType && (
          <div className='onboarding-list-item-icon-container'>
            <img className='onboarding-list-item-icon' src={getImageUrl(`${item.image}.svg`)} alt={`${item.image}`} />
          </div>
        )}
        <div
          className={classNames('onboarding-list-item-text', {
            'onboarding-list-item-text-checked': checked,
            'onboarding-list-item-text-income-type': isIncomeType
          })}
        >
          <div
            className={classNames('onboarding-list-item-title', {
              'onboarding-list-item-title-income-type': isIncomeType
            })}
          >
            {item.text}
          </div>
          {isIncomeType && <div className='onboarding-list-item-subtitle'>{item.sub_text}</div>}
        </div>
      </div>
    </div>
  );
};

export default OnboardingListItem;
