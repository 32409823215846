import {
  GET_WORK_INFO,
  LOAD_WORK_INFO,
  UPDATE_WORK_RES_MSG,
  UPDATE_WORK_CATEGORIES,
  SET_CAMPAIGN,
  SET_BUILD
} from '@app/src/actions/types';

const initialState = {
  workDetails: {},
  loading: false,
  successMsg: '',
  errorMsg: '',
  workCategories: {
    byId: {},
    allIds: []
  },
  campaign: {},
  features: [],
  featuresLoading: true,
  build: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_WORK_INFO:
      return {
        ...state,
        loading: action.payload
      };
    case GET_WORK_INFO:
      return {
        ...state,
        workDetails: action.payload,
        loading: false
      };
    case UPDATE_WORK_RES_MSG:
      return {
        ...state,
        successMsg: action.payload.success,
        errorMsg: action.payload.error
      };
    case UPDATE_WORK_CATEGORIES:
      return {
        ...state,
        workCategories: action.payload
      };
    case SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload
      };
    case SET_BUILD:
      return {
        ...state,
        build: action.payload
      };
    default:
      return state;
  }
}
