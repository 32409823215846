import _ from 'lodash';
import {
  CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_CALENDAR
} from '@app/src/constants/constants';

const createAnswerEventProperties = ({ currentQuestion, currentAnswer }) => {
  if (
    currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY ||
    currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION
  ) {
    return { answer: (_.get(currentAnswer, ['value']) || []).join(';') };
  } else if (
    currentQuestion.question_type === CATEGORY_TYPE_OPTIONS ||
    currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR
  ) {
    return { answer: _.get(currentAnswer, ['value']) };
  } else if (currentQuestion.slug === 'onboarding-duration') {
    return { answer: _.get(currentAnswer, ['value', 'onboarding-duration-date', 'value']) };
  } else {
    return {};
  }
};

export default createAnswerEventProperties;
