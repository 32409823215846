import _ from 'lodash';
import { createSelector } from 'reselect';
import { isEmail } from '@app/src/global/Helpers';
import { bankListSelector } from '@app/src/selectors/bankSelectors';

// Holistic onboarding selectors
export const contentSelector = (state) => _.get(state, ['holisticOnboarding', 'content']);
export const dependentsSelector = (state) => _.get(state, ['holisticOnboarding', 'dependents']);
export const drawerOpenSelector = (state) => _.get(state, ['holisticOnboarding', 'drawerOpen']);
export const driveSelector = (state) => _.get(state, ['holisticOnboarding', 'drive']);
export const emailSelector = (state) => _.get(state, ['holisticOnboarding', 'email']);
export const errorSelector = (state) => _.get(state, ['holisticOnboarding', 'error']);
export const firstNameSelector = (state) => _.get(state, ['holisticOnboarding', 'firstname']);
export const goalsSelector = (state) => _.get(state, ['holisticOnboarding', 'goals']);
export const homeOwnerSelector = (state) => _.get(state, ['holisticOnboarding', 'homeOwner']);
export const homeSelector = (state) => _.get(state, ['holisticOnboarding', 'home']);
export const income1099Selector = (state) => _.get(state, ['holisticOnboarding', 'income1099']);
export const incomeW2Selector = (state) => _.get(state, ['holisticOnboarding', 'incomeW2']);
export const incomeTypeSelector = (state) => _.get(state, ['holisticOnboarding', 'incomeType']);
export const initialLoadSelector = (state) => _.get(state, ['holisticOnboarding', 'initialLoad']);
export const jobCategoriesSelector = (state) => _.get(state, ['holisticOnboarding', 'jobCategories']);
export const jobDurationSelector = (state) => _.get(state, ['holisticOnboarding', 'jobDuration']);
export const jobsSelector = (state) => _.get(state, ['holisticOnboarding', 'jobs']);
export const lastNameSelector = (state) => _.get(state, ['holisticOnboarding', 'lastname']);
export const loadingSelector = (state) => _.get(state, ['holisticOnboarding', 'loading']);
export const marriedSelector = (state) => _.get(state, ['holisticOnboarding', 'married']);
export const mealSelector = (state) => _.get(state, ['holisticOnboarding', 'meal']);
export const partner1099IncomeSelector = (state) => _.get(state, ['holisticOnboarding', 'partnerIncome1099']);
export const partnerW2IncomeSelector = (state) => _.get(state, ['holisticOnboarding', 'partnerIncomeW2']);
export const phoneSelector = (state) => _.get(state, ['holisticOnboarding', 'phone']);
export const progressSelector = (state) => _.get(state, ['holisticOnboarding', 'progress']);
export const rentSelector = (state) => _.get(state, ['holisticOnboarding', 'rent']);
export const stateSelector = (state) => _.get(state, ['holisticOnboarding', 'state']);
export const studentLoansSelector = (state) => _.get(state, ['holisticOnboarding', 'studentLoans']);
export const taxEstimateSelector = (state) => _.get(state, ['holisticOnboarding', 'taxSavings']);
export const travelSelector = (state) => _.get(state, ['holisticOnboarding', 'travel']);

export const responseTextSelector = createSelector(
  [contentSelector, (_, { pathname }) => pathname],
  (content, pathname) => _.get(content, `${pathname}.responseText`)
);

export const jobWriteOffsSelector = createSelector([jobCategoriesSelector], (jobCategories) => {
  const onboardingState = JSON.parse(localStorage.getItem('onboardingState'));

  const firstJob = _.get(onboardingState, 'jobs[0].name');

  const userJob = jobCategories.find((job) => job.name === firstJob);
  const writeOffs = _.get(userJob, 'writeOffs', []);

  return writeOffs.map((writeOff) => _.get(writeOff, 'fields.displayName', ''));
});

// Validation selectors
const driveValidationSelector = createSelector(driveSelector, (drive) => drive !== null);
const emailValidationSelector = createSelector(emailSelector, (email) => isEmail(email));
const firstNameValidationSelector = createSelector(firstNameSelector, (firstName) => firstName.length > 0);
const goalsValidationSelector = createSelector(goalsSelector, (goals) => goals.length > 0);
const homeValidationSelector = createSelector(homeSelector, (home) => home !== null);
const jobValidationSelector = createSelector(jobsSelector, (jobs) => jobs?.length > 0);
const lastNameValidationSelector = createSelector(lastNameSelector, (lastName) => lastName.length > 0);
const mealValidationSelector = createSelector(mealSelector, (meal) => meal !== null);
const phoneValidationSelector = createSelector(phoneSelector, (phone) => phone.length === 10);
const jobDurationValidationSelector = createSelector(
  jobDurationSelector,
  (jobDuration) => jobDuration.year && jobDuration.month
);

const stateValidationSelector = createSelector(stateSelector, (state) => state !== '');
const travelValidationSelector = createSelector(travelSelector, (travel) => travel !== null);

export const isPreviousSelector = createSelector(
  [contentSelector, (_, { location }) => location.pathname],
  (content, pathname) => _.get(content, `${pathname}.isEntryPoint`, true) === false
);

export const learnMoreSelector = createSelector(
  [contentSelector, (_, { location }) => location.pathname],
  (content, pathname) => {
    const learnMoreInfo = _.get(content, `${pathname}.learnMoreInfo`);

    return _.get(learnMoreInfo, 'fields', null);
  }
);

export const skippableSelector = createSelector(
  [(_, { pathname }) => pathname],
  (pathname) => pathname === '/h/onboarding/link'
);

export const validationSelector = createSelector(
  [
    firstNameValidationSelector,
    lastNameValidationSelector,
    emailValidationSelector,
    goalsValidationSelector,
    phoneValidationSelector,
    stateValidationSelector,
    jobValidationSelector,
    jobDurationValidationSelector,
    driveSelector,
    driveValidationSelector,
    homeSelector,
    homeValidationSelector,
    mealSelector,
    mealValidationSelector,
    travelSelector,
    travelValidationSelector,
    income1099Selector,
    incomeW2Selector,
    partner1099IncomeSelector,
    partnerW2IncomeSelector,
    bankListSelector,
    (_, { pathname }) => pathname
  ],
  (
    firstName,
    lastName,
    email,
    goals,
    phone,
    state,
    job,
    jobDuration,
    drive,
    driveValidation,
    home,
    homeValidation,
    meal,
    mealValidation,
    travel,
    travelValidation,
    income1099,
    incomeW2,
    partner1099Income,
    partnerW2Income,
    banklist,
    pathname
  ) => {
    const validators = {
      '/h/onboarding/goals': goals,
      '/h/onboarding/signup': firstName && lastName && email,
      '/h/onboarding/phone': phone,
      '/h/onboarding/state': state,
      '/h/onboarding/job-select': job,
      '/h/onboarding/job-duration': jobDuration,
      '/h/onboarding/drive': drive && driveValidation,
      '/h/onboarding/home': home && homeValidation,
      '/h/onboarding/link': banklist?.length > 0,
      '/h/onboarding/meal': meal && mealValidation,
      '/h/onboarding/travel': travel && travelValidation,
      '/h/onboarding/income-estimate': income1099 || incomeW2 || partner1099Income || partnerW2Income,
      '/h/onboarding/situations': true,
      '/h/onboarding/deduction-estimate': true,
      '/h/onboarding/deduction-estimate-w2': true,
      '/h/onboarding/deductions-list': true,
      '/h/onboarding/deductions': true,
      '/h/onboarding/scan': true
    };

    return !!validators[pathname];
  }
);

export const currentProgressSelector = createSelector(
  [contentSelector, (_, { pathname }) => pathname],
  (content, pathname) => {
    if (_.isEmpty(content)) return { totalSections: 10, progress: 0 };

    const activePaths = Object.keys(content);
    const entryPoint = activePaths.find((path) => _.get(content, `${path}.isEntryPoint`, false));
    const order = [entryPoint];

    let nextUrl = _.get(content, `${entryPoint}.nextUrl`);

    while (nextUrl) {
      order.push(nextUrl);
      nextUrl = _.get(content, `${nextUrl}.nextUrl`);
    }

    const formattedPathname = pathname.replace('-w2', '');

    const totalSections = order.length;
    const progress = order.indexOf(formattedPathname) + 1;

    return { totalSections, progress };
  }
);
