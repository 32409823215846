import _ from 'lodash';
import axios from 'axios';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { serverUrl } from '@app/src/global/Environment';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const getErrors = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}api/tax-validation/validate`);
    const errors = _.get(res, ['data', 'data', 'errors'], []);
    dispatch(setErrors(errors));
  } catch (e) {
    notify('Something went wrong. Please try again.');
    defaultCaptureException(e);
  }
};
