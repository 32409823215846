import _ from 'lodash';
import { createSelector } from 'reselect';

export const isEmailUniqueSelector = (state) => _.get(state, ['auth', 'isEmailUnique']);
export const isPhoneUniqueSelector = (state) => _.get(state, ['auth', 'isPhoneUnique']);
export const isAuthenticatedSelector = (state) => _.get(state, ['auth', 'isAuthenticated']);
export const userSettingsSelector = (state) => _.get(state, ['auth', 'settings'], {});
export const notificationPlatformSelector = (state) => _.get(state, ['auth', 'settings', 'platform']);

export const messagingPlatformSelector = createSelector([userSettingsSelector], (settings) => settings.platform);
