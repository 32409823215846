import { TAX_VALIDATION__SET_ERRORS } from '@app/src/constants/taxValidationConstants';

const initialState = {
  errors: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TAX_VALIDATION__SET_ERRORS: {
      return {
        ...state,
        errors: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
