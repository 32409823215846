import React, { Component } from 'react';
import _ from 'lodash';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationPhoneField from '@app/src/Components/TaxValidation/TaxValidationPhoneField';
import TaxValidationWarning from '@app/src/Components/TaxValidation/TaxValidationWarning';

class TaxFlowFormSSNOrEINItem extends Component {
  onChange = (value) => {
    value = value.replace(/\D/g, '');
    this.props.onChange({ slug: this.props.question.slug, value, endpoint_attr: this.props.question.endpoint_attr });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;
    return (
      <div>
        <div className='form-group'>
          <TaxValidationLabel
            question={question}
            answer={answer}
            title={this.props.replaceStrings(question.title)}
            htmlFor={question.slug}
          />
          <div className='tax-flow-input-wrap'>
            <TaxValidationPhoneField
              question={question}
              answer={answer}
              mask='#########'
              name={question.slug}
              id={question.slug}
              className='form-control'
              placeholder={question.question_meta.default}
              required={question.required}
              onFocus={this.onFocus}
              onChange={(e) => this.onChange(e.target.value)}
              value={answer && !_.isNil(answer.value) ? answer.value : ''}
            />
            <TaxFlowInfoButton currentQuestion={question} />
          </div>
          <TaxValidationWarning question={question} answer={answer} />
        </div>
      </div>
    );
  }
}

export default TaxFlowFormSSNOrEINItem;
