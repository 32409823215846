// path components
export const PATH_COMPONENT__INCOME_INVEST_FORM_UPLOAD = 'income-invest-form-upload';
export const PATH_COMPONENT__INCOME_INVEST_PREFILL_LOADING = 'income-invest-prefill-loading';
export const PATH_COMPONENT__INCOME_INVEST_INFO = 'income-invest-info';
export const PATH_COMPONENT__INCOME_INVEST_MORE_INFO = 'income-invest-more-info';
export const PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE = 'income-invest-exit-bounce';
export const PATH_COMPONENT__INCOME_DIV_FORM_UPLOAD = 'income-div-form-upload';
export const PATH_COMPONENT__INCOME_DIV_PREFILL_LOADING = 'income-div-prefill-loading';
export const PATH_COMPONENT__INCOME_DIV_INFO = 'income-div-info';
export const PATH_COMPONENT__INCOME_DIV_EXIT_BOUNCE = 'income-div-exit-bounce';
export const PATH_COMPONENT__INCOME_INTEREST_FORM_UPLOAD = 'income-interest-form-upload';
export const PATH_COMPONENT__INCOME_INTEREST_INFO = 'income-interest-info';
export const PATH_COMPONENT__INCOME_INTEREST_PREFILL_LOADING = 'income-interest-prefill-loading';
export const PATH_COMPONENT__INCOME_INTEREST_EXIT_BOUNCE = 'income-interest-exit-bounce';
export const PATH_COMPONENT__INCOME_UNEMPLOYMENT_FORM_UPLOAD = 'income-unemployment-form-upload';
export const PATH_COMPONENT__INCOME_UNEMPLOYMENT_PREFILL_LOADING = 'income-unemployment-prefill-loading';
export const PATH_COMPONENT__INCOME_UNEMPLOYMENT_INFO = 'income-unemployment-info';
export const PATH_COMPONENT__INCOME_UNEMPLOYMENT_EXIT_BOUNCE = 'income-unemployment-exit-bounce';
export const PATH_COMPONENT__INCOME_W2_FORM_UPLOAD = 'income-w2-form-upload';
export const PATH_COMPONENT__INCOME_W2_PREFILL_LOADING = 'income-w2-prefill-loading';
export const PATH_COMPONENT__INCOME_W2_INFO = 'income-w2-info';
export const PATH_COMPONENT__INCOME_W2_EXIT_BOUNCE = 'income-w2-exit-bounce';
export const PATH_COMPONENT__INCOME_W2G_INFO = 'income-w2g-info';
export const PATH_COMPONENT__INCOME_W2G_EXIT_BOUNCE = 'income-w2g-exit-bounce';
export const PATH_COMPONENT__INCOME_FREELANCE_JOB = 'income-freelance-job';
export const PATH_COMPONENT__INCOME_FREELANCE_1099_TYPE = 'income-freelance-1099-type';
export const PATH_COMPONENT__INCOME_FREELANCE_AGI = 'income-freelance-agi';
export const PATH_COMPONENT__INCOME_FREELANCE_1099K_INFO = 'income-freelance-1099k-info';
export const PATH_COMPONENT__INCOME_FREELANCE_1099_NEC = 'income-freelance-1099-nec';
export const PATH_COMPONENT__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD = 'income-freelance-1099-nec-form-upload';
export const PATH_COMPONENT__INCOME_FREELANCE_1099_NEC_PREFILL_LOADING = 'income-freelance-1099-nec-prefill-loading';
export const PATH_COMPONENT__INCOME_FREELANCE_1099_MISC = 'income-freelance-1099-misc';
export const PATH_COMPONENT__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD = 'income-freelance-1099-misc-form-upload';
export const PATH_COMPONENT__INCOME_FREELANCE_1099_MISC_PREFILL_LOADING = 'income-freelance-1099-misc-prefill-loading';
export const PATH_COMPONENT__INCOME_FREELANCE_BUSINESS_CODE_INFO = 'income-freelance-business-code-info';
export const PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE = 'income-freelance-exit-bounce';
export const PATH_COMPONENT__INCOME_RETIREMENT_TYPE = 'income-retirement-type';
export const PATH_COMPONENT__INCOME_RETIREMENT_PENSION_INFO = 'income-retirement-pension-info';
export const PATH_COMPONENT__INCOME_RETIREMENT_PENSION_FORM_UPLOAD = 'income-retirement-pension-form-upload';
export const PATH_COMPONENT__INCOME_RETIREMENT_PENSION_PREFILL_LOADING = 'income-retirement-pension-prefill-loading';
export const PATH_COMPONENT__INCOME_RETIREMENT_SSA_INFO = 'income-retirement-ssa-info';
export const PATH_COMPONENT__INCOME_RETIREMENT_SSA_FORM_UPLOAD = 'income-retirement-ssa-form-upload';
export const PATH_COMPONENT__INCOME_RETIREMENT_SSA_PREFILL_LOADING = 'income-retirement-ssa-prefill-loading';
export const PATH_COMPONENT__INCOME_RETIREMENT_EXIT_BOUNCE = 'income-retirement-exit-bounce';
export const PATH_COMPONENT__INCOME_FREELANCE_1099K_EXPENSES_INFO = 'income-freelance-1099k-expenses-info';
export const PATH_COMPONENT__INCOME_PERSONAL_ITEMS = 'income-personal-items';
export const PATH_COMPONENT__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE = 'income-personal-items-exit-bounce';

// slugs
export const SLUG__INCOME_INVEST_FORM_UPLOAD = 'income-invest-form-upload';
export const SLUG__INCOME_INVEST_PREFILL_LOADING = 'income-invest-prefill-loading';
export const SLUG__INCOME_INVEST_INFO = 'income-invest-info';
export const SLUG__INCOME_INVEST_MORE_INFO = 'income-invest-more-info';
export const SLUG__INCOME_INVEST_CATEGORY = 'income-invest-category';
export const SLUG__INCOME_INVEST_SHORT_TERM_HEADER = 'income-invest-short-term-header';
export const SLUG__INCOME_INVEST_LONG_TERM_HEADER = 'income-invest-long-term-header';
export const SLUG__INCOME_INVEST_MANUAL_UPLOAD = 'income-invest-manual-upload';
export const SLUG__INCOME_INVEST_EARNER = 'income-invest-earner';
export const SLUG__INCOME_INVEST_UNIFICATION = 'income-invest-unification';
export const SLUG__INCOME_INVEST_EXIT_BOUNCE = 'income-invest-exit-bounce';
export const SLUG__INCOME_DIV_FORM_UPLOAD = 'income-div-form-upload';
export const SLUG__INCOME_DIV_PREFILL_LOADING = 'income-div-prefill-loading';
export const SLUG__INCOME_DIV_INFO = 'income-div-info';
export const SLUG__INCOME_DIV_EARNER = 'income-div-earner';
export const SLUG__INCOME_DIV_EXIT_BOUNCE = 'income-div-exit-bounce';
export const SLUG__INCOME_INTEREST_FORM_UPLOAD = 'income-interest-form-upload';
export const SLUG__INCOME_INTEREST_PREFILL_LOADING = 'income-interest-prefill-loading';
export const SLUG__INCOME_INTEREST_INFO = 'income-interest-info';
export const SLUG__INCOME_INTEREST_EARNER = 'income-interest-earner';
export const SLUG__INCOME_INTEREST_EXIT_BOUNCE = 'income-interest-exit-bounce';
export const SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD = 'income-unemployment-form-upload';
export const SLUG__INCOME_UNEMPLOYMENT_PREFILL_LOADING = 'income-unemployment-prefill-loading';
export const SLUG__INCOME_UNEMPLOYMENT_INFO = 'income-unemployment-info';
export const SLUG__INCOME_UNEMPLOYMENT_EARNER = 'income-unemployment-earner';
export const SLUG__INCOME_UNEMPLOYMENT_EXIT_BOUNCE = 'income-unemployment-exit-bounce';
export const SLUG__INCOME_W2_FORM_UPLOAD = 'income-w2-form-upload';
export const SLUG__INCOME_W2_PREFILL_LOADING = 'income-w2-prefill-loading';
export const SLUG__INCOME_W2_INFO = 'income-w2-info';
export const SLUG__INCOME_W2_UNCOMMON = 'income-w2-uncommon';
export const SLUG__INCOME_UNEMPLOYMENT_UNCOMMON = 'income-unemployment-uncommon';
export const SLUG__INCOME_DIV_UNCOMMON = 'income-div-uncommon';
export const SLUG__INCOME_INTEREST_UNCOMMON = 'income-interest-uncommon';
export const SLUG__INCOME_INVEST_UNKNOWN_TERM = 'income-invest-unknown-term';
export const SLUG__INCOME_W2_SOCIAL_SECURITY_TIPS = 'income-w2-social-security-tips';
export const SLUG__INCOME_W2_AllOCATED_TIPS = 'income-w2-allocated-tips';
export const SLUG__INCOME_W2_DEPENDENT_CARE_BENEFITS = 'income-w2-dependent-care-benefits';
export const SLUG__INCOME_W2_NONQUALIFIED_PLANS = 'income-w2-nonqualified-plans';
export const SLUG__INCOME_W2_TWELVE_A = 'income-w2-twelve-a';
export const SLUG__INCOME_W2_TWELVE_B = 'income-w2-twelve-b';
export const SLUG__INCOME_W2_TWELVE_C = 'income-w2-twelve-c';
export const SLUG__INCOME_W2_TWELVE_D = 'income-w2-twelve-d';
export const SLUG__INCOME_W2_EMPLOYER_ADDRESS_STREET = 'income-w2-employer-address-street';
export const SLUG__INCOME_W2_EMPLOYER_ADDRESS_APT = 'income-w2-employer-address-apt';
export const SLUG__INCOME_W2_EMPLOYER_ADDRESS_CITY = 'income-w2-employer-address-city';
export const SLUG__INCOME_W2_EMPLOYER_ADDRESS_STATE = 'income-w2-employer-address-state';
export const SLUG__INCOME_W2_EMPLOYER_ADDRESS_ZIP = 'income-w2-employer-address-zip';
export const SLUG__INCOME_W2_STATUTORY_EMPLOYEE = 'income-w2-statutory-employee';
export const SLUG__INCOME_W2_RETIREMENT_PLAN = 'income-w2-retirement-plan';
export const SLUG__INCOME_W2_SICK_PAY = 'income-w2-sick-pay';
export const SLUG__INCOME_W2_FOURTEEN_A = 'income-w2-fourteen-a';
export const SLUG__INCOME_W2_FOURTEEN_B = 'income-w2-fourteen-b';
export const SLUG__INCOME_W2_FOURTEEN_C = 'income-w2-fourteen-c';
export const SLUG__INCOME_W2_FOURTEEN_D = 'income-w2-fourteen-d';
export const SLUG__INCOME_W2_LOCAL_WAGES = 'income-w2-local-wages';
export const SLUG__INCOME_W2_LOCAL_TAXES = 'income-w2-local-taxes';
export const SLUG__INCOME_W2_LOCAL_NAME = 'income-w2-local-name';
export const SLUG__INCOME_W2_WHO = 'income-w2-who';
export const SLUG__INCOME_W2_FOREIGN_ADDRESS = 'income-w2-employer-foreign-address';
export const SLUG__INCOME_W2_ADDRESS_COUNTRY = 'income-w2-address-country';
export const SLUG__INCOME_W2_ADDRESS_FOREIGN_POSTAL_CODE = 'income-w2-address-foreign-postal-code';
export const SLUG__INCOME_W2_ADDRESS_PROVINCE = 'income-w2-address-province';
export const SLUG__INCOME_W2_HAS_EXTRA_STATE = 'income-w2-has-extra-state';
export const SLUG__INCOME_W2_STATE = 'income-w2-state';
export const SLUG__INCOME_W2_STATE_EMPLOYER_ID = 'income-w2-state-employer-id';
export const SLUG__INCOME_W2_STATE_WAGES = 'income-w2-state-wages';
export const SLUG__INCOME_W2_STATE_INCOME_TAX = 'income-w2-state-income-tax';
export const SLUG__INCOME_W2_STATE_2 = 'income-w2-state-2';
export const SLUG__INCOME_W2_STATE_EMPLOYER_ID_2 = 'income-w2-state-employer-id-2';
export const SLUG__INCOME_W2_STATE_WAGES_2 = 'income-w2-state-wages-2';
export const SLUG__INCOME_W2_STATE_INCOME_TAX_2 = 'income-w2-state-income-tax-2';
export const SLUG__INCOME_W2_LOCAL_WAGES_2 = 'income-w2-local-wages-2';
export const SLUG__INCOME_W2_LOCAL_TAXES_2 = 'income-w2-local-taxes-2';
export const SLUG__INCOME_W2_LOCAL_NAME_2 = 'income-w2-local-name-2';
export const SLUG__INCOME_W2_EXIT_BOUNCE = 'income-w2-exit-bounce';
export const SLUG__INCOME_W2G_EXIT_BOUNCE = 'income-w2g-exit-bounce';
export const SLUG__INCOME_W2G_INFO = 'income-w2g-info';
export const SLUG__INCOME_W2G_WHO = 'income-w2g-who';
export const SLUG__INCOME_W2G_ADDRESS_STREET = 'income-w2g-address-street';
export const SLUG__INCOME_W2G_ADDRESS_CITY = 'income-w2g-address-city';
export const SLUG__INCOME_W2G_ADDRESS_STATE = 'income-w2g-address-state';
export const SLUG__INCOME_W2G_ADDRESS_ZIP = 'income-w2g-address-zip';

export const SLUG__INCOME_FREELANCE_AGI = 'income-freelance-agi';
export const SLUG__INCOME_FREELANCE_PAYERS_NAME = 'income-freelance-payers-name';
export const SLUG__INCOME_FREELANCE_1099K_INFO = 'income-freelance-1099k-info';
export const SLUG__INCOME_FREELANCE_1099K_EXPENSES_AMOUNT = 'income-freelance-1099k-expenses-amount';
export const SLUG__INCOME_FREELANCE_1099K_EXPENSES_DESCRIPTION = 'income-freelance-1099k-expenses-description';
export const SLUG__INCOME_FREELANCE_1099_TYPE = 'income-freelance-1099-type';
export const SLUG__INCOME_FREELANCE_1099_NEC = 'income-freelance-1099-nec';
export const SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD = 'income-freelance-1099-nec-form-upload';
export const SLUG__INCOME_FREELANCE_1099_NEC_PREFILL_LOADING = 'income-freelance-1099-nec-prefill-loading';
export const SLUG__INCOME_FREELANCE_1099_MISC = 'income-freelance-1099-misc';
export const SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD = 'income-freelance-1099-misc-form-upload';
export const SLUG__INCOME_FREELANCE_1099_MISC_PREFILL_LOADING = 'income-freelance-1099-misc-prefill-loading';
export const SLUG__INCOME_FREELANCE_JOB = 'income-freelance-job';
export const SLUG__INCOME_FREELANCE_JOB_NAME = 'income-freelance-job-name';
export const SLUG__INCOME_FREELANCE_WHO = 'income-freelance-who';
export const SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO = 'income-freelance-business-code-info';
export const SLUG__INCOME_FREELANCE_INDUSTRY = 'income-freelance-industry';
export const SLUG__INCOME_FREELANCE_BUSINESS_CODE = 'income-freelance-business-code';
export const SLUG__INCOME_FREELANCE_EXIT_BOUNCE = 'income-freelance-exit-bounce';
export const SLUG__INCOME_FREELANCE_OTHER_FIELDS = 'income-freelance-other-fields';
export const SLUG__INCOME_FREELANCE_MISC_BOX_ONE = 'income-freelance-misc-box-1';
export const SLUG__INCOME_FREELANCE_MISC_BOX_TWO = 'income-freelance-misc-box-2';
export const SLUG__INCOME_FREELANCE_BOX_FOUR = 'income-freelance-box-4';
export const SLUG__INCOME_FREELANCE_BOX_FIVE = 'income-freelance-box-5';
export const SLUG__INCOME_FREELANCE_BOX_SIX = 'income-freelance-box-6';
export const SLUG__INCOME_FREELANCE_NEC_BOX_SEVEN = 'income-freelance-nec-box-7';
export const SLUG__INCOME_FREELANCE_MISC_BOX_SEVEN = 'income-freelance-misc-box-7';
export const SLUG__INCOME_FREELANCE_BOX_SIXTEEN = 'income-freelance-box-16';
export const SLUG__INCOME_FREELANCE_BOX_SEVENTEEN = 'income-freelance-box-17';
export const SLUG__INCOME_FREELANCE_BOX_EIGHTEEN = 'income-freelance-box-18';
export const SLUG__INCOME_FREELANCE_STATE = 'income-freelance-state';
export const SLUG__INCOME_FREELANCE_PAYER_ID = 'income-freelance-payer-id';
export const SLUG__INCOME_FREELANCE_PAYER_STATE_NUMBER = 'income-freelance-payer-state-number';
export const SLUG__INCOME_FREELANCE_STATE_INCOME = 'income-freelance-state-income';
export const SLUG__INCOME_FREELANCE_STATE_TAX = 'income-freelance-state-tax';
export const SLUG__INCOME_FREELANCE_ADDRESS_STREET = 'income-freelance-address-street';
export const SLUG__INCOME_FREELANCE_ADDRESS_CITY = 'income-freelance-address-city';
export const SLUG__INCOME_FREELANCE_ADDRESS_STATE = 'income-freelance-address-state';
export const SLUG__INCOME_FREELANCE_ADDRESS_ZIP = 'income-freelance-address-zip';
export const SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO = 'income-freelance-1099k-expenses-info';

export const SLUG__INCOME_RETIREMENT_TYPE = 'income-retirement-type';
export const SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD = 'income-retirement-pension-form-upload';
export const SLUG__INCOME_RETIREMENT_PENSION_PREFILL_LOADING = 'income-retirement-pension-prefill-loading';
export const SLUG__INCOME_RETIREMENT_PENSION_INFO = 'income-retirement-pension-info';
export const SLUG__INCOME_RETIREMENT_PENSION_ROTH = 'income-retirement-pension-roth';
export const SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_STREET = 'income-retirement-pension-payer-address-street';
export const SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_APT = 'income-retirement-pension-payer-address-apt';
export const SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_CITY = 'income-retirement-pension-payer-address-city';
export const SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_STATE = 'income-retirement-pension-payer-address-state';
export const SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_ZIP = 'income-retirement-pension-payer-address-zip';
export const SLUG__INCOME_RETIREMENT_SSA_INFO = 'income-retirement-ssa-info';
export const SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD = 'income-retirement-ssa-form-upload';
export const SLUG__INCOME_RETIREMENT_SSA_PREFILL_LOADING = 'income-retirement-ssa-prefill-loading';
export const SLUG__INCOME_RETIREMENT_SSA_WHO = 'income-retirement-ssa-who';
export const SLUG__INCOME_RETIREMENT_PENSION_WHO = 'income-retirement-pension-who';
export const SLUG__INCOME_RETIREMENT_EXIT_BOUNCE = 'income-retirement-exit-bounce';

// unemployment other fields
export const SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND = 'income-unemployment-state-local-refund';
export const SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR = 'income-unemployment-state-local-refund-year';
export const SLUG__INCOME_UNEMPLOYMENT_RTAA_PAYMENT = 'income-unemployment-rtaa-payment';
export const SLUG__INCOME_UNEMPLOYMENT_CARRY_TO_FORM = 'income-unemployment-carry-to-form';
export const SLUG__INCOME_UNEMPLOYMENT_MULTI_FORM_NUM = 'income-unemployment-multi-form-num';
export const SLUG__INCOME_UNEMPLOYMENT_TAXABLE_GRANTS = 'income-unemployment-taxable-grants';
export const SLUG__INCOME_UNEMPLOYMENT_AG_PAYMENTS = 'income-unemployment-ag-payments';
export const SLUG__INCOME_UNEMPLOYMENT_TRADE_BUSINESS = 'income-unemployment-trade-business';
export const SLUG__INCOME_UNEMPLOYMENT_MARKET_GAIN = 'income-unemployment-market-gain';
export const SLUG__INCOME_UNEMPLOYMENT_STATE = 'income-unemployment-state';
export const SLUG__INCOME_UNEMPLOYMENT_STATE_ID = 'income-unemployment-state-id';
export const SLUG__INCOME_UNEMPLOYMENT_STATE_UNEMPLOYMENT = 'income-unemployment-state-unemployment';
export const SLUG__INCOME_UNEMPLOYMENT_LOCAL_PAYMENT = 'income-unemployment-local-payment';
export const SLUG__INCOME_UNEMPLOYMENT_LOCAL_WITHHOLDING = 'income-unemployment-local-withholding';
export const SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STREET = 'income-unemployment-payer-address-street';
export const SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_CITY = 'income-unemployment-payer-address-city';
export const SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STATE = 'income-unemployment-payer-address-state';
export const SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_ZIP = 'income-unemployment-payer-address-zip';

// interest other fields
export const SLUG__INCOME_INTEREST_EARLY_PENALTY = 'income-interest-early-penalty';
export const SLUG__INCOME_INTEREST_GOVERNMENT_INTEREST = 'income-interest-government-interest';
export const SLUG__INCOME_INTEREST_FED_TAX_WITHHELD = 'income-interest-fed-tax-withheld';
export const SLUG__INCOME_INTEREST_INVESTMENT_EXPENSES = 'income-interest-investment-expenses';
export const SLUG__INCOME_INTEREST_FOREIGN_TAX_PAID = 'income-interest-foreign-tax-paid';
export const SLUG__INCOME_INTEREST_FOREIGN_COUNTRY = 'income-interest-foreign-country';
export const SLUG__INCOME_INTEREST_TAX_EXEMPT = 'income-interest-tax-exempt';
export const SLUG__INCOME_INTEREST_STATE = 'income-interest-state';
export const SLUG__INCOME_INTEREST_STATE_ID = 'income-interest-state-id';
export const SLUG__INCOME_INTEREST_STATE_TAX_WITHHELD = 'income-interest-state-tax-withheld';
export const SLUG__INCOME_INTEREST_STATE_TAX_EXEMPT = 'income-interest-state-tax-exempt';

// dividend other fields
export const SLUG__INCOME_DIV_ORDINARY_FOREIGN_AMOUNT = 'income-div-ordinary-foreign-amount';
export const SLUG__INCOME_DIV_QUALIFIED_FOREIGN_AMOUNT = 'income-div-qualified-foreign-amount';
export const SLUG__INCOME_DIV_CAPITAL_GAINS_FOREIGN_AMOUNT = 'income-div-capital-gains-foreign-amount';
export const SLUG__INCOME_DIV_FED_TAX_WITHHELD = 'income-div-fed-tax-withheld';
export const SLUG__INCOME_DIV_FOREIGN_TAX_PAID = 'income-div-foreign-tax-paid';
export const SLUG__INCOME_DIV_STATE = 'income-div-state';
export const SLUG__INCOME_DIV_STATE_ID = 'income-div-state-id';
export const SLUG__INCOME_DIV_STATE_TAX_WITHHELD = 'income-div-state-tax-withheld';

// investment other fields
export const SLUG__INCOME_INVEST_AMT_COST_BASIS = 'income-invest-amt-cost-basis';
export const SLUG__INCOME_INVEST_MARKET_DISCOUNT = 'income-invest-market-discount';
export const SLUG__INCOME_INVEST_FED_TAX_WITHHELD = 'income-invest-fed-tax-withheld';
export const SLUG__INCOME_INVEST_STATE = 'income-invest-state';
export const SLUG__INCOME_INVEST_STATE_ID = 'income-invest-state-id';
export const SLUG__INCOME_INVEST_STATE_TAX_WITHHELD = 'income-invest-state-tax-withheld';
export const SLUG__INCOME_INVEST_DATE_ACQUISITION = 'income-invest-date-acquisition';
export const SLUG__INCOME_INVEST_DATE_SALE = 'income-invest-date-sale';

// sold personal items fields
export const SLUG__INCOME_PERSONAL_ITEMS = 'income-personal-items';
export const SLUG__INCOME_PERSONAL_ITEMS_WHO = 'income-personal-items-who';
export const SLUG__INCOME_PERSONAL_ITEMS_MULTIPLE_DATES = 'income-personal-items-multiple-dates';
export const SLUG__INCOME_PERSONAL_ITEMS_ACQUIRED = 'income-personal-items-acquired';
export const SLUG__INCOME_PERSONAL_ITEMS_SOLD = 'income-personal-items-sold';
export const SLUG__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE = 'income-personal-items-exit-bounce';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_DESCRIPTION = 'income-invest-description';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_LONG_TERM_SALE_PROCEEDS = 'income-invest-long-term-sale-proceeds';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_SHORT_TERM_SALE_PROCEEDS = 'income-invest-short-term-sale-proceeds';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_UNKNOWN_TERM_SALE_PROCEEDS = 'income-invest-unknown-term-sale-proceeds';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_LONG_TERM_COST_BASIS = 'income-invest-long-term-cost-basis';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_SHORT_TERM_COST_BASIS = 'income-invest-short-term-cost-basis';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_UNKNOWN_TERM_COST_BASIS = 'income-invest-unknown-term-cost-basis';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_SALE_PROCEEDS = 'income-invest-sale-proceeds';
export const ENDPOINT_ATTRIBUTE__INCOME_DIV_PAYER_NAME = 'income-div-payer-name';
export const ENDPOINT_ATTRIBUTE__INCOME_DIV_ORDINARY = 'income-div-ordinary';
export const ENDPOINT_ATTRIBUTE__INCOME_INTEREST_PAYER_NAME = 'income-interest-payer-name';
export const ENDPOINT_ATTRIBUTE__INCOME_INTEREST_AMOUNT = 'income-interest-amount';
export const ENDPOINT_ATTRIBUTE__INCOME_INTEREST_GOVERNMENT_INTEREST = 'income-interest-government-interest';
export const ENDPOINT_ATTRIBUTE__INCOME_1099G_PAYER_NAME = 'income-1099g-payer-name';
export const ENDPOINT_ATTRIBUTE__INCOME_1099G_COMPENSATION = 'income-1099g-compensation';
export const ENDPOINT_ATTRIBUTE__INCOME_W2_EMPLOYER = 'income-w2-employer';
export const ENDPOINT_ATTRIBUTE__INCOME_W2_WAGES = 'income-w2-wages';
export const ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME = 'income-freelance-job-name';
export const ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME = 'income-freelance-income';
export const ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE = 'income-freelance-business-code';
export const ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INDUSTRY = 'income-freelance-industry';
export const ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_MISC_BOX_2 = 'income-freelance-misc-box-2';
export const ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_PENSION_GROSS = 'income-retirement-pension-gross-distribution';
export const ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_SSA_NET = 'income-retirement-ssa-net-benefits';
export const ENDPOINT_ATTRIBUTE__INCOME_W2G_PAYER_NAME = 'income-w2g-payer-name';
export const ENDPOINT_ATTRIBUTE__INCOME_W2G_REPORTABLE_WINNINGS = 'income-w2g-reportable-winnings';
export const ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_STARTED = 'income-freelance-started';
export const ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED = 'income-invest-started';
export const ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_AMOUNT = 'income-personal-items-amount';
export const ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_DESCRIPTION = 'income-personal-items-description';

// collection types
export const COLLECTION_TYPE__INCOME_INVEST = 'income-invest';
export const COLLECTION_TYPE__INCOME_DIV = 'income-div';
export const COLLECTION_TYPE__INCOME_INTEREST = 'income-interest';
export const COLLECTION_TYPE__INCOME_1099G = 'income-1099g';
export const COLLECTION_TYPE__INCOME_W2 = 'income-w2';
export const COLLECTION_TYPE__INCOME_W2G = 'income-w2g';
export const COLLECTION_TYPE__INCOME_FREELANCE = 'income-freelance';
export const COLLECTION_TYPE__INCOME_RETIREMENT = 'income-retirement';
export const COLLECTION_TYPE__INCOME_PERSONAL_ITEMS = 'income-personal-items';
export const COLLECTION_TYPE__INCOME_COMPOSITE = 'income-composite';

// 1099B groupings
export const INCOME_INVEST_INTRO_SLUGS = [
  'income-invest-earner',
  'income-invest-federal-id',
  'income-invest-description'
];
export const INCOME_INVEST_SHORT_TERM_SLUGS = [
  'income-invest-short-term-header',
  'income-invest-short-term-sale-proceeds',
  'income-invest-short-term-cost-basis',
  'income-invest-short-term-wash-sale-loss'
];
export const INCOME_INVEST_LONG_TERM_SLUGS = [
  'income-invest-long-term-header',
  'income-invest-long-term-sale-proceeds',
  'income-invest-long-term-cost-basis',
  'income-invest-long-term-wash-sale-loss'
];
export const INCOME_INVEST_UNKNOWN_TERM_SLUGS = [
  'income-invest-unknown-term-sale-proceeds',
  'income-invest-unknown-term-cost-basis',
  'income-invest-unknown-term-wash-sale-loss',
  'income-invest-date-acquisition',
  'income-invest-date-sale',
  'income-invest-market-discount',
  'income-invest-state',
  'income-invest-state-id',
  'income-invest-state-tax-withheld',
  'income-invest-fed-tax-withheld',
  'income-invest-amt-cost-basis'
];

// address suffixes
export const ADDRESS_SLUG_SUFFIXES = [
  '-address-street',
  '-address-apt',
  '-address-city',
  '-address-state',
  '-address-zip'
];
