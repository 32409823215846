import React from 'react';
import { connect } from 'react-redux';
import LoginRedirect from '@app/src/Components/Login/LoginWithOTP/LoginRedirect';
import { isAuthenticatedSelector } from '@app/src/selectors/authSelectors';
import { expensesErrorSelector } from '@app/src/selectors/expenseReviewSelectors';

const ExpenseReviewCard = ({ isAuthenticated, location, children }) => {
  if (!isAuthenticated) {
    return <LoginRedirect location={location} />;
  }

  return <div className='container'>{children}</div>;
};

const mapStateToProps = (state) => ({
  expensesError: expensesErrorSelector(state),
  isAuthenticated: isAuthenticatedSelector(state)
});

const ConnectedExpenseReviewCard = connect(mapStateToProps)(ExpenseReviewCard);

export default ConnectedExpenseReviewCard;
