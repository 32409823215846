import {
  SET_ONBOARDING_JOB_CATEGORY_LIST,
  SET_ONBOARDING_ERROR,
  SET_PARTNER_PRICING,
  SET_ONBOARDING_INITIAL_LOAD,
  SET_ONBOARDING_EXPERIMENTS,
  SET_ONBOARDING_MARKETING_CONTENT,
  SET_ONBOARDING_TYPE,
  SET_ONBOARDING_NORMALIZED_QUESTIONS,
  SET_ONBOARDING_CURRENT_QUESTION,
  SET_ONBOARDING_CURRENT_ANSWER,
  SET_ONBOARDING_LOADING,
  SET_ONBOARDING_UPDATING,
  SET_ONBOARDING_CURRENT_CONTEXT
} from '@app/src/actions/types';

const initialState = {
  job_category: [],
  resError: '',
  loading: false,
  updating: false,
  pricing: {},
  initialLoad: true,
  onboardingExperiments: [],
  marketingContent: {},
  type: null,
  normalizedQuestions: {
    allIds: [],
    byId: {}
  },
  currentQuestion: {},
  currentAnswer: {},
  currentContext: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ONBOARDING_JOB_CATEGORY_LIST: {
      return {
        ...state,
        job_category: action.payload
      };
    }
    case SET_ONBOARDING_ERROR: {
      return {
        ...state,
        resError: action.payload
      };
    }
    case SET_ONBOARDING_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case SET_ONBOARDING_UPDATING: {
      return {
        ...state,
        updating: action.payload
      };
    }
    case SET_PARTNER_PRICING: {
      return {
        ...state,
        pricing: action.payload
      };
    }
    case SET_ONBOARDING_INITIAL_LOAD: {
      return {
        ...state,
        initialLoad: action.payload
      };
    }
    case SET_ONBOARDING_EXPERIMENTS: {
      return {
        ...state,
        onboardingExperiments: action.payload
      };
    }
    case SET_ONBOARDING_MARKETING_CONTENT: {
      return {
        ...state,
        marketingContent: action.payload
      };
    }
    case SET_ONBOARDING_TYPE: {
      return {
        ...state,
        type: action.payload
      };
    }
    case SET_ONBOARDING_NORMALIZED_QUESTIONS: {
      return {
        ...state,
        normalizedQuestions: action.payload
      };
    }
    case SET_ONBOARDING_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: action.payload
      };
    }
    case SET_ONBOARDING_CURRENT_ANSWER: {
      return {
        ...state,
        currentAnswer: action.payload
      };
    }
    case SET_ONBOARDING_CURRENT_CONTEXT: {
      return {
        ...state,
        currentContext: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
