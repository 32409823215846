import _ from 'lodash';
import { createSelector } from 'reselect';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import { COLLECTION_TYPE__HOME } from '@app/src/taxflow/sections/home/homeConstants';
import { COLLECTION_TYPE__HOME_ADDRESS } from '@app/src/taxflow/sections/personal/constants/personalConstants';

// Api
const taxDataApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // TODO replace all references to in-state queryResults with this query
    getTaxData: builder.query({
      query: ({ collectionType }) => ({
        url: `taxes/user-tax-data/batch-get`,
        method: 'POST',
        body: { queries: [{ coll_type: collectionType }] }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { collectionType }) => [{ type: TAGS.TAX_DATA, id: collectionType }]
    }),
    updateTaxData: builder.mutation({
      query: ({ taxData, generateSharedCollectionId }) => ({
        url: `taxes/user-tax-data`,
        method: 'POST',
        body: { taxData, generateSharedCollectionId }
      }),
      invalidatesTags: (result, error, { taxData }) => {
        return _.chain(taxData)
          .map('coll_type')
          .uniq()
          .flatMap((collectionType) =>
            _.compact([
              { type: TAGS.TAX_DATA, id: collectionType },
              collectionType === COLLECTION_TYPE__CAR && TAGS.CAR_PILLS,
              collectionType === COLLECTION_TYPE__HOME && TAGS.HOME_PILLS,
              [COLLECTION_TYPE__HOME, COLLECTION_TYPE__HOME_ADDRESS].includes(collectionType) &&
                TAGS.HOME_ADDRESS_PRESELECT_OPTIONS
            ])
          )
          .concat(TAGS.SUBMIT_WARNINGS, TAGS.BULK_UPLOAD_PILLS, TAGS.REVIEW_PILLS)
          .value();
      },
      async onQueryStarted({ taxData }, { dispatch }) {
        const updatesGroupedByCollection = _.groupBy(taxData, 'coll_type');
        _.forEach(updatesGroupedByCollection, (updatedTaxData, collectionType) => {
          dispatch(
            taxDataApi.util.updateQueryData('getTaxData', { collectionType }, (taxData) => {
              return [
                ..._.differenceWith(taxData, updatedTaxData, (prev, update) => _.isMatch(prev, update)),
                ...updatedTaxData
              ];
            })
          );
        });
      }
    }),
    deleteTaxData: builder.mutation({
      query: ({ coll_type, coll_id, slug }) => ({
        url: `taxes/user-tax-data`,
        method: 'DELETE',
        body: { coll_type, coll_id, slug }
      }),
      invalidatesTags: (result, error, { coll_type }) => [
        { type: TAGS.TAX_DATA, id: coll_type },
        TAGS.SUBMIT_WARNINGS,
        TAGS.BULK_UPLOAD_PILLS,
        TAGS.REVIEW_PILLS,
        ...(coll_type === COLLECTION_TYPE__CAR ? [TAGS.CAR_PILLS] : []),
        ...(coll_type === COLLECTION_TYPE__HOME ? [TAGS.HOME_PILLS, TAGS.HOME_ADDRESS_PRESELECT_OPTIONS] : [])
      ],
      async onQueryStarted({ coll_type, coll_id, slug }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getTaxData', { collectionType: coll_type }, (taxData) => {
            return _.filter(taxData, _.negate(_.matches(_.omit({ coll_type, coll_id, slug }, _.isUndefined))));
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getBulkUploadPills', undefined, (bulkUploadPills) => {
            return _.isNil(slug)
              ? _.filter(bulkUploadPills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : bulkUploadPills;
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getCarPills', undefined, (carPills) => {
            return _.isNil(slug)
              ? _.filter(carPills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : carPills;
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getHomePills', undefined, (homePills) => {
            return _.isNil(slug)
              ? _.filter(homePills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : homePills;
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getHomeAddressPreselectOptions', undefined, (preselectOptions) => {
            return _.isNil(slug)
              ? _.filter(preselectOptions, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : preselectOptions;
          })
        );
      }
    }),
    getBulkUploadPills: builder.query({
      query: () => ({
        url: 'taxes/bulk-upload-pills',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.bulkUploadPills,
      providesTags: () => [TAGS.BULK_UPLOAD_PILLS]
    }),
    getCurrentQuestionnaireQuestion: builder.query({
      query: () => ({
        url: 'questionnaire/get-current-question',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.question,
      providesTags: () => [TAGS.QUESTIONNAIRE_PROGRESS]
    }),
    getQuestionnaireProgress: builder.query({
      query: () => ({
        url: 'questionnaire/get-progress',
        method: 'GET'
      }),
      transformResponse: (response) => response.data,
      providesTags: () => [TAGS.QUESTIONNAIRE_PROGRESS]
    }),
    getQuestionnaireSummaryPills: builder.query({
      query: () => ({
        url: 'taxes/questionnaire-summary-pills',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.questionnaireSummaryPills,
      providesTags: () => [TAGS.QUESTIONNAIRE_SUMMARY_PILLS]
    }),
    generateQuestionnaire: builder.mutation({
      query: () => ({
        url: 'questionnaire/generate-questions',
        method: 'POST'
      }),
      invalidatesTags: () => [
        TAGS.CURRENT_QUESTIONNAIRE_QUESTION,
        TAGS.QUESTIONNAIRE_SUMMARY_PILLS,
        TAGS.QUESTIONNAIRE_PROGRESS
      ]
    }),
    progressToNextQuestionnaireQuestion: builder.mutation({
      query: ({ maybeFollowUpQuestion }) => ({
        url: 'questionnaire/progress-to-next-question',
        method: 'POST',
        body: { maybeFollowUpQuestion }
      }),
      transformResponse: (response) => ({
        currentQuestion: _.get(response, ['data', 'currentQuestion']),
        questionnaireComplete: _.get(response, ['data', 'questionnaireComplete'], true)
      }),
      invalidatesTags: () => [
        TAGS.CURRENT_QUESTIONNAIRE_QUESTION,
        TAGS.QUESTIONNAIRE_SUMMARY_PILLS,
        TAGS.QUESTIONNAIRE_PROGRESS
      ]
    }),
    goBackToPreviousQuestionnaireQuestion: builder.mutation({
      query: () => ({
        url: 'questionnaire/go-back-to-previous-question',
        method: 'POST'
      }),
      transformResponse: (response) => ({
        currentQuestion: _.get(response, ['data', 'currentQuestion']),
        questionnaireExited: _.get(response, ['data', 'questionnaireExited'], true)
      }),
      invalidatesTags: () => [TAGS.CURRENT_QUESTIONNAIRE_QUESTION, TAGS.QUESTIONNAIRE_PROGRESS]
    }),
    getReviewPills: builder.query({
      query: () => ({
        url: 'taxes/review-pills',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.reviewPills,
      providesTags: () => [TAGS.REVIEW_PILLS]
    }),
    getSubmitWarnings: builder.query({
      query: () => ({
        url: 'taxes/submit-warnings',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.submitWarnings,
      providesTags: () => [TAGS.SUBMIT_WARNINGS]
    }),
    dismissSubmitWarning: builder.mutation({
      query: ({ slug }) => ({
        url: 'taxes/dismiss-submit-warning',
        method: 'POST',
        body: { slug }
      }),
      invalidatesTags: () => [TAGS.SUBMIT_WARNINGS],
      async onQueryStarted({ slug }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getSubmitWarnings', undefined, (submitWarnings) => {
            return _.filter(submitWarnings, ({ slug: warningSlug }) => warningSlug !== slug);
          })
        );
      }
    }),
    getSubmitIssues: builder.query({
      query: () => ({
        url: 'taxes/submit-issues',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.submitIssues,
      providesTags: () => [TAGS.SUBMIT_ISSUES]
    }),
    updateSubmitIssues: builder.mutation({
      query: ({ updatedIssues }) => ({
        url: 'taxes/update-submit-issues',
        method: 'POST',
        body: { updatedIssues }
      }),
      invalidatesTags: () => [TAGS.SUBMIT_ISSUES],
      async onQueryStarted({ updatedIssues }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getSubmitIssues', undefined, () => {
            return updatedIssues;
          })
        );
      }
    }),
    getSsnMatched: builder.query({
      query: () => ({
        url: 'taxes/get-submit-ssn-matched',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.SSN_MATCHED]
    }),
    executeSsnMatchedCheck: builder.mutation({
      query: () => ({
        url: 'taxes/get-id-match',
        method: 'POST'
      }),
      transformResponse: (response) => response.data.response.result,
      invalidatesTags: () => [TAGS.SUBMIT_WARNINGS, TAGS.SSN_MATCHED, TAGS.ID_VERIFICATION_QUESTIONS]
    }),
    getIdVerificationQuestions: builder.query({
      query: () => ({
        url: 'taxes/get-id-verification-questions',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.ID_VERIFICATION_QUESTIONS]
    }),
    answerIdVerificationQuestions: builder.mutation({
      query: ({ questions, answers }) => ({
        url: 'taxes/confirm-id',
        method: 'POST',
        body: { questions, answers }
      }),
      transformResponse: (response) => response.data.response,
      invalidatesTags: () => [TAGS.ID_VERIFICATION_RESULT]
    }),
    getIdVerificationResult: builder.query({
      query: () => ({
        url: 'taxes/get-id-verification-result',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.ID_VERIFICATION_RESULT]
    }),
    getTaxAmounts: builder.query({
      query: () => ({
        url: 'taxes/get-tax-amounts',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.taxAmounts,
      providesTags: () => [TAGS.TAX_AMOUNTS]
    }),
    getSubmitTimestamp: builder.query({
      query: () => ({
        url: 'taxes/submit-timestamp',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.submitTimestamp,
      providesTags: () => [TAGS.SUBMIT_TIMESTAMP]
    }),
    getReturnStatus: builder.query({
      query: () => ({
        url: 'taxes/return-status',
        method: 'GET'
      }),
      transformResponse: (response) => response.data,
      providesTags: () => [TAGS.RETURN_STATUS]
    }),
    getCarPills: builder.query({
      query: () => ({
        url: 'taxes/car-pills',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.carPills,
      providesTags: () => [TAGS.CAR_PILLS]
    }),
    calculateTaxEstimate: builder.query({
      query: (body) => ({ url: 'taxes/calculate-tax-estimate', method: 'POST', body }),
      providesTags: () => [TAGS.TAX_ESTIMATE],
      transformResponse: (response) => response.data
    }),
    getHomePills: builder.query({
      query: () => ({
        url: 'taxes/home-pills',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.homePills,
      providesTags: () => [TAGS.HOME_PILLS]
    }),
    getHomeAddressPreselectOptions: builder.query({
      query: () => ({
        url: 'taxes/home-address-preselect-options',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.options,
      providesTags: () => [TAGS.HOME_ADDRESS_PRESELECT_OPTIONS]
    })
  })
});

// Actions

export const getTaxData =
  ({ collectionType }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxData.initiate({ collectionType }),
      dispatch
    });

export const updateTaxData =
  ({ taxData, generateSharedCollectionId }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.updateTaxData.initiate({ taxData, generateSharedCollectionId }));
  };

export const deleteTaxData =
  ({ coll_type, coll_id, slug }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.deleteTaxData.initiate({ coll_type, coll_id, slug }));
  };

export const getBulkUploadPills = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getBulkUploadPills.initiate, dispatch });

export const getCurrentQuestionnaireQuestion = () => async (dispatch) =>
  getFulfilledRequestData({
    initiateFunction: taxDataApi.endpoints.getCurrentQuestionnaireQuestion.initiate,
    dispatch
  });

export const getQuestionnaireSummaryPills = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getQuestionnaireSummaryPills.initiate, dispatch });

export const getSsnMatched = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getSsnMatched.initiate, dispatch });

export const answerIdVerificationQuestions =
  ({ questions, answers }) =>
  async (dispatch) =>
    await dispatch(taxDataApi.endpoints.answerIdVerificationQuestions.initiate({ questions, answers }));

export const getIdVerificationQuestions = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getIdVerificationQuestions.initiate, dispatch });

export const getIdVerificationResult = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getIdVerificationResult.initiate, dispatch });

export const getCarPills = () => async (dispatch) =>
  getFulfilledRequestData({
    initiateFunction: taxDataApi.endpoints.getCarPills.initiate,
    dispatch
  });

export const getHomePills = () => async (dispatch) =>
  getFulfilledRequestData({
    initiateFunction: taxDataApi.endpoints.getHomePills.initiate,
    dispatch
  });

export const getHomeAddressPreselectOptions = () => async (dispatch) =>
  getFulfilledRequestData({
    initiateFunction: taxDataApi.endpoints.getHomeAddressPreselectOptions.initiate,
    dispatch
  });

// Hooks
export const {
  useGetTaxDataQuery,
  useUpdateTaxDataMutation,
  useGetBulkUploadPillsQuery,
  useGetCurrentQuestionnaireQuestionQuery,
  useGetQuestionnaireSummaryPillsQuery,
  useGetQuestionnaireProgressQuery,
  useGenerateQuestionnaireMutation,
  useGoBackToPreviousQuestionnaireQuestionMutation,
  useGetReviewPillsQuery,
  useGetSubmitWarningsQuery,
  useDismissSubmitWarningMutation,
  useGetSubmitIssuesQuery,
  useGetCarPillsQuery,
  useUpdateSubmitIssuesMutation,
  useLazyGetSsnMatchedQuery,
  useLazyGetIdVerificationQuestionsQuery,
  useLazyGetIdVerificationResultQuery,
  useExecuteSsnMatchedCheckMutation,
  useGetTaxAmountsQuery,
  useLazyGetTaxAmountsQuery,
  useGetSubmitTimestampQuery,
  useGetReturnStatusQuery,
  useCalculateTaxEstimateQuery,
  useGetHomePillsQuery,
  useGetHomeAddressPreselectOptionsQuery
} = taxDataApi;

// Selectors

export const questionnairePassedQuestionsSelector = createSelector(
  [taxDataApi.endpoints.getQuestionnaireProgress.select()],
  (data) => _.get(data, 'passedQuestions')
);

export const questionnaireTotalQuestionsSelector = createSelector(
  [taxDataApi.endpoints.getQuestionnaireProgress.select()],
  (data) => _.get(data, 'totalQuestions')
);

export const submitIssueItemsSelector = createSelector(
  [taxDataApi.endpoints.getSubmitIssues.select()],
  ({ data: submitIssues }) => submitIssues || []
);

export const submitWarningsSelector = createSelector(
  [taxDataApi.endpoints.getSubmitWarnings.select()],
  ({ data: submitWarnings }) => submitWarnings || []
);

export const taxAmountsSelector = createSelector(
  [taxDataApi.endpoints.getTaxAmounts.select()],
  ({ data: taxAmounts }) => taxAmounts || {}
);

export default taxDataApi;
