import React from 'react';
import _ from 'lodash';
import TaxFlowMoneyItem from '@app/src/Components/TaxFlow/Question/TaxFlowMoneyItem';
import TaxValidationSelect from '@app/src/Components/TaxValidation/TaxValidationSelect';
import '@app/src/Components/TaxFlow/Form/TaxFlowFormTwelveAItem.scss';

class TaxFlowFormTwelveAItem extends React.Component {
  componentDidMount() {
    const { question, answer } = this.props;

    if (_.isNil(_.get(answer, 'value'))) {
      const item = question.question_meta.options.find((x) => x.default === 1);
      if (item) {
        this.props.onChange({ slug: question.slug, value: item.value });
      }
    }
  }

  onPartOneChange = (value) => {
    this.props.onChange({
      slug: this.props.question.slug,
      value: { ..._.get(this.props.answer, 'value'), partOne: value },
      endpoint_attr: this.props.question.endpoint_attr
    });
  };

  onPartTwoChange = (value) => {
    value = value ? Number(value) : null;
    this.props.onChange({
      slug: this.props.question.slug,
      value: { ..._.get(this.props.answer, 'value'), partTwo: value }
    });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;
    const questionMeta = question.question_meta;

    const questionPartTwo = {
      ...question,
      header_style: 'hidden'
    };
    const answerPartOne = { value: _.get(answer, ['value', 'partOne']) };
    const answerPartTwo = { value: _.get(answer, ['value', 'partTwo']) };

    return (
      <div className='form-group'>
        <div className='taxflow-form-twelve-a-item-parts'>
          <TaxValidationSelect
            question={question}
            className='taxflow-form-twelve-a-item-part-one browser-default'
            name={question.slug}
            onFocus={this.onFocus}
            onChange={(e) => this.onPartOneChange(e.target.value)}
            value={_.get(answerPartOne, 'value', '')}
            short
          >
            {questionMeta.options.map((item) => (
              <option value={item.value} key={item.value}>
                {item.text}
              </option>
            ))}
          </TaxValidationSelect>
          <div className='taxflow-form-twelve-a-item-part-two'>
            <TaxFlowMoneyItem
              {...this.props}
              isChildQuestion
              currentQuestion={questionPartTwo}
              currentAnswer={answerPartTwo}
              inputMode='decimal'
              decimalScale={2}
              onChange={this.onPartTwoChange}
              onFocus={this.onFocus}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TaxFlowFormTwelveAItem;
