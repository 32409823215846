import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import {
  Airplane,
  BackSquare,
  BagHappy,
  Box,
  Box1,
  Briefcase,
  BrifecaseTick,
  Building4,
  Calendar,
  CallCalling,
  Car,
  CardPos,
  EmptyWallet,
  Gameboy,
  GasStation,
  Gift,
  I3Dcube,
  Judge,
  Layer,
  Money,
  MoneyTick,
  MonitorMobbile,
  Music,
  People,
  Personalcard,
  Pet,
  Repeat,
  SafeHome,
  ShoppingCart,
  SmartCar,
  Truck,
  UserTick,
  VideoPlay,
  WalletMoney,
  Wifi
} from 'iconsax-react';
import Advertising from '@app/src/assets/expenseCategoryIcons/Advertising.svg?react';
import BankFee from '@app/src/assets/expenseCategoryIcons/BankFee.svg?react';
import BusinessMeal from '@app/src/assets/expenseCategoryIcons/BusinessMeal.svg?react';
import CapitalExpense from '@app/src/assets/expenseCategoryIcons/CapitalExpense.svg?react';
import CarInsurance from '@app/src/assets/expenseCategoryIcons/CarInsurance.svg?react';
import CarLeasePymt from '@app/src/assets/expenseCategoryIcons/CarLeasePymt.svg?react';
import CarLoanPymt from '@app/src/assets/expenseCategoryIcons/CarLoanPymt.svg?react';
import CarMaintenance from '@app/src/assets/expenseCategoryIcons/CarMaintenance.svg?react';
import CarRental from '@app/src/assets/expenseCategoryIcons/CarRental.svg?react';
import CarWash from '@app/src/assets/expenseCategoryIcons/CarWash.svg?react';
import Charity from '@app/src/assets/expenseCategoryIcons/Charity.svg?react';
import ChildAdoptionExpense from '@app/src/assets/expenseCategoryIcons/ChildAdoptionExpense.svg?react';
import ChildCare from '@app/src/assets/expenseCategoryIcons/ChildCare.svg?react';
import Clothing from '@app/src/assets/expenseCategoryIcons/Clothing.svg?react';
import CoffeeShop from '@app/src/assets/expenseCategoryIcons/CoffeeShop.svg?react';
import CreditCardPayment from '@app/src/assets/expenseCategoryIcons/CreditCardPayment.svg?react';
import Education from '@app/src/assets/expenseCategoryIcons/Education.svg?react';
import EvCharging from '@app/src/assets/expenseCategoryIcons/EvCharging.svg?react';
import FastFood from '@app/src/assets/expenseCategoryIcons/FastFood.svg?react';
import FreelanceIncome from '@app/src/assets/expenseCategoryIcons/FreelanceIncome.svg?react';
import GamblingTransfer from '@app/src/assets/expenseCategoryIcons/GamblingTransfer.svg?react';
import GreenHomeImprovement from '@app/src/assets/expenseCategoryIcons/GreenHomeImprovement.svg?react';
import HealthInsurance from '@app/src/assets/expenseCategoryIcons/HealthInsurance.svg?react';
import HomeOfficeExpense from '@app/src/assets/expenseCategoryIcons/HomeOfficeExpense.svg?react';
import HsaTransfer from '@app/src/assets/expenseCategoryIcons/HsaTransfer.svg?react';
import Insurance from '@app/src/assets/expenseCategoryIcons/Insurance.svg?react';
import InvestmentTransfer from '@app/src/assets/expenseCategoryIcons/InvestmentTransfer.svg?react';
import LifeInsurance from '@app/src/assets/expenseCategoryIcons/LifeInsurance.svg?react';
import MedicalExpense from '@app/src/assets/expenseCategoryIcons/MedicalExpense.svg?react';
import Mortgage from '@app/src/assets/expenseCategoryIcons/Mortgage.svg?react';
import OtherExpense from '@app/src/assets/expenseCategoryIcons/OtherExpense.svg?react';
import OtherTransport from '@app/src/assets/expenseCategoryIcons/OtherTransport.svg?react';
import Parking from '@app/src/assets/expenseCategoryIcons/Parking.svg?react';
import PersonalCare from '@app/src/assets/expenseCategoryIcons/PersonalCare.svg?react';
import PublicTransport from '@app/src/assets/expenseCategoryIcons/PublicTransport.svg?react';
import Rent from '@app/src/assets/expenseCategoryIcons/Rent.svg?react';
import RetirementTransfer from '@app/src/assets/expenseCategoryIcons/RetirementTransfer.svg?react';
import SalariedIncome from '@app/src/assets/expenseCategoryIcons/SalariedIncome.svg?react';
import StudentLoanPayment from '@app/src/assets/expenseCategoryIcons/StudentLoanPayment.svg?react';
import StudentTuition from '@app/src/assets/expenseCategoryIcons/StudentTuition.svg?react';
import TaxPayment from '@app/src/assets/expenseCategoryIcons/TaxPayment.svg?react';
import TaxRefund from '@app/src/assets/expenseCategoryIcons/TaxRefund.svg?react';
import TravelLodging from '@app/src/assets/expenseCategoryIcons/TravelLodging.svg?react';
import UnemploymentIncome from '@app/src/assets/expenseCategoryIcons/UnemploymentIncome.svg?react';
import Utilities from '@app/src/assets/expenseCategoryIcons/Utilities.svg?react';
import { colorPrimary } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryIcon.scss';

const idToCategoryIconMap = {
  0: OtherExpense,
  1: GasStation,
  2: SmartCar,
  3: BusinessMeal,
  4: Parking,
  5: Box,
  6: Money,
  7: UserTick,
  8: BrifecaseTick,
  9: CarInsurance,
  10: SafeHome,
  11: Insurance,
  12: CallCalling,
  13: Layer,
  14: CarRental,
  15: TravelLodging,
  16: CoffeeShop,
  17: Rent,
  18: CarMaintenance,
  19: Wifi,
  20: Truck,
  21: Box1,
  22: I3Dcube,
  23: PublicTransport,
  24: CarWash,
  25: MonitorMobbile,
  26: Briefcase,
  27: FreelanceIncome,
  28: SalariedIncome,
  29: UnemploymentIncome,
  30: Airplane,
  31: OtherTransport,
  32: WalletMoney,
  35: Advertising,
  36: Personalcard,
  37: HomeOfficeExpense,
  39: Education,
  40: BankFee,
  41: People,
  42: CarLoanPymt,
  43: CardPos,
  44: Car,
  45: Utilities,
  46: Gift,
  47: Music,
  48: VideoPlay,
  49: CarLeasePymt,
  50: Pet,
  51: Building4,
  52: BagHappy,
  53: Judge,
  54: FastFood,
  55: Repeat,
  56: Charity,
  57: ChildCare,
  58: Gameboy,
  59: Calendar,
  60: MoneyTick,
  61: MedicalExpense,
  62: PersonalCare,
  63: ShoppingCart,
  64: Clothing,
  65: EmptyWallet,
  66: BackSquare,
  67: Mortgage,
  68: InvestmentTransfer,
  69: GamblingTransfer,
  70: StudentLoanPayment,
  71: TaxRefund,
  72: CreditCardPayment,
  73: TaxPayment,
  74: CapitalExpense,
  75: EvCharging,
  76: StudentTuition,
  77: RetirementTransfer,
  78: HsaTransfer,
  79: HealthInsurance,
  80: LifeInsurance,
  81: GreenHomeImprovement,
  82: ChildAdoptionExpense
};

const ExpenseReviewCategoryIcon = ({ id, color = colorPrimary, size = 16, className }) => {
  if (_.isNil(id)) {
    return null;
  }

  const CategoryIcon = idToCategoryIconMap[id];

  if (!CategoryIcon) {
    return null;
  }

  return (
    <div className={classNames('expense-review-category-icon', className)}>
      <CategoryIcon color={color} height={size} size={size} stroke={color} strokeWidth={1.5} width={size} />
    </div>
  );
};

export default ExpenseReviewCategoryIcon;
