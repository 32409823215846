import { get } from 'lodash';
import axios from 'axios';
import { setPayPalEmail, setReferrals, setReferralsContent } from '@app/src/actions/referralsActions';
import { serverUrl } from '@app/src/global/Environment';
import { getContentfulEntries } from '@app/src/services/contentfulService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

const baseUrl = serverUrl();

export const getReferrals = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}api/referrals/get-referrals`, {});

    if (data?.status !== 'ok') {
      throw new Error('Could not fetch referrals');
    }

    const referredUsers = get(data, 'data.referredUsers', []);
    dispatch(setReferrals(referredUsers));
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const updatePayPalEmail =
  ({ payPalEmail: paypal_email }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}api/profile/update-paypal-email`, { paypal_email });

      if (data?.status !== 'ok') {
        throw new Error(data?.msg || 'Failed to update PayPal email');
      }

      dispatch(setPayPalEmail(paypal_email));
    } catch (e) {
      defaultCaptureException(e);
    }
  };

const mapReferralsContentfulItem = (item) => {
  const { sys, fields } = item;

  return {
    id: sys.id,
    title: fields.title || '',
    summary: fields.body || '',
    articleSlug: fields.articleSlug || '',
    mainArticle: fields.mainArticle || false,
    icon_url: fields.icon ? fields.icon.fields.file.url : '',
    subArticles: Array.isArray(fields.subArticles)
      ? fields.subArticles.map((subItem) => ({
          id: subItem.sys.id,
          title: subItem.fields.title || '',
          summary: subItem.fields.body || '',
          articleSlug: subItem.fields.articleSlug || ''
        }))
      : []
  };
};

const getReferralsEntries = async () => {
  try {
    const entries = await getContentfulEntries('cashReferrals', {
      'fields.articleSlug[in]': 'referrals-tab-terms-and-conditions,referrals-tab-how-it-works',
      limit: 2
    });
    return entries.items.map((item) => mapReferralsContentfulItem(item));
  } catch (error) {
    defaultCaptureException(error);
    throw new Error('Failed to fetch referrals entries');
  }
};

export const loadReferralsContent = () => async (dispatch) => {
  try {
    const referralsEntries = await getReferralsEntries();
    dispatch(setReferralsContent(referralsEntries));
    return referralsEntries;
  } catch (error) {
    defaultCaptureException(error);
  }
};
