import React from 'react';
import classNames from 'classnames';

const OnboardingInfoItem = ({ className, question, replaceStrings }) => {
  return (
    <div className={classNames('form-group', className)}>
      <label className={question.header_style}>{replaceStrings(question.title)}</label>
    </div>
  );
};

export default OnboardingInfoItem;
