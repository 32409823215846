import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { deleteCollection, trackDeleteCollection } from '@app/src/taxflow/collection/services/collectionService';
import { formUploadAttemptsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { queryResultsSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowCollectionDeleteModal = ({
  collectionType,
  collectionId,
  className,
  deleteCollection,
  hideModal = _.noop,
  onDelete = _.noop
}) => {
  const onClose = () => {
    hideModal();
  };

  const onCancelClick = () => {
    hideModal();
  };

  const onDeleteClick = async () => {
    await deleteCollection({
      collectionType,
      collectionId
    });
    trackDeleteCollection({
      collectionType,
      collectionId
    });
    hideModal();
    onDelete({ collectionType, collectionId });
  };

  return (
    <Dialog className={classNames(className)} onClose={onClose} open={true}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogActions>
        <Button variant='contained' type='button' onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant='outlined' type='button' onClick={onDeleteClick}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  queryResults: queryResultsSelector(state),
  collectionDeleteModalCollectionType: _.get(state, ['taxFlowModals', 'collectionDeleteModalCollectionType']),
  collectionDeleteModalCollectionId: _.get(state, ['taxFlowModals', 'collectionDeleteModalCollectionId']),
  collectionDeleteModalSlug: _.get(state, ['taxFlowModals', 'collectionDeleteModalSlug']),
  formUploadAttempts: formUploadAttemptsSelector(state)
});

const mapDispatchToProps = {
  deleteCollection
};

const ConnectedTaxFlowCollectionDeleteModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowCollectionDeleteModal);

export default ConnectedTaxFlowCollectionDeleteModal;
