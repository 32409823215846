import _ from 'lodash';
import { getAnswers } from '@app/src/taxflow/common/services/workAnswers';

export const getManualInputStarted =
  ({ collectionType, collectionId }) =>
  async (dispatch) => {
    const res = await dispatch(
      getAnswers({
        queries: [
          {
            coll_type: collectionType,
            coll_id: collectionId
          }
        ]
      })
    );
    const data = _.get(res, ['data', 'data'], []);
    return data.some(
      (form) =>
        ![
          'income-freelance-job-name',
          'credit-healthcare-options',
          'credit-healthcare-employer-option',
          'credit-healthcare-1095a',
          'income-freelance-1099-type',
          'income-freelance-who',
          'income-freelance-industry',
          'income-freelance-business-code',
          'income-invest-unification',
          'income-invest-category',
          'credit-student-tuition-qualify',
          'credit-student-tuition-aotc-qualify'
        ].includes(form.slug) &&
        !(form.slug.endsWith('-started') || form.slug.endsWith('-form-upload') || form.slug.endsWith('-type'))
    );
  };
