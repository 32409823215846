import React from 'react';
import Button from '@mui/material/Button';
import classNames from 'classnames';

const TaxFlowActionButton = ({ className, children, ...props }) => {
  return (
    <Button className={classNames('action-button', className)} variant='outlined' {...props}>
      {children}
    </Button>
  );
};
export default TaxFlowActionButton;
