import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationInput from '@app/src/Components/TaxValidation/TaxValidationInput';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';

class TaxFlowTextItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * @desc save user input in question answer and set answer for analytics and signup
   */
  onChange = (result) => {
    this.props.setCurrentAnswer({ value: result });
  };

  render() {
    const { currentQuestion, currentAnswer, resultLoading, onFocus } = this.props;

    if (resultLoading) return null;

    return (
      <div className='steps-body'>
        <div className='tax-flow-input-wrap'>
          <TaxValidationInput
            question={currentQuestion}
            answer={currentAnswer}
            type='text'
            name={'text_' + currentQuestion.id}
            rows='5'
            placeholder={currentQuestion.question_meta.default}
            onChange={(e) => {
              this.onChange(e.target.value);
            }}
            value={currentAnswer && !_.isNil(currentAnswer.value) ? currentAnswer.value : ''}
            onFocus={onFocus}
          />
          <TaxFlowInfoButton currentQuestion={currentQuestion} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taxFlow: state.taxFlow,
  currentAnswer: state.taxFlow.currentAnswer,
  resultLoading: state.taxFlow.resultLoading
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowTextItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowTextItem);

export default ConnectedTaxFlowTextItem;
