import React from 'react';
import ExpenseReview from '@app/src/Components/ExpenseReview/ExpenseReview';
import ExpenseReviewCard from '@app/src/Components/ExpenseReview/ExpenseReviewCard';
import ExpenseReviewModal from '@app/src/Components/ExpenseReview/ExpenseReviewModal';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import TaxFlowNavigationPanel from '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel';
import '@app/src/Components/ExpenseReview/ExpenseReview.scss';

const ExpenseReviewContent = ({ location }) => (
  <StripeProvider>
    <div className='expense-review-wrapper'>
      <div className='expense-review-content'>
        <TaxFlowNavigationPanel />
        <div className='expense-review-content-center'>
          <ExpenseReviewCard location={location}>
            <ExpenseReview />
          </ExpenseReviewCard>
        </div>
      </div>
      <ExpenseReviewModal />
    </div>
  </StripeProvider>
);

export default ExpenseReviewContent;
