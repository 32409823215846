import React from 'react';
import getImageUrl from '@app/src/utils/getImageUrl';
import '@app/src/Components/HolisticOnboarding/components/PlaidSecurityNotice/PlaidSecurityNotice.scss';

const PlaidSecurityNotice = () => (
  <div className='plaid-security-notice-container'>
    <div className='plaid-security-notice-header'>
      <img className='plaid-logo' src={getImageUrl('plaid-link.svg')} alt='Connect your bank' />
    </div>
    <div className='plaid-security-notice-text'>To keep your data safe, we use Plaid which also powers:</div>
    <div className='plaid-security-notice-logo-container'>
      <img src={getImageUrl('venmo-logo.svg')} className='plaid-security-notice-logo' alt='Venmo' />
      <img src={getImageUrl('coinbase-logo.svg')} className='plaid-security-notice-logo' alt='Coinbase' />
    </div>
  </div>
);

export default PlaidSecurityNotice;
