import { useSnackbar } from 'notistack';
import '@app/src/Components/Snackbar/Snackbar.scss';

export let useSnackbarRef;

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();

  return null;
};
