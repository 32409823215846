import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { ArrowDown2 } from 'iconsax-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { setHolisticOnboardingStartDate } from '@app/src/actions/holisticOnboardingActions';
import { jobsSelector, jobDurationSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import { getMonths, getPreviousTwoYears } from '@app/src/utils/holisticOnboardingUtils';

const OnboardingJobDuration = ({ selectedDates, setHolisticOnboardingStartDate }) => {
  const months = getMonths();
  const years = getPreviousTwoYears();

  const handleSelect = ({ target: { name, value } }) => {
    setHolisticOnboardingStartDate({ ...selectedDates, [name]: value, ...(value === 'all' && { month: '1' }) });
  };

  return (
    <Stack spacing={1}>
      <Select
        onChange={handleSelect}
        name='year'
        IconComponent={() => <ArrowDown2 />}
        defaultValue=''
        value={selectedDates.year}
        fullWidth
        displayEmpty
      >
        <MenuItem value=''>Select a year</MenuItem>
        {years.map((year, idx) => (
          <MenuItem key={idx} value={year}>
            {year}
          </MenuItem>
        ))}
        <MenuItem value='all'>Before {years[1]}</MenuItem>
      </Select>
      {selectedDates?.year && selectedDates.year !== 'all' ? (
        <Select
          onChange={handleSelect}
          name='month'
          IconComponent={() => <ArrowDown2 />}
          defaultValue=''
          value={selectedDates.month}
          fullWidth
          displayEmpty
        >
          <MenuItem value=''>Select a month</MenuItem>
          {months.map((month, idx) => (
            <MenuItem key={idx} value={month}>
              {moment(month, 'M').format('MMMM')}
            </MenuItem>
          ))}
        </Select>
      ) : null}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  selectedDates: jobDurationSelector(state),
  selectedJobs: jobsSelector(state)
});

const mapDispatchToProps = { onContinue, setHolisticOnboardingStartDate };

const ConnectedOnboardingJobDuration = connect(mapStateToProps, mapDispatchToProps)(OnboardingJobDuration);

export default ConnectedOnboardingJobDuration;
