import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import TaxFlowBillContainer from '@app/src/Components/TaxFlow/Question/TaxFlowBillContainer';
import TaxFlowBillRow from '@app/src/Components/TaxFlow/Question/TaxFlowBillRow';
import { useGetTaxAmountsQuery } from '@app/src/api/taxDataApi';
import '@app/src/Components/TaxFlow/Question/TaxFlowBillElement.scss';

const TaxFlowBillElement = ({ className, setLoading = _.noop }) => {
  const { data: taxAmounts, isLoading: taxAmountsLoading } = useGetTaxAmountsQuery();

  useEffect(() => setLoading(taxAmountsLoading), [taxAmountsLoading, setLoading]);

  if (taxAmountsLoading) {
    return null;
  }

  const federalAmt = taxAmounts.federal_amount;
  const states = _.get(taxAmounts, 'state_amounts', []);

  const isBill = federalAmt >= 0 || states.some(({ amount }) => amount >= 0);
  const isRefund = federalAmt < 0 || states.some(({ amount }) => amount < 0);

  const getFederalBillLabel = (billDue) => {
    if (billDue) {
      return 'Federal tax bill due';
    }

    return 'Federal refund';
  };

  const getStateBillLabel = (state) => (billDue) => {
    if (billDue) {
      return `${state} State tax bill`;
    }

    return `${state} State refund`;
  };

  return (
    <TaxFlowBillContainer
      className={classNames(className, 'taxflow-bill-element')}
      title={`Your tax ${_.compact([isBill && 'bill', isRefund && 'refund']).join(' and ')}`}
    >
      <TaxFlowBillRow label={getFederalBillLabel} amount={federalAmt} />
      {states.map((state) => (
        <TaxFlowBillRow key={state.state} label={getStateBillLabel(state.state)} amount={state.amount} />
      ))}
    </TaxFlowBillContainer>
  );
};

export default TaxFlowBillElement;
