import React from 'react';
import { connect } from 'react-redux';
import TaxFlowBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowBillElement';
import TaxFlowPdfButton from '@app/src/Components/TaxFlow/Question/TaxFlowPdfButton';
import TaxFlowReviewPillsSection from '@app/src/Components/TaxFlow/Question/TaxFlowReviewPillsSection';
import { userSelector } from '@app/src/selectors/userSelectors';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { SLUG__SUBMIT_CONFIRMATION } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { currentQuestionSelector, resultLoadingSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitSummaryElement.scss';

const TaxFlowSubmitSummaryElement = ({ currentQuestion, resultLoading }) => {
  if (resultLoading) return null;

  return (
    <div className='taxflow-submit-summary'>
      <div className='taxflow-submit-summary-bill'>
        <TaxFlowBillElement />
      </div>
      <div className='taxflow-submit-summary-pills'>
        <TaxFlowReviewPillsSection></TaxFlowReviewPillsSection>
      </div>
      <div className='taxflow-submit-summary-elements'>
        {currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION && (
          <TaxFlowPdfButton className='taxflow-submit-summary-elements-pdf' />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  currentQuestion: currentQuestionSelector(state),
  resultLoading: resultLoadingSelector(state)
});

const mapDispatchToProps = {
  setSelfUnlockModalOpen
};

const ConnectedTaxFlowSubmitSummaryElement = connect(mapStateToProps, mapDispatchToProps)(TaxFlowSubmitSummaryElement);

export default ConnectedTaxFlowSubmitSummaryElement;
