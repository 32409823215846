import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TaxFlowPercentItem from '@app/src/Components/TaxFlow/Question/TaxFlowPercentItem';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

class TaxFlowFormPercentItem extends React.Component {
  onChange = (value) => {
    this.props.onChange({ slug: this.props.question.slug, value, endpoint_attr: this.props.question.endpoint_attr });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;

    return (
      <div className='form-group'>
        <TaxFlowPercentItem
          {...this.props}
          id={question.slug}
          isChildQuestion
          currentQuestion={question}
          currentAnswer={answer}
          onChange={this.onChange}
          onFocus={this.onFocus}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxFlowFormPercentItem = connect(mapStateToProps)(TaxFlowFormPercentItem);

export default ConnectedTaxFlowFormPercentItem;
