import React from 'react';
import { useImageHeight } from '@app/src/utils/assistantUtils';
import '@app/src/Components/Assistant/AssistantMessageAttachments.scss';

const AssistantMessageLinkPreview = ({ attachment, getImage }) => {
  const image = getImage(attachment.image_url);

  const [height, ref, onLoad] = useImageHeight(image);

  return (
    <a
      href={
        !attachment.og_scrape_url.startsWith('http') ? `https://${attachment.og_scrape_url}` : attachment.og_scrape_url
      }
      className='assistant-message-link-preview'
      target='_blank'
      rel='noopener noreferrer'
      ref={ref}
    >
      {attachment.image_url && (
        <img
          src={image.src}
          height={height}
          onLoad={onLoad}
          alt={attachment.title}
          className='assistant-message-image'
        />
      )}
      <div className='assistant-message-link-preview-content'>
        <div className='assistant-message-link-preview-title'>{attachment.title}</div>
        <div className='assistant-message-link-preview-description'>{attachment.text}</div>
      </div>
    </a>
  );
};

export default AssistantMessageLinkPreview;
