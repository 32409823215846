import {
  SET_ONBOARDING_JOB_CATEGORY_LIST,
  SET_ONBOARDING_MARKETING_CONTENT,
  SET_ONBOARDING_ERROR,
  SET_ONBOARDING_INITIAL_LOAD,
  SET_ONBOARDING_EXPERIMENTS,
  SET_ONBOARDING_TYPE,
  SET_ONBOARDING_NORMALIZED_QUESTIONS,
  SET_ONBOARDING_CURRENT_QUESTION,
  SET_ONBOARDING_CURRENT_ANSWER,
  SET_ONBOARDING_LOADING,
  SET_ONBOARDING_UPDATING,
  SET_ONBOARDING_CURRENT_CONTEXT
} from '@app/src/actions/types';

export const setOnboardingJobCategoryList = (payload) => ({
  type: SET_ONBOARDING_JOB_CATEGORY_LIST,
  payload
});

export const setOnboardingMarketingContent = (payload) => ({
  type: SET_ONBOARDING_MARKETING_CONTENT,
  payload
});

export const setOnboardingError = (payload) => ({
  type: SET_ONBOARDING_ERROR,
  payload
});

export const setOnboardingLoading = (payload) => ({
  type: SET_ONBOARDING_LOADING,
  payload
});

export const setOnboardingUpdating = (payload) => ({
  type: SET_ONBOARDING_UPDATING,
  payload
});

export const setInitialLoad = (payload) => ({
  type: SET_ONBOARDING_INITIAL_LOAD,
  payload
});

export const setOnboardingExperiments = (payload) => ({
  type: SET_ONBOARDING_EXPERIMENTS,
  payload
});

export const setType = (payload) => ({
  type: SET_ONBOARDING_TYPE,
  payload
});

export const setNormalizedQuestions = (payload) => ({
  type: SET_ONBOARDING_NORMALIZED_QUESTIONS,
  payload
});

export const setCurrentQuestion = (payload) => ({
  type: SET_ONBOARDING_CURRENT_QUESTION,
  payload
});

export const setCurrentAnswer = (payload) => ({
  type: SET_ONBOARDING_CURRENT_ANSWER,
  payload
});

export const setCurrentContext = (payload) => ({
  type: SET_ONBOARDING_CURRENT_CONTEXT,
  payload
});
