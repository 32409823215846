import React from 'react';
import classNames from 'classnames';
import { LOADING_BANNER_TITLE, BANK_SKIP_LOADING_BANNER_TITLE } from '@app/src/constants/loadingPageConstants';
import '@app/src/Components/Onboarding/LoadingBanner.scss';

const LoadingBanner = ({ bankLinkSkipped, hideBottomBorder, topPadding }) => (
  <div
    className={classNames('loading-banner', {
      'loading-banner-hide-bottom-border': hideBottomBorder,
      'loading-banner-top-padding': topPadding
    })}
  >
    <h1 className='loading-banner-title'>{bankLinkSkipped ? BANK_SKIP_LOADING_BANNER_TITLE : LOADING_BANNER_TITLE}</h1>
  </div>
);

export default LoadingBanner;
