import React from 'react';
import classNames from 'classnames';
import ExpenseReviewCategoryIcon from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryIcon';
import { colorNeutralGranite, colorPrimary } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCell.scss';

const ExpenseReviewCategoryCell = ({ id, isPersonal, value, className }) => {
  return (
    <div className={classNames('expense-review-category-cell', className)}>
      <ExpenseReviewCategoryIcon
        id={id}
        className='expense-review-category-cell-icon'
        color={isPersonal ? colorNeutralGranite : colorPrimary}
      />
      <span>{value}</span>
    </div>
  );
};

export default ExpenseReviewCategoryCell;
