export const incomeTypeQuestionContent = {
  id: '5CtVuixTMhoNaQpWw73MS0',
  title: 'What kinds of work do you do?',
  summary: 'This info allows Keeper to suggest tax savings. Select all that apply.',
  question_type: 'multi-option',
  next_question: '1xsQAKvO8DMlMyR5oZP1gy',
  required: true,
  question_meta: [
    {
      text: 'Independent  / freelance',
      image: '1099-income-type',
      value: 'contractor',
      sub_text: 'Contracting, side hustles, and self-employment',
      next_question_slug: 'onboarding-job-select'
    },
    {
      text: 'Employee',
      image: 'w2-income-type',
      value: 'salaried',
      sub_text: 'Full-time or part-time salaried or hourly employee',
      next_question_slug: 'onboarding-job-select'
    },
    {
      text: 'Business owner',
      image: 'llc-income-type',
      value: 'owner',
      sub_text: 'Includes LLCs, S corps, and partnerships',
      next_question_slug: 'onboarding-job-select'
    }
  ],
  question_meta_ext: {},
  endpoint_attr: 'onboarding-income-type',
  header_style: 'normal',
  slug: 'onboarding-income-type',
  section_id: '3VLQRN7afqMFEFEtesv4Ib',
  section: {
    metadata: {
      tags: []
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'xauw5r0xpp02'
        }
      },
      type: 'Entry',
      id: '3VLQRN7afqMFEFEtesv4Ib',
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'taxFlowNavigation'
        }
      },
      revision: 1,
      createdAt: '2019-12-17T19:38:35.290Z',
      updatedAt: '2019-12-17T19:38:40.137Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment'
        }
      },
      locale: 'en-US'
    },
    fields: {
      slug: 'onboarding',
      version: '2',
      title: 'Sign up for Keeper Tax',
      sectionLength: 14
    }
  },
  sub_section: {
    metadata: {
      tags: []
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'xauw5r0xpp02'
        }
      },
      type: 'Entry',
      id: '1JdIIl5uAVTw9Masd3ZzQS',
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'taxFlowNavigation'
        }
      },
      revision: 1,
      createdAt: '2019-12-17T19:38:53.441Z',
      updatedAt: '2019-12-17T19:38:57.859Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment'
        }
      },
      locale: 'en-US'
    },
    fields: {
      slug: 'onboarding-sub',
      version: '2',
      title: 'Sign up for Keeper Tax',
      sectionLength: 14
    }
  },
  nextQuestionOptions: [],
  preSignup: true,
  showWriteOffs: false,
  progressIndex: 10,
  info: null,
  infoItem: null,
  flow: 'onboarding',
  template: null,
  titleOrig: 'What kinds of work do you do?',
  summaryOrig: 'This info allows Keeper to suggest tax savings. Select all that apply.'
};
