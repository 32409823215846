import React from 'react';
import url from 'url';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RETURN_URL_KEY_NAME } from '@app/src/constants/constants';

class LoginRedirect extends React.Component {
  render() {
    const { redirectUrl } = this.props;

    return <Redirect to={redirectUrl} />;
  }
}

const getLoginRedirectUrl = ({ location }) => {
  let query = {};

  if (location.pathname !== '/' && location.pathname !== '/login') {
    const returnUrl = url.format({
      pathname: location.pathname,
      search: location.search.slice(1)
    });
    query = {
      [RETURN_URL_KEY_NAME]: returnUrl
    };
  }

  return url.format({
    pathname: '/login',
    query
  });
};

const mapStateToProps = (state, props) => ({
  redirectUrl: getLoginRedirectUrl({ location: props.location })
});

const mapDispatchToProps = {};

const ConnectedLoginRedirect = connect(mapStateToProps, mapDispatchToProps)(LoginRedirect);

export default ConnectedLoginRedirect;
