import _ from 'lodash';
import { createSelector } from 'reselect';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';

const profileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkInfo: builder.query({
      query: () => ({
        url: 'profile/workinfo',
        method: 'GET'
      }),
      providesTags: [TAGS.WORK_INFO],
      transformResponse: (response) => response.data
    }),
    updateWorkInfo: builder.mutation({
      query: (body) => ({
        url: 'profile/merge-workinfo',
        method: 'POST',
        body
      }),
      invalidatesTags: [TAGS.WORK_INFO, TAGS.TAX_ESTIMATE]
    }),
    getCampaign: builder.query({
      query: () => ({
        url: 'profile/get-campaign',
        method: 'GET'
      }),
      providesTags: [TAGS.CAMPAIGN],
      transformResponse: (response) => response.data.campaign
    }),
    updateCampaign: builder.mutation({
      query: ({ campaign }) => ({
        url: 'profile/update-campaign',
        method: 'POST',
        body: { campaign }
      }),
      invalidatesTags: [TAGS.CAMPAIGN]
    }),
    // TODO move all account details refs to make use of this endpoint
    getAccountDetails: builder.query({
      query: () => ({
        url: 'profile/account-details',
        method: 'GET'
      }),
      providesTags: [TAGS.ACCOUNT_DETAILS],
      transformResponse: (response) => response.data
    }),
    getRetroStatus: builder.query({
      query: ({ year }) => ({
        url: 'profile/run-retro',
        method: 'GET',
        params: { year }
      }),
      providesTags: (result, error, { year }) => [{ type: TAGS.RETRO_STATUS, id: _.toNumber(year) }],
      transformResponse: (response) => response.status
    }),
    getFeatures: builder.query({
      query: () => ({
        url: 'profile/features',
        method: 'GET'
      }),
      providesTags: [TAGS.FEATURES],
      transformResponse: (response) => response.data.features
    })
  })
});

export default profileApi;

export const {
  useGetWorkInfoQuery,
  useGetCampaignQuery,
  useUpdateCampaignMutation,
  useGetAccountDetailsQuery,
  useGetRetroStatusQuery,
  useGetFeaturesQuery,
  useUpdateWorkInfoMutation
} = profileApi;

// Actions

export const getCampaign = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: profileApi.endpoints.getCampaign.initiate, dispatch });

export const updateCampaign =
  ({ campaign }) =>
  async (dispatch) => {
    await dispatch(profileApi.endpoints.updateCampaign.initiate({ campaign }));
  };

export const getRetroStatus = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: profileApi.endpoints.getRetroStatus.initiate, dispatch });

export const getFeatures = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: profileApi.endpoints.getFeatures.initiate, dispatch });

// Selectors
export const campaignSelector = createSelector(
  [profileApi.endpoints.getCampaign.select()],
  ({ data: campaign }) => campaign
);

export const featuresSelector = createSelector(
  [profileApi.endpoints.getFeatures.select()],
  ({ data: features }) => features || []
);
