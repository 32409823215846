import React, { useState } from 'react';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import { bulkEditExpensesSelector } from '@app/src/selectors/expenseReviewSelectors';
import { bulkEditBase } from '@app/src/services/bulkEditService';

const ExpenseReviewModalBulkNote = ({ selectedExpenses, bulkEditBase, setExpenseReviewModalType }) => {
  const [note, setNote] = useState('');

  const handleSave = async () => {
    await bulkEditBase({ type: 'note', value: note });
    setExpenseReviewModalType(null);
  };

  return (
    <div style={{ minWidth: '480px' }}>
      <div className='expense-review-modal-add-header'>Edit {selectedExpenses?.length} selected transactions</div>
      <div className='expense-review-modal-add-line' />
      <Stack spacing={2}>
        <div>
          <InputLabel>Note</InputLabel>
          <OutlinedInput
            fullWidth
            placeholder='e.g. meeting with my client Tom'
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <Button variant='contained' color='secondary' fullWidth onClick={handleSave}>
          Save
        </Button>
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedExpenses: bulkEditExpensesSelector(state)
});

const mapDispatchToProps = {
  bulkEditBase,
  setExpenseReviewModalType
};

const ConnectedExpenseReviewModalBulkNote = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalBulkNote);

export default ConnectedExpenseReviewModalBulkNote;
