import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import CategoryUsageSection from '@app/src/Components/TaxProfile/FormSections/CategoryUsageSection';
import CreditsAndDeductionsSection from '@app/src/Components/TaxProfile/FormSections/CreditsAndDeductionsSection';
import HouseholdDetailsSection from '@app/src/Components/TaxProfile/FormSections/HouseholdDetailsSection';
import IncomeSection from '@app/src/Components/TaxProfile/FormSections/IncomeSection';
import TaxesPaidSection from '@app/src/Components/TaxProfile/FormSections/TaxesPaidSection';
import { TaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { trackActivity } from '@app/src/services/analyticsService';

const useJobType = (jobs = [], incomeTypes, allJobs) => {
  const incomeType = useMemo(() => {
    const isOwner = incomeTypes.includes('owner');

    const isContractor = incomeTypes.includes('contractor');

    if (isOwner && isContractor) {
      return 'business / freelancing';
    }

    if (isOwner) {
      return 'business';
    }

    return 'freelancing';
  }, [incomeTypes]);

  if (jobs.length === 1) {
    const job = _.find(allJobs, { slug: jobs[0] });

    const jobAsVerb = job?.jobAsVerb;

    if (job?.jobAsVerb) {
      return {
        jobType: jobAsVerb,
        incomeType
      };
    }
  }

  return {
    jobType: incomeType,
    incomeType
  };
};

const TaxProfileForm = ({ year, incomeTypes = [], allJobs, topJobCategories, onSubmit, values }) => {
  const methods = useForm({
    values,
    defaultValues: {
      state_residence: '',
      filing_status: '',
      meals: 0,
      travel: 0,
      agi: null,
      spouse_agi: null,
      spouse_w2_income: null,
      tax_calc_credits: {
        numDependents: 0,
        tuitionFeesAmt: null,
        mortgageIntAmt: null,
        charity: null,
        iraContrAmt: null
      },
      jobs: [],
      percent_phone: 50,
      home: 1,
      percent_home: 10,
      car: 1,
      percent_car: 50,
      public_transport: 1,
      percent_public_transport: 50,
      w2_federal_income_taxes_withheld: null,
      w2_state_income_taxes_withheld: null,
      spouse_w2_federal_income_taxes_withheld: null,
      spouse_w2_state_income_taxes_withheld: null
    }
  });

  const {
    watch,
    reset,
    formState: { isSubmitting }
  } = methods;

  const jobs = watch('jobs');

  const { jobType, incomeType } = useJobType(jobs, incomeTypes, allJobs);

  useEffect(() => {
    trackActivity('navigation: tax profile');
  }, []);

  useEffect(() => {
    reset(values);
  }, [reset, values]);

  return (
    <FormProvider {...methods}>
      <TaxProfileFormContext.Provider
        value={{
          year,
          jobType,
          allJobs,
          topJobCategories,
          incomeType,
          onSubmit: (property) => async (data) => {
            const previousValue = _.get(values, property);
            const newValue = _.get(data, property);

            if (previousValue === newValue) {
              return;
            }

            await onSubmit(data);

            trackActivity('tax profile changed', {
              property_changed: property,
              old: previousValue,
              new: newValue,
              origin: 'taxProfile'
            });
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '1.5',
            paddingBottom: 58,
            width: 450,
            cursor: isSubmitting ? 'progress' : undefined
          }}
        >
          <h2
            style={{
              margin: 0,
              fontSize: 24,
              fontWeight: 500
            }}
          >
            Tax profile
          </h2>
          <hr
            style={{
              marginTop: 0,
              marginBottom: 16,
              width: '100%'
            }}
          />
          <HouseholdDetailsSection />
          <IncomeSection />
          <CategoryUsageSection />
          <TaxesPaidSection />
          <CreditsAndDeductionsSection />
        </div>
      </TaxProfileFormContext.Provider>
    </FormProvider>
  );
};

export default TaxProfileForm;
