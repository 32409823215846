import axios from 'axios';
import {
  setBankList,
  setBankLoader,
  clearBankList,
  setBankResMsg,
  setChargeableAccount
} from '@app/src/actions/bankActions';
import { serverUrl } from '@app/src/global/Environment';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

const baseUrl = serverUrl();

/**
 * @desc Get Users bank list and dispatch request
 */
export const getBankSummaryV2 = (callback) => (dispatch) => {
  dispatch(setBankLoader(true));

  axios
    .get(`${baseUrl}api/profile/banklinks-new`)
    .then((response) => response.data)
    .then((res) => {
      dispatch(setBankLoader(false));
      const { status, data, msg } = res;
      if (status === 'ok') {
        dispatch(setBankList(data));
        if (callback) callback(1, data);
      } else {
        dispatch(clearBankList());
        if (callback) callback(0, msg);
      }
    })
    .catch((error) => {
      dispatch(setBankLoader(false));
      dispatch(clearBankList());
      if (callback) callback(0, error);
      defaultCaptureException(error);
    });
};

/**
 * @desc Re link Bank Account
 * @param {Object} obj
 */
export const relinkBank = (obj, callback) => {
  axios
    .post(`${baseUrl}api/plaid/relink-bank`, obj)
    .then((response) => response.data)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      callback(0, 'Error occured');
      defaultCaptureException(error);
    });
};

/**
 * @desc Get Bank Details
 * @param {Object} obj
 * @param {Function} callback
 */
export const getBankDetails = (obj, callback) => {
  axios
    .post(`${baseUrl}api/profile/bank-details`, obj)
    .then((response) => response.data)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      callback({ stats: 'error', msg: 'Error occured ' });
      defaultCaptureException(error);
    });
};

/**
 * @desc Change Account Type
 */
export const changeAccountType = (obj, callback) => () => {
  axios
    .post(`${baseUrl}api/plaid/edit-account-type`, obj)
    .then((response) => response.data)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      defaultCaptureException(error);
    });
};

/**
 * @desc get charge account details
 */
const getChargedAccount = () => async (dispatch) => {
  dispatch(setBankLoader(true));
  try {
    const res = await axios.get(`${baseUrl}api/plaid/charged-acct`);
    dispatch(setBankLoader(false));
    const { status, msg, data } = res.data;
    if (status === 'ok') {
      return data;
    } else {
      dispatch(setBankResMsg({ error: msg || 'Something went wrong, please try again' }));
    }
    return;
  } catch (error) {
    dispatch(setBankLoader(false));
    dispatch(setBankResMsg({ error: error.msg || 'Something went wrong, please try again' }));
    defaultCaptureException(error);
    return error;
  }
};

export const getChargeableAccount = () => async (dispatch) => {
  const data = await dispatch(getChargedAccount());
  dispatch(setChargeableAccount(data.customer));
};

export const removeSubAccount =
  ({ accountId, itemId, usage, institutionName }) =>
  async (dispatch, getState) => {
    const bankList = bankListSelector(getState());

    const updatedBankList = bankList.map((account) => {
      const updatedSubAccounts = account.subAccounts.map((subAccount) =>
        subAccount.account_id === accountId ? { ...subAccount, archived: 1 } : subAccount
      );

      return { ...account, subAccounts: updatedSubAccounts };
    });

    dispatch(setBankList(updatedBankList));

    await dispatch(archiveSubAccount({ accountId, itemId, usage, institutionName }));
  };

const archiveSubAccount =
  ({ accountId, itemId, institutionName, usage }) =>
  async (dispatch) => {
    try {
      await archiveSubAccountRemote({ accountId, itemId });
      await dispatch(getBankSummaryV2());
      trackActivity('bank sub-account removed', {
        institution_name: institutionName,
        usage,
        account_id: accountId
      });
    } catch (e) {
      defaultCaptureException(e);
    }
  };

const archiveSubAccountRemote = async ({ accountId, itemId }) => {
  const res = await axios.delete(`${baseUrl}api/plaid/archive-subaccount`, {
    params: {
      account_id: accountId,
      item_id: itemId
    }
  });

  return res;
};
