import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TaxEstimateDetails from '@app/src/Components/TaxProfile/TaxEstimateDetails';
import TaxProfileForm from '@app/src/Components/TaxProfile/TaxProfileForm';
import { useTaxEstimateInput } from '@app/src/Components/TaxProfile/useTaxEstimateInput';
import { useGetWorkInfoQuery, useUpdateWorkInfoMutation } from '@app/src/api/profileApi';
import { useCalculateTaxEstimateQuery } from '@app/src/api/taxDataApi';
import { jobCategoriesSelector, topJobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getJobCategoryList } from '@app/src/services/contentfulService';

const useTaxEstimate = (year) => {
  const args = useTaxEstimateInput(year);

  const result = useCalculateTaxEstimateQuery(...args);

  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (!(result.isSuccess && isFirstLoad.current)) {
      return;
    }

    trackActivity('navigation: tax estimate', {
      new_est_tax_bill: result.data?.federal?.taxesOwed,
      est_state_tax_bill: result.data?.state?.taxesOwed ?? 0
    });

    isFirstLoad.current = false;
  }, [result.data, result.isSuccess]);

  return result;
};

const TaxProfile = ({ year = 2023 }) => {
  const dispatch = useDispatch();
  const topJobCategories = useSelector(topJobCategoriesSelector);
  const allJobs = useSelector(jobCategoriesSelector);

  const { data: workinfo, isLoading } = useGetWorkInfoQuery();
  const [showForm, setShowForm] = useState(false);

  const {
    data: taxEstimate,
    error: taxEstimateError,
    isLoading: taxEstimateIsLoading,
    isUninitialized: taxEstimateIsUninitialized
  } = useTaxEstimate(year);

  const [updateWorkInfo] = useUpdateWorkInfoMutation();

  useEffect(() => {
    if (taxEstimateError) {
      setShowForm(true);
    }
  }, [taxEstimateError]);

  useEffect(() => {
    dispatch(getJobCategoryList());
  }, [dispatch]);

  if (isLoading) {
    return null;
  }

  if (showForm) {
    return (
      <TaxProfileForm
        year={year}
        onSubmit={updateWorkInfo}
        allJobs={allJobs}
        topJobCategories={topJobCategories}
        values={workinfo}
      />
    );
  }

  return (
    <TaxEstimateDetails
      year={year}
      navigateToForm={() => {
        setShowForm(true);
      }}
      isLoading={taxEstimateIsLoading || taxEstimateIsUninitialized || taxEstimateError}
      taxEstimate={taxEstimate}
    />
  );
};

export default TaxProfile;
