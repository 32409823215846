import {
  GET_AUTH_RES_ERROR,
  SET_CURRENT_USER,
  SET_AUTH_LOADER,
  SET_SETTINGS,
  SET_EMAIL_UNIQUE,
  SET_PHONE_UNIQUE
} from '@app/src/actions/types';

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

/**
 * @desc Set Auth Response Errors
 */
export const setAuthResponseError = (payload) => {
  return {
    type: GET_AUTH_RES_ERROR,
    payload: payload
  };
};

/**
 * @desc Set Auth Loader
 */
export const setAuthLoader = (payload) => {
  return {
    type: SET_AUTH_LOADER,
    payload: payload
  };
};

export const setSettings = (payload) => {
  return {
    type: SET_SETTINGS,
    payload: payload
  };
};

export const setEmailUnique = (payload) => {
  return {
    type: SET_EMAIL_UNIQUE,
    payload: payload
  };
};

export const setPhoneUnique = (payload) => {
  return {
    type: SET_PHONE_UNIQUE,
    payload: payload
  };
};
