import React, { useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TaxFormSmall from '@app/src/assets/tax-form-small.svg?react';
import { trackQuestionAnswer } from '@app/src/taxflow/main/services/mainService';
import { ENDPOINT_ATTRIBUTE__PAST_RETURNS, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

const TaxFlowPastReturnItem = ({ year, amounts, trackQuestionAnswer }) => {
  const federalAmount = Number(amounts.federal);

  const descriptions = useMemo(() => {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });

    const format = (amount) => formatter.format(Math.abs(Number(amount)));

    const formattedFederalAmount = format(amounts.federal);

    const descriptors = [];

    if (federalAmount >= 0) {
      descriptors.push(`${formattedFederalAmount} federal tax bill`);
    } else {
      descriptors.push(`${formattedFederalAmount} federal tax refund`);
    }

    if (amounts.states) {
      descriptors.push(
        ...amounts.states.map(({ amount, state }) => {
          const formattedStateAmount = format(amount);

          if (amount >= 0) {
            return `${formattedStateAmount} ${state} tax bill`;
          } else {
            return `${formattedStateAmount} ${state} tax refund`;
          }
        })
      );
    }

    return _.compact(descriptors);
  }, [amounts.federal, amounts.states, federalAmount]);

  return (
    <Link
      onClick={() => trackQuestionAnswer({ answer: year })}
      to={`/${TAXFLOW_BASE_URL}/${ENDPOINT_ATTRIBUTE__PAST_RETURNS}/${year}`}
      className='item'
    >
      <TaxFormSmall className='icon' />
      <div className='info'>
        <div className='title'>{year} Return</div>
        <div className='description'>
          {descriptions.map((description, idx) => (
            <div key={idx}>{description}</div>
          ))}
        </div>
      </div>
    </Link>
  );
};

const mapDispatchToProps = {
  trackQuestionAnswer
};

const ConnectedTaxFlowPastReturnItem = connect(null, mapDispatchToProps)(TaxFlowPastReturnItem);

export default ConnectedTaxFlowPastReturnItem;
