import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { CloseCircle, TickCircle } from 'iconsax-react';
import { connect } from 'react-redux';
import { pricingObjSelector } from '@app/src/selectors/pricingSelectors';
import { colorError, colorFontDark, colorSuccess } from '@app/src/theme';
import { getSubscriptionFeatures } from '@app/src/utils/subscriptionUtils';
import '@app/src/Components/Subscription/Common/SubscriptionTypeElement.scss';

export const SubscriptionTypeElement = ({
  type,
  onClick,
  focused,
  selected,
  style,
  priceOverride,
  children,
  pricingObj,
  ...props
}) => {
  const subscriptionFeatures = getSubscriptionFeatures(pricingObj?.isEligibleForReferralPricing);
  const { title, subtitle, price, frequency, features } = _.get(subscriptionFeatures, type, {});

  // If the user is eligible for referral pricing, it takes precedence,
  // even over a price override. This is because if the user has a price
  // override (aka they were grandfathered into a less expensive price),
  // they're necesarily ineligible for referral pricing, as it
  // is not their first time signing up.
  const formattedPrice = pricingObj?.isEligibleForReferralPricing ? price : priceOverride ?? price;

  const handleSelect = () => {
    onClick(type);
  };

  return (
    <Button
      className='subscription-type-element'
      onClick={handleSelect}
      style={{ ...style, padding: focused ? '16px' : '16px 16px 0' }}
      variant={selected ? 'subscription-type-selected' : 'subscription-type'}
      sx={{ height: 'fit-content', position: 'relative' }}
      {...props}
    >
      <div style={{ width: '100%' }}>
        <Stack direction='column' justifyContent='space-between'>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='column' justifyContent='space-between'>
              <div className='base-label title'>{title}</div>
              <div className='base-label subtitle'>{subtitle}</div>
            </Stack>
            <Stack direction='column' justifyContent='space-between'>
              <div style={{ color: colorFontDark, display: 'flex' }}>
                <span style={{ alignSelf: 'flex-start', fontSize: '12px' }}>$</span>
                <span className='base-label' style={{ fontSize: '24px' }}>
                  {formattedPrice}
                </span>
                <span style={{ alignSelf: 'center', fontSize: '12px' }}>{frequency}</span>
              </div>
              {type !== 'monthly' && (
                <div
                  className='base-label'
                  style={{ color: '#054628', backgroundColor: '#DBFFDB', padding: '4px 8px', borderRadius: '14px' }}
                >
                  ${Math.floor(formattedPrice / 12)} / mo
                </div>
              )}
            </Stack>
          </Stack>
          <Collapse in={focused}>
            <Stack direction='column' justifyContent='space-between' spacing={1}>
              {features.map((feature, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                  {feature.included ? (
                    <TickCircle variant='Bulk' color={colorSuccess} />
                  ) : (
                    <CloseCircle variant='Bulk' color={colorError} />
                  )}
                  <div style={{ marginLeft: '4px' }}>{feature.text}</div>
                </div>
              ))}
            </Stack>
          </Collapse>
        </Stack>
        {children}
      </div>
    </Button>
  );
};

const mapStateToProps = (state) => ({
  pricingObj: pricingObjSelector(state)
});

const SubscriptionTypeElementReferrals = connect(mapStateToProps, null)(SubscriptionTypeElement);

// eslint-disable-next-line import/no-unused-modules
export default SubscriptionTypeElementReferrals;
