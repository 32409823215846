import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { usePrevious } from 'react-use';
import TaxFlowPillsSection from '@app/src/Components/TaxFlow/Common/TaxFlowPillsSection';
import TaxFlowFormUploadItem from '@app/src/Components/TaxFlow/Question/TaxFlowFormUploadItem';
import { FIXED_CACHE_KEYS } from '@app/src/api/baseApi';
import { useGetFeaturesQuery } from '@app/src/api/profileApi';
import {
  useGenerateQuestionnaireMutation,
  useGetBulkUploadPillsQuery,
  useGetQuestionnaireProgressQuery,
  useGetTaxDataQuery,
  useUpdateTaxDataMutation
} from '@app/src/api/taxDataApi';
import { collectionFormUploadErrorModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { getErrors } from '@app/src/services/taxValidationService';
import { getUploadAttemptsStatuses } from '@app/src/taxFlowMessageEntrypoint';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import TaxFlowCollectionFormUploadErrorModal from '@app/src/taxflow/collection/components/TaxFlowCollectionFormUploadErrorModal';
import { getBulkUploadFormUrl } from '@app/src/taxflow/collection/utils/collectionUtils';
import { bulkUploadItemsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  COLLECTION_TYPE__SPECIAL,
  ENDPOINT_ATTRIBUTE__ALL_FORMS_UPLOADED
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';

const TaxFlowBulkUploadItem = ({ collectionFormUploadErrorModalShow, getUploadAttemptsStatuses, setLoading }) => {
  const history = useHistory();

  const { data: specialTaxData, isLoading: specialTaxDataLoading } = useGetTaxDataQuery({
    collectionType: 'special'
  });
  const { data: bulkUploadPills, isLoading: bulkUploadPillsLoading } = useGetBulkUploadPillsQuery();
  const { data: questionnaireProgress, isLoading: isQuestionnaireProgressLoading } = useGetQuestionnaireProgressQuery();
  const { data: features, isLoading: featuresLoading } = useGetFeaturesQuery();
  const [generateQuestionnaire] = useGenerateQuestionnaireMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.GENERATE_QUESTIONNAIRE
  });

  const previousBulkUploadPills = usePrevious(bulkUploadPills);
  const [pills, setPills] = useState([]);
  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [collectionDeleteModalTarget, setCollectionDeleteModalTarget] = useState({
    collectionType: undefined,
    collectionId: undefined
  });

  const isLoading =
    specialTaxDataLoading || bulkUploadPillsLoading || isQuestionnaireProgressLoading || featuresLoading;

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const navigateToLinkedQuestion = useCallback(
    ({ collectionType, collectionId, clickable, nextSlug, text }) => {
      trackActivity('bulk upload: pill clicked', { collectionType, collectionId, pillName: text });
      if (!clickable || _.isNil(nextSlug)) {
        return;
      }
      history.push(getBulkUploadFormUrl({ nextPathComponent: nextSlug, collectionId }));
    },
    [history]
  );
  const confirmPillDeletion = useCallback(({ collectionType, collectionId, text }) => {
    trackActivity('bulk upload: pill removal requested', { collectionType, collectionId, pillName: text });
    setShowCollectionDeleteModal(true);
    setCollectionDeleteModalTarget({
      collectionType: collectionType,
      collectionId
    });
  }, []);

  const onPillRemoved = () => {
    getUploadAttemptsStatuses();
    if (!questionnaireProgress?.questionnaireComplete) {
      generateQuestionnaire();
    }
  };

  const parseInProgress = _.some(bulkUploadPills, { loading: false });
  const priorParseInProgress = _.some(previousBulkUploadPills, { loading: true });

  // Re-generate questionnaire upon parse completion (we may need to ask a different set of questions)
  useEffect(() => {
    if (!questionnaireProgress?.questionnaireComplete && !parseInProgress && priorParseInProgress) {
      generateQuestionnaire();
    }
  }, [parseInProgress, priorParseInProgress, generateQuestionnaire, questionnaireProgress]);

  // Form upload UI analytics - report parse behavior as seen by the user
  useEffect(() => {
    if (_.isNil(previousBulkUploadPills)) {
      return;
    }
    const pillsWithLoadingChange = _.differenceWith(bulkUploadPills, previousBulkUploadPills, (pill, previousPill) =>
      _.isMatch(pill, _.pick(previousPill, ['collectionType', 'collectionId', 'loading']))
    );

    _.map(pillsWithLoadingChange, ({ collectionType, collectionId, loading, parseFailed, validationFailed }) => {
      if (loading) {
        trackActivity('bulk upload FE: parsing waiting', { collectionType, collectionId });
      } else {
        trackActivity('bulk upload FE: parsing complete', {
          success: !parseFailed && !validationFailed,
          uploadStatus: parseFailed ? 'failure' : 'success',
          collectionType,
          collectionId
        });
      }
    });
  }, [bulkUploadPills, previousBulkUploadPills]);

  useEffect(() => {
    setPills(
      _.map(
        bulkUploadPills,
        ({
          collectionType,
          collectionId,
          loading,
          text,
          validationFailed,
          parseFailed,
          clickable,
          formInputQuestionSlug
        }) => ({
          text,
          variant: validationFailed || parseFailed ? 'warning' : 'default',
          loading,
          removable: true,
          onClick: () =>
            navigateToLinkedQuestion({
              collectionType,
              collectionId,
              clickable,
              nextSlug: formInputQuestionSlug,
              text
            }),
          onRemove: () => confirmPillDeletion({ collectionType, collectionId, text })
        })
      )
    );
  }, [bulkUploadPills, setPills, navigateToLinkedQuestion, confirmPillDeletion]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <TaxFlowFormUploadItem isBulkUpload={true}></TaxFlowFormUploadItem>
      {!_.isEmpty(bulkUploadPills) && (
        <TaxFlowPillsSection sectionHeader={'Your forms'} pills={pills}></TaxFlowPillsSection>
      )}
      <AllFormsAddedButton
        bulkUploadPills={bulkUploadPills}
        specialTaxData={specialTaxData}
        generateQuestionnaire={generateQuestionnaire}
        features={features}
      />
      {showCollectionDeleteModal && (
        <TaxFlowCollectionDeleteModal
          collectionType={collectionDeleteModalTarget.collectionType}
          collectionId={collectionDeleteModalTarget.collectionId}
          hideModal={() => setShowCollectionDeleteModal(false)}
          onDelete={onPillRemoved}
        />
      )}
      {collectionFormUploadErrorModalShow && <TaxFlowCollectionFormUploadErrorModal />}
    </>
  );
};

const AllFormsAddedButton = ({ bulkUploadPills, specialTaxData, generateQuestionnaire, features }) => {
  const [updateTaxData] = useUpdateTaxDataMutation();

  const bulkOptional = _.some(features, { name: 'bulk-optional', value: 1 });
  const userNeedsToAddForms = _.isEmpty(bulkUploadPills) && !bulkOptional;

  const markAllFormsUploaded = () => {
    updateTaxData({
      taxData: [
        {
          coll_type: COLLECTION_TYPE__SPECIAL,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__ALL_FORMS_UPLOADED,
          value: '1'
        },
        {
          coll_type: COLLECTION_TYPE__SPECIAL,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: 'special-started',
          value: '1'
        }
      ],
      generateSharedCollectionId: false
    });
    generateQuestionnaire();
  };

  const userClaimsAllFormsUploaded = _.some(specialTaxData, {
    slug: ENDPOINT_ATTRIBUTE__ALL_FORMS_UPLOADED,
    value: '1'
  });

  if (userClaimsAllFormsUploaded) {
    return null;
  }

  return (
    <Button
      fullWidth
      size='large'
      variant='contained'
      disabled={userNeedsToAddForms || _.some(bulkUploadPills, { loading: true })}
      onClick={markAllFormsUploaded}
    >
      <div>{userNeedsToAddForms ? `Add tax forms to continue` : `I've uploaded all my forms`}</div>
    </Button>
  );
};

const mapStateToProps = (state) => ({
  collectionDeleteModalShow: _.get(state, ['taxFlowModals', 'collectionDeleteModalShow']),
  collectionFormUploadErrorModalShow: collectionFormUploadErrorModalShowSelector(state),
  bulkUploadItems: bulkUploadItemsSelector(state)
});

const mapDispatchToProps = {
  getErrors,
  setCurrentAnswer,
  getUploadAttemptsStatuses
};

const ConnectedTaxFlowBulkUploadItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowBulkUploadItem);

export default ConnectedTaxFlowBulkUploadItem;
