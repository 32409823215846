import _ from 'lodash';

export const isTooShort = ({ question, answer }) => {
  const minLength = _.get(question, ['question_meta', 'minLength']);
  if (minLength && _.get(answer, 'value')) {
    return +minLength > answer.value.length;
  }
};

export const isTooLarge = ({ question, answer }) => {
  const maxValue = _.get(question, ['question_meta', 'maxValue']);
  if (maxValue && _.get(answer, 'value')) {
    return answer.value > maxValue;
  }
};
