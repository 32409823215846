export const Url_EXPENSE_RETRO_LOADING_INITIAL = 'expense-retro-loading-initial';
export const Url_FACEBOOK_CLICK_ID_PARAM_KEY = 'fbclid';
export const Url_FACEBOOK_CLICK_ID_STORAGE_KEY = 'fbc';
export const Url_FACEBOOK_CLICK_ID_COOKIE_KEY = '_fbc';
export const Url_GOOGLE_CLICK_ID_PARAM_KEY = 'gclid';
export const Url_GOOGLE_CLICK_ID_STORAGE_KEY = 'gclid';
export const Url_ONBOARDING_DURATION = 'onboarding-duration';
export const Url_ONBOARDING_DURATION_MONTH = 'onboarding-duration-month';
export const Url_ONBOARDING_DURATION_YEAR = 'onboarding-duration-year';
export const Url_ONBOARDING_DURATION_DATE = 'onboarding-duration-date';
export const Url_ONBOARDING_ESTIMATE_REFUND = 'onboarding-estimate-refund';
export const Url_ONBOARDING_HOME = 'onboarding-home';
export const Url_ONBOARDING_INCOME_TYPE = 'onboarding-income-type';
export const Url_ONBOARDING_JOB_SELECT = 'onboarding-job-select';
export const Url_ONBOARDING_MEAL = 'onboarding-meal';
export const Url_ONBOARDING_SAVINGS_CALC = 'onboarding-savings-calc';
export const Url_ONBOARDING_TRANSPORT = 'onboarding-transport';
export const Url_ONBOARDING_TRAVEL = 'onboarding-travel';
export const Url_ONBOARDING_WRITE_OFFS = 'onboarding-write-offs';
export const Url_ONBOARDING_APP_DOWNLOAD = 'onboarding-app-download';
export const Url_ONBOARDING_VERIFY = 'onboarding-verify';
export const Url_PRESELECT_JOB_PARAM = 'job';
export const Url_SIGNUP = 'signup';
export const Url_SIGNUP_LINK = 'signup-link';
export const Url_SIGNUP_PHONE = 'signup-phone';
export const Url_SIGNUP_PHONE_ONLY = 'signup-phone-only';
export const Url_SIGNUP_PHONE_ONLY_TEST = 'signup-phone-only-test';
export const Url_TIKTOK_PARAM_KEY = 'ttclid';
export const Url_TIKTOK_STORAGE_KEY = 'ttclid';
export const Url_TUNE_TRANSACTION_PARAM_KEY = 'tid';
export const Url_TUNE_TRANSACTION_STORAGE_KEY = 'tune_transaction_id';
export const Url_ONBOARDING_ANCHOR_FIRST = 'onboarding-anchor-first';
export const Url_ONBOARDING_ANCHOR_LAST = 'onboarding-anchor-last';
export const Url_ONBOARDING_ANCHOR_BANK = 'onboarding-anchor-bank';
export const Url_SIGNUP_PHONE_ONLY_PHONE = 'signup-phone-only-phone';

// Experiments Key in Local Storage
export const ONBOARDING_EXPERIMENTS_KEY = 'onboardingExperiments';
