import React, { forwardRef } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import KeeperSelect from '@app/src/Components/Common/KeeperSelect/KeeperSelect';
import '@app/src/Components/TaxProfile/TaxProfileSelect.scss';

/**
 * @typedef {import('@mui/material/Select').SelectProps & { options: { value: string, label: string }[] }} TaxProfileSelectProps
 */
/** @type {React.FC<TaxProfileSelectProps>} */
const TaxProfileSelect = forwardRef(({ placeholder, options, id, label, error, ...props }, ref) => {
  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel className='tax-profile-select-label' id={`${id}Label`}>
        {label}
      </InputLabel>
      <KeeperSelect
        ref={ref}
        labelId={`${id}Label`}
        id={id}
        label={label}
        value=''
        renderValue={(value) => {
          if (value === '') {
            return <span className='tax-profile-select-placeholder'>{placeholder}</span>;
          }

          return options.find((option) => option.value === value)?.label;
        }}
        error={!!error}
        {...props}
      >
        <MenuItem value='' disabled>
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </KeeperSelect>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
});

export default TaxProfileSelect;
