import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isHeaderHiddenSelector } from '@app/src/selectors/taxFlowSelectors';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';
import { replaceStrings } from '@app/src/taxflow/common';
import '@app/src/Components/TaxValidation/TaxValidationInputLabel.scss';

const TaxValidationInputLabel = ({ question, hasError, isHeaderHidden, replaceStrings, htmlFor }) => {
  if (isHeaderHidden) return null;

  return (
    <InputLabel color={hasError ? 'error' : 'info'} className='tax-validation-input-label' htmlFor={htmlFor}>
      {replaceStrings(question.title)}
    </InputLabel>
  );
};

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector,
  isHeaderHidden: isHeaderHiddenSelector
});

const mapDispatchToProps = {
  replaceStrings
};

const ConnectedTaxValidationInputLabel = connect(mapStateToProps, mapDispatchToProps)(TaxValidationInputLabel);

export default ConnectedTaxValidationInputLabel;
