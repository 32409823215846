import React from 'react';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import { useWindowSize } from 'react-use';
import BasePill from '@app/src/Components/Common/BasePill/BasePill';
import ExpenseReviewCategoryCountPill from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCountPill';
import { useGetExpenseReviewDetailsQuery } from '@app/src/api/expensesApi';
import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCounts.scss';

const ExpenseReviewCategoryCounts = ({ expensesYear, loading, onClick = _.noop }) => {
  const { width: windowWidth } = useWindowSize();
  const { data: expenseReviewDetails, isLoading: expenseReviewDetailsLoading } = useGetExpenseReviewDetailsQuery({
    year: expensesYear
  });

  const maxCategories = _.min([10, windowWidth / 100]);

  const orderedCategoryCounts = _.chain(expenseReviewDetails)
    .get('categoryCounts')
    .map(({ writeOffs }, categoryId) => ({ count: writeOffs, categoryId }))
    .sortBy(({ count }) => -count)
    .value();

  const mostCommonCategories = orderedCategoryCounts.slice(0, maxCategories);
  const restCount = _.chain(orderedCategoryCounts).slice(maxCategories).sumBy('count').value();

  return (
    <Stack direction='row' spacing={0.5} onClick={onClick}>
      {loading || expenseReviewDetailsLoading ? (
        <>
          {Array.from({ length: 10 }).map((val, index) => (
            <ExpenseReviewCategoryCountPill loading key={index} />
          ))}
        </>
      ) : (
        <>
          {mostCommonCategories.map(({ categoryId, count }, index) => (
            <ExpenseReviewCategoryCountPill id={categoryId} count={count} key={index} />
          ))}
          {restCount > 0 && (
            <BasePill>
              <div className='category-tile-text'>{restCount} More</div>
            </BasePill>
          )}
        </>
      )}
    </Stack>
  );
};

export default ExpenseReviewCategoryCounts;
