import { Url_SIGNUP_PHONE } from '@app/src/constants/onboardingConstants';

const isQuestionPresent = ({ question }) => {
  if (question.slug === Url_SIGNUP_PHONE) {
    return false;
  }

  return true;
};

export default isQuestionPresent;
