import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import AnimatedNumber from '@app/src/Components/Common/AnimatedNumber/AnimatedNumber';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import { EXPENSE_CATEGORIES } from '@app/src/constants/constants';
import { expensesSelector } from '@app/src/selectors/dashboardSelectors';
import { CAR_ENDPOINT_ATTRIBUTES } from '@app/src/taxflow/sections/car/carConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowWorkMilesEstimated.scss';

const TaxFlowWorkMilesEstimated = ({ question, answer, parentAnswer, expenses, onChange }) => {
  const workMilesEstimated = useMemo(() => {
    if (_.isEmpty(parentAnswer)) {
      return 0;
    }
    const sumGasExpenses = _.sumBy(
      expenses.filter((e) => e.keeper_category_id === EXPENSE_CATEGORIES.GAS),
      'amount'
    );
    const pricePerGallon = Number(
      parentAnswer.value[CAR_ENDPOINT_ATTRIBUTES.MILEAGE_ESTIMATION_PRICE_PER_GALLON]?.value ?? 0
    );
    const milesPerGallon = Number(
      parentAnswer.value[CAR_ENDPOINT_ATTRIBUTES.MILEAGE_ESTIMATION_MILES_PER_GALLON]?.value ?? 0
    );
    const workPercent = Number(parentAnswer.value[CAR_ENDPOINT_ATTRIBUTES.MILEAGE_ESTIMATION_WORK_PERCENT]?.value ?? 0);

    const estimate =
      pricePerGallon > 0 ? Math.round((sumGasExpenses / pricePerGallon) * milesPerGallon * (workPercent / 100)) : 0;

    return estimate;
  }, [expenses, parentAnswer]);

  useEffect(() => {
    if (answer?.value != workMilesEstimated) {
      onChange({
        slug: question.slug,
        value: workMilesEstimated
      });
    }
  }, [answer, workMilesEstimated, onChange, question]);

  return (
    <div className='form-group'>
      <TaxValidationInputLabel question={question} />
      <div className='tax-flow-work-miles-estimated-container'>
        <div className='tax-flow-work-miles-estimated-amount'>
          <AnimatedNumber value={workMilesEstimated} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  expenses: expensesSelector(state)
});

const ConnectedTaxFlowWorkMilesEstimated = connect(mapStateToProps)(TaxFlowWorkMilesEstimated);

export default ConnectedTaxFlowWorkMilesEstimated;
