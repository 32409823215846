import { getWorkInfo } from '@app/src/services/workService';
import { getAnswersBackend, getAnswersFrontend } from '@app/src/taxflow/common/services/answers';

export const getAnswers = (params) => async (dispatch, getState) => {
  if (!getState().auth.isAuthenticated) {
    return getAnswersFrontend(params);
  } else {
    await dispatch(getWorkInfo());
    return dispatch(getAnswersBackend(params));
  }
};
