import React from 'react';
import { useSnackbarRef } from '@app/src/Components/Snackbar/Snackbar';
import { SnackbarActions } from '@app/src/Components/Snackbar/SnackbarActions';
import { trackActivity } from '@app/src/services/analyticsService';

export const notify = (message, options = {}) => {
  if (!message) return;

  trackActivity('snackbar: view', {
    type: options.variant || 'default',
    text: message,
    origin: options.origin || 'unknown'
  });

  useSnackbarRef.enqueueSnackbar(message, {
    ...options,
    action: <SnackbarActions message={message} data={options?.data} />
  });
};
