import React from 'react';
import _ from 'lodash';
import TaxFlowMoneyItem from '@app/src/Components/TaxFlow/Question/TaxFlowMoneyItem';
import TaxValidationInput from '@app/src/Components/TaxValidation/TaxValidationInput';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import '@app/src/Components/TaxFlow/Form/TaxFlowFormBox14Item.scss';

class TaxFlowFormBox14Item extends React.Component {
  onCodeChange = (e) => {
    this.props.onChange({
      slug: this.props.question.slug,
      value: { ..._.get(this.props.answer, 'value'), code: e.target.value },
      endpoint_attr: this.props.question.endpoint_attr
    });
  };

  onAmountChange = (value) => {
    this.props.onChange({
      slug: this.props.question.slug,
      value: { ..._.get(this.props.answer, 'value'), amount: value }
    });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;

    const codeQuestion = {
      ...question,
      question_meta: {
        ...question.question_meta,
        default: _.get(question, ['question_meta', 'defaultCode']),
        maxLength: 8
      }
    };
    const codeAnswer = { value: _.get(answer, ['value', 'code'], '') };

    const amountQuestion = {
      ...question,
      header_style: 'hidden',
      question_meta: {
        ...question.question_meta,
        default: _.get(question, ['question_meta', 'defaultAmount']),
        // Accounts for the extra characters from formatting with currency. Max 8 digits.
        maxLength: 12
      }
    };
    const amountAnswer = { value: _.get(answer, ['value', 'amount'], '') };

    return (
      <div className='taxflow-form-box-14-item form-group'>
        <TaxValidationLabel question={question} answer={answer} title={this.props.replaceStrings(question.title)} />
        <div className='taxflow-form-box-14-item-row'>
          <div className='taxflow-form-box-14-item-field'>
            <TaxValidationInput
              className='taxflow-form-box-14-item-input'
              question={codeQuestion}
              placeholder={_.get(codeQuestion, ['question_meta', 'default'])}
              value={_.get(codeAnswer, 'value')}
              onChange={this.onCodeChange}
              onFocus={this.onFocus}
            />
          </div>
          <TaxFlowMoneyItem
            className='taxflow-form-box-14-item-field'
            isChildQuestion
            currentQuestion={amountQuestion}
            currentAnswer={amountAnswer}
            inputMode='decimal'
            decimalScale={2}
            onChange={this.onAmountChange}
            onFocus={this.onFocus}
          />
        </div>
      </div>
    );
  }
}

export default TaxFlowFormBox14Item;
