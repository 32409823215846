import React from 'react';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import ResponseText from '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText';
import { setHolisticOnboardingDrive } from '@app/src/actions/holisticOnboardingActions';
import { driveSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import { autoAdvance } from '@app/src/utils/holisticOnboardingUtils';

const OnboardingDrive = ({ drive, setHolisticOnboardingDrive, onContinue }) => {
  const {
    location: { pathname },
    push
  } = useHistory();

  const handleSelect = (value) => {
    setHolisticOnboardingDrive(value);

    if (value === 0) {
      autoAdvance({ f: onContinue, push, pathname });
      return;
    }
  };

  return (
    <Stack spacing={1}>
      <ToggleButton selected={drive === 1} onClick={() => handleSelect(1)}>
        Yes, I drive for work!
      </ToggleButton>
      <ToggleButton selected={drive === 0} onClick={() => handleSelect(0)}>
        No, I don't
      </ToggleButton>
      <ResponseText pathname={pathname} visible={drive === 1} />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  drive: driveSelector(state)
});

const mapDispatchToProps = {
  onContinue,
  setHolisticOnboardingDrive
};

const ConnectedOnboardingDrive = connect(mapStateToProps, mapDispatchToProps)(OnboardingDrive);

export default ConnectedOnboardingDrive;
