import React from 'react';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import ExpenseReviewCategoryList from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryList';
import CloseIcon from '@app/src/assets/close.svg?react';
import { currencyWith2DecimalPlaces, numberWith0DecimalPlaces } from '@app/src/global/Helpers';
import {
  deductionsSelector,
  savingsAmountSelector,
  totalWriteOffsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { taxRateSelector } from '@app/src/selectors/workSelectors';
import '@app/src/Components/ExpenseReview/ExpenseReviewSidebarSavingsInfo.scss';

const ExpenseReviewSidebarSavingsInfo = ({ totalExpensesCount, savingsAmount, deductionsAmount, taxRate }) => (
  <>
    <h1 className='text-xl bold'>Estimated tax savings</h1>
    <div>
      <Divider />
    </div>
    <h4 className='savings-info-text'>
      This is the money you'll get back from the government at tax time, either as a tax refund or bill reduction.
    </h4>
    <div className='savings-info-section'>
      <div className='savings-info-item'>
        <div className='savings-info-item-content-column'>
          <div className='savings-info-label-text'>{currencyWith2DecimalPlaces(deductionsAmount)}</div>
          <div className='savings-info-caption'>Total deductions</div>
        </div>
        <div className='savings-info-item-content'>
          <CloseIcon />
        </div>
        <div className='savings-info-item-content-column'>
          <div className='savings-info-label-text'>{Math.floor(taxRate)}%</div>
          <div className='savings-info-caption'>Your tax rate</div>
        </div>
      </div>
      <div className='savings-info-item'>
        <div className='savings-info-item-content-row'>
          <hr className='savings-info-divider savings-info-divider-section-left' />
          <h2 className='savings-info-equal-sign'>=</h2>
          <hr className='savings-info-divider savings-info-divider-section-right' />
        </div>
      </div>
      <div className='savings-info-item'>
        <div className='savings-info-item-content savings-info-total'>
          <div className='savings-info-item-content-row'>
            <div className='savings-info-total-dollar-sign'>$</div>
            <div className='savings-info-total-amount'>{numberWith0DecimalPlaces(savingsAmount)}</div>
            <div className='savings-info-total-text'>Est. tax savings</div>
          </div>
        </div>
      </div>
      <div className='savings-info-item savings-info-item-content-column'>
        <div className='savings-info-title savings-info-quantity-text'>Your {totalExpensesCount} deductions</div>
        <ExpenseReviewCategoryList />
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  totalExpensesCount: totalWriteOffsSelector(state),
  savingsAmount: savingsAmountSelector(state),
  taxRate: taxRateSelector(state),
  deductionsAmount: deductionsSelector(state)
});

const mapDispatchToProps = {};

const ConnectedExpenseReviewSidebarSavingsInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseReviewSidebarSavingsInfo);

export default ConnectedExpenseReviewSidebarSavingsInfo;
