import React from 'react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import YearlyMoneyInput from '@app/src/Components/TaxProfile/YearlyMoneyInput';

/**
 * @typedef ControlledYearlyMoneyInputProps
 * @property {string} name
 * @property {string} label
 * @property {number} [defaultValue]
 * @property {...import('@mui/material/TextField').TextFieldProps} textFieldProps
 */

/** @type {React.FC<ControlledYearlyMoneyInputProps>} */
const ControlledYearlyMoneyInput = ({ name, label, defaultValue = null, textFieldProps }) => {
  const { handleSubmit } = useFormContext();

  const { onSubmit } = useTaxProfileFormContext();

  return (
    <Controller
      name={name}
      rules={{
        min: {
          value: 0,
          message: 'Amount cannot be negative'
        },
        max: {
          value: 1000000000,
          message: 'Amount too high'
        }
      }}
      render={({ field: { onChange, ...field }, fieldState }) => (
        <YearlyMoneyInput
          {...field}
          textFieldProps={{
            error: !!fieldState.error,
            helperText: fieldState.error?.message,
            ...textFieldProps
          }}
          onValueChange={(values, { event }) => {
            if (event?.type === 'change') {
              if (!_.isNil(values?.floatValue)) {
                onChange(values.floatValue);
              } else {
                onChange(defaultValue);
              }
            }
          }}
          onBlur={handleSubmit(onSubmit(field.name))}
          id={name}
          label={label}
        />
      )}
    />
  );
};

export default ControlledYearlyMoneyInput;
