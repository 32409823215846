import React, { useState } from 'react';
import _ from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxFlowFormMoneyItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormMoneyItem';
import { updateCurrentAnswer } from '@app/src/actions/taxFlowActions';
import {
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_AMOUNT,
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_DESCRIPTION
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlow1099KExpenses.scss';

const TaxFlow1099KExpenses = ({
  currentQuestion,
  resultLoading,
  updateCurrentAnswer,
  replaceStrings,
  currentAnswer
}) => {
  const [amounts, descriptions] = [
    SLUG__INCOME_FREELANCE_1099K_EXPENSES_AMOUNT,
    SLUG__INCOME_FREELANCE_1099K_EXPENSES_DESCRIPTION
  ].map((slug) => {
    const jsonArray = _.get(currentAnswer, ['value', slug, 'value']);
    return jsonArray ? JSON.parse(jsonArray) : [];
  });
  const [expenseCount, setExpenseCount] = useState(Math.max(amounts.length, 1));
  const maxNumberExpenses = _.get(currentQuestion, ['question_meta', 'maxNumberExpenses'], 10);
  const [amountQuestion, descriptionQuestion] = currentQuestion.sub_question;
  if (resultLoading) return null;

  return (
    <form className='steps-signup-form'>
      <div className={classNames('steps-body')}>
        {new Array(expenseCount).fill(0).map((notUsing, index) => {
          const descriptionI = descriptions[index] || '';
          const originalOptions = _.get(descriptionQuestion, ['question_meta', 'data']).map(({ value }) => value);
          const notUsedOptions = originalOptions.filter((d) => !descriptions.includes(d));

          const descriptionOptions = [...new Set([...notUsedOptions, ...(descriptionI ? [descriptionI] : [])])].map(
            (value) => ({ title: value, value })
          );

          return (
            <div key={currentQuestion.slug + index}>
              <h1>{'Expense ' + (index + 1)} </h1>
              <TaxFlowFormMoneyItem
                data-id={currentQuestion.slug + index}
                question={{ ...amountQuestion, slug: amountQuestion.slug + '-' + index }}
                answer={{ value: _.isNil(amounts[index]) ? '' : amounts[index] }}
                onChange={({ value }) => {
                  const newAmounts = [...amounts];
                  newAmounts[index] = value;
                  updateCurrentAnswer({
                    answer: {
                      value: JSON.stringify(newAmounts)
                    },
                    slug: amountQuestion.slug
                  });
                }}
                replaceStrings={replaceStrings}
              />
              <Autocomplete
                value={descriptionI}
                onChange={(event, newValue) => {
                  let description = _.get(newValue, 'title', '');
                  if (typeof newValue === 'string') {
                    description = newValue;
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    description = newValue.inputValue;
                  }
                  const newDescriptions = [...descriptions];
                  newDescriptions[index] = description;
                  updateCurrentAnswer({
                    slug: descriptionQuestion.slug,
                    answer: {
                      value: JSON.stringify(newDescriptions)
                    }
                  });
                }}
                filterOptions={(options, { inputValue }) => {
                  const filtered = options.filter(
                    ({ title }) => title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                  );
                  const isExisting = options.some((option) => inputValue.toLowerCase() === option.title.toLowerCase());
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Add "${inputValue}"`
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id={descriptionQuestion.slug + '-' + index}
                options={descriptionOptions}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  if (option.inputValue) return option.inputValue;
                  return option.value;
                }}
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={descriptionQuestion.title}
                    placeholder={_.get(descriptionQuestion, ['question_meta', 'placeholder'])}
                    inputProps={{
                      ...params.inputProps,
                      maxLength: _.get(descriptionQuestion, ['question_meta', 'maxLength'])
                    }}
                  />
                )}
              />
            </div>
          );
        })}
        <div className='tax-flow-input-wrap expense-top'>
          {expenseCount < maxNumberExpenses ? (
            <Button
              fullWidth
              variant='label'
              onClick={() => setExpenseCount(Math.min(expenseCount + 1, maxNumberExpenses))}
            >
              Add another expense
            </Button>
          ) : (
            'You have reached the maximum number of expenses.'
          )}
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: state.taxFlow.currentAnswer
});

const mapDispatchToProps = {
  updateCurrentAnswer
};

const ConnectedTaxFlow1099KExpenses = connect(mapStateToProps, mapDispatchToProps)(TaxFlow1099KExpenses);

export default ConnectedTaxFlow1099KExpenses;
