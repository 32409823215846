import {
  SET_TAXFILING_LOADER,
  SET_TAXFILING_ERROR,
  SET_TAXFILING_COLLECTION_TYPE_ITEMS,
  SET_TAXFILING_SELECTED_COLLECTION_TYPES,
  SET_TAXFILING_CURRENT_ANSWER,
  SET_TAXFILING_CURRENT_QUESTION,
  SET_TAXFILING_CURRENT_CONTEXT,
  RESET_TAXFILING_CURRENT_QUESTION,
  UPDATE_TAXFILING_CURRENT_ANSWER_BULK,
  UPDATE_TAXFILING_CURRENT_ANSWER,
  SET_TAXFILING_UPDATING,
  UPDATE_TAXFILING_CACHE,
  REPLACE_TAXFILING_QUESTIONS,
  REPLACE_TAXFILING_INFO_ITEMS,
  MERGE_TAXFILING_CURRENT_CONTEXT,
  MERGE_TAXFILING_CURRENT_QUESTION,
  SET_TAXFILING_CURRENT_CONTEXT_ONLY,
  SET_TAXFILING_CONTEXT_LOADING,
  SET_TAXFILING_INDUSTRIES,
  SET_TAXFILING_BUSINESS_CODES,
  SET_TAXFILING_RATING,
  SET_TAXFILING_HOVER_RATING,
  SET_TAXFILING_ALL_QUESTIONS,
  SET_TAXFILING_QUERY_RESULTS,
  SET_TAXFILING_QUESTIONS_ANSWERED,
  SET_TAXFILING_COGS_SUMMARY,
  SET_TAXFILING_CAR_PRIOR_DEPRECIATION,
  SET_TAXFILING_HOME_PRIOR_DEPRECIATION,
  SET_TAXFILING_FORM_UPLOAD_ATTEMPTS,
  SET_TAXFILING_DEDUCTIONS,
  SET_TAXFILING_APP_OS,
  SET_TAXFILING_IS_PAYMENT_VALID,
  SET_TAXFILING_CURRENT_COLLECTION_ID,
  SET_TAXFILING_CURRENT_TAX_STATE,
  SET_TAXFILING_IRS_PAYMENT_ACCOUNT,
  SET_TAXFILING_TAX_AMOUNTS,
  SET_TAXFILING_CURRENT_UPLOADS,
  SET_TAXFILING_SUBMIT_TIMESTAMP,
  SET_IS_NEXT_BUTTON_LOADING,
  SET_TAXFILING_DROPDOWN_WARNINGS,
  SET_TAXFILING_BUSINESS_CODE,
  SET_TAXFILING_IS_QUESTIONNAIRE_FLOW,
  SET_TAXFILING_QUESTION_WRAPPER,
  SET_TAXFILING_RESULT_LOADING,
  SET_TAXFILING_UPLOAD_ERROR,
  SET_TAXFILING_FORM_UPLOAD_STATUS,
  SET_TAXFILING_DROPZONE_FILES,
  SET_TAXFILING_DROPZONE_FILES_SAME_FORM,
  SET_TAXFILING_DROPZONE_LOADING,
  SET_TAXFILING_DROPZONE_FILES_READY_FOR_UPLOAD,
  SET_TAXFILING_CURRENT_GET_QUESTION_ANSWER_LOADING_SLUG,
  SET_TAXFILING_FORM_UPLOAD_KEYED_FILES,
  SET_TAXFILING_BULK_UPLOAD_ITEMS,
  SET_TAXFILING_SELECTED_BULK_MANUAL_ENTRY_ITEM,
  SET_TAXFILING_BULK_UPLOAD_PILLS,
  SET_TAXFILING_ADD_MORE_ITEMS,
  SET_TAXFILING_NAVIGATION_SECTIONS
} from '@app/src/actions/types';

export const initialState = {
  collectionTypeItems: [],
  selectedCollectionTypes: [],
  aboutYouPredictions: [],
  answers: [],
  resError: '',
  loading: true,
  jobQuestion: undefined,
  currentAnswer: {},
  currentContext: {},
  currentQuestion: {},
  resultLoading: true,
  contextLoading: false,
  updating: false,
  cache: {},
  normalizedQuestions: {
    allIds: [],
    byId: {}
  },
  normalizedInfoItems: {
    allIds: [],
    byId: {}
  },
  industries: [],
  businessCodes: [],
  rating: 0,
  hoverRating: 0,
  allQuestions: [],
  currentCollectionId: null,
  currentTaxState: null,
  queryResults: [],
  businessCode: null,
  cogsSummary: {},
  taxDeadline: {},
  deductions: {},
  carPriorDepreciation: 0,
  homePriorDepreciation: 0,
  irsPaymentAccount: {},
  taxAmounts: {},
  paid: null,
  formUploadAttempts: {},
  uploadError: null,
  appOs: null,
  isPaymentValid: false,
  navigationSections: [],
  currentUploads: [],
  isBulkUploadFlow: false,
  bulkUploadItems: [],
  bulkUploadPills: [],
  selectedBulkManualEntryItem: undefined,
  formUploadKeyedFiles: {},
  formUploadStatus: '',
  dropzoneFiles: [],
  dropzoneFilesReadyForUpload: false,
  currentQuestionnaireQuestion: null,
  questionnaireSummaryPills: [],
  questionnaireProgress: {},
  reviewPills: {},
  addMoreItems: [],
  questionWrapper: {},
  submitTimestamp: null,
  isNextButtonLoading: false,
  questionsAnswered: [],
  getQuestionAnswerLoadingSlug: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TAXFILING_COLLECTION_TYPE_ITEMS:
      return {
        ...state,
        collectionTypeItems: action.payload
      };

    case SET_TAXFILING_SELECTED_COLLECTION_TYPES:
      return {
        ...state,
        selectedCollectionTypes: action.payload
      };

    case SET_TAXFILING_ERROR:
      return {
        ...state,
        resError: action.payload
      };

    case SET_TAXFILING_LOADER:
      return {
        ...state,
        loading: action.payload
      };

    case SET_TAXFILING_CURRENT_ANSWER: {
      return {
        ...state,
        currentAnswer: action.payload,
        resultLoading: false
      };
    }

    case SET_TAXFILING_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: action.payload,
        currentAnswer: initialState.currentAnswer,
        currentContext: initialState.currentContext
      };
    }

    case SET_TAXFILING_CURRENT_CONTEXT: {
      return {
        ...state,
        currentContext: action.payload
      };
    }

    case SET_TAXFILING_CURRENT_CONTEXT_ONLY: {
      return {
        ...state,
        currentContext: action.payload
      };
    }

    case SET_TAXFILING_CONTEXT_LOADING: {
      return {
        ...state,
        contextLoading: action.payload
      };
    }

    case SET_TAXFILING_RESULT_LOADING: {
      return {
        ...state,
        resultLoading: action.payload
      };
    }

    case RESET_TAXFILING_CURRENT_QUESTION: {
      return {
        ...state,
        resultLoading: initialState.resultLoading,
        contextLoading: true
      };
    }

    case UPDATE_TAXFILING_CURRENT_ANSWER: {
      if (action.payload.replaceAll) {
        return {
          ...state,
          currentAnswer: action.payload.answer
        };
      }
      return {
        ...state,
        currentAnswer: {
          ...state.currentAnswer,
          value: {
            ...state.currentAnswer.value,
            ...(action.payload.collId
              ? {
                  [action.payload.collId]: {
                    ...state.currentAnswer.value[action.payload.collId],
                    [action.payload.slug]: {
                      value: action.payload.answer.value
                    }
                  }
                }
              : { [action.payload.slug]: action.payload.answer })
          }
        }
      };
    }

    case UPDATE_TAXFILING_CURRENT_ANSWER_BULK: {
      return {
        ...state,
        currentAnswer: {
          ...state.currentAnswer,
          value: {
            ...state.currentAnswer.value,
            ...action.payload
          }
        }
      };
    }

    case SET_TAXFILING_UPDATING: {
      return {
        ...state,
        updating: action.payload
      };
    }

    case UPDATE_TAXFILING_CACHE: {
      return {
        ...state,
        cache: {
          ...state.cache,
          ...action.payload
        }
      };
    }

    case REPLACE_TAXFILING_QUESTIONS: {
      return {
        ...state,
        normalizedQuestions: action.payload
      };
    }

    case REPLACE_TAXFILING_INFO_ITEMS: {
      return {
        ...state,
        normalizedInfoItems: action.payload
      };
    }

    case MERGE_TAXFILING_CURRENT_CONTEXT: {
      return {
        ...state,
        currentContext: {
          ...state.currentContext,
          ...action.payload
        }
      };
    }

    case MERGE_TAXFILING_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: {
          ...state.currentQuestion,
          ...action.payload
        }
      };
    }

    case SET_TAXFILING_INDUSTRIES: {
      return {
        ...state,
        industries: action.payload
      };
    }

    case SET_TAXFILING_BUSINESS_CODES: {
      return {
        ...state,
        businessCodes: action.payload
      };
    }

    case SET_TAXFILING_BULK_UPLOAD_ITEMS: {
      return {
        ...state,
        bulkUploadItems: action.payload
      };
    }

    case SET_TAXFILING_BULK_UPLOAD_PILLS: {
      return {
        ...state,
        bulkUploadPills: action.payload
      };
    }

    case SET_TAXFILING_SELECTED_BULK_MANUAL_ENTRY_ITEM: {
      return {
        ...state,
        selectedBulkManualEntryItem: action.payload
      };
    }

    case SET_TAXFILING_RATING: {
      return {
        ...state,
        rating: action.payload
      };
    }

    case SET_TAXFILING_HOVER_RATING: {
      return {
        ...state,
        hoverRating: action.payload
      };
    }

    case SET_TAXFILING_ALL_QUESTIONS: {
      return {
        ...state,
        allQuestions: action.payload
      };
    }

    case SET_TAXFILING_CURRENT_COLLECTION_ID: {
      return {
        ...state,
        currentCollectionId: action.payload
      };
    }

    case SET_TAXFILING_CURRENT_TAX_STATE: {
      return {
        ...state,
        currentTaxState: action.payload
      };
    }

    case SET_TAXFILING_QUERY_RESULTS: {
      return {
        ...state,
        queryResults: action.payload
      };
    }

    case SET_TAXFILING_QUESTIONS_ANSWERED: {
      return {
        ...state,
        questionsAnswered: action.payload
      };
    }

    case SET_TAXFILING_COGS_SUMMARY: {
      return {
        ...state,
        cogsSummary: action.payload
      };
    }

    case SET_TAXFILING_DEDUCTIONS: {
      return {
        ...state,
        deductions: action.payload
      };
    }

    case SET_TAXFILING_CAR_PRIOR_DEPRECIATION: {
      return {
        ...state,
        carPriorDepreciation: action.payload
      };
    }

    case SET_TAXFILING_HOME_PRIOR_DEPRECIATION: {
      return {
        ...state,
        homePriorDepreciation: action.payload
      };
    }

    case SET_TAXFILING_IRS_PAYMENT_ACCOUNT: {
      return {
        ...state,
        irsPaymentAccount: action.payload
      };
    }

    case SET_TAXFILING_TAX_AMOUNTS: {
      return {
        ...state,
        taxAmounts: action.payload
      };
    }

    case SET_TAXFILING_SUBMIT_TIMESTAMP: {
      return {
        ...state,
        submitTimestamp: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_ATTEMPTS: {
      return {
        ...state,
        formUploadAttempts: action.payload
      };
    }

    case SET_TAXFILING_UPLOAD_ERROR: {
      return {
        ...state,
        uploadError: action.payload
      };
    }

    case SET_TAXFILING_APP_OS: {
      return {
        ...state,
        appOs: action.payload
      };
    }

    case SET_TAXFILING_IS_PAYMENT_VALID: {
      return {
        ...state,
        isPaymentValid: action.payload
      };
    }

    case SET_TAXFILING_NAVIGATION_SECTIONS: {
      return {
        ...state,
        navigationSections: action.payload
      };
    }

    case SET_TAXFILING_CURRENT_UPLOADS: {
      return {
        ...state,
        currentUploads: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_KEYED_FILES: {
      return {
        ...state,
        formUploadKeyedFiles: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_STATUS: {
      return {
        ...state,
        formUploadStatus: action.payload
      };
    }

    case SET_TAXFILING_DROPZONE_LOADING: {
      return {
        ...state,
        dropzoneLoading: action.payload
      };
    }

    case SET_TAXFILING_DROPZONE_FILES: {
      return {
        ...state,
        dropzoneFiles: action.payload
      };
    }

    case SET_TAXFILING_DROPZONE_FILES_READY_FOR_UPLOAD: {
      return {
        ...state,
        dropzoneFilesReadyForUpload: action.payload
      };
    }

    case SET_TAXFILING_DROPZONE_FILES_SAME_FORM: {
      return {
        ...state,
        dropzoneFilesSameForm: action.payload
      };
    }

    case SET_TAXFILING_IS_QUESTIONNAIRE_FLOW: {
      return {
        ...state,
        isBulkUploadFlow: action.payload
      };
    }

    case SET_TAXFILING_QUESTION_WRAPPER: {
      return {
        ...state,
        questionWrapper: action.payload
      };
    }

    case SET_TAXFILING_ADD_MORE_ITEMS: {
      return {
        ...state,
        addMoreItems: action.payload
      };
    }

    case SET_IS_NEXT_BUTTON_LOADING: {
      return {
        ...state,
        isNextButtonLoading: action.payload
      };
    }

    case SET_TAXFILING_DROPDOWN_WARNINGS: {
      return {
        ...state,
        dropdownWarnings: action.payload
      };
    }

    case SET_TAXFILING_BUSINESS_CODE: {
      return {
        ...state,
        businessCode: action.payload
      };
    }

    case SET_TAXFILING_CURRENT_GET_QUESTION_ANSWER_LOADING_SLUG: {
      return {
        ...state,
        getQuestionAnswerLoadingSlug: action.payload
      };
    }

    default:
      return state;
  }
}
