// path components
export const CAR_PATH_COMPONENTS = {
  BRAND: 'car-brand',
  DEPRECIATION_AMOUNT_ESTIMATE: 'car-depreciation-amount-estimate',
  DEPRECIATION_AMOUNT_SPECIFIC: 'car-depreciation-amount-specific',
  DEPRECIATION_STRAIGHT_LINE: 'car-depreciation-straight-line',
  EXIT_BOUNCE: 'car-exit-bounce',
  LEASE: 'car-lease',
  LOAN: 'car-loan',
  MILEAGE_ESTIMATION: 'car-mileage-estimation',
  MILEAGE_SPECIFIC: 'car-mileage-specific',
  NAV_START: 'car-nav-start',
  NEW_USED: 'car-new-used',
  OPTIONS: 'car-options',
  OTHER_PERSONAL: 'car-other-personal',
  PAST_DEPRECIATION: 'car-past-depreciation',
  PAST_DEPRECIATION_KNOWN: 'car-past-depreciation-known',
  PURCHASE_DATA: 'car-purchase-data',
  SERVICE_DATE: 'car-service-date',
  WORK_MILES_TRACKED: 'car-work-miles-tracked',
  YEARS_DEPRECIATION: 'car-years-depreciation'
};

// slugs
export const CAR_SLUGS = {
  BRAND: 'car-brand',
  COST: 'car-cost',
  DEPRECIATION_AMOUNT_ESTIMATE: 'car-depreciation-amount-estimate',
  DEPRECIATION_AMOUNT_SPECIFIC: 'car-depreciation-amount-specific',
  DEPRECIATION_STRAIGHT_LINE: 'car-depreciation-straight-line',
  EXIT_BOUNCE: 'car-exit-bounce',
  LEASE: 'car-lease',
  LOAN: 'car-loan',
  MILEAGE_ESTIMATION: 'car-mileage-estimation',
  MILEAGE_ESTIMATION_GAS_EXPENSES: 'car-mileage-estimation-gas-expenses',
  MILEAGE_ESTIMATION_MILES_PER_GALLON: 'car-mileage-estimation-miles-per-gallon',
  MILEAGE_ESTIMATION_PRICE_PER_GALLON: 'car-mileage-estimation-price-per-gallon',
  MILEAGE_ESTIMATION_WORK_MILES_ESTIMATED: 'car-mileage-estimation-work-miles-estimated',
  MILEAGE_ESTIMATION_WORK_PERCENT: 'car-mileage-estimation-work-percent',
  MILEAGE_SPECIFIC: 'car-mileage-specific',
  MILEAGE_SPECIFIC_BUSINESS_MILEAGE: 'car-mileage-specific-business-mileage',
  NAV_START: 'car-nav-start',
  NEW_USED: 'car-new-used',
  OPTIONS: 'car-options',
  OTHER_PERSONAL: 'car-other-personal',
  PAST_DEPRECIATION: 'car-past-depreciation',
  PAST_DEPRECIATION_KNOWN: 'car-past-depreciation-known',
  PURCHASE_DATA: 'car-purchase-data',
  PURCHASE_DATE: 'car-purchase-date',
  SERVICE_DATE: 'car-service-date',
  WORK_MILES_TRACKED: 'car-work-miles-tracked',
  YEARS_DEPRECIATION: 'car-years-depreciation'
};

// endpoint attributes
export const CAR_ENDPOINT_ATTRIBUTES = {
  BRAND: 'car-brand',
  COST: 'car-cost',
  MILEAGE_ESTIMATION_MILES_PER_GALLON: 'car-mileage-estimation-miles-per-gallon',
  MILEAGE_ESTIMATION_PRICE_PER_GALLON: 'car-mileage-estimation-price-per-gallon',
  MILEAGE_ESTIMATION_WORK_PERCENT: 'car-mileage-estimation-work-percent',
  OPTIONS: 'car-options',
  SERVICE_DATE: 'car-service-date',
  YEARS_DEPRECIATION: 'car-years-depreciation'
};

// collection types
export const COLLECTION_TYPE__CAR = 'car';
