import React from 'react';
import _ from 'lodash';
import MailOpenedImage from '@app/src/assets/mail-opened.svg?react';
import '@app/src/Components/TaxFlow/Question/TaxFlowPremiumStartElement.scss';

const TaxFlowSupportAccessElement = ({ currentQuestion }) => {
  const premiumStartHeader = _.get(currentQuestion, ['question_meta', 'premiumStartHeader']);
  const premiumStartText = _.get(currentQuestion, ['question_meta', 'premiumStartText']);

  return (
    <div className='tax-flow-premium-start'>
      <div className='steps-head'>
        <MailOpenedImage />
        <h1>{premiumStartHeader}</h1>
        <p>{premiumStartText}</p>
      </div>
    </div>
  );
};

export default TaxFlowSupportAccessElement;
