import _ from 'lodash';
import { TAXFLOW__FORM_UPLOAD_VALID_STATES } from '@app/src/taxflow/collection/constants/formUploadConstants';
import {
  PATH_COMPONENT__INCOME_INVEST_INFO,
  PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_DIV_INFO,
  PATH_COMPONENT__INCOME_DIV_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_INTEREST_INFO,
  PATH_COMPONENT__INCOME_INTEREST_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_UNEMPLOYMENT_INFO,
  PATH_COMPONENT__INCOME_UNEMPLOYMENT_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_W2_INFO,
  PATH_COMPONENT__INCOME_W2_FORM_UPLOAD,
  PATH_COMPONENT__INCOME_W2_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_W2_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_W2G_INFO,
  PATH_COMPONENT__INCOME_W2G_EXIT_BOUNCE,
  SLUG__INCOME_INVEST_INFO,
  SLUG__INCOME_INVEST_EXIT_BOUNCE,
  SLUG__INCOME_DIV_INFO,
  SLUG__INCOME_DIV_EXIT_BOUNCE,
  SLUG__INCOME_INTEREST_INFO,
  SLUG__INCOME_INTEREST_EXIT_BOUNCE,
  SLUG__INCOME_UNEMPLOYMENT_INFO,
  SLUG__INCOME_UNEMPLOYMENT_EXIT_BOUNCE,
  SLUG__INCOME_W2_INFO,
  SLUG__INCOME_W2_EXIT_BOUNCE,
  SLUG__INCOME_W2G_INFO,
  SLUG__INCOME_W2G_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE,
  SLUG__INCOME_FREELANCE_EXIT_BOUNCE,
  COLLECTION_TYPE__INCOME_FREELANCE,
  PATH_COMPONENT__INCOME_FREELANCE_BUSINESS_CODE_INFO,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO,
  SLUG__INCOME_FREELANCE_JOB_NAME,
  PATH_COMPONENT__INCOME_FREELANCE_AGI,
  PATH_COMPONENT__INCOME_FREELANCE_1099_NEC,
  PATH_COMPONENT__INCOME_FREELANCE_1099_MISC,
  PATH_COMPONENT__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
  PATH_COMPONENT__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_AGI,
  SLUG__INCOME_FREELANCE_1099_MISC,
  SLUG__INCOME_FREELANCE_1099_TYPE,
  SLUG__INCOME_FREELANCE_1099_NEC,
  SLUG__INCOME_W2_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_TYPE,
  PATH_COMPONENT__INCOME_RETIREMENT_TYPE,
  SLUG__INCOME_RETIREMENT_PENSION_INFO,
  PATH_COMPONENT__INCOME_RETIREMENT_PENSION_INFO,
  SLUG__INCOME_RETIREMENT_SSA_INFO,
  PATH_COMPONENT__INCOME_RETIREMENT_SSA_INFO,
  PATH_COMPONENT__INCOME_RETIREMENT_EXIT_BOUNCE,
  SLUG__INCOME_RETIREMENT_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_UNEMPLOYMENT_FORM_UPLOAD,
  PATH_COMPONENT__INCOME_INTEREST_FORM_UPLOAD,
  PATH_COMPONENT__INCOME_DIV_FORM_UPLOAD,
  PATH_COMPONENT__INCOME_INVEST_FORM_UPLOAD,
  SLUG__INCOME_INVEST_FORM_UPLOAD,
  SLUG__INCOME_DIV_FORM_UPLOAD,
  SLUG__INCOME_INTEREST_FORM_UPLOAD,
  SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD,
  PATH_COMPONENT__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
  PATH_COMPONENT__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
  SLUG__INCOME_W2_PREFILL_LOADING,
  SLUG__INCOME_RETIREMENT_SSA_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_RETIREMENT_SSA_PREFILL_LOADING,
  SLUG__INCOME_RETIREMENT_PENSION_PREFILL_LOADING,
  SLUG__INCOME_INVEST_PREFILL_LOADING,
  SLUG__INCOME_INTEREST_PREFILL_LOADING,
  SLUG__INCOME_DIV_PREFILL_LOADING,
  SLUG__INCOME_UNEMPLOYMENT_PREFILL_LOADING,
  SLUG__INCOME_FREELANCE_1099_NEC_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_INVEST_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_DIV_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_INTEREST_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_UNEMPLOYMENT_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_FREELANCE_1099_NEC_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_FREELANCE_1099_MISC_PREFILL_LOADING,
  SLUG__INCOME_FREELANCE_1099_MISC_PREFILL_LOADING,
  PATH_COMPONENT__INCOME_RETIREMENT_PENSION_PREFILL_LOADING,
  SLUG__INCOME_INVEST_MORE_INFO,
  PATH_COMPONENT__INCOME_INVEST_MORE_INFO,
  PATH_COMPONENT__INCOME_FREELANCE_1099K_INFO,
  SLUG__INCOME_FREELANCE_1099K_INFO,
  PATH_COMPONENT__INCOME_FREELANCE_1099K_EXPENSES_INFO,
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
  SLUG__INCOME_FREELANCE_PAYERS_NAME,
  PATH_COMPONENT__INCOME_FREELANCE_1099_TYPE,
  SLUG__INCOME_FREELANCE_JOB,
  PATH_COMPONENT__INCOME_FREELANCE_JOB,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  PATH_COMPONENT__INCOME_PERSONAL_ITEMS,
  SLUG__INCOME_PERSONAL_ITEMS,
  PATH_COMPONENT__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE,
  SLUG__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__INCOME_INVEST_MORE_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_DIV_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_DIV_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },

    [SLUG__INCOME_INTEREST_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_INTEREST_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_UNEMPLOYMENT_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_UNEMPLOYMENT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_W2_INFO]: { nextPathComponent: PATH_COMPONENT__INCOME_W2_EXIT_BOUNCE, questionnaireEarlyExit: false },
    [SLUG__INCOME_W2G_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_W2G_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_W2_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_W2_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_1099_MISC]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_1099_NEC]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_1099_TYPE]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_JOB,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_RETIREMENT_PENSION_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_RETIREMENT_SSA_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_SSA_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_PENSION_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_UNEMPLOYMENT_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_W2_FORM_UPLOAD]: { nextPathComponent: PATH_COMPONENT__INCOME_W2_INFO, questionnaireEarlyExit: false },
    [SLUG__INCOME_INTEREST_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_INTEREST_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_INVEST_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_INVEST_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_DIV_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_DIV_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099_NEC,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099_MISC,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_AGI]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_1099K_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099K_EXPENSES_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO]: {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__INCOME_PERSONAL_ITEMS]: {
      nextPathComponent: PATH_COMPONENT__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    }
  };
};

export const getNextPathComponent = ({
  question,
  queryResults,
  businessCode,
  currentCollectionId,
  uploadAttempts,
  isCurrentCollectionAccessibleInBulk
}) => {
  if (question.slug === SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO) {
    return {
      ...getFreelanceUploadComponent({ queryResults, currentCollectionId }),
      questionnaireEarlyExit: isCurrentCollectionAccessibleInBulk
    };
  } else if (question.slug === SLUG__INCOME_FREELANCE_JOB) {
    if (businessCode) {
      return {
        ...getFreelanceUploadComponent({ queryResults, currentCollectionId }),
        questionnaireEarlyExit: isCurrentCollectionAccessibleInBulk
      };
    } else {
      return { nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_BUSINESS_CODE_INFO };
    }
  } else if (question.slug === SLUG__INCOME_INVEST_INFO) {
    const uploadAttempt = _.get(uploadAttempts, [question.collectionType, currentCollectionId]);
    if (
      TAXFLOW__FORM_UPLOAD_VALID_STATES.includes(_.get(uploadAttempt, 'status')) ||
      (_.get(uploadAttempt, 'status') === 'in_cloud_storage' && _.get(uploadAttempt, 'isDocOnly'))
    ) {
      return { nextPathComponent: PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE };
    }
    return { nextPathComponent: PATH_COMPONENT__INCOME_INVEST_MORE_INFO };
  } else if (question.slug === SLUG__INCOME_INVEST_MORE_INFO) {
    return { nextPathComponent: PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE };
  } else {
    return { nextPathComponent: null };
  }
};

const getFreelanceUploadComponent = ({ queryResults, currentCollectionId }) => {
  const freelance1099Type = _.get(
    getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
      slug: SLUG__INCOME_FREELANCE_1099_TYPE,
      collectionId: currentCollectionId
    }),
    ['answer', 'value']
  );

  if (freelance1099Type === 'nec') {
    return {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
      questionnaireNextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099_NEC
    };
  } else if (freelance1099Type === 'misc') {
    return {
      nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
      questionnaireNextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099_MISC
    };
  } else if (freelance1099Type === 'k') {
    return { nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099K_INFO };
  }
  return { nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_AGI };
};

export const getOptionPathComponentMap = () => {
  return {
    [SLUG__INCOME_RETIREMENT_TYPE]: {
      pension: {
        nextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
        questionnaireNextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_PENSION_INFO,
        questionnaireEarlyExit: false
      },
      ssa: {
        nextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
        questionnaireNextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_SSA_INFO,
        questionnaireEarlyExit: false
      }
    }
  };
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__INCOME_INVEST_FORM_UPLOAD]: SLUG__INCOME_INVEST_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_INVEST_PREFILL_LOADING]: SLUG__INCOME_INVEST_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_INVEST_INFO]: SLUG__INCOME_INVEST_INFO,
    [PATH_COMPONENT__INCOME_INVEST_MORE_INFO]: SLUG__INCOME_INVEST_MORE_INFO,
    [PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE]: SLUG__INCOME_INVEST_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_DIV_FORM_UPLOAD]: SLUG__INCOME_DIV_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_DIV_PREFILL_LOADING]: SLUG__INCOME_DIV_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_DIV_INFO]: SLUG__INCOME_DIV_INFO,
    [PATH_COMPONENT__INCOME_DIV_EXIT_BOUNCE]: SLUG__INCOME_DIV_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_INTEREST_FORM_UPLOAD]: SLUG__INCOME_INTEREST_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_INTEREST_PREFILL_LOADING]: SLUG__INCOME_INTEREST_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_INTEREST_INFO]: SLUG__INCOME_INTEREST_INFO,
    [PATH_COMPONENT__INCOME_INTEREST_EXIT_BOUNCE]: SLUG__INCOME_INTEREST_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_UNEMPLOYMENT_FORM_UPLOAD]: SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_UNEMPLOYMENT_PREFILL_LOADING]: SLUG__INCOME_UNEMPLOYMENT_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_UNEMPLOYMENT_INFO]: SLUG__INCOME_UNEMPLOYMENT_INFO,
    [PATH_COMPONENT__INCOME_UNEMPLOYMENT_EXIT_BOUNCE]: SLUG__INCOME_UNEMPLOYMENT_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_W2_INFO]: SLUG__INCOME_W2_INFO,
    [PATH_COMPONENT__INCOME_W2G_INFO]: SLUG__INCOME_W2G_INFO,
    [PATH_COMPONENT__INCOME_W2_FORM_UPLOAD]: SLUG__INCOME_W2_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_W2_PREFILL_LOADING]: SLUG__INCOME_W2_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_W2_EXIT_BOUNCE]: SLUG__INCOME_W2_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_W2G_EXIT_BOUNCE]: SLUG__INCOME_W2G_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_FREELANCE_JOB]: SLUG__INCOME_FREELANCE_JOB,
    [PATH_COMPONENT__INCOME_FREELANCE_1099_TYPE]: SLUG__INCOME_FREELANCE_1099_TYPE,
    [PATH_COMPONENT__INCOME_FREELANCE_AGI]: SLUG__INCOME_FREELANCE_AGI,
    [PATH_COMPONENT__INCOME_FREELANCE_1099K_INFO]: SLUG__INCOME_FREELANCE_1099K_INFO,
    [PATH_COMPONENT__INCOME_FREELANCE_1099K_EXPENSES_INFO]: SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
    [PATH_COMPONENT__INCOME_FREELANCE_1099_NEC]: SLUG__INCOME_FREELANCE_1099_NEC,
    [PATH_COMPONENT__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD]: SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_FREELANCE_1099_NEC_PREFILL_LOADING]: SLUG__INCOME_FREELANCE_1099_NEC_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_FREELANCE_1099_MISC]: SLUG__INCOME_FREELANCE_1099_MISC,
    [PATH_COMPONENT__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD]: SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_FREELANCE_1099_MISC_PREFILL_LOADING]: SLUG__INCOME_FREELANCE_1099_MISC_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_FREELANCE_BUSINESS_CODE_INFO]: SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO,
    [PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE]: SLUG__INCOME_FREELANCE_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_RETIREMENT_TYPE]: SLUG__INCOME_RETIREMENT_TYPE,
    [PATH_COMPONENT__INCOME_RETIREMENT_PENSION_INFO]: SLUG__INCOME_RETIREMENT_PENSION_INFO,
    [PATH_COMPONENT__INCOME_RETIREMENT_PENSION_FORM_UPLOAD]: SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_RETIREMENT_PENSION_PREFILL_LOADING]: SLUG__INCOME_RETIREMENT_PENSION_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_RETIREMENT_SSA_PREFILL_LOADING]: SLUG__INCOME_RETIREMENT_SSA_PREFILL_LOADING,
    [PATH_COMPONENT__INCOME_RETIREMENT_SSA_INFO]: SLUG__INCOME_RETIREMENT_SSA_INFO,
    [PATH_COMPONENT__INCOME_RETIREMENT_SSA_FORM_UPLOAD]: SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
    [PATH_COMPONENT__INCOME_RETIREMENT_EXIT_BOUNCE]: SLUG__INCOME_RETIREMENT_EXIT_BOUNCE,
    [PATH_COMPONENT__INCOME_PERSONAL_ITEMS]: SLUG__INCOME_PERSONAL_ITEMS,
    [PATH_COMPONENT__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE]: SLUG__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE
  };
};

export const getQuestionQueries = ({ question, collectionId }) => {
  if (question.slug === SLUG__INCOME_FREELANCE_JOB) {
    return [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_1099_TYPE
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_PAYERS_NAME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: SLUG__INCOME_FREELANCE_JOB_NAME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE
      }
    ];
  } else if (
    [
      SLUG__INCOME_FREELANCE_AGI,
      SLUG__INCOME_FREELANCE_1099_NEC,
      SLUG__INCOME_FREELANCE_1099_MISC,
      SLUG__INCOME_FREELANCE_1099K_INFO
    ].includes(question.slug)
  ) {
    return [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_JOB_NAME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_PAYERS_NAME
      }
    ];
  } else if (question.slug === SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO) {
    return [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_1099_TYPE
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_PAYERS_NAME
      }
    ];
  } else if (question.slug === SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO) {
    return [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_PAYERS_NAME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_1099_TYPE
      }
    ];
  } else {
    return [];
  }
};
