import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import DelayedLoader from '@app/src/Components/Common/DelayedLoader/DelayedLoader';
import SettingsContainer from '@app/src/Components/Settings/SettingsContainer';
import TaxFlowHead from '@app/src/Components/TaxFlow/Common/TaxFlowHead';
import TaxFlowResponseText from '@app/src/Components/TaxFlow/Common/TaxFlowResponseText';
import TaxFlowFormItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormItem';
import TaxFlowFormPercentageSplit from '@app/src/Components/TaxFlow/Form/TaxFlowFormPercentageSplit';
import TaxFlow1099BItem from '@app/src/Components/TaxFlow/Question/TaxFlow1099BItem';
import TaxFlow1099KExpenses from '@app/src/Components/TaxFlow/Question/TaxFlow1099KExpenses';
import TaxFlowAddMoreItem from '@app/src/Components/TaxFlow/Question/TaxFlowAddMoreItem';
import TaxFlowBusinessCategoryItem from '@app/src/Components/TaxFlow/Question/TaxFlowBusinessCategoryItem';
import TaxFlowCalendarItem from '@app/src/Components/TaxFlow/Question/TaxFlowCalendarItem';
import TaxFlowDateItem from '@app/src/Components/TaxFlow/Question/TaxFlowDateItem';
import TaxFlowDropdownItem from '@app/src/Components/TaxFlow/Question/TaxFlowDropdownItem';
import TaxFlowDropdownSearchItem from '@app/src/Components/TaxFlow/Question/TaxFlowDropdownSearchItem';
import TaxFlowExpensesLinkItem from '@app/src/Components/TaxFlow/Question/TaxFlowExpensesLinkItem';
import TaxFlowFormCaptureItem from '@app/src/Components/TaxFlow/Question/TaxFlowFormCaptureItem';
import TaxFlowFormInputDropdown from '@app/src/Components/TaxFlow/Question/TaxFlowFormInputDropdown';
import TaxFlowFormUploadItem from '@app/src/Components/TaxFlow/Question/TaxFlowFormUploadItem';
import TaxFlowInfoItem from '@app/src/Components/TaxFlow/Question/TaxFlowInfoItem';
import TaxFlowMilesItem from '@app/src/Components/TaxFlow/Question/TaxFlowMilesItem';
import TaxFlowMoneyItem from '@app/src/Components/TaxFlow/Question/TaxFlowMoneyItem';
import TaxFlowMultiOptionElement from '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionElement';
import TaxFlowNumberItem from '@app/src/Components/TaxFlow/Question/TaxFlowNumberItem';
import TaxFlowOptionItem from '@app/src/Components/TaxFlow/Question/TaxFlowOptionItem';
import TaxFlowPastReturnDetails from '@app/src/Components/TaxFlow/Question/TaxFlowPastReturnDetails';
import TaxFlowPastReturns from '@app/src/Components/TaxFlow/Question/TaxFlowPastReturns';
import TaxFlowPaymentElement from '@app/src/Components/TaxFlow/Question/TaxFlowPaymentElement';
import TaxFlowPaymentOptionsElement from '@app/src/Components/TaxFlow/Question/TaxFlowPaymentOptionsElement';
import TaxFlowPercentItem from '@app/src/Components/TaxFlow/Question/TaxFlowPercentItem';
import TaxFlowPrefillLoadingScreen from '@app/src/Components/TaxFlow/Question/TaxFlowPrefillLoadingScreen';
import TaxFlowPremiumStartElement from '@app/src/Components/TaxFlow/Question/TaxFlowPremiumStartElement';
import TaxFlowQuestionFooter from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionFooter';
import TaxFlowSliderItem from '@app/src/Components/TaxFlow/Question/TaxFlowSliderItem';
import TaxFlowSquareFootageItem from '@app/src/Components/TaxFlow/Question/TaxFlowSquareFootageItem';
import TaxFlowStandardGraph from '@app/src/Components/TaxFlow/Question/TaxFlowStandardGraph';
import TaxFlowSubmitConfirmationModal from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitConfirmationModal';
import TaxFlowSubmitEmailMyReturn from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitEmailMyReturn';
import TaxFlowSubmitSummaryElement from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitSummaryElement';
import TaxFlowSupportAccessElement from '@app/src/Components/TaxFlow/Question/TaxFlowSupportAccessElement';
import TaxFlowSwitchToDesktop from '@app/src/Components/TaxFlow/Question/TaxFlowSwitchtoDesktop';
import TaxFlowTextItem from '@app/src/Components/TaxFlow/Question/TaxFlowTextItem';
import TaxFlowWriteOffsElement from '@app/src/Components/TaxFlow/Question/TaxFlowWriteOffsElement';
import TaxFlowYearsItem from '@app/src/Components/TaxFlow/Question/TaxFlowYearsItem';
import {
  taxAmountsSelector,
  useGetBulkUploadPillsQuery,
  useGetCurrentQuestionnaireQuestionQuery
} from '@app/src/api/taxDataApi';
import {
  CATEGORY_TYPE_TEXT,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_YEARS,
  CATEGORY_TYPE_PERCENT,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD,
  CATEGORY_TYPE_DROPDOWN_SEARCH,
  CATEGORY_TYPE_TAXFLOW_INFO,
  CATEGORY_TYPE_TAXFLOW_FORM_CAPTURE,
  CATEGORY_TYPE_BUSINESS_CODE,
  CATEGORY_TYPE_TAXFLOW_FORM_INPUT_DROPDOWN,
  CATEGORY_TYPE_ADD_MORE,
  CATEGORY_TYPE_TAXFLOW_PREFILL_LOADING,
  CATEGORY_TYPE_EXPENSES_LINK
} from '@app/src/constants/constants';
import { isMobileBrowser, sentMsgToReactNative } from '@app/src/global/Helpers';
import { isConfirmationModalOpenSelector, isPaymentModalOpenSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import {
  isQuestionnaireFlowSelector,
  questionWrapperSelector,
  renderWideDesktopContentSelector,
  responseTextsSelector
} from '@app/src/selectors/taxFlowSelectors';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { allCollectionTypesSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { SLUG__CREDIT_STANDARD_RESULT } from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
  SLUG__INCOME_INVEST_INFO
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  SLUG__FIND_WRITE_OFFS,
  SLUG__PREMIUM_START
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { getClarifyingQuestionHeader } from '@app/src/taxflow/sections/special/utils/specialUtils';
import { SLUG__STATE_EXPENSES, SLUG__STATE_INCOME } from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_PAY_NOW,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_CONFIRM_ID_INTRO
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  SLUG__CONTACT_SUPPORT,
  TAXFLOW_BASE_URL,
  ENDPOINT_ATTRIBUTE__PAST_RETURNS,
  SLUG__PAST_RETURNS,
  PATH_COMPONENT__SWITCH_TO_DESKTOP
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  contextLoadingSelector,
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  queryResultsSelector,
  updatingSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowQuestionItem = (props) => {
  const {
    currentQuestion,
    replaceStrings,
    updating,
    contextLoading,
    responseTexts,
    isQuestionnaireFlow,
    questionWrapper,
    currentCollectionId,
    queryResults,
    renderWideDesktopContent
  } = props;

  const { data: currentQuestionnaireQuestion } = useGetCurrentQuestionnaireQuestionQuery();
  const { data: bulkUploadPills } = useGetBulkUploadPillsQuery();

  let learnMoreInfo = _.get(currentQuestion, 'learnMoreInfo');
  if (_.get(props, ['taxFlow', 'currentQuestion', 'slug']) === 'submit-signature') {
    const federalConsent = _.get(props, ['currentQuestion', 'learnMoreInfo', 'fields', 'description']);
    if (federalConsent) {
      const usersStates = queryResults.filter(({ slug }) => slug === 'state-return');
      const allStateConsents = _.get(props, ['taxFlow', 'currentQuestion', 'question_meta']);
      const stateConsentText = usersStates.reduce((acc, stateQuery) => {
        const stateCode = _.get(stateQuery, ['answer', 'value'], '');
        const stateConsent = _.get(allStateConsents, stateCode.toLowerCase());
        if (stateConsent) {
          acc += ` \n\n ${stateConsent.state}: ${stateConsent.text}`;
        }
        return acc;
      }, '');

      learnMoreInfo = {
        ...learnMoreInfo,
        fields: {
          ...props.currentQuestion.learnMoreInfo.fields,
          description: federalConsent + stateConsentText
        }
      };
    }
  }

  if (!currentQuestion || _.isEmpty(currentQuestion)) {
    return (
      <div className='wrapper-view'>
        <div className='steps-content-wrapper'>
          <div className='taxflow-wrapper-view-centered'>
            <DelayedLoader />
          </div>
        </div>
      </div>
    );
  }

  if (contextLoading) {
    sentMsgToReactNative('stop_native_loader');
  }

  const title = replaceStrings(currentQuestion.title);
  const summary = replaceStrings(currentQuestion.summary);
  const isFormType = currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM;
  const isFindWriteOffs = currentQuestion.slug === SLUG__FIND_WRITE_OFFS;
  const isPastReturns = currentQuestion.slug === SLUG__PAST_RETURNS;
  const displayHeader =
    !isFindWriteOffs &&
    !isPastReturns &&
    !(isQuestionnaireFlow && _.get(currentQuestionnaireQuestion, 'type') === 'form-review');

  const clarifyingFlowHeader =
    isQuestionnaireFlow &&
    getClarifyingQuestionHeader({
      currentQuestion,
      currentCollectionId,
      currentQuestionnaireQuestion,
      bulkUploadPills
    });

  return (
    <div className='wrapper-view'>
      {contextLoading ? (
        <div className='steps-content-wrapper'>
          <div className='taxflow-wrapper-view-centered'>
            <DelayedLoader />
          </div>
        </div>
      ) : (
        <>
          <div className={classNames('steps-content-wrapper', 'steps-wrapper')}>
            <div
              className={classNames('steps-content', {
                'steps-content-mobile': isMobileBrowser()
              })}
            >
              {isQuestionnaireFlow && (
                <TaxFlowHead
                  isQuestionnaireFlow
                  question={questionWrapper}
                  title={clarifyingFlowHeader?.title}
                  summary={clarifyingFlowHeader?.summary}
                />
              )}
              {displayHeader && (
                <TaxFlowHead
                  question={currentQuestion}
                  title={title}
                  summary={summary}
                  examples={currentQuestion.examples}
                  learnMoreInfo={learnMoreInfo}
                  learnMoreTitle={currentQuestion.learnMoreTitle}
                  renderWideDesktopContent={renderWideDesktopContent}
                />
              )}

              <div
                className={classNames({
                  'steps-body-wrapper': !renderWideDesktopContent,
                  'steps-body-wrapper-large': renderWideDesktopContent
                })}
              >
                {currentQuestion.question_type === CATEGORY_TYPE_ADD_MORE ? (
                  <TaxFlowAddMoreItem {...props}></TaxFlowAddMoreItem>
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_DROPDOWN_SEARCH ? (
                  <TaxFlowDropdownSearchItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD ? (
                  <TaxFlowFormUploadItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_CAPTURE ? (
                  <TaxFlowFormCaptureItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_INPUT_DROPDOWN ? (
                  <TaxFlowFormInputDropdown {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_PREFILL_LOADING ? (
                  <TaxFlowPrefillLoadingScreen {...props} />
                ) : null}
                {currentQuestion.slug === SLUG__SUBMIT_EMAIL_INFO ? <TaxFlowSubmitEmailMyReturn {...props} /> : null}
                {currentQuestion.question_type === CATEGORY_TYPE_OPTIONS &&
                currentQuestion.question_meta &&
                currentQuestion.slug !== SLUG__SUBMIT_EMAIL_INFO ? (
                  <TaxFlowOptionItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION ? (
                  <TaxFlowMultiOptionElement {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TEXT ? <TaxFlowTextItem {...props} /> : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_NUMBER ? (
                  <TaxFlowNumberItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN ||
                currentQuestion.question_type === CATEGORY_TYPE_STATE ? (
                  <TaxFlowDropdownItem currentQuestion={currentQuestion} {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SLIDER ? (
                  <TaxFlowSliderItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_MONEY ? <TaxFlowMoneyItem {...props} /> : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE ? (
                  <TaxFlowDateItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR ? (
                  <TaxFlowCalendarItem {...props} />
                ) : null}
                {currentQuestion.question_type === CATEGORY_TYPE_BUSINESS_CODE && (
                  <TaxFlowBusinessCategoryItem {...props} />
                )}
                {currentQuestion.question_type === CATEGORY_TYPE_EXPENSES_LINK && (
                  <TaxFlowExpensesLinkItem {...this.props} />
                )}
                {currentQuestion.slug === SLUG__SUBMIT_PAY_NOW ? <TaxFlowPaymentElement {...props} /> : null}
                {currentQuestion.slug === SLUG__SUBMIT_DEBIT ? <TaxFlowPaymentOptionsElement {...props} /> : null}
                {currentQuestion.slug === SLUG__SUBMIT_CONFIRM_ID_INTRO ? <TaxFlowInfoItem {...props} /> : null}
                {currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION ? <TaxFlowSubmitSummaryElement {...props} /> : null}
                {currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION && props.isConfirmationModalOpen ? (
                  <TaxFlowSubmitConfirmationModal {...props} />
                ) : null}
                {isFindWriteOffs ? <TaxFlowWriteOffsElement {...props} /> : null}
                {currentQuestion.slug === SLUG__INCOME_INVEST_INFO ? (
                  <TaxFlow1099BItem {...props} onNext={props.onNext} />
                ) : null}
                {currentQuestion.slug === SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO ? (
                  <TaxFlow1099KExpenses {...props} onNext={props.onNext} />
                ) : null}
                {currentQuestion.slug === SLUG__STATE_INCOME || currentQuestion.slug === SLUG__STATE_EXPENSES ? (
                  <TaxFlowFormPercentageSplit {...props} />
                ) : null}
                {currentQuestion.slug === SLUG__CONTACT_SUPPORT && <TaxFlowSupportAccessElement {...props} />}
                {currentQuestion.slug === SLUG__PREMIUM_START && <TaxFlowPremiumStartElement {...props} />}
                {currentQuestion.question_type === CATEGORY_TYPE_MILES ? <TaxFlowMilesItem {...props} /> : null}
                {currentQuestion.question_type === CATEGORY_TYPE_YEARS ? <TaxFlowYearsItem {...props} /> : null}
                {currentQuestion.question_type === CATEGORY_TYPE_PERCENT ? <TaxFlowPercentItem {...props} /> : null}
                {currentQuestion.question_type === CATEGORY_TYPE_SQUARE_FOOTAGE ? (
                  <TaxFlowSquareFootageItem {...props} />
                ) : null}
                {currentQuestion.slug === SLUG__CREDIT_STANDARD_RESULT && <TaxFlowStandardGraph />}
                {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_INFO && <TaxFlowInfoItem {...props} />}
                {isFormType &&
                ![
                  SLUG__INCOME_INVEST_INFO,
                  SLUG__SUBMIT_EMAIL_INFO,
                  SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO
                ].includes(currentQuestion.slug) ? (
                  <TaxFlowFormItem {...props} onNext={props.onNext} />
                ) : null}
                <TaxFlowResponseText responseTexts={responseTexts} replaceStrings={replaceStrings} />
                <Route
                  path={`/${TAXFLOW_BASE_URL}/${ENDPOINT_ATTRIBUTE__PAST_RETURNS}`}
                  component={() => <TaxFlowPastReturns {...props} />}
                  exact
                />
                <Route
                  path={`/${TAXFLOW_BASE_URL}/${ENDPOINT_ATTRIBUTE__PAST_RETURNS}/:year`}
                  component={() => <TaxFlowPastReturnDetails {...props} />}
                  exact
                />
                <Route
                  path={`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SWITCH_TO_DESKTOP}`}
                  component={() => <TaxFlowSwitchToDesktop {...props} />}
                  exact
                />
                <Route path={'/settings'} component={() => <SettingsContainer />} exact />
              </div>
            </div>
          </div>
        </>
      )}
      <TaxFlowQuestionFooter
        {...props}
        contextLoading={contextLoading}
        currentQuestion={currentQuestion}
        isUpdating={updating}
        NextButtonLabel={props.NextButtonLabel || currentQuestion.nextButtonLabel}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  taxFlow: state.taxFlow,
  taxAmounts: taxAmountsSelector(state),
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: currentAnswerSelector(state),
  queryResults: queryResultsSelector(state),
  replaceStrings: props.replaceStrings || _.identity,
  isConfirmationModalOpen: isConfirmationModalOpenSelector(state),
  isPaymentModalOpen: isPaymentModalOpenSelector(state),
  updating: updatingSelector(state),
  contextLoading: contextLoadingSelector(state),
  responseTexts: responseTextsSelector(state),
  isQuestionnaireFlow: isQuestionnaireFlowSelector(state),
  questionWrapper: questionWrapperSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  allCollectionTypes: allCollectionTypesSelector(state),
  renderWideDesktopContent: renderWideDesktopContentSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowQuestionItem = connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxFlowQuestionItem));

export default ConnectedTaxFlowQuestionItem;
