import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import BankLink from '@app/src/Components/BankLink/BankLink';
import Loader from '@app/src/Components/Common/Loader/Loader';
import { setBankInfo } from '@app/src/actions/bankActions';
import { getBankDetails } from '@app/src/services/bankService';
import '@app/src/Components/BankInfo/BankInfo.scss';

class BankInfo extends Component {
  componentDidMount() {
    const bankId = this.props.match.params.id;
    const bankObj = {
      type: 'relink',
      itemId: bankId
    };
    getBankDetails(bankObj, (res) => {
      const { status, data } = res;
      if (status === 'ok') {
        this.props.setBankInfo(data);
        window.analytics.track('navigation: bank relink page');
      } else {
        this.props.history.push('/bank');
      }
    });
  }

  render() {
    const { bankInfo, bankInfoLoading: progress } = this.props.bank;

    return (
      <>
        <Container>
          <div className='bank-info'>
            <h1>Your {bankInfo.institution_name} account has unlinked</h1>
            <p>
              Although rare, accounts can occasionally unlink. This might've been due to recent password change or bank
              security update.
            </p>

            <p>Relink now to continue watching your tax savings grow!</p>
            <Divider />
            <div className='bank-details'>
              {!bankInfo.linked && (bankInfo.source !== 'plaid' || bankInfo.public_token !== undefined) && (
                <BankLink className={'btn btn-primary mt-10'} type='relink' bankInfo={bankInfo}>
                  Relink Now
                </BankLink>
              )}
              {progress && <Loader />}
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bank: state.bank
});

const mapDispatchToProps = {
  setBankInfo
};

const ConnectedBankInfo = connect(mapStateToProps, mapDispatchToProps)(BankInfo);

export default ConnectedBankInfo;
