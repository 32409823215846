import _ from 'lodash';

export const overrideGetQueries = ({ queries }) => {
  return queries.map((query) => {
    if (query.slug === 'intro-tax-status') {
      return {
        coll_type: 'profile-self',
        coll_id: 'default',
        slug: 'profile-tax-status'
      };
    } else {
      return query;
    }
  });
};

export const overrideGetData =
  ({ queries, taxData }) =>
  async (dispatch, getState) => {
    const newData = [];
    queries.forEach((query) => {
      if (query.coll_type === 'profile-self' && query.coll_id === 'default' && !query.slug) {
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'profile-first-name',
          value: _.get(getState(), ['auth', 'user', 'firstname'])
        });
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'profile-last-name',
          value: _.get(getState(), ['auth', 'user', 'lastname'])
        });
      }

      if (query.coll_type === 'profile-home' && query.coll_id === 'default') {
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'profile-state',
          value: _.get(getState(), ['work', 'workDetails', 'state_residence'])
        });
      }

      if (query.coll_type === 'profile-self' && query.coll_id === 'default') {
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'state-dropdown',
          value: _.get(getState(), ['work', 'workDetails', 'state_residence'])
        });
      }

      if (query.coll_type === 'signup' && query.coll_id === 'default') {
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'signup-first-name',
          value: _.get(getState(), ['auth', 'user', 'firstname'])
        });

        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'signup-last-name',
          value: _.get(getState(), ['auth', 'user', 'lastname'])
        });

        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'signup-email',
          value: _.get(getState(), ['auth', 'user', 'email'])
        });

        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'signup-phone',
          value: _.get(getState(), ['auth', 'user', 'phone'], '').slice(1)
        });
      }

      if (query.slug === 'intro-state') {
        newData.push({
          coll_type: null,
          coll_id: null,
          slug: query.slug,
          value: _.get(getState(), ['work', 'workDetails', 'state_residence'])
        });
      }

      if (query.slug === 'job-selection' || query.slug === 'onboarding-job-select') {
        newData.push({
          coll_type: null,
          coll_id: null,
          slug: query.slug,
          value: _.get(getState(), ['work', 'workDetails', 'jobs'], []).join(';')
        });
      }

      if (query.slug === 'job-workstation' || query.slug === 'onboarding-home') {
        newData.push({
          coll_type: null,
          coll_id: null,
          slug: query.slug,
          value: _.get(getState(), ['work', 'workDetails', 'home'])
        });
      }

      if (query.slug === 'job-meal' || query.slug === 'onboarding-meal') {
        newData.push({
          coll_type: null,
          coll_id: null,
          slug: query.slug,
          value: _.get(getState(), ['work', 'workDetails', 'meals'])
        });
      }

      if (query.slug === 'job-travel' || query.slug === 'onboarding-travel') {
        newData.push({
          coll_type: null,
          coll_id: null,
          slug: query.slug,
          value: _.get(getState(), ['work', 'workDetails', 'travel'])
        });
      }

      if (query.slug === 'job-transport' || query.slug === 'onboarding-transport') {
        newData.push({
          coll_type: null,
          coll_id: null,
          slug: query.slug,
          value: [...(_.get(getState(), ['work', 'workDetails', 'car']) ? ['car'] : [])].join(';')
        });
      }

      if (query.coll_type === 'expense-percentages' && query.coll_id === 'default') {
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'percentage-car-present',
          value: _.get(getState(), ['work', 'workDetails', 'percent_car'])
        });
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'percentage-transport-present',
          value: _.get(getState(), ['work', 'workDetails', 'percent_public_transport'])
        });
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'percentage-home-present',
          value: _.get(getState(), ['work', 'workDetails', 'percent_home'])
        });
        newData.push({
          coll_type: query.coll_type,
          coll_id: query.coll_id,
          slug: 'percentage-phone-present',
          value: _.get(getState(), ['work', 'workDetails', 'percent_phone'])
        });
      }
    });

    const getData = [];
    taxData.forEach((item) => {
      if (item.coll_type === 'profile-self' && item.coll_id === 'default' && item.slug === 'profile-tax-status') {
        getData.push({
          slug: 'intro-tax-status',
          value: item.value
        });
      }

      getData.push(item);
    });

    return [...getData, ...newData];
  };
