import React, { memo, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useSignedUrl } from '@app/src/services/assistantService';
import { useImageHeight } from '@app/src/utils/assistantUtils';

const AssistantImageFileAttachment = ({ attachment, getImage }) => {
  const { signedUrl, thumbnailSignedUrl } = useSignedUrl(attachment.key);

  const image = useMemo(() => {
    const result = getImage(thumbnailSignedUrl || signedUrl);

    result.width = thumbnailSignedUrl ? attachment.thumbnailWidth : attachment.width;
    result.height = thumbnailSignedUrl
      ? attachment.thumbnailWidth * (attachment.height / attachment.width)
      : attachment.height;

    return result;
  }, [attachment.height, attachment.thumbnailWidth, attachment.width, getImage, signedUrl, thumbnailSignedUrl]);

  const [computedHeight, ref, onLoad] = useImageHeight(image);

  if (!signedUrl) {
    return <Skeleton variant='rectangular' ref={ref} height={computedHeight} />;
  }

  return (
    <a href={signedUrl} target='_blank' rel='noreferrer' className='assistant-message-image-link'>
      <img
        src={image.src}
        alt={attachment.filename}
        height={computedHeight}
        ref={ref}
        onLoad={onLoad}
        className='assistant-message-image'
      />
    </a>
  );
};

const MemoizedAssistantImageFileAttachment = memo(
  AssistantImageFileAttachment,
  (prevProps, nextProps) => prevProps.attachment.key === nextProps.attachment.key
);

export default MemoizedAssistantImageFileAttachment;
