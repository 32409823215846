import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import getProgressMap from '@app/src/utils/onboarding/getProgressMap';

function OnboardingProgressBar({ question }) {
  if (!question) return null;

  const progressMap = getProgressMap();
  const steps = Object.keys(progressMap);
  if (_.isEmpty(steps)) return null;

  const progress = progressMap[question.slug];
  if (_.isNil(progress)) return null;

  return (
    <div className='keeper-progress-bar'>
      {steps.map((step, i) => {
        const stepNumber = progressMap ? progressMap[step] : i;
        return (
          <div
            key={i}
            className={classNames('keeper-progress-bar-step', {
              active: stepNumber <= progress
            })}
          ></div>
        );
      })}
    </div>
  );
}

export default OnboardingProgressBar;
