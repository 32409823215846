import { DASHBOARD2__SET_EXPENSES, DASHBOARD2__SET_CATEGORIES } from '@app/src/constants/dashboardConstants';

const initialState = {
  expenses: [],
  categories: []
};

const dashboardEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD2__SET_EXPENSES: {
      return {
        ...state,
        expenses: action.expenses
      };
    }

    case DASHBOARD2__SET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories
      };
    }

    default: {
      return state;
    }
  }
};

export default dashboardEntityReducer;
