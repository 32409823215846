import _ from 'lodash';
import axios from 'axios';
import { setSettings } from '@app/src/actions/authActions';
import {
  setIsEmailLoading,
  setIsFirstnameLoading,
  setIsLastnameLoading,
  setIsPhoneLoading
} from '@app/src/actions/settingsActions';
import { setWorkDetails, setWorkLoader, updateWorkCategories } from '@app/src/actions/workActions';
import { getFeatures } from '@app/src/api/profileApi';
import { serverUrl, CONTENTFUL_CONTENT_WORK_CATEGORY_TYPE } from '@app/src/global/Environment';
import { userSettingsSelector } from '@app/src/selectors/authSelectors';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getNewToken, redirectIfUnauthorized, validateLoginOtpBase } from '@app/src/services/authService';
import { getContentfulEntries } from '@app/src/services/contentfulService';
import { getAnswersFrontend } from '@app/src/taxflow/common/services/answers';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

const createJobDateMap = (jobDates) => {
  return _.assign(
    {},
    ...jobDates.map((job) => ({
      [job.job]:
        job.dates[0] === 'all'
          ? { value: 0, months: _.range(12) }
          : { value: 1, months: job.dates.map((x) => parseInt(x)).filter((x) => !_.isNaN(x)) }
    }))
  );
};

/**
 * @desc Get Users list and dispatch request
 */
export const getWorkInfo = () => async (dispatch, getState) => {
  // user is authenticated => use workinfo endpoint
  // user is NOT authenticated => use local storage

  if (!getState().auth.isAuthenticated) {
    const answers = await getAnswersFrontend({
      queries: [
        {
          slug: 'onboarding-job-select'
        },
        {
          slug: 'onboarding-transport'
        },
        {
          slug: 'onboarding-home'
        },
        {
          slug: 'onboarding-meal'
        },
        {
          slug: 'onboarding-travel'
        }
      ]
    });
    const data = _.get(answers, ['data', 'data'], []);

    const jobNames = _.get(
      data.find((d) => d.slug === 'onboarding-job-select'),
      'value',
      ''
    )
      .split(';')
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));

    const hasCar =
      `${_.get(
        data.find((d) => d.slug === 'onboarding-transport'),
        'value',
        '0'
      )}` === '1';
    const hasHomeOffice =
      `${_.get(
        data.find((d) => d.slug === 'onboarding-home'),
        'value',
        '0'
      )}` === '1';
    const hasMeals =
      `${_.get(
        data.find((d) => d.slug === 'onboarding-meal'),
        'value',
        '0'
      )}` === '1';
    const hasTravel =
      `${_.get(
        data.find((d) => d.slug === 'onboarding-travel'),
        'value',
        '0'
      )}` === '1';

    const workDetails = {
      jobs: jobNames,
      car: hasCar,
      home: hasHomeOffice,
      meals: hasMeals,
      travel: hasTravel
    };

    dispatch(setWorkDetails(workDetails));
    return;
  }

  dispatch(setWorkLoader());

  return axios
    .get(`${baseUrl}api/profile/workinfo`)
    .then((response) => response.data)
    .then((res) => {
      const { status, data } = res;
      if (status === 'ok') {
        dispatch(setWorkDetails({ ...data, jobDateMap: createJobDateMap(_.get(data, 'job_date_objects', [])) }));
      } else {
        dispatch(setWorkDetails({}));
        dispatch(redirectIfUnauthorized(res));
      }
    })
    .catch((error) => {
      dispatch(setWorkDetails({}));
      defaultCaptureException(error);
    });
};

/**
 * @desc get account details
 */
export const getAccountDetails = () => async (dispatch) => {
  dispatch(setWorkLoader(true));
  try {
    const res = await axios.get(`${baseUrl}api/profile/account-details`);

    const { status, msg, data } = res.data;

    if (status === 'ok') {
      return data;
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    notify(e.message || 'Something went wrong, please try again');
    defaultCaptureException(e);
  } finally {
    dispatch(setWorkLoader(false));
  }
};

/**
 * @desc update user email
 */
export const updateEmail = (email, oldEmail) => async (dispatch) => {
  dispatch(setWorkLoader(true));
  dispatch(setIsEmailLoading(true));
  try {
    const res = await axios.post(`${baseUrl}api/profile/email`, { email });
    const { status, msg } = res.data;
    if (status === 'ok') {
      notify(msg);
      trackActivity('settings: email change attempt', { from: oldEmail, to: email, msg });
      dispatch(getNewToken());
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    notify(e.message || 'Something went wrong, please try again');
    defaultCaptureException(e);
  } finally {
    dispatch(setWorkLoader(false));
    dispatch(setIsEmailLoading(false));
  }
};

/**
 * @desc update user phone
 */
export const updatePhone = (phone, oldPhone) => async (dispatch) => {
  dispatch(setWorkLoader(true));
  dispatch(setIsPhoneLoading(true));
  try {
    const res = await axios.post(`${baseUrl}api/profile/phone`, { phone });
    const { status, msg } = res.data;
    if (status === 'ok') {
      notify(msg);
      trackActivity('settings: phone change attempt', { from: oldPhone, to: phone, msg });
      dispatch(getNewToken());
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    notify(e.message || 'Something went wrong, please try again');
    defaultCaptureException(e);
  } finally {
    dispatch(setWorkLoader(false));
    dispatch(setIsPhoneLoading(false));
  }
};

/**
 * @desc update user firstname
 */
export const updateFirstname = (firstname, oldFirstname) => async (dispatch) => {
  dispatch(setWorkLoader(true));
  dispatch(setIsFirstnameLoading(true));
  try {
    const res = await axios.post(`${baseUrl}api/profile/firstname`, { firstname });
    const { status, msg } = res.data;
    if (status === 'ok') {
      notify(msg);
      trackActivity('settings: firstname change attempt', { from: oldFirstname, to: firstname, msg });
      dispatch(getNewToken());
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    notify(e.message || 'Something went wrong, please try again');
    defaultCaptureException(e);
  } finally {
    dispatch(setWorkLoader(false));
    dispatch(setIsFirstnameLoading(false));
  }
};

/**
 * @desc update user lastname
 */
export const updateLastname = (lastname, oldLastname) => async (dispatch) => {
  dispatch(setWorkLoader(true));
  dispatch(setIsLastnameLoading(true));
  try {
    const res = await axios.post(`${baseUrl}api/profile/lastname`, { lastname });
    const { status, msg } = res.data;
    if (status === 'ok') {
      notify(msg);
      trackActivity('settings: lastname change attempt', { from: oldLastname, to: lastname, msg });
      dispatch(getNewToken());
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    notify(e.message || 'Something went wrong, please try again');
    defaultCaptureException(e);
  } finally {
    dispatch(setWorkLoader(false));
    dispatch(setIsLastnameLoading(false));
  }
};

const getWorkCategories = () => async (dispatch) => {
  const res = await getContentfulEntries(CONTENTFUL_CONTENT_WORK_CATEGORY_TYPE, { limit: 100 });
  const workCategories = {
    byId: _.assign(
      {},
      ...res.items.map((item) => ({
        [item.fields.slug]: item
      }))
    ),
    allIds: res.items.map((item) => item.fields.slug)
  };
  dispatch(updateWorkCategories(workCategories));
};

export const requireWorkCategories = () => async (dispatch, getState) => {
  if (_.isEmpty(getState().work.workCategories.allIds)) {
    await dispatch(getWorkCategories());
  }
};

/**
 * @desc update user email
 */
export const updateNotificationPreference = (platform, oldPlatform) => async (dispatch, getState) => {
  dispatch(setWorkLoader(true));
  try {
    const res = await axios.post(`${baseUrl}api/profile/sms`, { platform, sms: platform !== 'none' });

    const { status, msg } = res.data;

    if (status === 'ok') {
      notify('Notifications preference updated!');
      trackActivity('settings: notification preference changed', { from: oldPlatform, to: platform });
    } else {
      throw new Error(msg);
    }
    const settings = userSettingsSelector(getState());
    if (platform !== oldPlatform) {
      dispatch(setSettings({ ...settings, platform }));
    }
  } catch (e) {
    notify(e.message || 'Something went wrong, please try again');
    defaultCaptureException(e);
  } finally {
    dispatch(setWorkLoader(false));
  }
};

export const getWorkInfoLazy = () => async (dispatch, getState) => {
  const workDetails = workDetailsSelector(getState());
  if (_.isEmpty(workDetails)) {
    await dispatch(getWorkInfo());
  }
};

export const postTaxSubmission = (submitType) => async () => {
  await axios.post(`${baseUrl}api/taxes/tax-submission`, { submitType });
};

export const validateLoginOtp = (obj, onSuccess) => async (dispatch, getState) => {
  await dispatch(validateLoginOtpBase(obj));

  const isAuthenticated = _.get(getState(), ['auth', 'isAuthenticated']);

  if (isAuthenticated) {
    await dispatch(getFeatures());

    if (onSuccess) {
      onSuccess();
    }
  }

  return isAuthenticated;
};
