import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Fade from '@mui/material/Fade';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { skippableSelector, validationSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingFooter = ({ buttonText = 'Next', loading, onContinue, isValid, pathname, skippable }) => {
  const { push } = useHistory();

  if (skippable && !isValid) {
    return (
      <Fade in>
        <div className='h-onboarding-footer'>
          <div className='h-onboarding-footer-content'>
            <LoadingButton
              variant='outlined'
              color='primary'
              sx={{ minWidth: '100px' }}
              disableElevation
              loading={loading}
              disableRipples
              onClick={() => onContinue({ pathname, push })}
            >
              Skip
            </LoadingButton>
          </div>
        </div>
      </Fade>
    );
  }

  return (
    <Fade in={isValid}>
      <div className='h-onboarding-footer'>
        <div className='h-onboarding-footer-content'>
          <LoadingButton
            variant='contained'
            color='primary'
            fullWidth
            sx={{ minWidth: '100px' }}
            disableElevation
            loading={loading}
            onClick={() => onContinue({ pathname, push })}
          >
            {buttonText}
          </LoadingButton>
        </div>
      </div>
    </Fade>
  );
};
const mapStateToProps = (state, props) => ({
  buttonLabel: 'Next',
  isValid: validationSelector(state, props),
  skippable: skippableSelector(state, props)
});

const mapDispatchToProps = {
  onContinue
};

const ConnectedOnboardingFooter = connect(mapStateToProps, mapDispatchToProps)(OnboardingFooter);

export default ConnectedOnboardingFooter;
