import _ from 'lodash';
import {
  setHolisticOnboardingContentfulValues,
  setHolisticOnboardingError,
  setHolisticOnboardingInitialLoad,
  setHolisticOnboardingLoading,
  setHolisticOnboardingProgress,
  setHolisticOnboardingTaxEstimate
} from '@app/src/actions/holisticOnboardingActions';
import { ONBOARDING_EXPERIMENTS_KEY } from '@app/src/constants/onboardingConstants';
import { isEmailUniqueSelector, isPhoneUniqueSelector } from '@app/src/selectors/authSelectors';
import {
  contentSelector,
  dependentsSelector,
  driveSelector,
  emailSelector,
  goalsSelector,
  homeSelector,
  income1099Selector,
  incomeW2Selector,
  incomeTypeSelector,
  jobDurationSelector,
  jobsSelector,
  marriedSelector,
  mealSelector,
  partner1099IncomeSelector,
  partnerW2IncomeSelector,
  phoneSelector,
  stateSelector,
  travelSelector,
  firstNameSelector,
  lastNameSelector,
  rentSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { generateClientDedupId, setUserWithObj, trackActivity } from '@app/src/services/analyticsService';
import {
  axiosWithErrorHandling,
  checkEmailUnique,
  checkPhoneUnique,
  setTokenUser
} from '@app/src/services/authService';
import { getContentfulEntries, getJobCategoryList } from '@app/src/services/contentfulService';
import {
  clearOnboardingState,
  consolidateContentfulValues,
  getExperimentProperties,
  getLocalStorage,
  getMarketingData,
  getOnboardingState,
  preserveProgress
} from '@app/src/utils/holisticOnboardingUtils';

export const initOnboarding = () => async (dispatch) => {
  try {
    dispatch(setHolisticOnboardingLoading(true));

    const contentful = await getContentfulEntries('onboarding');

    await dispatch(getJobCategoryList());

    dispatch(setHolisticOnboardingContentfulValues(consolidateContentfulValues(contentful)));
  } catch ({ message }) {
    throw new Error({ message });
  } finally {
    dispatch(setHolisticOnboardingLoading(false));
  }
};

export const initPage =
  ({ url }) =>
  async (dispatch, getState) => {
    const content = contentSelector(getState());

    if (_.isEmpty(content)) {
      return;
    }

    const progress = _.get(content, [url, 'progress']); // might be able to remove
    dispatch(setHolisticOnboardingProgress(progress));

    const analyticsEvent = _.get(content, [url, 'analyticsEvent']);
    const title = _.get(content, [url, 'title']);
    const isAuthenticated = !!getLocalStorage('KeeperToken');
    const isEntryPoint = _.get(content, [url, 'isEntryPoint']);

    trackActivity('question: view', {
      flow: 'onboarding',
      type: 'holistic-onboarding',
      is_authenticated: isAuthenticated,
      question: analyticsEvent,
      title,
      client_dedup_id: generateClientDedupId()
    });

    if (!isEntryPoint) {
      dispatch(setHolisticOnboardingInitialLoad(false));
    }
  };

export const onContinue =
  ({ pathname, push }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setHolisticOnboardingLoading(true));

      const content = contentSelector(getState());
      const nextUrl = _.get(content, [pathname, 'nextUrl']);

      const analyticsValues = {
        analyticsEvent: _.get(content, [pathname, 'analyticsEvent']),
        title: _.get(content, [pathname, 'title']),
        isAuthenticated: !!getLocalStorage('KeeperToken')
      };

      if (pathname === '/h/onboarding/goals') {
        preserveProgress({ goals: goalsSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/signup') {
        const firstname = firstNameSelector(getState());
        const lastname = lastNameSelector(getState());
        const email = emailSelector(getState());

        await dispatch(checkEmailUnique(email));

        const isEmailUnique = isEmailUniqueSelector(getState());

        if (!isEmailUnique) {
          dispatch(setHolisticOnboardingError('Email already exists'));
          return;
        }

        preserveProgress({ firstname, lastname, email }, analyticsValues);
      } else if (pathname === '/h/onboarding/phone') {
        const phone = phoneSelector(getState());
        await dispatch(checkPhoneUnique(`1${phone}`));

        const isPhoneUnique = isPhoneUniqueSelector(getState());

        if (isPhoneUnique) {
          await dispatch(createAccount());
        } else {
          dispatch(setHolisticOnboardingError('Phone already exists'));
          return;
        }

        preserveProgress({ phone }, analyticsValues);
      } else if (pathname === '/h/onboarding/state') {
        preserveProgress({ state: stateSelector(getState()) }, analyticsValues);
        const incomeTypes = _.get(getOnboardingState(), 'incomeType', '');

        if (!incomeTypes.includes('contractor') && !incomeTypes.includes('owner')) {
          push('/h/onboarding/deduction-estimate-w2');
          return;
        }
      } else if (pathname === '/h/onboarding/income-estimate') {
        const values = {
          income1099: income1099Selector(getState()),
          incomeW2: incomeW2Selector(getState()),
          partnerIncome1099: partner1099IncomeSelector(getState()),
          partnerIncomeW2: partnerW2IncomeSelector(getState())
        };

        preserveProgress(values, analyticsValues);
      } else if (pathname === '/h/onboarding/situations') {
        const selectedIncomeTypes = incomeTypeSelector(getState());

        const incomeType = _.isEmpty(selectedIncomeTypes) ? ['salaried', 'owner', 'contractor'] : selectedIncomeTypes;

        const values = {
          incomeType,
          married: marriedSelector(getState()),
          dependents: dependentsSelector(getState()),
          rent: rentSelector(getState()),
          homeowner: false,
          studentLoans: false
        };

        preserveProgress(values, analyticsValues);
      } else if (pathname === '/h/onboarding/job-select') {
        preserveProgress({ jobs: jobsSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/job-duration') {
        preserveProgress({ jobDuration: jobDurationSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/drive') {
        preserveProgress({ drive: driveSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/home') {
        preserveProgress({ home: homeSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/meal') {
        preserveProgress({ meal: mealSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/travel') {
        preserveProgress({ travel: travelSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/app-download') {
        clearOnboardingState();
      }

      push(nextUrl);
    } catch (error) {
      setHolisticOnboardingError(error);
    } finally {
      dispatch(setHolisticOnboardingLoading(false));
    }
  };

const createAccount = () => async (dispatch, getState) => {
  try {
    const localStorageValues = JSON.parse(getLocalStorage('onboardingState'));

    const phone = '1' + phoneSelector(getState());
    const firstname = _.get(localStorageValues, 'firstname') || firstNameSelector(getState());
    const lastname = _.get(localStorageValues, 'lastname') || lastNameSelector(getState());
    const email = _.get(localStorageValues, 'email') || emailSelector(getState());
    const goals = _.get(localStorageValues, 'goals') || goalsSelector(getState());
    const state = _.get(localStorageValues, 'state') || stateSelector(getState());
    const married = _.get(localStorageValues, 'married') || marriedSelector(getState());
    const dependents = _.get(localStorageValues, 'dependents') || dependentsSelector(getState());
    const incomeType = _.get(localStorageValues, 'incomeType') || incomeTypeSelector(getState());
    const incomeW2 = _.get(localStorageValues, 'incomeW2') || incomeW2Selector(getState());
    const income1099 = _.get(localStorageValues, 'income1099') || income1099Selector(getState());
    const partnerW2Income = _.get(localStorageValues, 'partnerW2Income') || partnerW2IncomeSelector(getState());
    const partner1099Income = _.get(localStorageValues, 'partner1099Income') || partner1099IncomeSelector(getState());
    const car = _.get(localStorageValues, 'drive') || driveSelector(getState());
    const home = _.get(localStorageValues, 'home') || homeSelector(getState());
    const meal = _.get(localStorageValues, 'meal') || mealSelector(getState());
    const travel = _.get(localStorageValues, 'travel') || travelSelector(getState());
    const dates = _.get(localStorageValues, 'jobDuration') || jobDurationSelector(getState());
    const jobs = _.get(localStorageValues, 'jobs') || jobsSelector(getState());

    const formattedJobDates = jobs.map(({ slug }) => ({ job: slug, dates }));

    const signupObject = {
      phone,
      firstname,
      lastname,
      email,
      goals,
      state,
      married,
      car,
      home,
      meal,
      travel,
      dependents,
      agi: income1099,
      spouse_w2_income: partnerW2Income,
      spouse_agi: partner1099Income,
      income_type: incomeType,
      w2_income: Number(!!incomeW2),
      w2_income_amount: incomeW2,
      job_dates: formattedJobDates
    };

    const marketingData = getMarketingData();
    const experimentProperties = getExperimentProperties(_.get(getState(), ['onboarding', ONBOARDING_EXPERIMENTS_KEY]));

    const data = {
      ...signupObject,
      ...marketingData,
      experiments: experimentProperties
    };

    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: `api/auth/signup`,
        data
      })
    );

    if (res.data.status === 'error') {
      throw new Error(res.data.message);
    }

    await dispatch(setTokenUser(res.data));

    const user = userSelector(getState());

    trackActivity('signup (frontend)', {
      userId: user.id,
      phone: user.phone,
      referrer: marketingData.referrer,
      client_dedup_id: marketingData.client_dedup_id
    });

    const userObj = {
      ...signupObject,
      Affiliate_referral: marketingData.referrer,
      ...experimentProperties
    };

    setUserWithObj(data.phone, userObj);
  } catch ({ message }) {
    trackActivity('onboarding: signup failed', { reason: message });
    dispatch(setHolisticOnboardingError(message));
  }
};

export const getTaxEstimate = () => async (dispatch) => {
  try {
    dispatch(setHolisticOnboardingLoading(true));

    const localStorageValues = JSON.parse(getLocalStorage('onboardingState'));

    const status = _.get(localStorageValues, 'married');
    const hasW2 = Number(!!_.get(localStorageValues, 'incomeW2') || !!_.get(localStorageValues, 'partnerIncomeW2'));
    const selfW2 = _.get(localStorageValues, 'incomeW2');
    const self1099 = _.get(localStorageValues, 'income1099');
    const spouseW2 = _.get(localStorageValues, 'partnerIncomeW2');
    const spouse1099 = _.get(localStorageValues, 'partnerIncome1099');
    const creditsFields = {
      numDependents: _.get(localStorageValues, 'dependents')
    };

    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: 'api/taxes/presignup-tax-calc-estimate',
        data: {
          status,
          hasW2,
          selfW2,
          self1099,
          spouseW2,
          spouse1099,
          creditsFields
        }
      })
    );

    const taxEstimate = _.get(res, 'data.data.resObj');

    dispatch(setHolisticOnboardingTaxEstimate(taxEstimate));
  } catch ({ message }) {
    await dispatch(setHolisticOnboardingError(message));
  } finally {
    dispatch(setHolisticOnboardingLoading(false));
  }
};
