import _ from 'lodash';
import axios from 'axios';
import pluralize from 'pluralize';
import { setBulkEditExpenses, setBulkEditLoading, setExpenses } from '@app/src/actions/expenseReviewActions';
import { serverUrl } from '@app/src/global/Environment';
import {
  bulkEditExpensesSelector,
  expensesByIdSelector,
  orderedExpensesSelector,
  orderedTransactionIdsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getExpenseReviewDetails, getExpenses } from '@app/src/services/expenseReviewService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

const bulkUpdateRemote = async (data) => {
  const res = await axios.post(`${baseUrl}api/expense/batch-update`, data);

  return res.data;
};

const undoBulkEdit = (transactions) => async (dispatch, getState) => {
  try {
    dispatch(setBulkEditLoading(true));

    await bulkUpdateRemote({ transactions });

    dispatch(
      setExpenses({
        expensesById: {
          ...expensesByIdSelector(getState()),
          ..._.keyBy(transactions, 'transaction_id')
        },
        orderedTransactionIds: orderedTransactionIdsSelector(getState())
      })
    );

    notify(`Undo successful. We reverted the ${pluralize('change', transactions.length, true)}.`);

    await dispatch(getExpenseReviewDetails());
  } catch (e) {
    notify(e?.message);
    defaultCaptureException(e);
  } finally {
    dispatch(setBulkEditLoading(false));
  }
};

export const bulkEditBase =
  ({ type, value }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setBulkEditLoading(true));

      const transactionIds = bulkEditExpensesSelector(getState());

      if (!transactionIds.length) {
        return;
      }

      const transactions = transactionIds.map((id) => _.get(expensesByIdSelector(getState()), id));

      // This handles a mismatch between the API and the frontend `businessUsePercentage` vs `allocation` in the API
      const formattedType = type === 'allocation' ? 'businessUsePercentage' : type;

      const originalTransactions = transactions.map((transaction) => ({
        ...transaction,
        [type]: transaction[type],
        [formattedType]: transaction[type],
        value
      }));

      const formattedTransactionsWithoutNote = transactions.map((transaction) => ({
        ...transaction,
        [type]: value,
        note: type !== 'note' ? null : transaction.note
      }));

      await bulkUpdateRemote({ transactions: formattedTransactionsWithoutNote });

      for (const transaction of transactions) {
        transaction[formattedType] = value;
      }

      dispatch(
        setExpenses({
          expensesById: {
            ...expensesByIdSelector(getState()),
            ..._.keyBy(transactions, 'transaction_id')
          },
          orderedTransactionIds: orderedTransactionIdsSelector(getState())
        })
      );

      notify(
        `${pluralize('deduction', transactions.length, true)} ${transactions.length > 1 ? 'were' : 'was'} updated.`,
        { data: { onUndo: () => dispatch(undoBulkEdit(originalTransactions)) } }
      );

      trackActivity('expense review: bulk edit completed', {
        action: type,
        filters_applied: value,
        num_selected: originalTransactions.length
      });

      await dispatch(getExpenseReviewDetails());

      dispatch(setBulkEditExpenses([]));
    } catch (e) {
      notify(e?.message);
      defaultCaptureException(e);
    } finally {
      dispatch(setBulkEditLoading(false));
    }
  };

export const loadAllTransactions = () => async (dispatch, getState) => {
  try {
    dispatch(setBulkEditLoading(true));

    await dispatch(getExpenses({ limit: null, offset: 0 }));

    const expenses = orderedExpensesSelector(getState());

    dispatch(setBulkEditExpenses(expenses.map((expense) => expense.transaction_id)));
  } catch (e) {
    notify(e?.message);
    defaultCaptureException(e);
  } finally {
    dispatch(setBulkEditLoading(false));
  }
};
