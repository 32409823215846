import React, { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import '@app/src/Components/Assistant/ChatPanel.scss';

const ChatPanel = ({ children, className, header, footer, open, onHeaderClick, estimatedHeaderHeight = 58 }) => {
  const headerRef = useRef(null);

  // Initial state prevents strange transitions when being rerendered
  const [headerHeight, setHeaderHeight] = React.useState(estimatedHeaderHeight);

  useLayoutEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const headerWithRef = React.cloneElement(header, { ref: headerRef, onClick: onHeaderClick });

  return (
    <div
      className={classNames('chat-panel', className, { open, hidden: headerHeight === 0 })}
      style={{
        transform: !open && `translateY(calc(100% - ${headerHeight}px))`
      }}
    >
      {headerWithRef}
      {children}
      {footer}
    </div>
  );
};

export default ChatPanel;
