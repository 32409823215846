import { SET_REFERRALS, SET_PAYPAL_EMAIL, SET_REFERRALS_CONTENT } from '@app/src/actions/types.js';

const initialState = {
  referrals: [],
  contentfulEntries: []
};

const referralsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFERRALS:
      return {
        ...state,
        referrals: action.payload
      };
    case SET_REFERRALS_CONTENT:
      return {
        ...state,
        contentfulEntries: action.payload
      };
    case SET_PAYPAL_EMAIL:
      return {
        ...state,
        paypalEmail: action.payload
      };
    default:
      return state;
  }
};

export default referralsReducer;
