import _ from 'lodash';
import { setCurrentAnswer as setCurrentAnswerOrig } from '@app/src/actions/taxFlowActions';
import { setWorkDetails } from '@app/src/actions/workActions';
import getLiveWorkDetails from '@app/src/taxflow/common/utils/getLiveWorkDetails';
import { showWarningMessage, trackWarningShown } from '@app/src/taxflow/shared/utils/warningUtils';

export const setCurrentAnswer = (payload) => (dispatch, getState) => {
  dispatch(setCurrentAnswerOrig(payload));

  const currentQuestion = _.get(getState(), ['taxFlow', 'currentQuestion']);
  const currentAnswer = _.get(getState(), ['taxFlow', 'currentAnswer']);

  if (showWarningMessage({ question: currentQuestion, answer: currentAnswer })) {
    trackWarningShown({ question: currentQuestion, answer: currentAnswer });
  }
  if (_.get(getState(), ['taxFlow', 'currentQuestion', 'showWriteOffs'])) {
    dispatch(
      setWorkDetails(
        getLiveWorkDetails({
          workDetails: _.get(getState(), ['work', 'workDetails']),
          currentQuestion,
          currentAnswer
        })
      )
    );
  }
};
