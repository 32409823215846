import React from 'react';
import classNames from 'classnames';
import '@app/src/Components/TaxFlow/Question/TaxFlowBillElement.scss';

const TaxFlowBillContainer = ({ children, className, title }) => {
  return (
    <div className={classNames('taxflow-bill-container', className)}>
      {title && <div className='taxflow-bill-title'>{title}</div>}
      {children}
    </div>
  );
};

export default TaxFlowBillContainer;
