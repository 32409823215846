import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { connect } from 'react-redux';
import PaymentElements from '@app/src/Components/Subscription/PaymentModal/PaymentElements';
import { setOrigin } from '@app/src/actions/pricingActions';
import { SUBSCRIPTION_STATUS_MAP } from '@app/src/constants/subscriptionConstants';
import history from '@app/src/keeperHistory';
import { pricingObjSelector } from '@app/src/selectors/pricingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getSubscriptionFeatures } from '@app/src/utils/subscriptionUtils';

const ExpenseReviewModalPayment = ({ subscriptionStatus, setOrigin, pricingObj }) => {
  useEffect(() => {
    trackActivity('Expense review: payment', { subscriptionStatus });
    setOrigin({ origin: 'expense-review' });
  });

  const isEligibleForReferralPricing = pricingObj?.isEligibleForReferralPricing;
  const subscriptionFeatures = getSubscriptionFeatures(isEligibleForReferralPricing);
  const { price } = _.get(subscriptionFeatures, 'annual', {});

  const freeTrial = subscriptionStatus === 'pre free trial';
  const title = SUBSCRIPTION_STATUS_MAP[subscriptionStatus]?.title;

  const pricingSubtitle = useMemo(() => {
    if (freeTrial) {
      return isEligibleForReferralPricing
        ? `7-day free trial, then receive discounted price of $${price} for the first year. Cancel anytime.`
        : `7-day free trial, then $192/year. Cancel anytime.`;
    } else {
      return isEligibleForReferralPricing
        ? `Receive discounted price of $${price} for the first year. Cancel anytime.`
        : `$192/year. Cancel anytime.`;
    }
  }, [freeTrial, isEligibleForReferralPricing, price]);

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <Stack spacing={1}>
        <DialogContentText className='center-text'>{pricingSubtitle}</DialogContentText>
        <PaymentElements buttonCopy='Continue' history={history} />
        {freeTrial && (
          <DialogContentText>
            No-questions-asked refund available until {moment().add(7, 'days').format('MMMM D, YYYY')}.
          </DialogContentText>
        )}
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionStatus: accountDetailsSelector(state).status,
  pricingObj: pricingObjSelector(state)
});

const mapDispatchToProps = {
  setOrigin
};

const ConnectedExpenseReviewModalPayment = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalPayment);

export default ConnectedExpenseReviewModalPayment;
