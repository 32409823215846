import React from 'react';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { sendStreamMessage } from '@app/src/actions/assistantActions';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import { RESPONSE_TEXT_DELAY_MS } from '@app/src/taxflow/shared/constants/sharedConstants';

const AskButton = ({ message, response, field }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      sendStreamMessage({
        text_type: `info - taxProfile.${field}`,
        sub_type: `info - taxProfile.${field}`,
        text: message,
        origin: 'Tax Profile Input',
        response_text: response,
        response_delay: RESPONSE_TEXT_DELAY_MS
      })
    );
  };

  return (
    <IconButton edge='end' onClick={handleClick}>
      <HelpSupportIcon />
    </IconButton>
  );
};

export default AskButton;
