import React from 'react';
import { init as amplitudeInit } from '@amplitude/analytics-browser';
import { init as sentryInit } from '@sentry/react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import 'react-spring-bottom-sheet/dist/style.css';
import App from '@app/src/App';
import SentryBoundary from '@app/src/Components/Sentry/SentryBoundary';
import { AMPLITUDE_API_KEY, REACT_APP_ENV } from '@app/src/global/Environment';
import { initDatadogRum } from '@app/src/services/analyticsService';
import '@app/src/Components/ExpenseCleanup/expenseOverride.scss';

if (REACT_APP_ENV !== 'development') {
  sentryInit({
    dsn: 'https://3219186159f642679dfcf2ff5402f61b@o307744.ingest.sentry.io/1768725',
    environment: REACT_APP_ENV,
    ignoreErrors: [
      // suppress network errors (network connectivity issue)
      'Network Error',
      // suppress unauthorized requests (expired jwt token)
      'Unauthorized Request',
      // suppress resize observer errors since these aren't actionable (https://github.com/juggle/resize-observer/issues/103)
      'ResizeObserver loop completed with undelivered notifications.',
      // suppress stripe load errors (network connectivity issue)
      'Failed to load Stripe.js',
      // suppress web socket errors (network connectivity issue)
      /^web_socket_error/,
      // suppress connect errors (network connectivity issue)
      /^connect_error/
    ]
  });
}

if (REACT_APP_ENV === 'production') {
  initDatadogRum();
}

amplitudeInit(AMPLITUDE_API_KEY, {
  defaultTracking: true,
  deviceId: 'amp_device_id'
});

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect disabled={REACT_APP_ENV === 'development'}>
      <SentryBoundary>
        <App />
      </SentryBoundary>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);
