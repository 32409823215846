import React, { useEffect } from 'react';
import _ from 'lodash';
import capitalize from 'capitalize';
import TaxFlowDetailedBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowDetailedBillElement';
import TaxFlowPdfButton from '@app/src/Components/TaxFlow/Question/TaxFlowPdfButton';
import TaxFlowRatingFeedbackElement from '@app/src/Components/TaxFlow/Question/TaxFlowRatingFeedbackElement';
import TaxFlowSettlementStatus from '@app/src/Components/TaxFlow/Question/TaxFlowSettlementStatus';
import { useGetReturnStatusQuery, useGetTaxAmountsQuery } from '@app/src/api/taxDataApi';
import { useDetailedAmounts } from '@app/src/hooks/useDetailedAmounts';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitToIrsElement.scss';

const TaxFlowSubmitToIrsElement = ({ setLoading = _.noop }) => {
  const { data: taxAmounts, isLoading: taxAmountsLoading } = useGetTaxAmountsQuery();
  const { data: returnStatus, isLoading: returnStatusLoading } = useGetReturnStatusQuery();
  const { amounts, isLoading: detailedAmountsLoading } = useDetailedAmounts();

  const federalAmt = _.get(taxAmounts, 'federal_amount');
  const states = _.get(taxAmounts, 'state_amounts', []);
  const hasBill = federalAmt > 0 || states.some((state) => state.amount > 0);
  const hasRefund = federalAmt < 0 || states.some((state) => state.amount < 0);

  const isLoading = detailedAmountsLoading || taxAmountsLoading || returnStatusLoading;

  useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

  if (isLoading) {
    return null;
  }

  const mask = _.get(returnStatus, 'accountMask');
  const paymentMethod = _.get(returnStatus, 'paymentMethod');

  return (
    <div className='taxflow-submit-to-irs-element'>
      <div className='refund-statuses'>
        {(hasBill || hasRefund) && (
          <>
            <h2>{capitalize(_.compact([hasRefund && 'refund', hasBill && 'tax bill']).join(' and '))} status</h2>

            {amounts.drake.federal_amount != null && (
              <TaxFlowSettlementStatus
                amount={amounts.drake.federal_amount}
                mask={mask}
                entity='federal'
                paymentMethod={paymentMethod}
                status='In Review'
              />
            )}
            {amounts.drake.multistate &&
              amounts.drake.multistate.map(({ state, amount }) => (
                <TaxFlowSettlementStatus
                  key={state}
                  amount={amount}
                  mask={mask}
                  entity={state}
                  paymentMethod={paymentMethod}
                  status='In Review'
                />
              ))}
          </>
        )}
      </div>

      <TaxFlowDetailedBillElement className='taxflow-submit-to-irs-element-bill' amounts={amounts} />
      <TaxFlowPdfButton className='pdf-button' />
      <TaxFlowRatingFeedbackElement />
    </div>
  );
};

export default TaxFlowSubmitToIrsElement;
