import React from 'react';
import { connect } from 'react-redux';
import AnimatedList from '@app/src/Components/HolisticOnboarding/components/AnimatedList/AnimatedList';
import {
  dependentsSelector,
  homeOwnerSelector,
  incomeTypeSelector,
  marriedSelector,
  rentSelector,
  studentLoansSelector
} from '@app/src/selectors/holisticOnboardingSelectors';

const OnboardingDeductionList = ({ owner, contractor, dependents, rent, married, homeowner, studentLoans }) => {
  const deductionContent = [
    {
      visible: contractor || owner,
      text: 'Business deductions'
    },
    {
      visible: contractor || owner,
      text: 'Qualified business income deduction'
    },
    {
      visible: dependents > 0,
      text: 'Child tax credit'
    },
    {
      visible: dependents > 0,
      text: 'Childcare expense tax credit'
    },
    {
      visible: rent,
      text: 'Rent credits'
    },
    {
      visible: married,
      text: 'Married filing jointly tax breaks'
    },
    {
      visible: homeowner,
      text: 'Mortgage interest deduction'
    },
    {
      visible: studentLoans,
      text: 'Student loan interest deduction'
    }
  ];

  const items = deductionContent.filter(({ visible }) => visible).map(({ text }) => text);
  const DEFAULT_ITEMS = ['Medical expense premiums', 'Health insurance premiums'];

  return <AnimatedList items={[...items, ...DEFAULT_ITEMS]} />;
};

const mapStateToProps = (state) => ({
  owner: incomeTypeSelector(state).includes('owner'),
  contractor: incomeTypeSelector(state).includes('contractor'),
  married: marriedSelector(state) === 'married',
  dependents: dependentsSelector(state),
  rent: rentSelector(state),
  homeowner: homeOwnerSelector(state),
  studentLoans: studentLoansSelector(state)
});

const ConnectedOnboardingDeductionList = connect(mapStateToProps)(OnboardingDeductionList);

export default ConnectedOnboardingDeductionList;
