import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearError } from '@app/src/actions/holisticOnboardingActions';
import { errorSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const OnboardingSnackbar = ({ message, clearError }) => {
  const history = useHistory();

  const getMessage = () => {
    if (message === 'Email already exists') {
      return 'This email address is associated with an existing account. Please log in.';
    } else if (message === 'Phone already exists') {
      return 'This phone number is associated with an existing account. Please log in.';
    }

    return message;
  };

  const getAction = () => {
    if (message === 'Email already exists') {
      return (
        <Button onClick={() => history.push('/login-email')} color='primary'>
          Log in
        </Button>
      );
    } else if (message === 'Phone already exists') {
      return (
        <Button onClick={() => history.push('/')} color='primary'>
          Log in
        </Button>
      );
    }

    return <Button onClick={clearError}>Dismiss</Button>;
  };

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={5000}
      onClose={clearError}
      message={getMessage()}
      action={getAction()}
    />
  );
};

const mapStateToProps = (state) => ({
  message: errorSelector(state)
});

const mapDispatchToProps = {
  clearError
};

const ConnectedOnboardingSnackbar = connect(mapStateToProps, mapDispatchToProps)(OnboardingSnackbar);

export default ConnectedOnboardingSnackbar;
