import React from 'react';
import Slide from '@mui/material/Slide';
import { useHistory } from 'react-router-dom';

const PageTransition = ({ loading, children }) => {
  const { action, location } = useHistory();

  return loading ? (
    <div>{children}</div>
  ) : (
    <Slide key={location.pathname} in timeout={300} direction={action !== 'POP' ? 'left' : 'right'}>
      {children}
    </Slide>
  );
};

export default PageTransition;
