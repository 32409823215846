import React from 'react';
import classNames from 'classnames';
import CloseIcon from '@app/src/assets/close.svg?react';
import MessageQuestionIcon from '@app/src/assets/message-question.svg?react';
import '@app/src/Components/Common/Chip/Chip.scss';

export default function Chip({
  item,
  text,
  emoji,
  icon,
  type,
  clickable,
  onClick,
  className,
  hasCloseIcon,
  onCloseClick
}) {
  return (
    <div
      className={classNames('common-chip', className, {
        'common-chip-small': type === 'small',
        'common-chip-clickable': clickable,
        'common-chip-deadline': type === 'deadline'
      })}
      onClick={() => onClick(item)}
      role='button'
    >
      {icon && <span className='common-chip-icon'>{icon}</span>}
      {emoji && <span className='common-chip-icon'>{emoji}</span>}
      <span>{text}</span>
      {type === 'deadline' && <MessageQuestionIcon className='common-chip-icon' />}
      {hasCloseIcon && (
        <CloseIcon className='common-chip-icon-close' width={16} height={16} onClick={() => onCloseClick(item)} />
      )}
    </div>
  );
}

Chip.defaultProps = {
  onClick: function () {}
};
