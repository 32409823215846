import _ from 'lodash';
import { useGetExpenseReviewDetailsQuery } from '@app/src/api/expensesApi';

export const useDeductionsCount = ({ year }) => {
  const { data: expenseReviewDetails, isLoading: expenseReviewDetailsLoading } = useGetExpenseReviewDetailsQuery({
    year
  });
  return {
    deductionsCount: _.chain(expenseReviewDetails)
      .get('categoryCounts')
      .defaultTo([])
      .values()
      .sumBy('writeOffs')
      .value(),
    isLoading: expenseReviewDetailsLoading
  };
};
