import _ from 'lodash';
import moment from 'moment';
import {
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_FORM_TEXT,
  CATEGORY_TYPE_TAXFLOW_FORM_EMAIL,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TAXFLOW_FORM_SSN,
  CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_DROPDOWN_SEARCH,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_ZIP,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_TAXFLOW_FORM_MULTI
} from '@app/src/constants/constants';
import {
  Url_SIGNUP_PHONE,
  Url_ONBOARDING_SAVINGS_CALC,
  Url_SIGNUP_PHONE_ONLY_PHONE,
  Url_ONBOARDING_VERIFY
} from '@app/src/constants/onboardingConstants';
import { isNumber, isFloat, isEmail, removeExtraWhitespace, convertEmpty } from '@app/src/global/Helpers';
import { isTooShort } from '@app/src/services/taxFlow/helpers';
import {
  SLUG__CREDIT_CHILD_CARE_DETAIL,
  SLUG__CREDIT_CHILD_CARE_FEDERAL_ID,
  SLUG__CREDIT_CHILD_CARE_PHONE,
  SLUG__CREDIT_HEALTH_PLAN_END_DATE,
  SLUG__CREDIT_HEALTH_PLAN_START_DATE,
  SLUG__CREDIT_STANDARD_ITEMIZED
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  SLUG__INCOME_INVEST_INFO,
  INCOME_INVEST_INTRO_SLUGS,
  INCOME_INVEST_LONG_TERM_SLUGS,
  INCOME_INVEST_SHORT_TERM_SLUGS,
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
  SLUG__INCOME_FREELANCE_JOB_NAME,
  SLUG__INCOME_W2_STATE,
  SLUG__INCOME_W2_STATE_EMPLOYER_ID,
  SLUG__INCOME_W2_STATE_WAGES,
  SLUG__INCOME_W2_STATE_INCOME_TAX,
  SLUG__INCOME_W2_STATE_2,
  SLUG__INCOME_W2_STATE_EMPLOYER_ID_2,
  SLUG__INCOME_W2_STATE_WAGES_2,
  SLUG__INCOME_W2_STATE_INCOME_TAX_2
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  SLUG__SELF_BIRTHDATE,
  SLUG__SPOUSE_BIRTHDATE,
  SLUG__SELF_ID_STATE,
  SLUG__SELF_ID_DOCUMENT_NUMBER,
  SLUG__SPOUSE_ID_STATE,
  SLUG__SPOUSE_ID_DOCUMENT_NUMBER,
  SLUG__SELF_ID_INFO,
  SLUG__SPOUSE_ID_INFO,
  SLUG__SPOUSE_RIP_DATE,
  SLUG__SELF_ID_EXPIRY_DATE,
  SLUG__SPOUSE_ID_EXPIRY_DATE,
  SLUG__DEPENDENT_BIRTHDATE,
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
  SLUG__SELF_ID_ISSUE_DATE,
  SLUG__SPOUSE_ID_ISSUE_DATE,
  COLLECTION_TYPE__SPOUSE,
  ENDPOINT_ATTRIBUTE__SELF_SSN,
  ENDPOINT_ATTRIBUTE__SPOUSE_SSN
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE,
  ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE,
  SLUG__NY_SCHOOL_DISTRICT
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_HAVE_PIN,
  SLUG__SUBMIT_PIN,
  SLUG__SUBMIT_SELF_PIN_NUMBER,
  SLUG__SUBMIT_SPOUSE_PIN_NUMBER
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { DEFAULT_COLLECTION_ID, TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import { isQuestionPresent } from '@app/src/utils/taxValidationUtils';

const isValidInput = ({
  currentQuestion,
  question,
  answer,
  status,
  queryResults,
  currentCollectionId,
  props,
  formAnswer,
  formErrors = {}
}) => {
  if (_.get(props, 'isNextDisabled')) {
    return false;
  }

  if (question) {
    if (!_.isEmpty(_.get(formErrors, question.endpoint_attr))) {
      return false;
    }
    if (isBirthDateInvalid({ question, answer })) {
      return false;
    }
    if (isRipDateInvalid({ question, answer, status })) {
      return false;
    }
    if (isChildCareIDInvalid({ question, answer, queryResults })) {
      return false;
    }
    if (isDocumentNumMissing({ question, answer })) {
      return false;
    }
    if (isPinNumMissing({ question, answer })) {
      return false;
    }
    if (isTooShort({ question, answer })) {
      return false;
    }
    if (isOnboardingPhoneSkipped({ question, answer })) {
      return true;
    }
    if (question.slug === Url_ONBOARDING_VERIFY) {
      return false;
    }
    if (isW2StatesInvalid({ question, answer, formAnswer })) {
      return false;
    }
    if (isW2State2Invalid({ question, answer, formAnswer })) {
      return false;
    }
    if (question.slug === SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO) {
      return (
        answer.value &&
        Object.keys(answer.value).every((key) => {
          const jsonArray = answer.value[key].value;
          return jsonArray && JSON.parse(jsonArray).some((amountOrDescription) => amountOrDescription);
        })
      );
    }
    if (question.required && question.question_type === 'ein' && _.get(answer, 'value.length') !== 9) {
      return false;
    }

    if (isJobNameTooShort({ question, answer })) {
      return false;
    }

    // When dealing with form-multi items, the slugs from the relevant query results need to become the endpoint_attr
    // for the original sub_question (the one defined in Contentful that determines the input type for each field) and each answer
    // needs to be injected as well
    if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_MULTI) {
      return question.sub_question.every((parentQuestion) => {
        const subQueryResults = queryResults.filter((qr) => qr.slug.startsWith(parentQuestion.slug));
        const remappedParentQuestions = subQueryResults.map((sqr) => {
          return {
            ...parentQuestion,
            endpoint_attr: sqr.slug,
            answer: sqr.answer
          };
        });
        return remappedParentQuestions.every((subQuestion) =>
          isValidInput({
            currentQuestion,
            question: subQuestion,
            answer: subQuestion?.answer,
            status,
            queryResults,
            currentCollectionId,
            props,
            formAnswer: subQuestion?.answer,
            formErrors
          })
        );
      });
    }

    if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
      if (question.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
        return question.sub_question.some((q) => {
          const a = _.get(answer, ['value', q.slug], null);

          return isValidInput({ question: q, answer: a, status, queryResults, currentCollectionId, props, formErrors });
        });
      } else if (question.slug === SLUG__INCOME_INVEST_INFO) {
        return are1099BInputsValid({ question, answer, collId: currentCollectionId, queryResults, props, status });
      }

      return question.sub_question.every((subQuestion) => {
        const a = _.get(answer, ['value', subQuestion.slug], null);

        return isValidInput({
          currentQuestion: question,
          question: subQuestion,
          answer: a,
          status,
          queryResults,
          currentCollectionId,
          props,
          formAnswer: answer,
          formErrors
        });
      });
    } else {
      if (
        question.required &&
        (isQuestionPresent({ currentQuestion, question, answer, status }) ||
          (formAnswer && isQuestionPresent({ currentQuestion, question, answer: formAnswer, status })))
      ) {
        if (question.question_type === CATEGORY_TYPE_OPTIONS) {
          const defaultExists = question.question_meta.find((m) => m.default === 1);
          if (defaultExists) {
            return true;
          } else {
            return !_.isEmpty(_.get(_.get(answer, ['value', question.slug], answer), 'value'));
          }
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY) {
          return answer && !(_.isEmpty(answer.value) && _.isEmpty(answer.other));
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR) {
          const defaultExists = question.question_meta.find((m) => m.default === 1);
          if (!defaultExists) {
            if (`${_.get(answer, 'value')}` === '0') {
              return true;
            } else {
              return !_.isEmpty(_.get(answer, 'months', []));
            }
          }
        } else if (
          question.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION &&
          question.slug !== Url_ONBOARDING_SAVINGS_CALC
        ) {
          return answer && !_.isEmpty(answer.value);
        } else if (
          question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_TEXT ||
          question.question_type === CATEGORY_TYPE_MILES
        ) {
          if (question.slug === SLUG__SELF_ID_DOCUMENT_NUMBER || question.slug === SLUG__SPOUSE_ID_DOCUMENT_NUMBER) {
            return true;
          }
          return answer && !_.isEmpty(answer.value);
        } else if (question.question_type === CATEGORY_TYPE_DROPDOWN_SEARCH) {
          return answer && !_.isEmpty(answer.value);
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_EMAIL) {
          return answer && isEmail(answer.value);
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PHONE) {
          return isPhoneValid({ question, answer });
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SSN) {
          return answer && !_.isEmpty(answer.value) && answer.value.length === 9;
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD) {
          return answer && !_.isEmpty(answer.value);
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_NUMBER) {
          return answer && isFloat(answer.value);
        } else if (question.question_type === CATEGORY_TYPE_MONEY) {
          return answer && isFloat(answer.value);
        } else if (question.question_type === CATEGORY_TYPE_SQUARE_FOOTAGE) {
          return answer && isFloat(answer.value);
        } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SLIDER) {
          return answer && isNumber(answer.value) && parseInt(answer.value) >= 0 && parseInt(answer.value) <= 100;
        } else if (question.slug === SLUG__SUBMIT_DEBIT) {
          return answer && !_.isEmpty(answer.value);
        } else if (question.slug === SLUG__STATE_RETURN && queryResults) {
          if (_.get(answer, 'value') === '') {
            return false;
          }
          const firstStateReturn = queryResults.find((result) => {
            return (
              result.coll_type === COLLECTION_TYPE__STATE_RETURN && result.slug === ENDPOINT_ATTRIBUTE__STATE_RETURN
            );
          });
          return !(_.get(answer, 'value') === _.get(firstStateReturn, ['answer', 'value']));
        } else if (question.question_type === CATEGORY_TYPE_STATE && _.get(answer, 'value') === '') {
          return false;
        } else if (question.question_type === CATEGORY_TYPE_ZIP) {
          return answer && _.get(answer, 'value.length') === 5;
        } else if (question.question_type === 'address') {
          const value = _.get(answer, 'value');
          const trimmed = _.isString(value) && value.trim();
          return answer && !_.isEmpty(trimmed);
        }
        // if no validation added, questions that are present and required default to valid
        return true;
        // validate for questions that are not required but need validation if answered
      } else if (isQuestionPresent({ currentQuestion, question, answer, status })) {
        if (!_.get(answer, 'value')) return true;

        if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PHONE) {
          return isPhoneValid({ question, answer });
        }
      }
    }
  }
  return true;
};

const isPhoneValid = ({ question, answer }) => {
  const isCorrectLength = answer && !_.isEmpty(answer.value) && answer.value.length === 11;
  if ([Url_SIGNUP_PHONE_ONLY_PHONE, SLUG__CREDIT_CHILD_CARE_PHONE].includes(question.slug)) {
    return isCorrectLength && !_.get(answer, ['value'], '').startsWith('11');
  } else {
    return isCorrectLength;
  }
};

const are1099BInputsValid = ({ question, answer, collId, queryResults, props, status }) => {
  return question.sub_question.every((q) => {
    if ([...INCOME_INVEST_INTRO_SLUGS, ...INCOME_INVEST_SHORT_TERM_SLUGS].includes(q.slug)) {
      return isValidInput({
        question: q,
        answer: _.get(answer, ['value', collId, q.slug]),
        status,
        queryResults,
        currentCollectionId: collId,
        props
      });
    } else if (INCOME_INVEST_LONG_TERM_SLUGS.includes(q.slug)) {
      return isValidInput({
        question: q,
        answer: _.get(answer, ['value', Number(collId) + 1, q.slug]),
        status,
        queryResults,
        currentCollectionId: Number(collId) + 1,
        props
      });
    } else {
      return Object.keys(_.get(answer, 'value') || {})
        .filter((cId) => Number(cId) > Number(collId) + 1)
        .every((cId) =>
          isValidInput({
            question: q,
            answer: _.get(answer, ['value', cId, q.slug]),
            status,
            queryResults,
            currentCollectionId: cId,
            props
          })
        );
    }
  });
};

export const isStreetInvalid = ({ answer }) => {
  if (_.get(answer, 'value')) {
    const addressParts = removeExtraWhitespace(answer.value).split(' ');
    return addressParts.length < 2;
  } else {
    return true;
  }
};

export const isCityInvalid = ({ answer }) => {
  if (_.get(answer, 'value')) {
    return answer.value.length < 3;
  } else {
    return true;
  }
};

export const isIncomeYearInvalid = ({ answer }) => {
  if (_.get(answer, 'value')) {
    return parseInt(answer.value, 10) <= 1900 || parseInt(answer.value, 10) > TAX_FILING_YEAR;
  } else {
    return true;
  }
};

export const isSsnInvalid = ({ answer }) => {
  return _.get(answer, 'value') === '000000000' || _.get(answer, 'value') === '111111111';
};

const isBirthDateInvalid = ({ question, answer }) => {
  if (question.slug === SLUG__SELF_BIRTHDATE || question.slug === SLUG__SPOUSE_BIRTHDATE) {
    if (answer.value) {
      return isDateBefore1900OrAfterTaxYear({ answer });
    }
    return true;
  } else if (question.slug === SLUG__DEPENDENT_BIRTHDATE) {
    if (answer.value) {
      return isDateBefore1900OrAfterNow({ answer });
    }
    return true;
  } else {
    return false;
  }
};

const isRipDateInvalid = ({ question, answer, status }) => {
  const lastYear = TAX_FILING_YEAR - 1;
  const twoYearsAgo = TAX_FILING_YEAR - 2;

  if (question.slug === SLUG__SPOUSE_RIP_DATE && status === 'widowed') {
    if (_.get(answer, 'value')) {
      const year = moment(answer.value, 'DD/MMM/YYYY').year();
      return year < twoYearsAgo || year > lastYear;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const isIdExpiryDateInvalid = ({ question, answer }) => {
  if (question.slug === SLUG__SELF_ID_EXPIRY_DATE || question.slug === SLUG__SPOUSE_ID_EXPIRY_DATE) {
    if (_.get(answer, 'value')) {
      const year = moment(answer.value, 'DD/MMM/YYYY').year();
      return year <= 1900 || year > 2100;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const isIdExpiryDateAfterIssueDate = ({ question, answer }) => {
  const issueDate = _.get(answer, [
    'value',
    question.slug === SLUG__SELF_ID_EXPIRY_DATE ? SLUG__SELF_ID_ISSUE_DATE : SLUG__SPOUSE_ID_ISSUE_DATE,
    'value'
  ]);
  const expiryDate = _.get(answer, [
    'value',
    question.slug === SLUG__SELF_ID_EXPIRY_DATE ? SLUG__SELF_ID_EXPIRY_DATE : SLUG__SPOUSE_ID_EXPIRY_DATE,
    'value'
  ]);

  if (!_.isNil(issueDate) && !_.isNil(expiryDate)) {
    if (_.get(answer, 'value')) {
      return moment(expiryDate, 'DD/MMM/YYYY').isAfter(moment(issueDate, 'DD/MMM/YYYY'));
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const isResidencySplitValid = (answer) => {
  const state1Months = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE, 'months']);
  const state2Months = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE, 'months']);
  if (state1Months && state2Months) {
    const totalMonths = [...state1Months, ...state2Months];
    const totalMonthsUnique = _.uniq(totalMonths);
    if (totalMonths.length !== totalMonthsUnique.length || totalMonths.length !== 12) {
      return false;
    }
  }
  return true;
};

const isDocumentNumMissing = ({ question, answer }) => {
  const selfState = _.get(answer, ['value', SLUG__SELF_ID_STATE, 'value']);
  if (question.slug === SLUG__SELF_ID_INFO && selfState === 'NY') {
    const selfAnswerValue = _.get(answer, ['value', SLUG__SELF_ID_DOCUMENT_NUMBER, 'value']);
    return _.isEmpty(selfAnswerValue) || selfAnswerValue.length < 3;
  }

  const spouseState = _.get(answer, ['value', SLUG__SPOUSE_ID_STATE, 'value']);
  if (question.slug === SLUG__SPOUSE_ID_INFO && spouseState === 'NY') {
    const spouseAnswerValue = _.get(answer, ['value', SLUG__SPOUSE_ID_DOCUMENT_NUMBER, 'value']);
    return _.isEmpty(spouseAnswerValue) || spouseAnswerValue.length < 3;
  }
};

const isHealthcareEndDateAfterStartDate = ({ answer }) => {
  const startDate = _.get(answer, ['value', SLUG__CREDIT_HEALTH_PLAN_START_DATE, 'value']);
  const endDate = _.get(answer, ['value', SLUG__CREDIT_HEALTH_PLAN_END_DATE, 'value']);

  if (!_.isNil(startDate) && !_.isNil(endDate)) {
    return moment(endDate, 'DD/MMM/YYYY').isAfter(moment(startDate, 'DD/MMM/YYYY'));
  } else {
    return false;
  }
};

const isHealthcareEndDateValidForCurrentTaxYear = ({ answer }) => {
  const endDate = moment(_.get(answer, ['value', SLUG__CREDIT_HEALTH_PLAN_END_DATE, 'value']));

  return endDate.year() >= TAX_FILING_YEAR;
};

export const isChildCareIDInvalid = ({ question, answer, queryResults }) => {
  const selfSSN =
    _.get(
      getQueryResultByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__SELF,
        collectionId: '0',
        slug: ENDPOINT_ATTRIBUTE__SELF_SSN
      }),
      ['answer', 'value']
    ) || '';
  const spouseSSN =
    _.get(
      getQueryResultByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__SPOUSE,
        collectionId: '0',
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_SSN
      }),
      ['answer', 'value']
    ) || '';
  const childCareID = _.get(answer, ['value', SLUG__CREDIT_CHILD_CARE_FEDERAL_ID, 'value']);

  return (
    question.slug === SLUG__CREDIT_CHILD_CARE_DETAIL &&
    answer.value &&
    (selfSSN === childCareID || spouseSSN === childCareID || _.isEmpty(childCareID))
  );
};

const isPinNumMissing = ({ question, answer }) => {
  if (question.slug === SLUG__SUBMIT_PIN && answer.value) {
    return (
      answer.value[SLUG__SUBMIT_HAVE_PIN].value === '1' &&
      !(answer.value[SLUG__SUBMIT_SELF_PIN_NUMBER].value || answer.value[SLUG__SUBMIT_SPOUSE_PIN_NUMBER].value)
    );
  }
};

const isOnboardingPhoneSkipped = ({ question }) => {
  return question.slug === Url_SIGNUP_PHONE;
};

const isJobNameTooShort = ({ question, answer }) => {
  if (question.slug === SLUG__INCOME_FREELANCE_JOB_NAME) {
    const length = _.get(answer, ['value', 'length']);
    return length < 3;
  }
  return false;
};

export const isDateBefore1900OrAfterTaxYear = ({ answer }) => {
  const year = moment(answer.value, 'DD/MMM/YYYY').year();

  if (!moment(answer.value, 'DD/MMM/YYYY', true).isValid() || !year || year.toString().length < 4) {
    return false;
  } else {
    return year <= 1900 || year > TAX_FILING_YEAR;
  }
};

export const isDateBefore1900OrAfterNow = ({ answer }) => {
  const answerDate = moment(answer.value, 'DD/MMM/YYYY');
  const year = answerDate.year();

  if (!moment(answer.value, 'DD/MMM/YYYY', true).isValid() || !year || year.toString().length < 4) {
    return false;
  } else {
    return year <= 1900 || answerDate.isAfter(moment());
  }
};

export const isSubQuestionDateInvalid = ({ subquestion, subanswer, answer, queryResults }) => {
  const answerValue = _.get(subanswer, ['value']);

  if (moment.isMoment(answerValue) && _.isEmpty(answerValue.parsingFlags().unusedTokens) && !answerValue.isValid()) {
    return true;
  }

  if (_.get(subquestion, ['question_meta', 'dateAfter1900AndBeforeToday']) && !_.isNil(answerValue)) {
    return isDateBefore1900OrAfterNow({ answer: subanswer });
  }

  if (_.get(subquestion, ['question_meta', 'dateAfter1900AndBeforeTaxYearEnd']) && !_.isNil(answerValue)) {
    return isDateBefore1900OrAfterTaxYear({ answer: subanswer });
  }

  if (subquestion.slug === SLUG__SPOUSE_RIP_DATE && !_.isNil(answerValue)) {
    const statusQuery = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__SELF,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
    });
    return isRipDateInvalid({
      question: subquestion,
      answer: subanswer,
      status: _.get(statusQuery, ['answer', 'value'])
    });
  }

  if (
    (subquestion.slug === SLUG__SELF_ID_EXPIRY_DATE || subquestion.slug === SLUG__SPOUSE_ID_EXPIRY_DATE) &&
    !_.isNil(answerValue)
  ) {
    const idExpiryDateInvalid = isIdExpiryDateInvalid({
      question: subquestion,
      answer: subanswer
    });
    const idExpiryDateAfterIssueDate = isIdExpiryDateAfterIssueDate({ question: subquestion, answer });

    return idExpiryDateInvalid || !idExpiryDateAfterIssueDate;
  }

  if (subquestion.slug === SLUG__CREDIT_HEALTH_PLAN_START_DATE && !_.isNil(answerValue)) {
    return isDateBefore1900OrAfterTaxYear({ answer: subanswer });
  }

  if (subquestion.slug === SLUG__CREDIT_HEALTH_PLAN_END_DATE && !_.isNil(answerValue)) {
    return !isHealthcareEndDateAfterStartDate({ answer }) || !isHealthcareEndDateValidForCurrentTaxYear({ answer });
  }

  return false;
};

const isW2StatesInvalid = ({ question, answer, formAnswer }) =>
  [
    {
      state: SLUG__INCOME_W2_STATE,
      stateEmployerId: SLUG__INCOME_W2_STATE_EMPLOYER_ID,
      stateIncomeTax: SLUG__INCOME_W2_STATE_INCOME_TAX,
      stateWages: SLUG__INCOME_W2_STATE_WAGES
    },
    {
      state: SLUG__INCOME_W2_STATE_2,
      stateEmployerId: SLUG__INCOME_W2_STATE_EMPLOYER_ID_2,
      stateIncomeTax: SLUG__INCOME_W2_STATE_INCOME_TAX_2,
      stateWages: SLUG__INCOME_W2_STATE_WAGES_2
    }
  ].some((slugs) => {
    switch (question.slug) {
      case slugs.state:
      case slugs.stateEmployerId: {
        const value = convertEmpty(_.get(answer, 'value'));
        const stateIncomeTax = convertEmpty(_.get(formAnswer, ['value', slugs.stateIncomeTax, 'value']));
        const stateWages = convertEmpty(_.get(formAnswer, ['value', slugs.stateWages, 'value']));

        return ((stateIncomeTax && Number(stateIncomeTax) > 0) || (stateWages && Number(stateWages) > 0)) && !value;
      }
      default:
        return false;
    }
  });

const isW2State2Invalid = ({ question, answer, formAnswer }) => {
  const slugs = {
    state: SLUG__INCOME_W2_STATE_2,
    stateEmployerId: SLUG__INCOME_W2_STATE_EMPLOYER_ID_2,
    stateIncomeTax: SLUG__INCOME_W2_STATE_INCOME_TAX_2,
    stateWages: SLUG__INCOME_W2_STATE_WAGES_2
  };

  switch (question.slug) {
    case slugs.state:
    case slugs.stateEmployerId:
    case slugs.stateIncomeTax:
    case slugs.stateWages: {
      const value = convertEmpty(_.get(answer, 'value'));

      const firstStateIncomeTax = convertEmpty(_.get(formAnswer, ['value', SLUG__INCOME_W2_STATE_INCOME_TAX, 'value']));
      const firstStateWages = convertEmpty(_.get(formAnswer, ['value', SLUG__INCOME_W2_STATE_WAGES, 'value']));

      if (!_.isNil(value) && !firstStateIncomeTax && !firstStateWages) {
        return true;
      }

      return false;
    }
    default:
      return false;
  }
};

export const isNYCountyInvalid = ({ queryResults = [], allQuestions = [], currentCounty = '' }) => {
  const schoolDistrictCode = _.get(
    queryResults.find(({ slug }) => slug === SLUG__NY_SCHOOL_DISTRICT),
    ['answer', 'value']
  );
  const schoolDistrictQuestion = allQuestions.find(({ slug }) => slug === SLUG__NY_SCHOOL_DISTRICT);
  const schoolDistrictOptions = _.get(schoolDistrictQuestion, ['question_meta', 'data'], []);
  const validCounties = schoolDistrictOptions
    .filter(({ value }) => value === schoolDistrictCode)
    .map(({ county }) => county.toLowerCase());

  return !validCounties.includes(_.defaultTo(currentCounty, '').toLowerCase());
};

export default isValidInput;
