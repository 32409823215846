import _ from 'lodash';

export const referralsSelector = (state) => _.get(state, ['referrals', 'referrals'], []);

export const referralsHowItWorksSelector = (state) => {
  const contentfulEntries = _.get(state, ['referrals', 'contentfulEntries'], []);
  return contentfulEntries.find((entry) => entry.articleSlug === 'referrals-tab-how-it-works') || {};
};

export const referralsTermsAndConditionsSelector = (state) => {
  const contentfulEntries = _.get(state, ['referrals', 'contentfulEntries'], []);
  return contentfulEntries.find((entry) => entry.articleSlug === 'referrals-tab-terms-and-conditions') || {};
};
