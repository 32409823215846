import React, { forwardRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import '@app/src/Components/TaxProfile/YearlyMoneyInput.scss';

const NumberFormatWithRef = forwardRef((props, ref) => <NumberFormat {...props} getInputRef={ref} />);

/**
 * @typedef YearlyMoneyInputProps
 * @property {string} id
 * @property {string} label
 * @property {string} [placeholder]
 * @property {import('@mui/material/TextField').TextFieldProps} [textFieldProps]
 * @property {import('@mui/material/TextField').TextFieldProps['InputProps']} [inputProps]
 */
/** @type {React.FC<YearlyMoneyInputProps>} */
const YearlyMoneyInput = forwardRef(
  ({ id, label, placeholder = 'e.g. $50,000 / yr', textFieldProps, ...inputProps }, ref) => {
    return (
      <FormControl fullWidth>
        <InputLabel className='yearly-money-input-label' htmlFor={id} id={`${id}Label`}>
          {label}
        </InputLabel>
        <TextField
          ref={ref}
          placeholder={placeholder}
          id={id}
          {...textFieldProps}
          InputProps={{
            inputComponent: NumberFormatWithRef,
            inputProps: {
              prefix: '$',
              suffix: ' / yr',
              thousandSeparator: true,
              decimalScale: 2,
              ...inputProps
            },
            ...(textFieldProps.InputProps ?? {})
          }}
        />
      </FormControl>
    );
  }
);

export default YearlyMoneyInput;
