import moment from 'moment';
import {
  ADD_ASSISTANT_MESSAGE,
  SET_ASSISTANT_MESSAGES,
  INCREMENT_ASSISTANT_UNREAD_MESSAGES,
  SET_ASSISTANT_OPEN,
  SET_ASSISTANT_SESSION_ID,
  TOGGLE_ASSISTANT_OPEN,
  SET_ASSISTANT_UNREAD_MESSAGES,
  SET_ASSISTANT_TYPING,
  SET_ASSISTANT_LOADING,
  SET_ASSISTANT_HAS_ERROR,
  SET_ASSISTANT_CONNECTED,
  ADD_TO_SIGNED_URL_CACHE
} from '@app/src/actions/types';
import { SIGNED_URL_EXPIRATION_TIME_S } from '@app/src/taxflow/shared/constants/sharedConstants';

const initialState = {
  messages: [],
  sessionId: null,
  open: null,
  unreadMessages: 0,
  typing: false,
  loading: true,
  hasError: false,
  connected: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ASSISTANT_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };
    case ADD_ASSISTANT_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case SET_ASSISTANT_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload
      };
    case SET_ASSISTANT_OPEN:
      return {
        ...state,
        open: action.payload
      };
    case TOGGLE_ASSISTANT_OPEN: {
      return {
        ...state,
        open: !state.open
      };
    }
    case SET_ASSISTANT_UNREAD_MESSAGES: {
      return {
        ...state,
        unreadMessages: action.payload
      };
    }
    case INCREMENT_ASSISTANT_UNREAD_MESSAGES: {
      return {
        ...state,
        unreadMessages: state.open ? 0 : state.unreadMessages + action.payload
      };
    }
    case SET_ASSISTANT_TYPING: {
      return {
        ...state,
        typing: action.payload
      };
    }
    case SET_ASSISTANT_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case SET_ASSISTANT_HAS_ERROR: {
      return {
        ...state,
        hasError: action.payload
      };
    }
    case SET_ASSISTANT_CONNECTED: {
      return {
        ...state,
        connected: action.payload
      };
    }
    case ADD_TO_SIGNED_URL_CACHE: {
      return {
        ...state,
        signedUrlCache: {
          ...state.signedUrlCache,
          [action.payload.key]: {
            data: action.payload,
            expiresAt: moment().add(SIGNED_URL_EXPIRATION_TIME_S, 'seconds')
          }
        }
      };
    }
    default:
      return state;
  }
}
