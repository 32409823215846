import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import ExpenseReviewDropdown from '@app/src/Components/ExpenseReview/ExpenseReviewDropdown';
import IconCheckGreen from '@app/src/assets/icon-check-green.svg?react';
import IconClose from '@app/src/assets/icon-close.svg?react';
import { updateExpenseStatus } from '@app/src/services/expenseReviewService';
import { colorNeutralGraphite } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/ExpenseReviewStatusCell.scss';

const ExpenseReviewStatusCell = ({ data }) => {
  const dispatch = useDispatch();

  const status = _.get(data, 'status', '');

  const transactionId = _.get(data, 'transaction_id', '');
  const categoryId = _.get(data, 'keeper_category_id', '');

  const onChange = useCallback(
    (value) => {
      dispatch(updateExpenseStatus({ newStatus: value, transactionId, categoryId }));
    },
    [transactionId, categoryId, dispatch]
  );

  if (status === 'maybe') {
    return <ExpenseReviewDropdown value={status} onChange={onChange} />;
  } else {
    return (
      <div className='expense-review-status-cell'>
        {status === 'yes' ? (
          <>
            <IconCheckGreen className='expense-review-status-cell-icon' height={14} width={14} />
            <div>Deduction</div>
          </>
        ) : (
          <>
            <IconClose className='expense-review-status-cell-icon' height={14} width={14} />
            <div style={{ color: colorNeutralGraphite }}>Not deductible</div>
          </>
        )}
      </div>
    );
  }
};

export default ExpenseReviewStatusCell;
