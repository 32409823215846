import React, { forwardRef } from 'react';
import Select from '@mui/material/Select';
import { ArrowDown2 } from 'iconsax-react';

/**
 * @type {React.FC<React.ComponentProps<typeof Select>>}
 */
const KeeperSelect = forwardRef(({ children, ...props }, ref) => (
  <Select
    color='secondary'
    displayEmpty
    fullWidth
    IconComponent={ArrowDown2}
    notched={false}
    sx={{ '.MuiSelect-icon': { right: '16px', top: 'unset' } }}
    ref={ref}
    {...props}
  >
    {children}
  </Select>
));

export default KeeperSelect;
