export const MAX_REFERRALS_ALLOWED_COUNT = 10;

export const STATUSES = {
  EARNED: 'earned',
  PENDING: 'pending'
};

export const SIDEBAR_CONTENT = {
  HOW_IT_WORKS: 'HOW_IT_WORKS',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS'
};
