import React from 'react';
import _ from 'lodash';
import { useWindowSize } from 'react-use';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { PATH_COMPONENT__EXPENSES, TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowExpensesLinkItem.scss';

const TaxFlowEditExpensesModal = ({ open = false, expenseCategoryIds, setOpen }) => {
  const isMobile = useWindowSize().width < 1024;
  const handleConfirm = async () => {
    trackActivity('modal: clicked', {
      modal: 'leave tax filing to edit expenses'
    });
    // case: in-app
    if (isReactNative()) {
      sentMsgToReactNative('home', { year: TAX_FILING_YEAR, categoryIds: expenseCategoryIds });
    }
    // case: mobile web
    else if (isMobile) {
      window.open('https://keeper.tax/get-the-app', '_blank');
    }
    // case: desktop
    else {
      const params = new URLSearchParams();
      _.forEach(expenseCategoryIds, (categoryId) => {
        params.append('category', categoryId);
      });
      params.append('year', TAX_FILING_YEAR);
      history.push(`/${PATH_COMPONENT__EXPENSES}?${params}`);
    }
  };

  return (
    <TaxFlowConfirmModal
      open={open}
      setOpen={setOpen}
      header='Leave tax filing to edit expenses?'
      body='You can edit your expenses on the deductions tab and come back to finish filing.'
      // non breaking hyphen
      confirmText='Yes, edit my expenses'
      onConfirm={handleConfirm}
    />
  );
};

export default TaxFlowEditExpensesModal;
