import {
  GET_BANK_LIST,
  LOAD_BANK_DETAILS,
  CLEAR_BANK_LIST,
  SET_CHARGEABLE_ACCOUNT,
  SET_BANK_LIST_UPDATE_STATUS,
  UPDATE_BANK_RES_MSG,
  SET_LINK_TOKEN,
  SET_BANK_INFO,
  SET_BANK_INFO_LOADING,
  SET_BANK_INFO_ERROR_MSG,
  SET_BANK_LINK_FAILURE,
  SET_BANK_LINK_SKIPPED
} from '@app/src/actions/types';
import { isEmpty } from '@app/src/global/Helpers';

/**
 * @desc Set Bank List
 * @param {*} payload
 */
export const setBankList = (payload) => {
  let mappedList = [];

  if (payload.length > 0) {
    mappedList = payload.map((data) => {
      const ret = {
        accessToken: !isEmpty(data.access_token) ? data.access_token : data.accessToken || '',
        accountId: !isEmpty(data.account_id) ? data.account_id : data.accountId || '',
        accountSubtype: !isEmpty(data.account_subtype) ? data.account_subtype : data.accountSubtype || '',
        accountType: !isEmpty(data.account_type) ? data.account_type : data.accountType || '',
        institutionId: !isEmpty(data.institution_id) ? data.institution_id : data.institutionId || '',
        institutionName: !isEmpty(data.institution_name) ? data.institution_name : data.institutionName || '',
        itemId: !isEmpty(data.item_id) ? data.item_id : data.itemId || '',
        linked: !isEmpty(data.linked) ? data.linked : 0,
        logo: !isEmpty(data.logo) ? data.logo : '',
        publicToken: !isEmpty(data.public_token) ? data.public_token : data.publicToken || '',
        subAccounts: data.subAccounts
      };
      return ret;
    });
  } else {
    mappedList = [];
  }

  return {
    type: GET_BANK_LIST,
    payload: mappedList
  };
};

/**
 * @desc Set Bank Loader
 */
export const setBankLoader = (payload) => {
  return {
    type: LOAD_BANK_DETAILS,
    payload: payload
  };
};

/**
 * @desc Clear Bank List
 */
export const clearBankList = () => {
  return {
    type: CLEAR_BANK_LIST
  };
};

/**
 * @desc Get chargeable accounts
 * @param {*} payload
 */
export const setChargeableAccount = (payload) => {
  return {
    type: SET_CHARGEABLE_ACCOUNT,
    payload: payload
  };
};

/**
 * @desc Set flag for does bank list updated
 * @param {*} payload
 */
export const setBankListUpdateStatus = (payload) => {
  return {
    type: SET_BANK_LIST_UPDATE_STATUS,
    payload: payload
  };
};

/**
 * @desc Update Bank Res messages
 */
export const setBankResMsg = (payload) => {
  const { success, error } = payload;
  const resObj = {
    success: success !== undefined ? success : '',
    error: error !== undefined ? error : ''
  };
  return {
    type: UPDATE_BANK_RES_MSG,
    payload: resObj
  };
};

export const setLinkToken = (payload) => {
  return {
    type: SET_LINK_TOKEN,
    payload
  };
};

export const setBankInfo = (payload) => {
  return {
    type: SET_BANK_INFO,
    payload
  };
};

export const setBankInfoLoading = (payload) => {
  return {
    type: SET_BANK_INFO_LOADING,
    payload
  };
};

export const setBankInfoErrorMsg = (payload) => {
  return {
    type: SET_BANK_INFO_ERROR_MSG,
    payload
  };
};

export const setBankLinkFailure = (payload) => {
  localStorage.setItem('bankLinkFailure', payload);

  return {
    type: SET_BANK_LINK_FAILURE,
    payload
  };
};

export const setBankLinkSkipped = (payload) => {
  return {
    type: SET_BANK_LINK_SKIPPED,
    payload
  };
};
