import _ from 'lodash';
import { createSelector } from 'reselect';

export const categoriesSelector = (state) => _.get(state, ['dashboard2', 'categories']);
export const expensesSelector = (state) => _.get(state, ['dashboard2', 'expenses']);

export const savingsSelector = createSelector([expensesSelector], (expenses) => {
  return _.sumBy(expenses, (e) => e.taxSavings);
});

export const isW2OnlySelector = (state) => {
  const incomeType = _.get(state, ['work', 'workDetails', 'income_type'], []);
  return incomeType.length === 1 && incomeType[0] === 'salaried';
};
