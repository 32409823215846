import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '@app/src/Components/Common/Loader/Loader';
import TaxFlowActionButton from '@app/src/Components/TaxFlow/Common/TaxFlowActionButton';
import TaxFlowDetailedBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowDetailedBillElement';
import TaxFlowPdfButton from '@app/src/Components/TaxFlow/Question/TaxFlowPdfButton';
import DocumentDownloadIcon from '@app/src/assets/document-download.svg?react';
import { isReactNative, replaceString, sentMsgToReactNative } from '@app/src/global/Helpers';
import { downloadFileFromURL, getPastReturnByYear } from '@app/src/services/taxFlowService';
import { trackQuestionView } from '@app/src/taxflow/main/services/mainService';
import { PATH_COMPONENT__PAST_RETURNS, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowPastReturnDetails.scss';

const TaxFlowPastReturnDetails = ({ currentQuestion: question, history, downloadFileFromURL, trackQuestionView }) => {
  const [{ forms, amounts, showPDFDownloadLink }, setPastReturnData] = useState({
    forms: [],
    amounts: null,
    showPDFDownloadLink: false
  });
  const [loading, setLoading] = useState(true);

  const { year } = useParams();

  const title = replaceString(_.get(question, ['question_meta', 'detailsTitle']), { year });

  useEffect(() => {
    trackQuestionView(
      {
        slug: 'past-returns-details',
        title,
        flow: question.flow
      },
      { year }
    );
  }, [question, title, trackQuestionView, year]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const data = await getPastReturnByYear(year);

        setPastReturnData(data);
      } catch (e) {
        // Redirect if there is no past return
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__PAST_RETURNS}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [history, year]);

  if (loading) {
    return <Loader type='screen' />;
  }

  return (
    <>
      <div className='steps-head'>
        <h1>{title}</h1>
      </div>
      <div className='steps-body taxflow-past-return-detail'>
        {amounts && <TaxFlowDetailedBillElement amounts={amounts} />}
        {showPDFDownloadLink && <TaxFlowPdfButton year={year} />}
        {!_.isEmpty(forms) && (
          <>
            <h1>Documents</h1>
            <div className='taxflow-past-return-documents'>
              {forms
                .flatMap((form) => form.files)
                .map(({ file_name, fetch_url }) => {
                  const handleReactNativeClick = () => {
                    // Opens the file in a new tab
                    sentMsgToReactNative('open_learn_more_link', { url: fetch_url });
                  };

                  const handleClick = () => {
                    downloadFileFromURL(fetch_url, file_name);
                  };

                  return (
                    <TaxFlowActionButton
                      key={fetch_url}
                      onClick={isReactNative() ? handleReactNativeClick : handleClick}
                    >
                      <DocumentDownloadIcon />
                      <span>{file_name}</span>
                    </TaxFlowActionButton>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  downloadFileFromURL,
  trackQuestionView
};

const ConnectedTaxFlowPastReturnDetails = connect(null, mapDispatchToProps)(TaxFlowPastReturnDetails);

export default ConnectedTaxFlowPastReturnDetails;
