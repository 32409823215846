import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import OnboardingQuestion from '@app/src/Components/Onboarding/Question/OnboardingQuestion';
import { Url_ONBOARDING_DURATION } from '@app/src/constants/onboardingConstants';
import { getQuestion, updateAnswer } from '@app/src/services/onboardingService';

const OnboardingJobDuration = ({ history, getQuestion, updateAnswer }) => {
  useEffect(() => {
    getQuestion({ slug: Url_ONBOARDING_DURATION, jobNumberParam: 1, history });
  }, [getQuestion, history]);

  const onNext = async () => {
    await updateAnswer({ history });
  };

  return <OnboardingQuestion onNext={onNext} />;
};

const mapDispatchToProps = {
  getQuestion,
  updateAnswer
};

const ConnectedOnboardingJobDuration = connect(null, mapDispatchToProps)(OnboardingJobDuration);

export default ConnectedOnboardingJobDuration;
