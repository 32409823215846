import _ from 'lodash';
import { updateCache } from '@app/src/actions/taxFlowActions';
import { DEFAULT_WORK, Url_INTRO_TAX_STATUS } from '@app/src/constants/constants';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';
import { getWorkInfo } from '@app/src/services/workService';
import { refreshJobs } from '@app/src/taxflow/common/services/jobs';
import { getAnswers } from '@app/src/taxflow/common/services/workAnswers';

export const requireTaxStatus = () => async (dispatch, getState) => {
  if (_.isEmpty(_.get(getState(), ['taxFlow', 'cache', 'taxStatus']))) {
    await dispatch(forceRefreshTaxStatus());
  }
};

export const forceRefreshTaxStatus = () => async (dispatch) => {
  const res = await dispatch(
    getAnswers({
      queries: [
        {
          slug: Url_INTRO_TAX_STATUS
        }
      ]
    })
  );
  const taxStatus = _.get(res, ['data', 'data', 0, 'value'], '');
  dispatch(
    updateCache({
      taxStatus
    })
  );
};

export const requireWork = () => async (dispatch, getState) => {
  if (_.isEmpty(_.get(getState(), ['taxFlow', 'cache', 'work']))) {
    await dispatch(forceRefreshWork());
  }
};

export const forceRefreshWork = () => async (dispatch, getState) => {
  await dispatch(refreshJobs());

  const res = await dispatch(
    getAnswers({
      queries: [
        {
          slug: 'job-selection'
        }
      ]
    })
  );
  const jobNames = _.get(res, ['data', 'data', 0, 'value'], '').split(';');

  let work = DEFAULT_WORK;
  if (jobNames.length === 1) {
    const jobCategories = jobCategoriesSelector(getState());
    const jobCategory = jobCategories.find((job) => job.slug === jobNames[0]);
    if (jobCategory) {
      work = jobCategory.jobAsVerb;
    }
  }

  dispatch(
    updateCache({
      work
    })
  );
};

export const requireWorkInfo = () => async (dispatch, getState) => {
  const workDetails = workDetailsSelector(getState());
  if (_.isEmpty(workDetails)) {
    await dispatch(getWorkInfo());
  }
};
