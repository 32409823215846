import React from 'react';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { useTaxEstimateInput } from '@app/src/Components/TaxProfile/useTaxEstimateInput';
import taxDataApi from '@app/src/api/taxDataApi';

const TAXES_WITHHELD_RESPONSE = `This is the money your employer set aside from every paycheck to pay federal taxes.

Keeper will estimate your W-2 federal taxes withheld or you can enter a more accurate number from your W-2 tax form!`;

const STATE_TAXES_WITHHELD_RESPONSE = `This is the money your employer set aside from every paycheck to pay state taxes.

Keeper will estimate your W-2 state taxes withheld or you can enter a more accurate number from your W-2 tax form!`;

/**
 * @typedef TaxesPaidSectionProps
 * @property {import('react-hook-form').UseFormReturn['control']} control
 * @property {import('react-hook-form').UseFormReturn['handleSubmit']} handleSubmit
 * @property {string} filingStatus
 */

/** @type {React.FC<TaxesPaidSectionProps>} */
const TaxesPaidSection = () => {
  const {
    watch,
    getValues,
    formState: { isSubmitting }
  } = useFormContext();
  const { year } = useTaxProfileFormContext();

  const taxEstimateInput = useTaxEstimateInput();

  const { data: taxEstimate, isSuccess: taxEstimateIsSuccess } =
    taxDataApi.endpoints.calculateTaxEstimate.useQueryState(...taxEstimateInput);

  const filingStatus = watch('filing_status');
  const state = watch('state_residence');

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  return (
    <TaxProfileFormSection header='Taxes paid'>
      <ControlledYearlyMoneyInput
        name='w2_federal_income_taxes_withheld'
        label='W-2 federal income taxes withheld'
        textFieldProps={{
          ...(taxEstimateIsSuccess &&
            getValues('w2_federal_income_taxes_withheld') === null &&
            !isSubmitting && {
              placeholder: `${formatter.format(taxEstimate.federal.incomeTaxesWithheld)} / yr`,
              sx: {
                input: {
                  '&::placeholder': {
                    color: 'black'
                  }
                }
              }
            }),
          InputProps: {
            endAdornment: (
              <AskButton
                field='w2_federal_income_taxes_withheld'
                message='What are W-2 federal taxes withheld?'
                response={TAXES_WITHHELD_RESPONSE}
              />
            )
          }
        }}
      />
      {(_.isNil(state) || state !== '') && (
        <ControlledYearlyMoneyInput
          name='w2_state_income_taxes_withheld'
          label='W-2 state income taxes withheld'
          textFieldProps={{
            ...(taxEstimateIsSuccess &&
              getValues('w2_state_income_taxes_withheld') === null &&
              !isSubmitting &&
              taxEstimate.state?.totalTaxesWithheld && {
                placeholder: `${formatter.format(taxEstimate.state?.totalTaxesWithheld)} / yr`,
                sx: {
                  input: {
                    '&::placeholder': {
                      color: 'black'
                    }
                  }
                }
              }),
            InputProps: {
              endAdornment: (
                <AskButton
                  field='w2_state_income_taxes_withheld'
                  message='What are W-2 state taxes withheld?'
                  response={STATE_TAXES_WITHHELD_RESPONSE}
                />
              )
            }
          }}
        />
      )}
      {filingStatus === 'married' && (
        <ControlledYearlyMoneyInput
          name='spouse_w2_federal_income_taxes_withheld'
          label="Spouse's W-2 federal income taxes withheld"
          textFieldProps={{
            InputProps: {
              endAdornment: (
                <AskButton
                  field='spouse_w2_federal_income_taxes_withheld'
                  message='What are W-2 federal taxes withheld?'
                  response={TAXES_WITHHELD_RESPONSE}
                />
              )
            }
          }}
        />
      )}
      {filingStatus === 'married' && state !== '' && (
        <ControlledYearlyMoneyInput
          name='spouse_w2_state_income_taxes_withheld'
          label="Spouse's W-2 state income taxes withheld"
          textFieldProps={{
            InputProps: {
              endAdornment: (
                <AskButton
                  field='spouse_w2_state_income_taxes_withheld'
                  message='What are W-2 state taxes withheld?'
                  response={STATE_TAXES_WITHHELD_RESPONSE}
                />
              )
            }
          }}
        />
      )}
      <ControlledYearlyMoneyInput
        name='quarterly_tax_payments'
        label='Quarterly tax payments'
        textFieldProps={{
          InputProps: {
            endAdornment: (
              <AskButton
                field='quarterly_tax_payments'
                message='What are quarterly tax payments?'
                response={`Quarterly taxes, or estimated tax payments, are payments due four times a year to help manage your tax burden and avoid underpayment penalties. If you made quarterly tax payments, enter the total amount paid in ${year} here.`}
              />
            )
          }
        }}
      />
    </TaxProfileFormSection>
  );
};

export default TaxesPaidSection;
