import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { colorSurface } from '@app/src/theme';

const ExpenseTableHeader = ({ headerCells }) => (
  <TableRow
    style={{
      backgroundColor: colorSurface,
      height: '36px'
    }}
  >
    {headerCells.map(({ id, label, padding, width }) => (
      <TableCell padding={padding} key={id} style={{ width }}>
        {label}
      </TableCell>
    ))}
  </TableRow>
);

export default ExpenseTableHeader;
