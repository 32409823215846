import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { taxProgressStageSelector } from '@app/src/selectors/navigationListSelectors';
import { easyRefundsEnabledSelector } from '@app/src/selectors/workSelectors';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const isEmailLoadingSelector = (state) => _.get(state, ['settings', 'isEmailLoading'], false);
export const isPhoneLoadingSelector = (state) => _.get(state, ['settings', 'isPhoneLoading'], false);
export const isFirstnameLoadingSelector = (state) => _.get(state, ['settings', 'isFirstnameLoading'], false);
export const isLastnameLoadingSelector = (state) => _.get(state, ['settings', 'isLastnameLoading'], false);
export const nextChargeDateSelector = (state) => _.get(state, ['settings', 'nextChargeDate']);
export const isCancelingSubscriptionSelector = (state) => _.get(state, ['settings', 'isCancelingSubscription'], false);
export const settingsModalTypeSelector = (state) => _.get(state, ['settings', 'modalType']);
export const settingsLoadingSelector = (state) => _.get(state, ['settings', 'loading'], false);

const refundConditionsMetSelector = createSelector(
  [accountDetailsSelector, taxProgressStageSelector],
  (accountDetails, stage) => {
    const { firstChargeDate, subscriptionType, status } = accountDetails;
    const isWithinTheFirstMonth = firstChargeDate && moment().diff(firstChargeDate, 'days') < 30;
    const userFiledStates = ['filed_with_irs', 'accepted_by_irs', 'rejected_by_irs'];
    const hasFiledTaxes = userFiledStates.includes(stage);

    return status === 'active' && isWithinTheFirstMonth && !hasFiledTaxes && subscriptionType === 'stripe';
  }
);

export const isEligibleForRefundSelector = createSelector(
  [refundConditionsMetSelector, easyRefundsEnabledSelector],
  (refundConditionsMet, easyRefundsEnabled) => {
    return easyRefundsEnabled && refundConditionsMet;
  }
);
