import React from 'react';
import { connect } from 'react-redux';
import ChurnExplanation from '@app/src/Components/Settings/Modals/Churn/ChurnExplanation';
import ChurnInitial from '@app/src/Components/Settings/Modals/Churn/ChurnInitial';
import DeleteAccountModal from '@app/src/Components/Settings/Modals/DeleteAccountModal';
import PaymentMethodModal from '@app/src/Components/Settings/Modals/PaymentMethodModal';
import SubscriptionTypeModal from '@app/src/Components/Subscription/SubscriptionTypeModal/SubscriptionTypeModal';
import { settingsModalTypeSelector } from '@app/src/selectors/settingsSelectors';

const SettingsModalContent = ({ modalType }) => {
  switch (modalType) {
    case 'subscription':
      return <SubscriptionTypeModal />;
    case 'payment':
      return <PaymentMethodModal />;
    case 'churn-initial':
      return <ChurnInitial />;
    case 'churn-explanation':
      return <ChurnExplanation />;
    case 'delete-account':
      return <DeleteAccountModal />;
    default:
      return null;
  }
};

const mapStateToProps = (state) => ({
  modalType: settingsModalTypeSelector(state)
});

const ConnectedSettingsModalContent = connect(mapStateToProps)(SettingsModalContent);

export default ConnectedSettingsModalContent;
