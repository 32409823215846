import _ from 'lodash';
import io from 'socket.io-client';
import {
  CONNECT_SOCKET,
  DISCONNECT_SOCKET,
  SET_FORM_UPLOAD_CLOUD_STATUS,
  CLEAR_FORM_UPLOAD_ALERT
} from '@app/src/actions/types';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { serverUrl } from '@app/src/global/Environment';
import { trackActivity } from '@app/src/services/analyticsService';
import { getUploadAttemptsStatuses } from '@app/src/taxFlowMessageEntrypoint';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

export const createSocketMiddleware = () => (store) => {
  let socket = null;

  return (next) => (action) => {
    switch (action.type) {
      case CONNECT_SOCKET:
        if (socket) {
          socket.disconnect();
        }
        socket = createSocket(store);
        return;
      case DISCONNECT_SOCKET:
        if (socket) {
          socket.disconnect();
        }
        socket = null;
        return;
      case SET_FORM_UPLOAD_CLOUD_STATUS:
        if (socket) {
          socket.emit('cloud upload result', action.payload);
        } else {
          socket = createSocket(store);
          socket.emit('cloud upload result', action.payload);
        }
        return;
      case CLEAR_FORM_UPLOAD_ALERT:
        if (socket) {
          socket.emit('clear form upload alert', action.payload);
        } else {
          socket = createSocket(store);
          socket.emit('clear form upload alert', action.payload);
        }
        return;
      default:
        return next(action);
    }
  };
};

const createSocket = (store) => {
  const socket = io(`${serverUrl()}web`, {
    transports: ['websocket'],
    auth: (cb) => {
      cb({
        token: localStorage.getItem('KeeperToken')
      });
    }
  });

  socket.io.on('reconnect', async () => {
    store.dispatch(getUploadAttemptsStatuses());
  });

  socket.io.on('error', (e) => {
    defaultCaptureException(`web_socket_error: ${e}`);
  });

  socket.on('connect_error', (error) => {
    defaultCaptureException(`connect_error: ${error}`);
  });

  socket.on('message', async (action) => {
    trackActivity('web socket received', { webhook: action.type });
    if (['FORM_UPLOAD_OCR_RESULT', 'BULK_FORM_CLASSIFIED'].includes(action.type)) {
      store.dispatch(getUploadAttemptsStatuses());
    } else if (action.type === 'RETRO_STATUS_CHANGE') {
      if (action.payload.complete) {
        store.dispatch(baseApi.util.invalidateTags([{ type: TAGS.EXPENSE_REVIEW_DETAILS, id: action.payload.year }]));
      }
      store.dispatch(
        baseApi.util.invalidateTags(
          _.isNil(action.payload.year) ? [TAGS.RETRO_STATUS] : [{ type: TAGS.RETRO_STATUS, id: action.payload.year }]
        )
      );
    } else {
      return;
    }
  });

  return socket;
};
