import React from 'react';
import classNames from 'classnames';
import '@app/src/Components/Login/LoginWithOTP/LoginWindow.scss';

const LoginWindow = ({ className, children }) => {
  return (
    <div className={classNames('login-window', className)}>
      <div className='login-window-container'>
        <div className='login-window-form-container'>{children}</div>
      </div>
      <div className='login-window-privacy'>
        By logging in, you agree to our{' '}
        <a href='https://www.keepertax.com/terms' target='_blank' referrerPolicy='no-referrer' rel='noreferrer'>
          Terms
        </a>{' '}
        and acknowledge our{' '}
        <a href='https://www.keepertax.com/privacy' referrerPolicy='no-referrer' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default LoginWindow;
