import moment from 'moment';

export function getIndefiniteArticle(word) {
  if (word && word.length !== 0 && /[aeiou]/.test(word[0])) {
    return 'an';
  } else {
    return 'a';
  }
}

/**
 * Gets the default expense year based on the current date.
 * If it is after June 1, the current year is returned.
 * Otherwise, the previous year is returned.
 *
 * @returns {string} The default expense year
 */
export const getDefaultExpenseYear = () => {
  const currentYear = moment().year();
  const juneFirst = moment(`${currentYear}-06-01`);
  const isAfterJuneFirst = moment().isSameOrAfter(juneFirst);
  const year = isAfterJuneFirst ? currentYear : currentYear - 1;
  return `${year}`;
};
