import React from 'react';
import { connect } from 'react-redux';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import PlaidSecurityNotice from '@app/src/Components/HolisticOnboarding/components/PlaidSecurityNotice/PlaidSecurityNotice';
import { bankListSelector } from '@app/src/selectors/bankSelectors';

const OnboardingBankLink = ({ banklist }) => {
  return (
    <div style={{ overflow: 'auto' }}>
      <BankList isOnboarding />
      {banklist.length === 0 && <PlaidSecurityNotice />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  banklist: bankListSelector(state)
});

const ConnectedOnboardingBankLink = connect(mapStateToProps)(OnboardingBankLink);

export default ConnectedOnboardingBankLink;
