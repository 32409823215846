import _ from 'lodash';
import {
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_FORM_EMAIL,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TEXT
} from '@app/src/constants/constants';
import {
  Url_ONBOARDING_DURATION,
  Url_SIGNUP_PHONE,
  Url_ONBOARDING_VERIFY,
  Url_SIGNUP_LINK,
  Url_ONBOARDING_DURATION_DATE
} from '@app/src/constants/onboardingConstants';
import { getCurrentYear, getPreviousYear, isEmail } from '@app/src/global/Helpers';
import isQuestionPresent from '@app/src/utils/onboarding/isQuestionPresent';

const isValidInput = ({ question, answer, currentAnswer }) => {
  if (!question) {
    return false;
  }
  if (question.slug === Url_ONBOARDING_DURATION) {
    return isOnboardingDurationValid({ answer });
  }
  if (question.slug === Url_SIGNUP_PHONE) {
    return true;
  }
  if (question.slug === Url_SIGNUP_LINK) {
    return true;
  }
  if (question.slug === Url_ONBOARDING_VERIFY) {
    return false;
  }
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    return question.sub_question.every((q) => {
      const a = _.get(answer, ['value', q.slug], null);
      return isValidInput({ question: q, answer: a, currentAnswer });
    });
  }
  if (!question.required || !isQuestionPresent({ question, currentAnswer })) {
    return false;
  }
  if (question.question_type === CATEGORY_TYPE_OPTIONS) {
    const hasDefault = question.question_meta.some((option) => option.default);
    return !hasDefault && answer && !_.isNil(answer.value);
  }
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION) {
    return answer && !_.isEmpty(answer.value);
  }
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY) {
    return answer && !_.isEmpty(answer.value);
  }
  if (question.question_type === CATEGORY_TYPE_TEXT) {
    return answer && !_.isEmpty(answer.value);
  }
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_EMAIL) {
    return answer && isEmail(answer.value);
  }
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PHONE) {
    return isPhoneValid({ question, answer });
  }
  return true;
};

const isOnboardingDurationValid = ({ answer }) => {
  const [year] = _.get(answer, ['value', Url_ONBOARDING_DURATION_DATE, 'value'], '').split('-');

  if (year === getPreviousYear() || year === getCurrentYear()) {
    return true;
  }

  return false;
};

const isPhoneValid = ({ answer }) => {
  const isCorrectLength = answer && !_.isEmpty(answer.value) && answer.value.length === 11;
  return isCorrectLength && !_.get(answer, ['value'], '').startsWith('11');
};

export default isValidInput;
