import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { updateIsPaymentValid } from '@app/src/taxflow/sections/submit/services/submitService';
import '@app/src/Components/TaxFlow/Question/TaxFlowPaymentElement.scss';

class TaxFlowPaymentElement extends React.Component {
  isValidInput = (e) => {
    if (e.complete && !e.error) {
      this.props.updateIsPaymentValid(true);
    }
  };

  isComplete = (e) => {
    if (e.error || !e.complete) {
      this.props.updateIsPaymentValid(false);
    }
  };

  render() {
    const { className } = this.props;

    return (
      <div className={classNames('taxflow-payment-element', className)}>
        <div className='taxflow-payment-element-panel mb-20'>
          <div className='taxflow-payment-element-panel-header'>
            <div className='taxflow-payment-element-panel-header-content'>Credit or debit card</div>
          </div>
          <CardElement
            className='taxflow-payment-element-card'
            onChange={this.isValidInput}
            onReady={this.isComplete}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateIsPaymentValid
};

const ConnectedTaxFlowPaymentElement = connect(null, mapDispatchToProps)(TaxFlowPaymentElement);

export default ConnectedTaxFlowPaymentElement;
