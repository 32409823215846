import React from 'react';
import TaxFlowExpensesLinkItem from '@app/src/Components/TaxFlow/Question/TaxFlowExpensesLinkItem';

const TaxFlowFormExpensesLinkItem = ({ question, answer, onChange, ...props }) => {
  const handleChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  return (
    <div className='form-group'>
      <TaxFlowExpensesLinkItem
        {...props}
        isChildQuestion
        currentQuestion={question}
        currentAnswer={answer}
        onChange={handleChange}
      />
    </div>
  );
};

export default TaxFlowFormExpensesLinkItem;
