import _ from 'lodash';
import { useGetExpenseReviewDetailsQuery } from '@app/src/api/expensesApi';

export const useHasTransactions = ({ year }) => {
  const { data: expenseReviewDetails, isLoading: expenseReviewDetailsLoading } = useGetExpenseReviewDetailsQuery({
    year
  });
  return {
    hasTransactions: _.chain(expenseReviewDetails)
      .get('categoryCounts')
      .some(({ total }) => total > 0)
      .value(),
    isLoading: expenseReviewDetailsLoading
  };
};
