import React from 'react';
import Button from '@mui/material/Button';
import { Warning2 } from 'iconsax-react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { unlinkedBanksSelector } from '@app/src/selectors/bankSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { colorWarning } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/Common/BankUnlinkedButton/BankUnlinkedButton.scss';

const BankUnlinkedButton = ({ unlinkedBanks }) => {
  const { push } = useHistory();

  if (!unlinkedBanks.length) return null;

  const handleClick = () => {
    trackActivity('expense review: unlinked bank button clicked', { unlinkedBanks: unlinkedBanks.length });

    push('/settings/linked-accounts');
  };

  const bankName = unlinkedBanks[0]?.institutionName;
  const extraBanksCopy =
    unlinkedBanks.length > 1 ? ` and ${unlinkedBanks.length - 1} other${unlinkedBanks.length > 2 && 's'}` : '';

  return (
    <Button onClick={handleClick} fullWidth variant='warning-button' style={{ marginBottom: '16px' }}>
      <div className='unlinked-bank-button-content'>
        <Warning2 color={colorWarning} className='unlinked-bank-button-icon' /> Connection needs to be refreshed. To
        reconnect {bankName}
        {extraBanksCopy},
        <span style={{ marginLeft: 4 }}>
          <a>click here</a>
        </span>
        .
      </div>
    </Button>
  );
};

const mapStateToProps = (state) => ({
  unlinkedBanks: unlinkedBanksSelector(state)
});

const ConnectedBankUnlinkButton = connect(mapStateToProps)(BankUnlinkedButton);

export default ConnectedBankUnlinkButton;
