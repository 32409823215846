import {
  CLEAR_FORM_UPLOAD_ALERT,
  CONNECT_SOCKET,
  DISCONNECT_SOCKET,
  SET_FORM_UPLOAD_CLOUD_STATUS
} from '@app/src/actions/types';

export const connectSocket = () => {
  return {
    type: CONNECT_SOCKET
  };
};

export const disconnectSocket = () => {
  return {
    type: DISCONNECT_SOCKET
  };
};

export const setFormUploadCloudStatus = ({ recordId, status, error }) => {
  return {
    type: SET_FORM_UPLOAD_CLOUD_STATUS,
    payload: { recordId, status, error }
  };
};

export const clearFormUploadAlert = (recordId) => {
  return {
    type: CLEAR_FORM_UPLOAD_ALERT,
    payload: recordId
  };
};
