import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AnimatedList from '@app/src/Components/HolisticOnboarding/components/AnimatedList/AnimatedList';
import ResponseText from '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText';
import LoadingBanner from '@app/src/Components/Onboarding/LoadingBanner';
import { BANK_LINK_LOADING_LIST_ITEMS, BANK_SKIP_LOADING_LIST_ITEMS } from '@app/src/constants/loadingPageConstants';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import getImageUrl from '@app/src/utils/getImageUrl';

const OnboardingLoadingInitial = ({ bankList, onContinue }) => {
  const {
    location: { pathname },
    push
  } = useHistory();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 4000);

    setTimeout(() => {
      onContinue({ pathname, push });
    }, 5500);
  }, [onContinue, pathname, push]);

  const bankLinkSkipped = bankList.length === 0;
  const bulletsText = useRef(bankLinkSkipped ? BANK_SKIP_LOADING_LIST_ITEMS : BANK_LINK_LOADING_LIST_ITEMS);

  return (
    <div className='onboarding-2-loading'>
      <img
        style={{ display: 'flex', width: '100%', height: 'auto', margin: '0 auto', maxWidth: '300px' }}
        src={getImageUrl('bank-statement.webp')}
        alt='transaction scan'
      />
      <LoadingBanner
        bankLinkSkipped={bankLinkSkipped}
        className='onboarding-2-loading-title'
        hideBottomBorder
        topPadding
      />
      <AnimatedList items={bulletsText.current} />
      <ResponseText pathname={pathname} visible={visible} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bankList: bankListSelector(state)
});

const mapDispatchToProps = {
  onContinue
};

const ConnectedOnboardingLoadingInitial = connect(mapStateToProps, mapDispatchToProps)(OnboardingLoadingInitial);

export default ConnectedOnboardingLoadingInitial;
