import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import '@app/src/Components/Common/Loader/Loader.scss';

const Loader = ({ className, type }) => {
  const loader = <CircularProgress color='secondary' className={classNames('common-loader', className)} size={50} />;

  if (type === 'page') {
    return <div className='common-loader-container'>{loader}</div>;
  } else if (type === 'screen') {
    return <div className='common-loader-screen'>{loader}</div>;
  }

  return loader;
};

export default Loader;
