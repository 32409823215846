import React from 'react';
import TaxFlowNumberItem from '@app/src/Components/TaxFlow/Question/TaxFlowNumberItem';

class TaxFlowFormNumberItem extends React.Component {
  onChange = (value) => {
    this.props.onChange({ slug: this.props.question.slug, value, endpoint_attr: this.props.question.endpoint_attr });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;
    return (
      <div className='form-group'>
        <TaxFlowNumberItem
          {...this.props}
          isChildQuestion
          currentQuestion={question}
          currentAnswer={answer}
          onChange={this.onChange}
          onFocus={this.onFocus}
        />
      </div>
    );
  }
}

export default TaxFlowFormNumberItem;
