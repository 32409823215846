import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import '@app/src/taxflow/main/components/TaxFlowHome.scss';

export const HomeSkeleton = () => {
  return (
    <>
      <div className='taxflow-home-skeleton-nav-bar'>
        <div className='taxflow-home-skeleton-nav-bar-section' style={{ flexGrow: 1 }}>
          <Skeleton height={8} style={{ width: '100%', backgroundColor: 'grey' }} />
          <Skeleton height={8} style={{ width: '60%', backgroundColor: 'grey' }} />
        </div>
        <div className='taxflow-home-skeleton-nav-bar-section' style={{ flexGrow: 0.5 }}>
          <Skeleton height={8} style={{ width: '100%', backgroundColor: 'grey' }} />
          <Skeleton height={8} style={{ width: '60%', backgroundColor: 'grey' }} />
        </div>
        <div className='taxflow-home-skeleton-nav-bar-section' style={{ flexGrow: 0.5 }}>
          <Skeleton height={8} style={{ width: '100%', backgroundColor: 'grey' }} />
          <Skeleton height={8} style={{ width: '60%', backgroundColor: 'grey' }} />
        </div>
      </div>
      <Skeleton height={16} width={250} />
      <div className='taxflow-home-skeleton-section'>
        <Skeleton height={12} width={101} />
        <Skeleton height={136} />
        <Skeleton height={12} width={101} />
        <div className='taxflow-home-skeleton-section-row'>
          <Skeleton height={36} width={140} />
          <Skeleton height={36} width={120} />
          <Skeleton height={36} width={120} />
          <Skeleton height={36} width={140} />
          <Skeleton height={36} width={120} />
          <Skeleton height={36} width={120} />
        </div>
      </div>
      <div className='taxflow-home-skeleton-section'>
        <Skeleton height={12} width={250} />
        <div className='taxflow-home-skeleton-section-row'>
          <Skeleton height={33} width={80} />
          <Skeleton height={33} width={80} />
          <Skeleton height={33} width={80} />
          <Skeleton height={33} width={80} />
        </div>
      </div>
      <div className='taxflow-home-skeleton-section'>
        <Skeleton height={12} width={250} />
        <div className='taxflow-home-skeleton-section-row'>
          <Skeleton height={36} width={140} />
          <Skeleton height={36} width={120} />
          <Skeleton height={36} width={120} />
          <Skeleton height={36} width={140} />
          <Skeleton height={36} width={120} />
          <Skeleton height={36} width={120} />
          <Skeleton height={36} width={140} />
        </div>
        <Skeleton height={56} />
      </div>
      <Skeleton height={72} />
    </>
  );
};
