import _ from 'lodash';
import { createSelector } from 'reselect';
import { isSignupLinkSkippable } from '@app/src/utils/userUtils';

export const userSelector = (state) => _.get(state, ['auth', 'user']);

export const signupLinkSkippableSelector = createSelector([userSelector], (user) => isSignupLinkSkippable(user));

export const annualPriceSelector = (state) => {
  const accountDetails = _.get(state, ['pricing2', 'accountDetails']);
  return accountDetails.annualPrice;
};

export const premiumPriceSelector = (state) => {
  const accountDetails = _.get(state, ['pricing2', 'accountDetails']);
  return accountDetails.premiumPrice;
};
