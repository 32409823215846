import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  isHeaderHiddenSelector,
  isFormMissingHeaderSelector,
  firstFormQuestionSlugSelector
} from '@app/src/selectors/taxFlowSelectors';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

const TaxValidationLabel = ({
  className,
  question,
  title,
  hasError,
  isHeaderHidden,
  isPlaceholder,
  isFormMissingHeader,
  firstFormQuestionSlug,
  ...props
}) => {
  if (isHeaderHidden) return null;

  const summary = _.get(question, 'summary');

  return (
    <div>
      <label
        className={classNames(
          'tax-validation-label',
          { 'tax-validation-label-error': hasError && !isPlaceholder },
          { 'margin-top-none': isFormMissingHeader && firstFormQuestionSlug === question.slug },
          question.header_style,
          className
        )}
        {..._.omit(props, ['dispatch', 'answer'])}
      >
        {title}
      </label>
      {!_.isEmpty(summary) && <ReactMarkdown className='tax-validation-summary' source={summary} linkTarget='_blank' />}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector,
  isHeaderHidden: isHeaderHiddenSelector,
  isFormMissingHeader: isFormMissingHeaderSelector,
  firstFormQuestionSlug: firstFormQuestionSlugSelector
});

const ConnectedTaxValidationLabel = connect(mapStateToProps)(TaxValidationLabel);

export default ConnectedTaxValidationLabel;
