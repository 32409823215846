import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import OnboardingListItem from '@app/src/Components/Onboarding/Question/OnboardingListItem';

const OnboardingOptionItem = ({
  className,
  currentQuestion,
  currentAnswer,
  resultLoading,
  isChildQuestion,
  multiple,
  onChange,
  replaceStrings,
  setCurrentAnswer
}) => {
  const items = _.get(currentQuestion, 'question_meta', []).map((item) => ({
    ...item,
    text: replaceStrings(item.text),
    value: replaceStrings(item.value)
  }));

  const onChangeImpl = (item) => {
    if (isChildQuestion) {
      onChange({ slug: _.get(currentQuestion, 'slug'), value: item });
    } else {
      setCurrentAnswer({ value: item });
    }
  };

  if (resultLoading) {
    return null;
  }

  return (
    <div className={classNames({ 'steps-body': !isChildQuestion }, className)}>
      <div className='checkbox-list'>
        {items.map((item) => (
          <OnboardingListItem
            key={item.value}
            item={item}
            currentQuestion={currentQuestion}
            currentAnswer={currentAnswer}
            multiple={multiple}
            onChange={onChangeImpl}
          />
        ))}
      </div>
    </div>
  );
};

export default OnboardingOptionItem;
