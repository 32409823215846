import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { setFeedbackModalShow } from '@app/src/actions/taxFlowActions';
import CloseIcon from '@app/src/assets/close.svg?react';
import ReferralAsset from '@app/src/assets/referrals.svg?react';
import { feedbackModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getTrustpilotLink } from '@app/src/services/taxFlowService';
import '@app/src/Components/TaxFlow/Common/TaxFlowFeedbackModal.scss';

const TaxFlowFeedbackModal = ({ feedbackModalShow, setFeedbackModalShow }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onClose = () => {
    trackActivity('tax filing: reviews modal closed');
    setFeedbackModalShow(false);
    setButtonDisabled(false);
  };

  const submitFeedback = async () => {
    setButtonDisabled(true);
    trackActivity('tax filing: reviews modal clicked');
    const url = await getTrustpilotLink();
    window.open(url, '_blank');
  };

  return (
    <Dialog
      sx={{ '.MuiPaper-root': { maxWidth: '100%', width: '570px', borderRadius: '8px' } }}
      onClose={onClose}
      open={!!feedbackModalShow}
    >
      <div>
        <DialogTitle className='taxflow-feedback-modal-title'>
          Got a min? Help us with a public review!
          <CloseIcon onClick={onClose} className='taxflow-feedback-modal-header-icon' />
        </DialogTitle>
      </div>
      <DialogContent>
        <DialogContentText>
          Your honest feedback paints a picture for what other tax filers can expect from Keeper.
        </DialogContentText>
        <ReferralAsset className='taxflow-feedback-modal-asset' />
      </DialogContent>
      <Button
        variant='contained'
        color='secondary'
        onClick={submitFeedback}
        sx={{ margin: '16px' }}
        disabled={buttonDisabled}
      >
        Leave a review
      </Button>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  feedbackModalShow: feedbackModalShowSelector(state)
});

const mapDispatchToProps = {
  setFeedbackModalShow
};

const ConnectedTaxFlowFeedbackModal = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFeedbackModal);

export default ConnectedTaxFlowFeedbackModal;
