import { SET_SENDING_LINK_TYPE } from '@app/src/actions/types';

const initialState = {
  sendingLinkType: null
};

const mobileRedirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SENDING_LINK_TYPE: {
      return {
        ...state,
        sendingLinkType: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default mobileRedirectReducer;
