import React from 'react';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';

/**
 * @typedef CreditsAndDeductionsSectionProps
 * @property {import('react-hook-form').UseFormReturn['control']} control
 * @property {import('react-hook-form').UseFormReturn['handleSubmit']} handleSubmit
 */

/** @type {React.FC<CreditsAndDeductionsSectionProps>} */
const CreditsAndDeductionsSection = () => {
  return (
    <TaxProfileFormSection header='Credits and deductions'>
      <ControlledYearlyMoneyInput
        name='tax_calc_credits.tuitionFeesAmt'
        label='Student tuition paid'
        textFieldProps={{
          InputProps: {
            endAdornment: (
              <AskButton
                field='tax_calc_credits.tuitionFeesAmt'
                message='What is student tuition paid?'
                response='This is the annual payment made towards student tuition, excluding any scholarships received. Keeper will calculate education tax credits using the amount you paid.'
              />
            )
          }
        }}
      />
      <ControlledYearlyMoneyInput
        name='tax_calc_credits.mortgageIntAmt'
        label='Mortgage interest'
        textFieldProps={{
          InputProps: {
            endAdornment: (
              <AskButton
                field='tax_calc_credits.mortgageIntAmt'
                message='What is mortgage interest?'
                response='This is the interest charged on a loan used to purchase your home. Since mortgage interest is tax-deductible, Keeper will subtract this amount from your taxable income to reduce your taxes owed.'
              />
            )
          }
        }}
      />
      <ControlledYearlyMoneyInput
        name='tax_calc_credits.iraContrAmt'
        label='Traditional IRA contributions'
        textFieldProps={{
          InputProps: {
            endAdornment: (
              <AskButton
                field='tax_calc_credits.iraContrAmt'
                message='What are traditional IRA contributions?'
                response='This is the total amount you contributed to a traditional IRA retirement account. Since traditional IRA contributions are tax-deductible, Keeper will subtract this amount from your taxable income to reduce your taxes owed.'
              />
            )
          }
        }}
      />
    </TaxProfileFormSection>
  );
};

export default CreditsAndDeductionsSection;
