import React from 'react';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

/**
 * @typedef IncomeSectionProps
 * @property {string} jobType
 * @property {string} incomeType
 * @property {string} filingStatus
 */

/** @type {React.FC<IncomeSectionProps>} */
const IncomeSection = () => {
  const { watch } = useFormContext();
  const { jobType, incomeType } = useTaxProfileFormContext();

  const filingStatus = watch('filing_status');

  return (
    <TaxProfileFormSection header='Income'>
      <ControlledYearlyMoneyInput name='w2_income_amount' label='W-2 employee income' defaultValue={0} />
      <ControlledYearlyMoneyInput name='agi' label={`${_.capitalize(jobType)} income`} defaultValue={0} />
      {filingStatus === 'married' && (
        <>
          <ControlledYearlyMoneyInput name='spouse_w2_income' label='Spouse W-2 employee income' defaultValue={0} />
          <ControlledYearlyMoneyInput name='spouse_agi' label={`Spouse ${incomeType} income`} defaultValue={0} />
        </>
      )}
    </TaxProfileFormSection>
  );
};

export default IncomeSection;
