import axios from 'axios';

/**
 * @desc Sets token value in Authorization for private routes
 * @param {*} token
 */
const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setAuthToken;
