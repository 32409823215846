import _ from 'lodash';

function formatIdVerificationQuestions(input) {
  const formatted = input.questions.question.map((item, index) => {
    const title = item.prompt;
    const id = item.type;
    const question_meta = item.answer.map((answerText) => {
      return { text: answerText.toString(), value: answerText.toString() };
    });
    return { title, id, question_meta, slug: `id-verification-${index + 1}` };
  });
  return { slug: 'submit-confirm-id', id: _.get(input, ['idologyReqId']), sub_question: formatted };
}

function mergeQuestions(currentQuestions, idVerificationQuestions) {
  const currentQuestionsClone = _.cloneDeep(currentQuestions);
  const idVerificationQuestionsClone = _.cloneDeep(idVerificationQuestions);
  const merged = _.merge(currentQuestionsClone, idVerificationQuestionsClone);

  return {
    ...merged,
    sub_question: mergeSubQuestions(currentQuestionsClone.sub_question, idVerificationQuestionsClone.sub_question)
  };
}

function mergeSubQuestions(subQ1, subQ2) {
  const subQ2Map = _.keyBy(subQ2, 'id');
  return subQ1.map((subQ) => _.merge({}, subQ, subQ2Map[subQ.id]));
}

export { formatIdVerificationQuestions, mergeQuestions };
