import React from 'react';
import DeductionScan from '@app/src/assets/how_it_works_v2.webp';

const OnboardingScan = () => (
  <div className='h-onboarding-content-question-image'>
    <img src={DeductionScan} alt='Deduction Scan' />
  </div>
);

export default OnboardingScan;
