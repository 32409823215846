import React from 'react';
import * as Sentry from '@sentry/react';
import GenericErrorScreen from '@app/src/Components/GenericErrorScreen/GenericErrorScreen';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

class SentryBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false
    };
  }

  static getDerivedStateFromError() {
    return {
      error: true
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setTag('origin', 'generic error screen');
      scope.setContext('errorInfo', errorInfo);
      defaultCaptureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return <GenericErrorScreen />;
    }

    return this.props.children;
  }
}

export default SentryBoundary;
