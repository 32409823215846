import React from 'react';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import {
  setCollectionInfoModalShow,
  setCollectionModalDescription,
  setCollectionModalTitle,
  setSelectedCollectionTypeItem
} from '@app/src/actions/taxFlowActions';
import CloseIcon from '@app/src/assets/close.svg?react';
import {
  collectionModalDescriptionSelector,
  collectionModalTitleSelector
} from '@app/src/selectors/taxFlowModalsSelectors';
import { collectionTypeItemsWithSubstitutionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { selectedCollectionTypeItemSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowCollectionInfoModal = ({
  collectionTypeItems,
  selectedCollectionTypeItem,
  title,
  description,
  setCollectionInfoModalShow,
  setSelectedCollectionTypeItem
}) => {
  const onClose = () => {
    setCollectionInfoModalShow(false);
    setSelectedCollectionTypeItem(null);
    setCollectionModalTitle(null);
    setCollectionModalDescription(null);
  };
  const collectionTypeItem = collectionTypeItems.find((item) => item.fields.slug === selectedCollectionTypeItem);
  const headerText = _.get(collectionTypeItem, ['fields', 'modalTitle'], title);
  const bodyText = _.get(collectionTypeItem, ['fields', 'modalBody'], description);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        {headerText}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  collectionTypeItems: collectionTypeItemsWithSubstitutionsSelector(state),
  selectedCollectionTypeItem: selectedCollectionTypeItemSelector(state),
  title: collectionModalTitleSelector(state),
  description: collectionModalDescriptionSelector(state)
});

const mapDispatchToProps = {
  setCollectionInfoModalShow,
  setSelectedCollectionTypeItem
};

const ConnectedTaxFlowCollectionInfoModal = connect(mapStateToProps, mapDispatchToProps)(TaxFlowCollectionInfoModal);

export default ConnectedTaxFlowCollectionInfoModal;
