/**
 *
 * @param {number} progress - greater than or equal to 0 and less than or equal to 100
 * @param {number} totalSections
 * @param {number} currentSection
 * @returns {number}
 */
export const determineMultiProgressValue = (progress, totalSections, currentSection) => {
  const increment = 100 / totalSections;
  const currentIncrement = increment * currentSection;

  if (progress >= currentIncrement) {
    return 100;
  }

  if (progress < currentIncrement - increment) {
    return 0;
  }

  return (progress % increment) * totalSections;
};

/**
 * @param {number} progress - greater than or equal to 0 and less than or equal to 100
 * @param {string[]} stepsCopy
 * @returns {string}
 */
export const determineCopy = (progress, stepsCopy) => {
  const increment = 100 / stepsCopy.length;
  const currentSection = Math.floor(progress / increment);

  if (progress === 100) {
    return stepsCopy[stepsCopy.length - 1];
  }

  return stepsCopy[currentSection];
};

export const validateEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const capitalizeFirstLetter = (string) => {
  if (string && typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};
