import React, { useMemo } from 'react';
import _ from 'lodash';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import moment from 'moment';
import ArrowDownIcon from '@app/src/assets/arrow-down.svg?react';
import { getCurrentYear } from '@app/src/global/Helpers';

const OnboardingDropdownItem = ({ className, question, answer, onChange, replaceStrings }) => {
  const items = useMemo(() => {
    return _.get(question, 'question_meta', [])
      .map((item) => ({
        ...item,
        text: replaceStrings(item.text),
        value: replaceStrings(item.value)
      }))
      .filter((item) => {
        const currentYear = getCurrentYear();
        const currentMonth = moment().month() + 1;

        const [optionYear, optionMonth] = _.split(item.value, '-');

        if (optionYear && optionYear === currentYear) {
          return +optionMonth <= currentMonth;
        }

        return true;
      });
  }, [question, replaceStrings]);

  return (
    <div className={classNames('form-group', className)}>
      <Select
        fullWidth
        native
        value={answer && !_.isNil(answer.value) ? answer.value : ''}
        className='browser-default'
        IconComponent={() => <ArrowDownIcon />}
        name={question.slug}
        id={question.slug}
        onChange={(e) => onChange({ slug: question.slug, value: e.target.value })}
      >
        {items.map((item) => (
          <option value={item.value} key={item.value}>
            {item.text}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default OnboardingDropdownItem;
