import React from 'react';
import _ from 'lodash';
import OnboardingDropdownItem from '@app/src/Components/Onboarding/Question/OnboardingDropdownItem';
import OnboardingInfoItem from '@app/src/Components/Onboarding/Question/OnboardingInfoItem';
import OnboardingOptionItem from '@app/src/Components/Onboarding/Question/OnboardingOptionItem';
import OnboardingPhoneItem from '@app/src/Components/Onboarding/Question/OnboardingPhoneItem';
import OnboardingTextItem from '@app/src/Components/Onboarding/Question/OnboardingTextItem';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_TEXT,
  CATEGORY_TYPE_TAXFLOW_FORM_EMAIL,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_INFO
} from '@app/src/constants/constants';

const OnboardingFormQuestionItem = ({ question, answer, onChange, onFocus, ...props }) => {
  const question_type = _.get(question, ['question_meta', 'type']) || _.get(question, 'question_type');
  switch (question_type) {
    case CATEGORY_TYPE_TAXFLOW_FORM_TEXT: {
      return <OnboardingTextItem {...props} question={question} answer={answer} onChange={onChange} />;
    }
    case CATEGORY_TYPE_TAXFLOW_FORM_EMAIL: {
      return (
        <OnboardingTextItem
          {...props}
          question={question}
          answer={answer}
          onFocus={(arg) => onFocus(arg)}
          onChange={(arg) => onChange(arg)}
        />
      );
    }
    case CATEGORY_TYPE_TAXFLOW_FORM_PHONE: {
      return (
        <OnboardingPhoneItem
          {...props}
          question={question}
          answer={answer}
          onFocus={(arg) => onFocus(arg)}
          onChange={(arg) => onChange(arg)}
        />
      );
    }
    case CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN: {
      return <OnboardingDropdownItem {...props} question={question} answer={answer} onChange={onChange} />;
    }
    case CATEGORY_TYPE_OPTIONS: {
      return (
        <OnboardingOptionItem
          {...props}
          isChildQuestion
          currentQuestion={question}
          currentAnswer={answer}
          onChange={onChange}
        />
      );
    }
    case CATEGORY_TYPE_TAXFLOW_INFO: {
      return <OnboardingInfoItem {...props} question={question} />;
    }
    default: {
      return null;
    }
  }
};

export default OnboardingFormQuestionItem;
