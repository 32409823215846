import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';

const expensesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getExpenses: builder.query({
      query: (year) => ({
        url: 'expense/writeoffs',
        method: 'GET',
        params: {
          start: `${year}-01-01`,
          end: `${year}-12-31`
        }
      }),
      transformResponse: (response) => {
        return response.data;
      }
    }),
    getExpenseReviewDetails: builder.query({
      query: ({ year }) => ({
        url: 'expense/get-expense-review-details',
        method: 'GET',
        params: {
          start: `${year}-01-01`,
          end: `${year}-12-31`
        }
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, { year }) => [{ type: TAGS.EXPENSE_REVIEW_DETAILS, id: year }]
    })
  })
});

export const { useGetExpensesQuery, useGetExpenseReviewDetailsQuery } = expensesApi;

export const fetchExpenseReviewDetails =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => expensesApi.endpoints.getExpenseReviewDetails.initiate({ year }),
      dispatch
    });
