import React from 'react';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import { setHolisticOnboardingMarried } from '@app/src/actions/holisticOnboardingActions';
import { marriedSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const OnboardingMarriageStatus = ({ setHolisticOnboardingMarried, married }) => {
  return (
    <Stack spacing={1}>
      <ToggleButton selected={married === 'married'} onClick={() => setHolisticOnboardingMarried('married')}>
        Yes, I'm married
      </ToggleButton>
      <ToggleButton selected={married === 'single'} onClick={() => setHolisticOnboardingMarried('single')}>
        No, not married
      </ToggleButton>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  married: marriedSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingMarried
};

const ConnectedOnboardingMarriageStatus = connect(mapStateToProps, mapDispatchToProps)(OnboardingMarriageStatus);

export default ConnectedOnboardingMarriageStatus;
