import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import capitalize from 'capitalize';
import moment from 'moment';
import TaxFlowDetailedBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowDetailedBillElement';
import TaxFlowPdfButton from '@app/src/Components/TaxFlow/Question/TaxFlowPdfButton';
import TaxFlowSettlementStatus from '@app/src/Components/TaxFlow/Question/TaxFlowSettlementStatus';
import { useGetReturnStatusQuery, useGetTaxAmountsQuery } from '@app/src/api/taxDataApi';
import { useDetailedAmounts } from '@app/src/hooks/useDetailedAmounts';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/TaxFlow/Question/TaxFlowFiledWithIrsElement.scss';

const TaxFlowFiledWithIrsElement = ({ setLoading = _.noop }) => {
  const { amounts, isLoading: detailedAmountsLoading } = useDetailedAmounts();
  const { data: taxAmounts, isLoading: taxAmountsLoading } = useGetTaxAmountsQuery();
  const { data: returnStatus, isLoading: returnStatusLoading } = useGetReturnStatusQuery();
  const federalAmt = _.get(taxAmounts, 'federal_amount');
  const states = _.get(taxAmounts, 'state_amounts', []);
  const hasBill = federalAmt > 0 || states.some((state) => state.amount > 0);
  const hasRefund = federalAmt < 0 || states.some((state) => state.amount < 0);

  const isLoading = detailedAmountsLoading || taxAmountsLoading || returnStatusLoading;

  const filedWithIRSdate = useMemo(() => {
    const date = _.get(returnStatus, ['lastStageChanges', 'Filed with IRS', 'date']);

    if (date && returnStatus) {
      return moment(date);
    }
  }, [returnStatus]);

  const mask = _.get(returnStatus, 'accountMask');

  const settlementTransactions = _.get(returnStatus, 'settlementTransactions');

  const paymentMethod = _.get(returnStatus, 'paymentMethod');

  const statuses = _.get(returnStatus, 'statuses');

  const statusesMap = statuses ? _.chain(statuses).keyBy('entity').value() : {};

  useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

  useEffect(() => {
    if (statuses) {
      statuses.forEach((status) => {
        const properties = {
          filedWithIRSdate: filedWithIRSdate && filedWithIRSdate.toISOString(),
          ..._.omit(status, 'transaction'),
          transactionCleanName: _.get(status, ['transaction', 'clean_name']),
          transactionAmount: _.get(status, ['transaction', 'amount']),
          transactionDate: _.get(status, ['transaction', 'date']),
          hasAccountNumber: !_.isNil(mask),
          paymentMethod,
          status: status.status
        };

        trackActivity('return status: view', {
          ...properties,
          transactionDate: properties.transactionDate && moment(properties.transactionDate).toISOString(),
          completionDate: properties.completionDate && moment(properties.completionDate).toISOString()
        });
      });
    }
  }, [filedWithIRSdate, mask, paymentMethod, statuses]);

  if (isLoading) {
    return null;
  }

  return (
    <div className='taxflow-filed-with-irs-element'>
      {returnStatus && filedWithIRSdate && (hasRefund || hasBill) && (
        <div className='refund-statuses'>
          <h2>{capitalize(_.compact([hasRefund && 'refund', hasBill && 'tax bill']).join(' and '))} status</h2>
          {amounts.drake.federal_amount != null && (
            <TaxFlowSettlementStatus
              amount={amounts.drake.federal_amount}
              submittedDate={filedWithIRSdate}
              mask={_.get(returnStatus, 'accountMask')}
              transactionFoundDate={(() => {
                const date = _.get(settlementTransactions, ['federal', 'date']);

                if (_.isNil(date)) return null;

                return moment(date);
              })()}
              entity='federal'
              paymentMethod={paymentMethod}
              status={statusesMap.federal.status}
            />
          )}
          {amounts.drake.multistate &&
            amounts.drake.multistate.map(({ state, amount }) => (
              <TaxFlowSettlementStatus
                key={state}
                amount={amount}
                submittedDate={filedWithIRSdate}
                transactionFoundDate={(() => {
                  const date = _.get(settlementTransactions, [state, 'date']);

                  if (_.isNil(date)) return null;

                  return moment(date);
                })()}
                mask={mask}
                entity={state}
                paymentMethod={paymentMethod}
                status={statusesMap[state].status}
              />
            ))}
        </div>
      )}
      <TaxFlowDetailedBillElement className='taxflow-filed-with-irs-element-bill' amounts={amounts} />
      <TaxFlowPdfButton className='taxflow-filed-with-irs-element-pdf-button' />
    </div>
  );
};

export default TaxFlowFiledWithIrsElement;
