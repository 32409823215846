import React, { useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import history from '@app/src/keeperHistory';
import { logout } from '@app/src/services/authService';
import '@app/src/Components/ContractorLogout/ContractorLogout.scss';

const ContractorLogout = ({ className, logout }) => {
  useEffect(() => {
    logout();

    history.push('/');
  }, [logout]);

  return (
    <div className={classNames('contractor-logout', className)}>
      <Loader type='page' />
    </div>
  );
};

const mapDispatchToProps = { logout };

const ConnectedContractorLogout = connect(null, mapDispatchToProps)(ContractorLogout);

export default ConnectedContractorLogout;
