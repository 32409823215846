import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import PaymentBank from '@app/src/Components/Subscription/PaymentModal/PaymentBank';
import PaymentCard from '@app/src/Components/Subscription/PaymentModal/PaymentCard';
import PaymentWallet from '@app/src/Components/Subscription/PaymentModal/PaymentWallet';
import {
  hasDividerSelector,
  isUpdateEnabledSelector,
  loadingSelector,
  updatingSelector
} from '@app/src/selectors/pricingSelectors';
import { getPaymentMethods, saveCardPaymentMethod } from '@app/src/services/pricingService';

const PaymentElements = ({
  loading,
  updating,
  hasDivider,
  isUpdateEnabled,
  getPaymentMethods,
  saveCardPaymentMethod,
  onClick,
  history,
  buttonCopy
}) => {
  const [paymentRequest, setPaymentRequest] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const run = async () => {
      const paymentRequestTmp = await getPaymentMethods({ history, stripe, elements });

      setPaymentRequest(paymentRequestTmp);
    };

    run();
  }, [history, stripe, elements, getPaymentMethods, setPaymentRequest]);

  const handleClick = () => {
    if (onClick) onClick();

    saveCardPaymentMethod({ history, stripe, elements });
  };

  return (
    <>
      {loading ? (
        <div className='flex center'>
          <Loader />
        </div>
      ) : (
        <Stack spacing={1}>
          <PaymentBank history={history} />
          <PaymentWallet paymentRequest={paymentRequest} />
          {hasDivider && <div className='payment-modal-divider'>or proceed with</div>}
          <PaymentCard />
          <Stack direction='row' justifyContent='flex-end'>
            <LoadingButton
              variant='contained'
              loading={updating}
              disabled={loading || !isUpdateEnabled || updating}
              onClick={handleClick}
            >
              {buttonCopy || 'Update'}
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  updating: updatingSelector(state),
  isUpdateEnabled: isUpdateEnabledSelector(state),
  hasDivider: hasDividerSelector(state)
});

const mapDispatchToProps = { getPaymentMethods, saveCardPaymentMethod };

const ConnectedPaymentElements = connect(mapStateToProps, mapDispatchToProps)(PaymentElements);

export default ConnectedPaymentElements;
