import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { connect } from 'react-redux';
import ExpenseReviewCategoryIcon from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryIcon';
import { setSidebarComponent } from '@app/src/actions/expenseReviewActions';
import { allCategoriesSelector, orderedCategoryAmountsSelector } from '@app/src/selectors/expenseReviewSelectors';
import { updateCategoryFilter } from '@app/src/services/expenseReviewService';
import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryList.scss';

const ExpenseReviewCategoryList = ({ categories, allCategories, updateCategoryFilter, setSidebarComponent }) => {
  const handleClick = (catId) => {
    updateCategoryFilter(Number(catId));

    setSidebarComponent(null);
  };

  return (
    <List variant='category-list' disablePadding>
      {categories.map(({ categoryId, count }, index) => {
        const listCategory = allCategories.find((category) => Number(category.id) === Number(categoryId));
        const keeperCategory = _.get(listCategory, 'keeper_category', '');

        return (
          <ListItem
            key={index}
            variant='category-item'
            onClick={() => handleClick(categoryId)}
            secondaryAction={
              <Button edge='end' variant='icon-button'>
                <div className='expense-review-category-item-amount'>{count}</div>
              </Button>
            }
          >
            <div className='expense-review-category-item-content'>
              <ExpenseReviewCategoryIcon id={categoryId} size='20px' />
              <div className='expense-review-category-item-name'>{_.upperFirst(keeperCategory)}</div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};

const mapStateToProps = (state) => ({
  allCategories: allCategoriesSelector(state),
  categories: orderedCategoryAmountsSelector(state)
});

const mapDispatchToProps = {
  setSidebarComponent,
  updateCategoryFilter
};

const ConnectedExpenseReviewCategoryList = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewCategoryList);

export default ConnectedExpenseReviewCategoryList;
