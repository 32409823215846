import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Icon from '@mui/material/Icon';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxFlowEditExpensesModal from '@app/src/Components/TaxFlow/Common/TaxFlowEditExpensesModal';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import { setCurrentAnswer } from '@app/src/actions/taxFlowActions';
import ExpenseLinkIcon from '@app/src/assets/expense-link.svg?react';
import { expensesLinkDataSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/TaxFlow/Question/TaxFlowExpensesLinkItem.scss';

const TaxFlowExpensesLinkItem = ({
  className,
  currentQuestion,
  currentAnswer,
  resultLoading,
  isChildQuestion,
  setCurrentAnswer,
  onChange,
  expensesLinkData
}) => {
  const [leaveModalOpen, setLeaveModalOpen] = useState(false);

  const onOpenModal = (value) => {
    if (value) {
      trackActivity('modal: view', {
        modal: 'leave tax filing to edit expenses'
      });
    } else {
      trackActivity('modal: closed', {
        modal: 'leave tax filing to edit expenses'
      });
    }
    setLeaveModalOpen(value);
  };

  const formattedSumExpenses = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      signDisplay: 'never',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(expensesLinkData.sum);
  }, [expensesLinkData]);

  useEffect(() => {
    if (resultLoading) {
      return;
    }

    if (isChildQuestion && currentAnswer?.value != expensesLinkData.sum) {
      onChange(expensesLinkData.sum);
    } else if (!isChildQuestion && currentQuestion) {
      setCurrentAnswer({
        value: expensesLinkData.sum
      });
    }
  }, [resultLoading, expensesLinkData, setCurrentAnswer, currentQuestion, isChildQuestion, onChange, currentAnswer]);

  if (resultLoading) return null;

  return (
    <div className={classNames({ 'steps-body': !isChildQuestion }, className)}>
      <TaxValidationInputLabel question={currentQuestion} />
      <div className='tax-flow-expenses-link-item'>
        <div className='tax-flow-expenses-link-item-text' data-testid={`${currentQuestion.slug}-text`}>
          <span>
            {formattedSumExpenses}
            <span className='tax-flow-expenses-link-item-text-light'>
              , based on {expensesLinkData.count} expense{expensesLinkData.count > 1 ? 's' : ''}
            </span>
          </span>
        </div>
        <Icon
          className='tax-flow-expenses-link-item-icon'
          onClick={() => {
            onOpenModal(true);
          }}
        >
          <ExpenseLinkIcon width={22} height={22} />
        </Icon>
      </div>
      <TaxFlowEditExpensesModal
        open={leaveModalOpen}
        expenseCategoryIds={expensesLinkData.categoryIds}
        setOpen={setLeaveModalOpen}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentQuestion: _.get(props, ['currentQuestion']),
  currentAnswer: _.get(props, ['currentAnswer']),
  resultLoading: _.get(props, ['resultLoading'], state.taxFlow.resultLoading),
  expensesLinkData: expensesLinkDataSelector(state, _.get(props, ['currentQuestion']))
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowExpensesLinkItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowExpensesLinkItem);

export default ConnectedTaxFlowExpensesLinkItem;
