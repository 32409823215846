import React from 'react';
import BankLink from '@app/src/Components/BankLink/BankLink';
import AddIcon from '@app/src/assets/add.svg?react';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import CreditCardIcon from '@app/src/assets/card.svg?react';
import '@app/src/Components/BankLinkOptions/BankLinkOptions.scss';

export const BankLinkOptions = ({ title, subTitle }) => {
  return (
    <div className='bank-link-options'>
      {title && <div className='bank-link-options-header'>{title}</div>}
      {subTitle && <div className='bank-link-options-description'>{subTitle}</div>}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
        <BankLink
          icon={<BankIcon />}
          className='bank-link-options-link-button bank-link-options-link-button-1'
          fullWidth={false}
          type={'bankLinkOptions'}
          styles={{ justifyContent: 'space-between', flex: 1, minWidth: 250 }}
        >
          <span>Checking accounts</span>
          <AddIcon />
        </BankLink>
        <BankLink
          icon={<CreditCardIcon />}
          fullWidth={false}
          className='bank-link-options-link-button bank-link-options-link-button-2'
          type={'bankLinkOptions'}
          styles={{ justifyContent: 'space-between', flex: 1, minWidth: 250 }}
        >
          <span>Credit card</span>
          <AddIcon />
        </BankLink>
      </div>
    </div>
  );
};
