import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import qs from 'qs';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import { appUrl } from '@app/src/global/Environment';
import { isReactNative, safeJsonParse, sentMsgToReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { getCapitalOneAccessToken } from '@app/src/services/bankLinkService';
import '@app/src/Components/CapitalOneRedirect/CapitalOneRedirect.scss';

const CapitalOneRedirect = ({ className, location, getCapitalOneAccessToken }) => {
  const urls = {
    onboarding: `${appUrl()}/onboarding/link`
  };
  const defaultUrl = `${appUrl()}/bank`;

  let state = safeJsonParse({
    jsonStr: qs.parse(location.search, { ignoreQueryPrefix: true }).state,
    defaultVal: null
  });
  const type = _.get(state, 'type');
  const url = _.get(urls, type, defaultUrl);
  const item_id = _.get(state, 'item_id');

  const error = qs.parse(location.search, { ignoreQueryPrefix: true }).error;
  const error_description = qs.parse(location.search, { ignoreQueryPrefix: true }).error_description;
  const code = qs.parse(location.search, { ignoreQueryPrefix: true }).code;

  useEffect(() => {
    const run = async () => {
      const returnToOrigin = () => {
        if (isReactNative()) {
          sentMsgToReactNative('close_capital_one', {
            type
          });
        } else {
          window.location.assign(url);
        }
      };

      if (!_.isNil(error)) {
        trackActivity('bank link failed', {
          error_code: error,
          error_message: error_description,
          institution_name: 'Capital One',
          source: 'capital-one'
        });

        returnToOrigin();
      } else if (!_.isNil(code)) {
        await getCapitalOneAccessToken({ item_id, code });

        trackActivity('bank account linked', {
          institution_name: 'Capital One',
          source: 'capital-one'
        });

        returnToOrigin();
      }
    };
    run();
  }, [item_id, code, error, error_description, type, url, getCapitalOneAccessToken]);

  return (
    <div className={classNames('capital-one-redirect', className)}>
      <Loader type='page' />
    </div>
  );
};

const mapDispatchToProps = {
  getCapitalOneAccessToken
};

const ConnectedCapitalOneRedirect = connect(null, mapDispatchToProps)(CapitalOneRedirect);

export default ConnectedCapitalOneRedirect;
