import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import OnboardingQuestion from '@app/src/Components/Onboarding/Question/OnboardingQuestion';
import { Url_ONBOARDING_TRAVEL } from '@app/src/constants/onboardingConstants';
import { getQuestion, updateAnswer } from '@app/src/services/onboardingService';

const OnboardingTravel = ({ history, getQuestion, updateAnswer }) => {
  useEffect(() => {
    getQuestion({ slug: Url_ONBOARDING_TRAVEL, history });
  }, [getQuestion, history]);

  const onNext = async () => {
    await updateAnswer({ history });
  };

  return <OnboardingQuestion onNext={onNext} />;
};

const mapDispatchToProps = {
  getQuestion,
  updateAnswer
};

const ConnectedOnboardingTravel = connect(null, mapDispatchToProps)(OnboardingTravel);

export default ConnectedOnboardingTravel;
