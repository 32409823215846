import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import OnboardingLoading from '@app/src/Components/Onboarding/OnboardingLoading';
import { Url_EXPENSE_RETRO_LOADING_INITIAL } from '@app/src/constants/onboardingConstants';
import { getQuestion } from '@app/src/services/onboardingService';

const OnboardingLoadingInitial = ({ history, getQuestion }) => {
  useEffect(() => {
    getQuestion({ slug: Url_EXPENSE_RETRO_LOADING_INITIAL, history });
  }, [getQuestion, history]);

  return <OnboardingLoading history={history} />;
};

const mapDispatchToProps = {
  getQuestion
};

const ConnectedOnboardingLoadingInitial = connect(null, mapDispatchToProps)(OnboardingLoadingInitial);

export default ConnectedOnboardingLoadingInitial;
