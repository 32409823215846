import React from 'react';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const LoginLink = ({ className, to, ...props }) => {
  if (!to) {
    return <Button className={classNames('btn', className)} type='button' {...props} />;
  }

  return <Link className={classNames('cta-link', className)} to={to} {...props} />;
};

export default LoginLink;
