import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { setSettingsModalType } from '@app/src/actions/settingsActions';
import { settingsLoadingSelector } from '@app/src/selectors/settingsSelectors';
import { deleteUser } from '@app/src/services/authService';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

const DeleteAccountModal = ({ setSettingsModalType, accountDetails, deleteUser, loading }) => {
  const onConfirm = async () => {
    await deleteUser({ accountDetails });
    setSettingsModalType('');
  };

  const onCancel = () => {
    setSettingsModalType('');
  };

  return (
    <Stack direction='column' spacing={2}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContentText>
        IMPORTANT! Don’t forget to export your deductions before you leave! All your banks will be unlinked, and you’ll
        lose all records of your deductions and past year returns. Your account will be unrecoverable.
      </DialogContentText>
      <Stack direction={'row'} spacing={1}>
        <Button variant='outlined' fullWidth onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          loading={loading}
          loadingIndicator='Deleting account…'
          onClick={onConfirm}
          variant='contained'
        >
          Yes, delete my account
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  accountDetails: accountDetailsSelector(state),
  loading: settingsLoadingSelector(state)
});

const mapDispatchToProps = {
  deleteUser,
  setSettingsModalType
};

const ConnectedDeleteAccountModel = connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal);

export default ConnectedDeleteAccountModel;
