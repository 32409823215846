import React from 'react';
import _ from 'lodash';
import Icon from '@mui/material/Icon';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import { updateCurrentAnswer } from '@app/src/actions/taxFlowActions';
import MailIcon from '@app/src/assets/mail.svg?react';
import { userSelector } from '@app/src/selectors/userSelectors';
import { extractDrakePdf } from '@app/src/services/taxFlowService';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitEmailMyReturn.scss';

const TaxFlowSubmitEmailMyReturn = (props) => {
  const { user, currentQuestion, currentAnswer, resultLoading, isChildQuestion, extractDrakePdf, onNextQuest } = props;

  if (resultLoading) return null;
  const onClick = async ({ value }) => {
    if (value === '1') {
      await extractDrakePdf();
    }
    onNextQuest();
  };
  return (
    <div className={classNames('taxflow-submit-email-my-return', { 'steps-body-wide': !isChildQuestion })}>
      <div style={{ paddingBottom: '16px' }}>
        <TaxFlowPill
          text={user.email}
          icon={
            <Icon className='taxflow-chip-icon'>
              <MailIcon width={16} height={16} />
            </Icon>
          }
        />
      </div>
      <div className='checkbox-list'>
        {currentQuestion.question_meta.map((item) => (
          <TaxFlowListItem
            item={item}
            handleChange={onClick}
            checked={currentAnswer && !_.isNil(currentAnswer.value) && `${item.value}` === `${currentAnswer.value}`}
            rightIcon={<TaxFlowInfoButton currentQuestion={currentQuestion} item={item} />}
            key={item.value}
          >
            <span>{props.replaceStrings(item.text)}</span>
          </TaxFlowListItem>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: state.taxFlow.currentAnswer
});

const mapDispatchToProps = {
  updateCurrentAnswer,
  extractDrakePdf
};

const ConnectedTaxFlowSubmitEmailMyReturn = connect(mapStateToProps, mapDispatchToProps)(TaxFlowSubmitEmailMyReturn);

export default ConnectedTaxFlowSubmitEmailMyReturn;
