import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import {
  itemizedDeductionSelector,
  standardDeductionSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowStandardGraph.scss';

const TaxFlowStandardGraph = ({ standardDeduction, itemizedDeduction }) => {
  const maxDeduction = Math.max(standardDeduction, itemizedDeduction);
  const [itemizedLabelAbove, setItemizedLabelAbove] = useState(false);
  const itemizedRef = useRef(null);
  const itemizedLabelRef = useRef(null);

  useEffect(() => {
    if (itemizedRef.current && itemizedLabelRef.current) {
      setItemizedLabelAbove(itemizedRef.current.clientHeight < itemizedLabelRef.current.clientHeight);
    }
  }, [itemizedRef, itemizedLabelRef]);

  const standardBarStyle = useMemo(
    () => ({
      height: `${(standardDeduction * 100) / maxDeduction}%`,
      ...(standardDeduction === 0 && { border: 'none' })
    }),
    [standardDeduction, maxDeduction]
  );

  const itemizedBarStyle = useMemo(
    () => ({
      height: `${(itemizedDeduction * 100) / maxDeduction}%`,
      ...(itemizedDeduction === 0 && { border: 'none' })
    }),
    [itemizedDeduction, maxDeduction]
  );

  return (
    <div className='taxflow-standard-graph'>
      <div className='bar standard' style={standardBarStyle}>
        <div className='label'>
          <div>Standard deduction</div>
          <strong>{currencyWith0DecimalPlaces(standardDeduction)}</strong>
        </div>
      </div>
      <div className='bar itemized' style={itemizedBarStyle} ref={itemizedRef}>
        <div
          className={classNames('label', {
            above: itemizedLabelAbove
          })}
          ref={itemizedLabelRef}
        >
          <div>Itemized deduction</div>
          <strong>{currencyWith0DecimalPlaces(itemizedDeduction)}</strong>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    standardDeduction: standardDeductionSelector(state),
    itemizedDeduction: itemizedDeductionSelector(state)
  };
};

const ConnectedTaxFlowStandardGraph = connect(mapStateToProps)(TaxFlowStandardGraph);

export default ConnectedTaxFlowStandardGraph;
