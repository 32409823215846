import React from 'react';
import classNames from 'classnames';
import IconLogo from '@app/src/assets/logo.svg?react';
import '@app/src/Components/Login/LoginWithOTP/LoginHeader.scss';

const LoginHeader = ({ className, title, subtitle }) => {
  return (
    <div className={classNames('login-header', className)}>
      <IconLogo className='login-header-logo' />
      <h1>{title}</h1>
      {subtitle && <div className='login-header-subtitle'>{subtitle}</div>}
    </div>
  );
};

export default LoginHeader;
