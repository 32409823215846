import React, { useCallback } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import AskLink from '@app/src/Components/Common/AskLink/AskLink';
import { sendStreamMessage } from '@app/src/actions/assistantActions';
import { APP_URL } from '@app/src/global/Environment';
import { trackActivity } from '@app/src/services/analyticsService';
import { onModalClose } from '@app/src/services/expenseReviewService';
import '@app/src/Components/ExpenseReview/ExpenseReviewModalEdit.scss';

const ExpenseReviewCategoryDescription = ({ description, expense }) => {
  const dispatch = useDispatch();

  /** @type {import('react').MouseEventHandler} */
  const handleClick = useCallback(() => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const year = moment(expense.date).year();
    const url = new URL(
      `/expenses?${new URLSearchParams({
        year,
        transaction_id: expense.transaction_id
      })}`,
      APP_URL
    ).toString();

    // &#x203a; is a right-pointing double angle quotation mark
    const text = `Can I deduct this? [${expense.clean_name} (${currencyFormatter.format(expense.amount)})&nbsp;&#x203a;](${url})`;

    dispatch(
      sendStreamMessage({
        text,
        origin: 'ask my assistant button pressed',
        markdown: true,
        attachments: [
          {
            type: 'expense',
            id: expense.transaction_id
          }
        ],
        hide_open_graph_preview: true
      })
    );

    dispatch(onModalClose());

    trackActivity('ama: not sure pressed', {
      message: text,
      transactionId: expense.transaction_id,
      keeperCategoryId: expense.keeper_category_id,
      transaction_id: expense.transaction_id,
      keeper_category_id: expense.keeper_category_id
    });
  }, [dispatch, expense.amount, expense.clean_name, expense.date, expense.keeper_category_id, expense.transaction_id]);

  return (
    <div className='expense-review-modal-edit-category-description'>
      <div>{description}</div>
      <AskLink onClick={handleClick} styles={{ marginTop: '16px' }}>
        Learn more
      </AskLink>
    </div>
  );
};

export default ExpenseReviewCategoryDescription;
