import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { useGetRetroStatusQuery } from '@app/src/api/profileApi';
import { bankLinkPendingSelector } from '@app/src/selectors/bankSelectors';

export const useTransactionsSyncInProgress = ({ year }) => {
  const [syncInProgress, setSyncInProgress] = useState(false);
  const bankLinkPending = useSelector(bankLinkPendingSelector);
  const { data: retroStatus, isLoading: isRetroStatusLoading } = useGetRetroStatusQuery({ year });

  const retroInProgress = retroStatus === 'waiting';

  useDebounce(
    () => {
      if (!retroInProgress && !bankLinkPending) {
        setSyncInProgress(false);
      }
    },
    3000,
    [retroInProgress, bankLinkPending]
  );

  useEffect(() => {
    if (retroInProgress || bankLinkPending) {
      setSyncInProgress(true);
    }
  }, [retroInProgress, bankLinkPending]);

  return { syncInProgress, isLoading: isRetroStatusLoading };
};
