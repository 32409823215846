import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useTimeoutFn } from 'react-use';
import LoginLink from '@app/src/Components/Login/LoginWithOTP/LoginLink';
import { requestLoginEmail, requestLoginOtp } from '@app/src/services/authService';
import { validateLoginOtp } from '@app/src/services/workService';

const LoginResend = ({ className, type, id, onResendSuccess, loading, requestLoginOtp, requestLoginEmail }) => {
  const [resend, setResend] = useState(false);

  useTimeoutFn(() => {
    setResend(true);
  }, 10000);

  const onResend = (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (type === 'phone') {
      requestLoginOtp({ phone: id }, () => onResendSuccess());
    } else {
      requestLoginEmail({ email: id }, () => onResendSuccess());
    }
  };

  if (!resend) {
    return null;
  }

  return (
    <div className={classNames('login-resend', className)}>
      Haven't received the code?{' '}
      <LoginLink className='onboarding-resend-link' onClick={onResend}>
        Resend it
      </LoginLink>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: _.get(state, ['auth', 'loading'])
});

const mapDispatchToProps = {
  validateLoginOtp,
  requestLoginOtp,
  requestLoginEmail
};

const ConnectedLoginResend = connect(mapStateToProps, mapDispatchToProps)(LoginResend);

export default ConnectedLoginResend;
