import React from 'react';
import Fade from '@mui/material/Fade';
import { Verify } from 'iconsax-react';
import { connect } from 'react-redux';
import { responseTextSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText.scss';

const ResponseText = ({ responseText, visible }) => (
  <Fade in={visible} timeout={500}>
    <div className='response-container'>
      <Verify size={24} />
      <div className='response-container-text'>{responseText}</div>
    </div>
  </Fade>
);

const mapStateToProps = (state, props) => ({
  responseText: responseTextSelector(state, props)
});

const ConnectedResponseText = connect(mapStateToProps)(ResponseText);

export default ConnectedResponseText;
