import React, { useEffect } from 'react';
import _ from 'lodash';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { setInfoModalShow } from '@app/src/actions/taxFlowActions';
import ChevronDownIcon from '@app/src/assets/chevron-down.svg?react';
import CloseIcon from '@app/src/assets/close.svg?react';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { infoModalItemSelector, infoModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/TaxFlow/Common/TaxFlowInfoModalWindow.scss';

const TaxFlowInfoModalWindow = ({ infoModalShow, infoModalItem, setInfoModalShow }) => {
  const { title, source, contentType, accordionInfo, compositeUrl, image, type, origin } = infoModalItem;

  useEffect(() => {
    if (origin === 'taxFlowFindWriteOffsElement') {
      trackActivity('find write-offs: info');
    }
  }, [origin]);

  const { width: windowWidth } = useWindowSize();

  if (type === 'link') {
    return null;
  }

  const onClose = () => {
    setInfoModalShow(false);
  };

  const openLink = (e, url) => {
    e.preventDefault();

    if (isReactNative()) {
      sentMsgToReactNative('open_learn_more_link', {
        url
      });
    } else {
      window.open(url, '_blank');
    }
  };

  const linkRenderer = ({ href, children }) => {
    return (
      <Link to={href} onClick={(e) => openLink(e, href)}>
        {children}
      </Link>
    );
  };

  const Accordion = styled((props) => <MuiAccordion {...props} />)(() => ({
    boxShadow: 'none',
    border: 'none',
    '& .MuiExpanded': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '30px',
      marginBottom: '0px'
    }
  }));

  const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(() => ({
    boxShadow: 'none',
    border: 'none',
    paddingLeft: '0px',
    marginBottom: '16px',
    minHeight: '0px',
    '& .MuiAccordionSummary-content': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px'
    }
  }));

  const AccordionDetails = styled((props) => <MuiAccordionDetails {...props} />)(() => ({
    padding: '0px 16px 16px 0px'
  }));

  const content = (
    <>
      {compositeUrl && <img src={compositeUrl} alt='Example composite form' style={{ width: '100%' }}></img>}
      {_.get(image, ['file', 'url']) && (
        <img src={_.get(image, ['file', 'url'])} alt={_.get(image, 'description')} style={{ width: '100%' }} />
      )}
      {((!compositeUrl && !contentType) || contentType === 'markdown') && (
        <ReactMarkdown
          className='taxflow-info-modal-window-markdown'
          source={source}
          renderers={{
            link: linkRenderer
          }}
        />
      )}
      {contentType === 'accordion' && (
        <div>
          <ReactMarkdown
            source={_.get(accordionInfo, ['intro'], '')}
            className='taxflow-info-modal-window-accordion-intro'
          />
          {accordionInfo.data.map((info, i) => {
            return (
              <Accordion
                sx={{
                  '&:before': {
                    display: 'none'
                  }
                }}
                key={i + 1}
              >
                <AccordionSummary
                  expandIcon={<ChevronDownIcon />}
                  aria-controls={`panel${i}a-content`}
                  id={`panel${i + 1}a-header`}
                >
                  <ReactMarkdown className='taxflow-info-modal-window-accordion-summary' source={info.summary} />
                </AccordionSummary>
                <AccordionDetails>
                  <ReactMarkdown className='taxflow-info-modal-window-accordion-details' source={info.details} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </>
  );

  if (windowWidth < 1024) {
    return (
      <Dialog id='infomodal' className='taxflow-info-modal-window' open={infoModalShow} onClose={onClose}>
        <DialogTitle sx={{ paddingRight: '48px' }}>
          {title}
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              color: 'primary.main',
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      {
        <div className={classNames('taxflow-info-modal-window-panel', { visible: infoModalShow })}>
          <CloseIcon
            className='taxflow-navigation-panel-close'
            onClick={() => {
              onClose();
            }}
            color='primary'
          />
          <h1 className='taxflow-info-modal-window-panel-header'> {title}</h1>
          <div className='taxflow-info-modal-window-panel-text'>{content}</div>
          <div className='taxflow-info-modal-window-panel-assistance-box'>
            <h3 className='taxflow-info-modal-window-panel-assistance-box-header'>Need assistance? </h3>
            <p className='taxflow-info-modal-window-panel-assistance-box-caption'>
              Email support@keepertax.com and we’ll answer your questions within 24 hours.
              <br />
              <br /> You can also browse our{' '}
              <a href='https://help.keepertax.com/hc/en-us' target='_blank' rel='noopener noreferrer'>
                help center
              </a>{' '}
              for instant advice on common problems.
            </p>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  infoModalShow: infoModalShowSelector(state),
  infoModalItem: infoModalItemSelector(state)
});

const mapDispatchToProps = {
  setInfoModalShow
};

const ConnectedTaxFlowInfoModalWindow = connect(mapStateToProps, mapDispatchToProps)(TaxFlowInfoModalWindow);

export default ConnectedTaxFlowInfoModalWindow;
