const getLiveWorkDetails = ({ workDetails, currentQuestion, currentAnswer }) => {
  return {
    ...workDetails,
    ...(currentQuestion.slug === 'onboarding-job-select'
      ? {
          jobs: currentAnswer.value
        }
      : {}),
    ...(currentQuestion.slug === 'onboarding-transport'
      ? {
          car: `${currentAnswer.value}` === '1' ? 1 : 0
        }
      : {}),
    ...(currentQuestion.slug === 'onboarding-home'
      ? {
          home: `${currentAnswer.value}` === '1' ? 1 : 0
        }
      : {}),
    ...(currentQuestion.slug === 'onboarding-meal'
      ? {
          meals: `${currentAnswer.value}` === '1' ? 1 : 0
        }
      : {}),
    ...(currentQuestion.slug === 'onboarding-travel'
      ? {
          travel: `${currentAnswer.value}` === '1' ? 1 : 0
        }
      : {})
  };
};

export default getLiveWorkDetails;
