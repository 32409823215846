import React from 'react';
import NumberFormat from 'react-number-format';

const ExpenseReviewNumericFormat = React.forwardRef((props, ref) => {
  const { onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  );
});

export default ExpenseReviewNumericFormat;
