import React, { useRef } from 'react';
import _ from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { ArrowDown2, SearchNormal1 } from 'iconsax-react';
import { connect } from 'react-redux';
import { setMerchantNameSearchResults } from '@app/src/actions/expenseReviewActions';
import {
  merchantNameSearchSelector,
  merchantSearchLoadingSelector,
  merchantSearchResultsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getMerchantsWithCounts } from '@app/src/services/expenseReviewService';
import { colorNeutralGraphite, colorSecondary } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/ExpenseReviewMerchantSearchBar.scss';

const ExpenseReviewMerchantSearchBar = ({
  className,
  getMerchantsWithCounts,
  filter,
  isLoading,
  merchantsWithCounts,
  onSelect,
  searchQuery,
  origin
}) => {
  const searchInputRef = useRef(null);

  const handleSearchMerchants = _.debounce(getMerchantsWithCounts, 500);

  if (filter) {
    merchantsWithCounts = filter(merchantsWithCounts);
  }

  const handleFocus = () => {
    trackActivity('search: focus search bar', { origin });
  };

  return (
    <div className={classNames('expense-review-search-bar', className)}>
      <Autocomplete
        options={merchantsWithCounts}
        loading={isLoading}
        getOptionLabel={({ clean_name }) => clean_name}
        noOptionsText='No merchants found'
        onInputChange={handleSearchMerchants}
        onChange={(event, value) => onSelect(_.get(value, 'clean_name', ''))}
        fullWidth
        isOptionEqualToValue={(option, value) => option.clean_name === value.clean_name}
        popupIcon={<ArrowDown2 />}
        PaperComponent={({ children }) => (
          <Paper
            elevation={0}
            style={{
              border: `2px solid ${colorSecondary}`,
              borderRadius: '0 0 8px 8px',
              borderTop: 'none',
              marginTop: '-8px'
            }}
          >
            {children}
          </Paper>
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.clean_name} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{option.clean_name}</span>
            <span style={{ color: colorNeutralGraphite }}>{option.count} transactions</span>
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            onFocus={handleFocus}
            inputRef={searchInputRef}
            value={searchQuery}
            placeholder='e.g. Netflix'
            color='secondary'
            onChange={handleSearchMerchants}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchNormal1 size={24} />
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchQuery: merchantNameSearchSelector(state),
  merchantsWithCounts: merchantSearchResultsSelector(state),
  isLoading: merchantSearchLoadingSelector(state)
});

const mapDispatchToProps = {
  getMerchantsWithCounts,
  setMerchantNameSearchResults
};

const ConnectedExpenseReviewMerchantSearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseReviewMerchantSearchBar);

export default ConnectedExpenseReviewMerchantSearchBar;
