import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { connect } from 'react-redux';
import BankLinkPlaid from '@app/src/Components/BankLinkPlaid/BankLinkPlaid';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxFlowFormCustomJobItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormCustomJobItem';
import TaxFlowCarPills from '@app/src/Components/TaxFlow/Question/TaxFlowCarPills';
import TaxFlowHomePills from '@app/src/Components/TaxFlow/Question/TaxFlowHomePills';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import { setContextLoading, setBusinessCode } from '@app/src/actions/taxFlowActions';
import { useGetHomeAddressPreselectOptionsQuery, useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import BusinessCenterIcon from '@app/src/assets/briefcase.svg?react';
import { getCurrentYear, getPreviousYear, replaceString } from '@app/src/global/Helpers';
import { businessCodeSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { initPlaid } from '@app/src/services/bankLinkService';
import { isOnboardingQuestion } from '@app/src/services/taxFlow/isOnboardingQuestion';
import { jobOptionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getDerivedQuestion } from '@app/src/taxflow/main/utils/mainUtils';
import { CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import { COLLECTION_TYPE__HOME, HOME_SLUGS } from '@app/src/taxflow/sections/home/homeConstants';
import { SLUG__INCOME_FREELANCE_JOB_NAME } from '@app/src/taxflow/sections/income/constants/incomeConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowOptionItem.scss';

const OPTION_ITEM_TYPES = {
  DYNAMIC: 'dynamic',
  PILLS_SECTION: 'pills-section'
};

const TaxFlowOptionItem = ({
  currentQuestion: originalCurrentQuestion,
  currentAnswer,
  jobOptions,
  initPlaid,
  isChildQuestion,
  onChange = _.noop,
  onFocus = _.noop,
  setCurrentAnswer,
  setBusinessCode,
  taxFlow,
  businessCode,
  history,
  resultLoading,
  jobCategories,
  replaceStrings
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(originalCurrentQuestion);
  const [filteredList, setFilteredList] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState([]);
  const [isCustomJobSelected, setIsCustomJobSelected] = useState(false);
  const [timeoutId, setTimeoutId] = useState(undefined);
  const [plaidInitiated, setPlaidInitiated] = useState(false);

  const { data: carTaxData } = useGetTaxDataQuery({
    collectionType: COLLECTION_TYPE__CAR
  });
  const { data: homeTaxData } = useGetTaxDataQuery({
    collectionType: COLLECTION_TYPE__HOME
  });

  const skipHomeAddressPreselectFetch = !(
    currentQuestion?.collectionType === COLLECTION_TYPE__HOME &&
    [HOME_SLUGS.NAV_START, HOME_SLUGS.PRESELECT_ADDRESS].includes(currentQuestion?.slug)
  );
  const { data: homeAddressPreselectOptions } = useGetHomeAddressPreselectOptionsQuery(undefined, {
    skip: skipHomeAddressPreselectFetch
  });

  /**
   * @desc filter options and set in filteredList to show only filterjob category
   */
  const filterOptions = useCallback(
    (filterString) => {
      if (
        !currentQuestion.question_meta ||
        (currentQuestion.question_meta && currentQuestion.question_meta.length === 0)
      )
        return;

      const cleanedFilterString = filterString.trim().toLowerCase();

      const newList =
        !cleanedFilterString || cleanedFilterString === ''
          ? currentQuestion.question_meta
          : currentQuestion.question_meta.filter(
              (x) => x.text && x.text.trim().toLowerCase().indexOf(cleanedFilterString) !== -1
            );
      setFilteredList(newList);
    },
    [currentQuestion]
  );

  // Add any defined custom jobs to list if freelance income "job name" question
  useEffect(() => {
    setCurrentQuestion(
      _.get(originalCurrentQuestion, 'slug') === SLUG__INCOME_FREELANCE_JOB_NAME
        ? getDerivedQuestion({ question: originalCurrentQuestion, jobOptions })
        : originalCurrentQuestion
    );
  }, [originalCurrentQuestion, jobOptions]);

  useEffect(() => {
    if (!plaidInitiated && _.some(items, { openPlaid: true })) {
      initPlaid({ type: 'payment' });
      setPlaidInitiated(true);
    }
  }, [initPlaid, items, plaidInitiated]);

  const filteredListNil = _.isNil(filteredList);
  useEffect(() => {
    if (filteredListNil && currentQuestion) {
      filterOptions('');
    }
  }, [currentQuestion, filterOptions, filteredListNil]);

  useEffect(() => {
    setItems(
      _.get(currentQuestion, 'question_meta', []).map((item) => ({
        ...item,
        value: replaceString(_.get(item, 'value', ''), {
          currentYear: getCurrentYear(),
          previousYear: getPreviousYear()
        })
      }))
    );
  }, [currentQuestion]);

  const onClick = async (item) => {
    const result = `${item.value}`;

    setIsCustomJobSelected(false);

    if (item.openPlaid) {
      setCurrentAnswer({ value: null });
    } else if (isChildQuestion) {
      // Component used as child question
      onChange(result);

      if (currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME && item.businessCode) {
        setBusinessCode(item.businessCode);
      }

      onFocus();
    } else {
      if (currentQuestion) {
        // for single option items, don't toggle on click - clicking on the item again should just move us to the next page.
        const newAnswer = {
          value: result,
          response_text: item.response_text
        };

        setCurrentAnswer(newAnswer);
      }
    }
  };

  const getPills = () => {
    if (currentQuestion.slug === CAR_SLUGS.NAV_START) {
      return <TaxFlowCarPills />;
    } else if (currentQuestion.slug === HOME_SLUGS.NAV_START) {
      return <TaxFlowHomePills />;
    }
  };

  /**
   * When the options list includes a pills-section option, we might have to filter out some
   * of the dropdown options if the user went through a sub-nav to add a new collection item
   * (e.g. for vehicle deductions)
   */
  const filterPillsSectionOptions = (options) => {
    if (currentQuestion.slug === CAR_SLUGS.NAV_START && carTaxData?.length > 0) {
      const collectionStarted = carTaxData.some((r) => r.slug === 'car-started');
      if (collectionStarted) {
        return options.filter((o) => o.value > 0);
      }
    } else if (currentQuestion.slug === HOME_SLUGS.NAV_START && homeTaxData?.length > 0) {
      const collectionStarted = homeTaxData.some((r) => r.slug === 'credit-home-started');
      if (collectionStarted) {
        return options.filter((o) => o.value > 0);
      }
    }
    return options;
  };

  /**
   * @desc search options
   */
  const Search = (filterString) => {
    setSearchText(filterString);
    if (filterString) filterString = filterString.trim();
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        filterOptions(filterString);
      }, 50)
    );
  };

  const renderJobIcon = ({ iconUrl, jobSlug, className }) => {
    if (iconUrl) {
      return <img className={className} src={iconUrl} alt={`${jobSlug}-icon`} />;
    }

    return <BusinessCenterIcon className={className} alt={`${jobSlug} icon`} />;
  };

  const getDynamicOptions = () => {
    if (currentQuestion.slug === HOME_SLUGS.PRESELECT_ADDRESS && homeAddressPreselectOptions?.length > 0) {
      return homeAddressPreselectOptions;
    }
    return [];
  };

  const renderItem = (item) => {
    const info = _.get(taxFlow, ['normalizedInfoItems', 'byId', item.info]);

    const itemProps = {
      item,
      handleChange: onClick,
      checked:
        currentAnswer &&
        !_.isNil(currentAnswer.value) &&
        `${item.value}` === `${currentAnswer.value}` &&
        (currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME && item.businessCode
          ? businessCode === item.businessCode
          : true) &&
        !isCustomJobSelected,
      className: classNames({ onboarding: isOnboardingQuestion(currentQuestion) }),
      showRightIcon: !!info,
      rightIcon: <TaxFlowInfoButton currentQuestion={currentQuestion} item={item} />
    };

    if (item.openPlaid) {
      return (
        <BankLinkPlaid
          type='payment'
          key={item.value}
          preSuccess={() => {
            setContextLoading(true);
          }}
          postSuccess={({ metadata }) => {
            const accountId = _.get(metadata, ['accounts', 0, 'id']);

            if (accountId && item.plaidSuccessRedirect) {
              history.push(
                `${item.plaidSuccessRedirect}?${new URLSearchParams({ account_id: accountId, plaid: true })}`
              );
            } else {
              history.push(item.plaidSuccessRedirect);
            }
          }}
          renderButton={({ open, ready }) => (
            <TaxFlowListItem
              ready={ready}
              {...itemProps}
              handleChange={async (...props) => {
                await onClick(...props);
                trackActivity('question: plaid option selected', {
                  question: currentQuestion.slug
                });
                open();
              }}
            >
              <span>{replaceStrings(item.text)}</span>
            </TaxFlowListItem>
          )}
        />
      );
    }

    if (item.type === OPTION_ITEM_TYPES.PILLS_SECTION) {
      return (
        <div key={item.value} className='taxflow-option-item-pills-section'>
          <div className='taxflow-option-item-pills-section-header'>{item.text}</div>
          <div className='taxflow-option-item-pills-section-pills'>{getPills()}</div>
        </div>
      );
    } else if (item.type === OPTION_ITEM_TYPES.DYNAMIC) {
      const dynamicOptions = getDynamicOptions();
      return dynamicOptions.map((option) => {
        const dynamicItemProps = {
          item: option,
          handleChange: onClick,
          checked: currentAnswer && !_.isNil(currentAnswer.value) && `${option.value}` === `${currentAnswer.value}`,
          showRightIcon: !!info,
          rightIcon: <TaxFlowInfoButton currentQuestion={currentQuestion} item={item} />
        };
        return (
          <TaxFlowListItem {...dynamicItemProps} key={option.value} item={option}>
            <span>{replaceStrings(option.text)}</span>
          </TaxFlowListItem>
        );
      });
    }

    return (
      <TaxFlowListItem key={_.compact([item.value, item.businessCode]).join(' - ')} {...itemProps}>
        {currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME &&
          renderJobIcon({ iconUrl: item.iconUrl, jobSlug: item.value, className: 'tax-flow-list-item-job-icon' })}
        <span>{replaceStrings(item.text)}</span>
      </TaxFlowListItem>
    );
  };

  if (resultLoading) return null;

  const selectedAnswer =
    _.size(items) > 10 && currentAnswer ? items.find((m) => `${m.value}` === `${currentAnswer.value}`) : undefined;

  return (
    <div className={classNames({ 'steps-body': !isChildQuestion })}>
      <div className='checkbox-list'>
        {_.size(items) <= 10 || currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME ? (
          filterPillsSectionOptions(items).map((item) => renderItem(item))
        ) : (
          // big list
          <>
            {selectedAnswer && renderItem(selectedAnswer)}
            <div className='searchbox'>
              <TextField
                className='form-control'
                type='text'
                name='search'
                autoComplete='off'
                value={searchText}
                onChange={(e) => {
                  Search(e.target.value);
                }}
                onInput={(e) => {
                  Search(e.target.value);
                }}
                placeholder='Search...'
              />
            </div>

            {currentQuestion.question_meta &&
              filteredList &&
              currentQuestion.question_meta.length > 0 &&
              filteredList
                .filter((item) => !(selectedAnswer && `${selectedAnswer.value}` === `${item.value}`))
                .map((item) => renderItem(item))}
          </>
        )}
      </div>
      {currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME && (
        <TaxFlowFormCustomJobItem
          question={currentQuestion}
          answer={currentAnswer}
          setCurrentAnswer={setCurrentAnswer}
          onChange={onChange}
          onFocus={onFocus}
          jobCategories={jobCategories}
          jobOptions={jobOptions}
          isCustomJobSelected={isCustomJobSelected}
          setIsCustomJobSelected={(value) => {
            setIsCustomJobSelected(value);
          }}
          setBusinessCode={setBusinessCode}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    taxFlow: state.taxFlow,
    resultLoading: !_.isNil(props.resultLoading) ? props.resultLoading : state.taxFlow.resultLoading,
    jobOptions: jobOptionsSelector(state),
    businessCode: businessCodeSelector(state)
  };
};

const mapDispatchToProps = {
  initPlaid,
  setContextLoading,
  setBusinessCode
};

const ConnectedTaxFlowOptionItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowOptionItem);

export default ConnectedTaxFlowOptionItem;
