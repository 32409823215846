import _ from 'lodash';
import axios from 'axios';
import { bulkUploadItemsSelector } from '@app/src//taxflow/main/selectors/formUploadSelectors';
import { setCurrentNavigationSection } from '@app/src/actions/taxFlowActions';
import { serverUrl } from '@app/src/global/Environment';
import {
  currentNavigationSectionSelector,
  isFilingQualifiedSelector,
  navSectionsStartedSelector
} from '@app/src/selectors/navigationListSelectors';
import { selectedBulkManualEntryItemSelector } from '@app/src/selectors/taxFlowSelectors';
import { getBulkUploadFormUrl } from '@app/src/taxflow/collection/utils/collectionUtils';
import { getNextCollectionId } from '@app/src/taxflow/main/services/taxFlowDataService';
import {
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS,
  PATH_COMPONENT__FILING_QUALIFIED,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  PATH_COMPONENT__HOME
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

const baseUrl = serverUrl();

const getCurrentNavigationSection = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/get-current-nav-section`);
  const currentNavSection = _.get(res, ['data', 'data', 'currentNavigationSection'], {});
  dispatch(setCurrentNavigationSection(currentNavSection));
};

export const requireNavigationData = () => async (dispatch, getState) => {
  const currentNavigationSection = currentNavigationSectionSelector(getState());

  if (_.isEmpty(currentNavigationSection)) {
    await dispatch(getCurrentNavigationSection());
  }
};

export const getNextUrlNavigation =
  ({ navigationItem }) =>
  (dispatch, getState) => {
    const isFilingQualified = isFilingQualifiedSelector(getState());
    const sectionsStarted = navSectionsStartedSelector(getState());

    if (navigationItem.id === 'personal' && !sectionsStarted.includes('personal') && !isFilingQualified) {
      return `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__FILING_QUALIFIED}`;
    }

    return navigationItem.url;
  };

export const getNextUrlWithBulkUpload =
  ({ currentQuestion }) =>
  async (dispatch, getState) => {
    let nextPathComponent;

    if (currentQuestion.slug === PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO) {
      nextPathComponent = PATH_COMPONENT__BULK_UPLOAD_QUESTIONS;
    } else if (currentQuestion.slug === SLUG__BULK_UPLOAD_MANUAL_ENTRY) {
      const manualEntryItem = selectedBulkManualEntryItemSelector(getState());
      if (_.isNil(manualEntryItem)) {
        nextPathComponent = PATH_COMPONENT__HOME;
      } else {
        const { collectionType, subType } = manualEntryItem;
        const bulkUploadItems = bulkUploadItemsSelector(getState());
        const { formInputQuestionSlug } = _.find(bulkUploadItems, { collectionType, subType });
        const nextCollectionId = await getNextCollectionId({ collectionType });
        return getBulkUploadFormUrl({ nextPathComponent: formInputQuestionSlug, collectionId: nextCollectionId });
      }
    }

    return nextPathComponent ? `/${TAXFLOW_BASE_URL}/${nextPathComponent}` : null;
  };
