import {
  SET_SETTINGS_MODAL_TYPE,
  SET_IS_EMAIL_LOADING,
  SET_IS_PHONE_LOADING,
  SET_IS_FIRSTNAME_LOADING,
  SET_IS_LASTNAME_LOADING,
  SET_IS_CANCELING_SUBSCRIPTION,
  SET_SETTINGS_LOADING,
  SET_SETTINGS_ERROR
} from '@app/src/actions/types';

export const setIsEmailLoading = (payload) => {
  return {
    type: SET_IS_EMAIL_LOADING,
    payload: payload
  };
};

export const setIsPhoneLoading = (payload) => {
  return {
    type: SET_IS_PHONE_LOADING,
    payload: payload
  };
};

export const setIsFirstnameLoading = (payload) => {
  return {
    type: SET_IS_FIRSTNAME_LOADING,
    payload: payload
  };
};

export const setIsLastnameLoading = (payload) => {
  return {
    type: SET_IS_LASTNAME_LOADING,
    payload: payload
  };
};

export const setIsCancelingSubscription = (payload) => {
  return {
    type: SET_IS_CANCELING_SUBSCRIPTION,
    payload: payload
  };
};

export const setSettingsModalType = (payload) => {
  return {
    type: SET_SETTINGS_MODAL_TYPE,
    payload: payload
  };
};

export const setSettingsLoading = (payload) => {
  return {
    type: SET_SETTINGS_LOADING,
    payload: payload
  };
};

// eslint-disable-next-line import/no-unused-modules
export const setSettingsError = (payload) => {
  return {
    type: SET_SETTINGS_ERROR,
    payload: payload
  };
};
