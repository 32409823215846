// @ts-check

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import TaxFlowRefundStatusStep from '@app/src/Components/TaxFlow/Question/TaxFlowRefundStatusStep';
import TaxFlowSettlementStatusContainer from '@app/src/Components/TaxFlow/Question/TaxFlowSettlementStatusContainer';
import SubmittedIcon from '@app/src/assets/bank-submitted.svg?react';
import AcceptedIcon from '@app/src/assets/clipboard-tick.svg?react';
import DepositedIcon from '@app/src/assets/dollar-circle.svg?react';

/**
 * @typedef {Object} TaxFlowRefundStatusProps
 * @property {number} amount
 * @property {moment.Moment} submittedDate
 * @property {moment.Moment} acceptedDate
 * @property {string} entity
 * @property {string} mask
 * @property {moment.Moment} refundDate
 * @property {string} paymentMethod
 * @property {string} status
 */

/**
 * @type {React.FC<TaxFlowRefundStatusProps>}
 */
const TaxFlowRefundStatus = ({
  amount,
  submittedDate,
  acceptedDate,
  entity,
  mask,
  refundDate,
  paymentMethod,
  status
}) => {
  const estimatedDate = acceptedDate ? acceptedDate.clone().add(3, 'weeks') : null;
  const datePassed = estimatedDate ? estimatedDate.isBefore(moment().startOf('day')) : null;

  const formatDate = (date) => {
    if (_.isNil(date)) {
      return null;
    }

    return date.format('MMM D, YYYY');
  };

  return (
    <TaxFlowSettlementStatusContainer
      entity={entity}
      estimatedDate={refundDate || estimatedDate}
      amount={amount}
      status={status}
      completed={!!(paymentMethod !== 'paymentPlan' && (refundDate || datePassed))}
    >
      {submittedDate ? (
        <TaxFlowRefundStatusStep
          success
          icon={<SubmittedIcon />}
          summary='Return submitted'
          details={`Your ${entity} return was submitted ${formatDate(submittedDate)}.`}
        />
      ) : (
        <TaxFlowRefundStatusStep icon={<SubmittedIcon />} summary='Return submitted' />
      )}
      {acceptedDate ? (
        <TaxFlowRefundStatusStep
          success
          icon={<AcceptedIcon />}
          summary='Return accepted'
          details={`Your ${entity} return was accepted ${formatDate(acceptedDate)}.`}
        />
      ) : (
        <TaxFlowRefundStatusStep icon={<AcceptedIcon />} summary='Return accepted' />
      )}
      {estimatedDate ? (
        <TaxFlowRefundStatusStep
          success={paymentMethod !== 'paymentPlan' && (refundDate || datePassed)}
          icon={<DepositedIcon />}
          summary={`Refund deposited | ${
            paymentMethod === 'paymentPlan' || (!refundDate && !datePassed) ? 'Est. ' : ''
          }${(refundDate || estimatedDate).format('MMMM D')}`}
          details={(() => {
            if (paymentMethod === 'paymentPlan') {
              return 'You should expect to receive a check in the mail for your refund.';
            }

            if (refundDate) {
              return `Your ${entity} refund was deposited${mask ? ` to account ending in ${mask}` : ''} on ${formatDate(
                refundDate
              )}`;
            }

            if (datePassed) {
              if (entity === 'federal') {
                return (
                  <>
                    Your refund is estimated to have been deposited on {formatDate(estimatedDate)}
                    {mask ? ` to account ending in ${mask}` : ''}. Check the status of your refund with the IRS{' '}
                    <a href='https://www.irs.gov/refunds' target='_blank' rel='noreferrer'>
                      here
                    </a>
                    .
                  </>
                );
              }

              return (
                <>
                  Your refund is estimated to have been deposited on {formatDate(estimatedDate)}
                  {mask ? ` to account ending in ${mask}` : ''}. You can check the status of your refund by visiting
                  your state revenue department's website.
                </>
              );
            }

            return 'The IRS issues 90% of refunds in 21 days or less from the date the return is accepted.';
          })()}
        />
      ) : (
        <TaxFlowRefundStatusStep icon={<DepositedIcon />} summary='Refund deposited' />
      )}
    </TaxFlowSettlementStatusContainer>
  );
};

export default TaxFlowRefundStatus;
