import React, { useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { LoadingContext } from '@app/src/Components/TaxProfile/LoadingContext';

/**
 * @typedef TaxEstimateDetailsRowProps
 * @property {string} label
 * @property {number} value
 * @property {React.CSSProperties['fontWeight'] | undefined} [fontWeight]
 * @property {Intl.NumberFormatOptionsSignDisplay | undefined} [signDisplay]
 * @property {string | undefined} [fontSize]
 */
/**
 * @type {React.FC<TaxEstimateDetailsRowProps>}
 */
const TaxEstimateDetailsRow = ({ label, value, fontWeight, signDisplay, fontSize }) => {
  const isLoading = useContext(LoadingContext);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize
        }}
      >
        <div
          style={{
            fontWeight
          }}
        >
          {label}
        </div>
        <div
          style={{
            alignSelf: 'flex-end',
            color: value < 0 || Object.is(value, -0) ? '#4ABC78' : 'black',
            fontWeight
          }}
        >
          <Skeleton variant='text' width={50} />
        </div>
      </div>
    );
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    signDisplay
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize
      }}
    >
      <div
        style={{
          fontWeight
        }}
      >
        {label}
      </div>
      <div
        style={{
          alignSelf: 'flex-end',
          color: value < 0 || Object.is(value, -0) ? '#4ABC78' : 'black',
          fontWeight
        }}
      >
        {formatter.format(value)}
      </div>
    </div>
  );
};

export default TaxEstimateDetailsRow;
