import * as Sentry from '@sentry/react';

export default function setSentryUser(settings) {
  Sentry.configureScope((scope) => {
    scope.setUser(
      settings
        ? {
            id: settings.id,
            email: settings.email,
            phone: settings.phone,
            firstname: settings.firstname,
            lastname: settings.lastname
          }
        : null
    );
  });
}
