import React, { Component } from 'react';
import OnboardingConnectBank from '@app/src/Components/Onboarding/Question/OnboardingConnectBank';
import { TAXFLOW_SYSTEM_QUESTION_TYPE_CONNECT_BANK } from '@app/src/constants/constants';

class OnboardingSystemTypeItem extends Component {
  render() {
    const { currentQuestion } = this.props;

    if (currentQuestion.sysId === TAXFLOW_SYSTEM_QUESTION_TYPE_CONNECT_BANK) {
      return <OnboardingConnectBank currentQuestion={currentQuestion} />;
    } else {
      return null;
    }
  }
}

export default OnboardingSystemTypeItem;
