import _ from 'lodash';
import { CATEGORY_TYPE_TAXFLOW_FORM, CATEGORY_TYPE_TAXFLOW_FORM_MULTI } from '@app/src/constants/constants';
import { isQuestionAnswerEmpty } from '@app/src/taxflow/main/utils/mainUtils';
import {
  SLUG__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_HEALTH_PLAN_WHO,
  SLUG__CREDIT_HOMEOWNER_SECURING_CITY,
  SLUG__CREDIT_HOMEOWNER_SECURING_HEADER,
  SLUG__CREDIT_HOMEOWNER_SECURING_SAME,
  SLUG__CREDIT_HOMEOWNER_SECURING_STATE,
  SLUG__CREDIT_HOMEOWNER_SECURING_STREET,
  SLUG__CREDIT_HOMEOWNER_SECURING_ZIP,
  SLUG__CREDIT_HSA_WHO,
  SLUG__CREDIT_RETIREMENT_ACCOUNT_WHO,
  SLUG__CREDIT_STUDENT_LOAN_WHO,
  SLUG__CREDIT_TEACHING_AMOUNT_SPOUSE
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  SLUG__INCOME_FREELANCE_OTHER_FIELDS,
  SLUG__INCOME_FREELANCE_MISC_BOX_ONE,
  SLUG__INCOME_FREELANCE_MISC_BOX_TWO,
  SLUG__INCOME_FREELANCE_BOX_FOUR,
  SLUG__INCOME_FREELANCE_BOX_FIVE,
  SLUG__INCOME_FREELANCE_BOX_SIX,
  SLUG__INCOME_FREELANCE_NEC_BOX_SEVEN,
  SLUG__INCOME_FREELANCE_MISC_BOX_SEVEN,
  SLUG__INCOME_FREELANCE_BOX_SIXTEEN,
  SLUG__INCOME_FREELANCE_BOX_SEVENTEEN,
  SLUG__INCOME_FREELANCE_STATE,
  SLUG__INCOME_FREELANCE_PAYER_ID,
  SLUG__INCOME_FREELANCE_PAYER_STATE_NUMBER,
  SLUG__INCOME_FREELANCE_STATE_INCOME,
  SLUG__INCOME_FREELANCE_STATE_TAX,
  SLUG__INCOME_FREELANCE_WHO,
  SLUG__INCOME_W2_WHO,
  SLUG__INCOME_UNEMPLOYMENT_EARNER,
  SLUG__INCOME_INTEREST_EARNER,
  SLUG__INCOME_DIV_EARNER,
  SLUG__INCOME_INVEST_EARNER,

  // unemployment other fields
  SLUG__INCOME_UNEMPLOYMENT_UNCOMMON,
  SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND,
  SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR,
  SLUG__INCOME_UNEMPLOYMENT_RTAA_PAYMENT,
  SLUG__INCOME_UNEMPLOYMENT_CARRY_TO_FORM,
  SLUG__INCOME_UNEMPLOYMENT_MULTI_FORM_NUM,
  SLUG__INCOME_UNEMPLOYMENT_TAXABLE_GRANTS,
  SLUG__INCOME_UNEMPLOYMENT_AG_PAYMENTS,
  SLUG__INCOME_UNEMPLOYMENT_TRADE_BUSINESS,
  SLUG__INCOME_UNEMPLOYMENT_MARKET_GAIN,
  SLUG__INCOME_UNEMPLOYMENT_STATE,
  SLUG__INCOME_UNEMPLOYMENT_STATE_ID,
  SLUG__INCOME_UNEMPLOYMENT_STATE_UNEMPLOYMENT,
  SLUG__INCOME_UNEMPLOYMENT_LOCAL_PAYMENT,
  SLUG__INCOME_UNEMPLOYMENT_LOCAL_WITHHOLDING,

  // interest other fields
  SLUG__INCOME_INTEREST_UNCOMMON,
  SLUG__INCOME_INTEREST_EARLY_PENALTY,
  SLUG__INCOME_INTEREST_GOVERNMENT_INTEREST,
  SLUG__INCOME_INTEREST_FED_TAX_WITHHELD,
  SLUG__INCOME_INTEREST_INVESTMENT_EXPENSES,
  SLUG__INCOME_INTEREST_FOREIGN_TAX_PAID,
  SLUG__INCOME_INTEREST_FOREIGN_COUNTRY,
  SLUG__INCOME_INTEREST_TAX_EXEMPT,
  SLUG__INCOME_INTEREST_STATE,
  SLUG__INCOME_INTEREST_STATE_ID,
  SLUG__INCOME_INTEREST_STATE_TAX_WITHHELD,
  SLUG__INCOME_INTEREST_STATE_TAX_EXEMPT,

  // dividend other fields
  SLUG__INCOME_DIV_UNCOMMON,
  SLUG__INCOME_DIV_ORDINARY_FOREIGN_AMOUNT,
  SLUG__INCOME_DIV_QUALIFIED_FOREIGN_AMOUNT,
  SLUG__INCOME_DIV_CAPITAL_GAINS_FOREIGN_AMOUNT,
  SLUG__INCOME_DIV_FED_TAX_WITHHELD,
  SLUG__INCOME_DIV_FOREIGN_TAX_PAID,
  SLUG__INCOME_DIV_STATE,
  SLUG__INCOME_DIV_STATE_ID,
  SLUG__INCOME_DIV_STATE_TAX_WITHHELD,

  // investment other fields
  SLUG__INCOME_INVEST_UNKNOWN_TERM,
  SLUG__INCOME_INVEST_AMT_COST_BASIS,
  SLUG__INCOME_INVEST_MARKET_DISCOUNT,
  SLUG__INCOME_INVEST_FED_TAX_WITHHELD,
  SLUG__INCOME_INVEST_STATE,
  SLUG__INCOME_INVEST_STATE_ID,
  SLUG__INCOME_INVEST_STATE_TAX_WITHHELD,
  SLUG__INCOME_INVEST_DATE_ACQUISITION,
  SLUG__INCOME_INVEST_DATE_SALE,

  // w2 other fields
  SLUG__INCOME_W2_UNCOMMON,
  SLUG__INCOME_W2_SOCIAL_SECURITY_TIPS,
  SLUG__INCOME_W2_AllOCATED_TIPS,
  SLUG__INCOME_W2_DEPENDENT_CARE_BENEFITS,
  SLUG__INCOME_W2_NONQUALIFIED_PLANS,
  SLUG__INCOME_W2_TWELVE_A,
  SLUG__INCOME_W2_TWELVE_B,
  SLUG__INCOME_W2_TWELVE_C,
  SLUG__INCOME_W2_TWELVE_D,
  SLUG__INCOME_W2_FOURTEEN_A,
  SLUG__INCOME_W2_FOURTEEN_B,
  SLUG__INCOME_W2_FOURTEEN_C,
  SLUG__INCOME_W2_FOURTEEN_D,
  SLUG__INCOME_W2_STATUTORY_EMPLOYEE,
  SLUG__INCOME_W2_RETIREMENT_PLAN,
  SLUG__INCOME_W2_SICK_PAY,
  SLUG__INCOME_W2_LOCAL_WAGES,
  SLUG__INCOME_W2_LOCAL_TAXES,
  SLUG__INCOME_W2_LOCAL_NAME,
  SLUG__INCOME_W2G_WHO,
  SLUG__INCOME_W2_FOREIGN_ADDRESS,
  SLUG__INCOME_W2_EMPLOYER_ADDRESS_STATE,
  SLUG__INCOME_W2_EMPLOYER_ADDRESS_ZIP,
  SLUG__INCOME_W2_ADDRESS_COUNTRY,
  SLUG__INCOME_W2_ADDRESS_FOREIGN_POSTAL_CODE,
  SLUG__INCOME_W2_ADDRESS_PROVINCE,
  SLUG__INCOME_W2_HAS_EXTRA_STATE,
  SLUG__INCOME_W2_STATE_2,
  SLUG__INCOME_W2_STATE_EMPLOYER_ID_2,
  SLUG__INCOME_W2_STATE_WAGES_2,
  SLUG__INCOME_W2_STATE_INCOME_TAX_2,
  SLUG__INCOME_W2_LOCAL_WAGES_2,
  SLUG__INCOME_W2_LOCAL_TAXES_2,
  SLUG__INCOME_W2_LOCAL_NAME_2,
  SLUG__INCOME_RETIREMENT_PENSION_WHO,
  SLUG__INCOME_RETIREMENT_SSA_WHO,
  SLUG__INCOME_FREELANCE_BOX_EIGHTEEN,
  SLUG__INCOME_PERSONAL_ITEMS_WHO,
  SLUG__INCOME_PERSONAL_ITEMS_MULTIPLE_DATES,
  SLUG__INCOME_PERSONAL_ITEMS_ACQUIRED,
  SLUG__INCOME_PERSONAL_ITEMS_SOLD
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  SLUG__SPOUSE_RIP_DATE,
  SLUG__SELF_ID_DOCUMENT_NUMBER,
  SLUG__SELF_ID_STATE,
  SLUG__SPOUSE_ID_DOCUMENT_NUMBER,
  SLUG__SPOUSE_ID_STATE,
  SLUG__DEPENDENT_RELATIONSHIP_TYPE,
  SLUG__DEPENDENT_RELATIONSHIP_OTHER,
  SLUG__HOME_ADDRESS_STREET_MULTISTATE,
  SLUG__HOME_ADDRESS_MULTISTATE_HEADER,
  SLUG__HOME_ADDRESS_APT_MULTISTATE,
  SLUG__HOME_ADDRESS_CITY_MULTISTATE,
  SLUG__HOME_ADDRESS_ZIP_MULTISTATE,
  SLUG__HOME_ADDRESS_STATE_MULTISTATE,
  SLUG__HOME_ADDRESS_MULTISTATE,
  SLUG__SELF_MARRIAGE_FILING_OPTION,
  SLUG__SELF_MARRIAGE_STATUS,
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__SELF_LAST_NAME,
  COLLECTION_TYPE__SPOUSE,
  ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__SPOUSE_LAST_NAME
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  SLUG__BULK_UPLOAD_CHARITY_OPTION,
  SLUG__BULK_UPLOAD_HAS_CHARITY
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  SLUG__ME_USE_TAX_AMOUNT_SPOUSE,
  SLUG__STATE_RESIDENCY_LIVED_SEPARATELY,
  SLUG__VA_USE_TAX_AMOUNT_SPOUSE
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_BANK_NUMBERS,
  SLUG__SUBMIT_CONFIRMATION_MODAL,
  SLUG__SUBMIT_DEBIT_ACCOUNT,
  SLUG__SUBMIT_DEBIT_INSTITUTION,
  SLUG__SUBMIT_DEBIT_MANUAL_HEADER,
  SLUG__SUBMIT_DEBIT_MANUAL_OPTION,
  SLUG__SUBMIT_DEBIT_ROUTING,
  SLUG__SUBMIT_DEBIT_TYPE,
  SLUG__SUBMIT_HAVE_PIN,
  SLUG__SUBMIT_SELF_PIN_NUMBER,
  SLUG__SUBMIT_SIGNATURE_SELF,
  SLUG__SUBMIT_SIGNATURE_SPOUSE,
  SLUG__SUBMIT_SPOUSE_PIN_NUMBER
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';

const isRoutingNumberInvalid = ({ question, answer }) => {
  if (question.slug === SLUG__SUBMIT_DEBIT_ROUTING) {
    const routing = answer.value || '';
    return routing.length !== 9 || routing === '000000000' || routing === '111111111';
  }
  return false;
};

export const isSignatureInvalid = ({ question, answer, status, queryResults }) => {
  if (question.slug === SLUG__SUBMIT_SIGNATURE_SELF) {
    const signature = _.get(answer, 'value', '').toLowerCase();
    const firstName =
      _.get(
        getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__SELF,
          collectionId: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SELF_FIRST_NAME
        }),
        ['answer', 'value']
      ) || '';
    const lastName =
      _.get(
        getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__SELF,
          collectionId: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SELF_LAST_NAME
        }),
        ['answer', 'value']
      ) || '';
    return !signature.includes(firstName.trim().toLowerCase()) || !signature.includes(lastName.trim().toLowerCase());
  } else if (question.slug === SLUG__SUBMIT_SIGNATURE_SPOUSE && status === 'married') {
    const signature = _.get(answer, 'value', '').toLowerCase();
    const firstName =
      _.get(
        getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__SPOUSE,
          collectionId: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
        }),
        ['answer', 'value']
      ) || '';
    const lastName =
      _.get(
        getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__SPOUSE,
          collectionId: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SPOUSE_LAST_NAME
        }),
        ['answer', 'value']
      ) || '';
    return !signature.includes(firstName.trim().toLowerCase()) || !signature.includes(lastName.trim().toLowerCase());
  }

  return false;
};

export const hasQuestionError = ({ question, answer, status, queryResults }) => {
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    return question.sub_question
      .filter((subQuestion) => {
        return isQuestionPresent({ currentQuestion: question, question: subQuestion, answer, status });
      })
      .some((subQuestion) => {
        const subAnswer = _.get(answer, ['value', subQuestion.slug]);
        return hasQuestionError({ question: subQuestion, answer: subAnswer, status, queryResults });
      });
  } else {
    return question.required
      ? isQuestionAnswerEmpty({ question, answer }) ||
          isRoutingNumberInvalid({ question, answer }) ||
          isSignatureInvalid({ question, answer, status, queryResults })
      : false;
  }
};

export const isQuestionPresent = ({ currentQuestion, question, answer, status }) => {
  if (
    question.slug === SLUG__CREDIT_HOMEOWNER_SECURING_HEADER ||
    question.slug === SLUG__CREDIT_HOMEOWNER_SECURING_STREET ||
    question.slug === SLUG__CREDIT_HOMEOWNER_SECURING_CITY ||
    question.slug === SLUG__CREDIT_HOMEOWNER_SECURING_STATE ||
    question.slug === SLUG__CREDIT_HOMEOWNER_SECURING_ZIP
  ) {
    const same = _.get(answer, ['value', SLUG__CREDIT_HOMEOWNER_SECURING_SAME, 'value']);
    if (same !== '0') {
      return false;
    }
  }

  if (question.slug === SLUG__SELF_ID_DOCUMENT_NUMBER) {
    const state = _.get(answer, ['value', SLUG__SELF_ID_STATE, 'value']);
    if (state !== 'NY') {
      return false;
    }
  }

  if (question.slug === SLUG__SPOUSE_ID_DOCUMENT_NUMBER) {
    const state = _.get(answer, ['value', SLUG__SPOUSE_ID_STATE, 'value']);
    if (state !== 'NY') {
      return false;
    }
  }

  if (
    question.slug === SLUG__HOME_ADDRESS_MULTISTATE_HEADER ||
    question.slug === SLUG__HOME_ADDRESS_STREET_MULTISTATE ||
    question.slug === SLUG__HOME_ADDRESS_APT_MULTISTATE ||
    question.slug === SLUG__HOME_ADDRESS_CITY_MULTISTATE ||
    question.slug === SLUG__HOME_ADDRESS_ZIP_MULTISTATE ||
    question.slug === SLUG__HOME_ADDRESS_STATE_MULTISTATE
  ) {
    return _.get(answer, ['value', SLUG__HOME_ADDRESS_MULTISTATE, 'value']) === '1';
  }

  if (question.slug === SLUG__DEPENDENT_RELATIONSHIP_OTHER) {
    const dependentType = _.get(answer, ['value', SLUG__DEPENDENT_RELATIONSHIP_TYPE, 'value']);
    if (dependentType !== 'other') {
      return false;
    }
  }

  if (currentQuestion?.slug === SLUG__BULK_UPLOAD_HAS_CHARITY && question.slug === SLUG__CREDIT_CHARITY_AMOUNT) {
    return _.get(answer, ['value', SLUG__BULK_UPLOAD_CHARITY_OPTION, 'value']) === '1';
  }

  if (question.slug === SLUG__STATE_RESIDENCY_LIVED_SEPARATELY && status !== 'married') {
    return false;
  }

  if (question.slug === SLUG__SUBMIT_SELF_PIN_NUMBER) {
    const pin = _.get(answer, ['value', SLUG__SUBMIT_HAVE_PIN, 'value']);
    if (pin !== '1') {
      return false;
    }
  }

  if (question.slug === SLUG__SUBMIT_SPOUSE_PIN_NUMBER) {
    const pin = _.get(answer, ['value', SLUG__SUBMIT_HAVE_PIN, 'value']);
    if (pin !== '1' || status !== 'married') {
      return false;
    }
  }

  if (question.slug === SLUG__SUBMIT_SIGNATURE_SPOUSE && status !== 'married') {
    return false;
  }

  if (question.slug === SLUG__SUBMIT_CONFIRMATION_MODAL) {
    return false;
  }

  if (
    question.slug === SLUG__INCOME_W2_SOCIAL_SECURITY_TIPS ||
    question.slug === SLUG__INCOME_W2_AllOCATED_TIPS ||
    question.slug === SLUG__INCOME_W2_DEPENDENT_CARE_BENEFITS ||
    question.slug === SLUG__INCOME_W2_NONQUALIFIED_PLANS ||
    question.slug === SLUG__INCOME_W2_TWELVE_A ||
    question.slug === SLUG__INCOME_W2_TWELVE_B ||
    question.slug === SLUG__INCOME_W2_TWELVE_C ||
    question.slug === SLUG__INCOME_W2_TWELVE_D ||
    question.slug === SLUG__INCOME_W2_STATUTORY_EMPLOYEE ||
    question.slug === SLUG__INCOME_W2_RETIREMENT_PLAN ||
    question.slug === SLUG__INCOME_W2_SICK_PAY ||
    question.slug === SLUG__INCOME_W2_FOURTEEN_A ||
    question.slug === SLUG__INCOME_W2_FOURTEEN_B ||
    question.slug === SLUG__INCOME_W2_FOURTEEN_C ||
    question.slug === SLUG__INCOME_W2_FOURTEEN_D ||
    question.slug === SLUG__INCOME_W2_LOCAL_WAGES ||
    question.slug === SLUG__INCOME_W2_LOCAL_TAXES ||
    question.slug === SLUG__INCOME_W2_LOCAL_NAME
  ) {
    const uncommon = _.get(answer, ['value', SLUG__INCOME_W2_UNCOMMON, 'value']);
    if (uncommon !== '1') {
      return false;
    }
  }

  if (
    question.slug === SLUG__INCOME_FREELANCE_MISC_BOX_ONE ||
    question.slug === SLUG__INCOME_FREELANCE_MISC_BOX_TWO ||
    question.slug === SLUG__INCOME_FREELANCE_BOX_FOUR ||
    question.slug === SLUG__INCOME_FREELANCE_BOX_FIVE ||
    question.slug === SLUG__INCOME_FREELANCE_BOX_SIX ||
    question.slug === SLUG__INCOME_FREELANCE_NEC_BOX_SEVEN ||
    question.slug === SLUG__INCOME_FREELANCE_MISC_BOX_SEVEN ||
    question.slug === SLUG__INCOME_FREELANCE_BOX_SIXTEEN ||
    question.slug === SLUG__INCOME_FREELANCE_BOX_SEVENTEEN ||
    question.slug === SLUG__INCOME_FREELANCE_BOX_EIGHTEEN ||
    question.slug === SLUG__INCOME_FREELANCE_STATE ||
    question.slug === SLUG__INCOME_FREELANCE_PAYER_ID ||
    question.slug === SLUG__INCOME_FREELANCE_STATE_INCOME ||
    question.slug === SLUG__INCOME_FREELANCE_STATE_TAX ||
    question.slug === SLUG__INCOME_FREELANCE_PAYER_STATE_NUMBER
  ) {
    const uncommon = _.get(answer, ['value', SLUG__INCOME_FREELANCE_OTHER_FIELDS, 'value']);
    if (uncommon !== '1') {
      return false;
    }
  }

  if (
    (question.slug === SLUG__INCOME_FREELANCE_WHO ||
      question.slug === SLUG__INCOME_W2_WHO ||
      question.slug === SLUG__INCOME_UNEMPLOYMENT_EARNER ||
      question.slug === SLUG__INCOME_INTEREST_EARNER ||
      question.slug === SLUG__INCOME_DIV_EARNER ||
      question.slug === SLUG__INCOME_INVEST_EARNER ||
      question.slug === SLUG__INCOME_RETIREMENT_PENSION_WHO ||
      question.slug === SLUG__INCOME_RETIREMENT_SSA_WHO ||
      question.slug === SLUG__INCOME_PERSONAL_ITEMS_WHO ||
      question.slug === SLUG__CREDIT_HEALTH_PLAN_WHO ||
      question.slug === SLUG__CREDIT_HSA_WHO ||
      question.slug === SLUG__CREDIT_RETIREMENT_ACCOUNT_WHO ||
      question.slug === SLUG__CREDIT_STUDENT_LOAN_WHO ||
      question.slug === SLUG__CREDIT_TEACHING_AMOUNT_SPOUSE) &&
    status !== 'married'
  ) {
    return null;
  }

  if (question.slug === SLUG__SPOUSE_RIP_DATE && status !== 'widowed') {
    return null;
  }

  // unemployment other fields
  if (
    question.slug === SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_RTAA_PAYMENT ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_CARRY_TO_FORM ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_MULTI_FORM_NUM ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_TAXABLE_GRANTS ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_AG_PAYMENTS ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_TRADE_BUSINESS ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_MARKET_GAIN ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_STATE ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_STATE_ID ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_STATE_UNEMPLOYMENT ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_LOCAL_PAYMENT ||
    question.slug === SLUG__INCOME_UNEMPLOYMENT_LOCAL_WITHHOLDING
  ) {
    const uncommon = _.get(answer, ['value', SLUG__INCOME_UNEMPLOYMENT_UNCOMMON, 'value']);
    if (uncommon !== '1') {
      return false;
    }
  }

  // interest other fields
  if (
    question.slug === SLUG__INCOME_INTEREST_EARLY_PENALTY ||
    question.slug === SLUG__INCOME_INTEREST_GOVERNMENT_INTEREST ||
    question.slug === SLUG__INCOME_INTEREST_FED_TAX_WITHHELD ||
    question.slug === SLUG__INCOME_INTEREST_INVESTMENT_EXPENSES ||
    question.slug === SLUG__INCOME_INTEREST_FOREIGN_TAX_PAID ||
    question.slug === SLUG__INCOME_INTEREST_FOREIGN_COUNTRY ||
    question.slug === SLUG__INCOME_INTEREST_TAX_EXEMPT ||
    question.slug === SLUG__INCOME_INTEREST_STATE ||
    question.slug === SLUG__INCOME_INTEREST_STATE_ID ||
    question.slug === SLUG__INCOME_INTEREST_STATE_TAX_WITHHELD ||
    question.slug === SLUG__INCOME_INTEREST_STATE_TAX_EXEMPT
  ) {
    const uncommon = _.get(answer, ['value', SLUG__INCOME_INTEREST_UNCOMMON, 'value']);
    if (uncommon !== '1') {
      return false;
    }
  }

  // dividend other fields
  if (
    question.slug === SLUG__INCOME_DIV_ORDINARY_FOREIGN_AMOUNT ||
    question.slug === SLUG__INCOME_DIV_QUALIFIED_FOREIGN_AMOUNT ||
    question.slug === SLUG__INCOME_DIV_CAPITAL_GAINS_FOREIGN_AMOUNT ||
    question.slug === SLUG__INCOME_DIV_FED_TAX_WITHHELD ||
    question.slug === SLUG__INCOME_DIV_FOREIGN_TAX_PAID ||
    question.slug === SLUG__INCOME_DIV_STATE ||
    question.slug === SLUG__INCOME_DIV_STATE_ID ||
    question.slug === SLUG__INCOME_DIV_STATE_TAX_WITHHELD
  ) {
    const uncommon = _.get(answer, ['value', SLUG__INCOME_DIV_UNCOMMON, 'value']);
    if (uncommon !== '1') {
      return false;
    }
  }

  // investment other fields
  if (
    question.slug === SLUG__INCOME_INVEST_DATE_ACQUISITION ||
    question.slug === SLUG__INCOME_INVEST_DATE_SALE ||
    question.slug === SLUG__INCOME_INVEST_AMT_COST_BASIS ||
    question.slug === SLUG__INCOME_INVEST_MARKET_DISCOUNT ||
    question.slug === SLUG__INCOME_INVEST_FED_TAX_WITHHELD ||
    question.slug === SLUG__INCOME_INVEST_STATE ||
    question.slug === SLUG__INCOME_INVEST_STATE_ID ||
    question.slug === SLUG__INCOME_INVEST_STATE_TAX_WITHHELD
  ) {
    const unknownTerm = _.get(answer, ['value', SLUG__INCOME_INVEST_UNKNOWN_TERM, 'value']);
    if (unknownTerm !== '1') {
      return false;
    }
  }

  // Income personal items
  if (question.slug === SLUG__INCOME_PERSONAL_ITEMS_ACQUIRED || question.slug === SLUG__INCOME_PERSONAL_ITEMS_SOLD) {
    const multipleDates = _.get(answer, ['value', SLUG__INCOME_PERSONAL_ITEMS_MULTIPLE_DATES, 'value']);
    if (multipleDates !== '0') {
      return false;
    }
  }

  if (question.slug === SLUG__VA_USE_TAX_AMOUNT_SPOUSE || question.slug === SLUG__ME_USE_TAX_AMOUNT_SPOUSE) {
    if (status !== 'married') {
      return false;
    }
  }

  if (question.slug === SLUG__INCOME_W2G_WHO && ['single', 'married_separate', 'head'].includes(status)) {
    return false;
  }
  if (
    [
      SLUG__INCOME_W2_STATE_2,
      SLUG__INCOME_W2_STATE_EMPLOYER_ID_2,
      SLUG__INCOME_W2_STATE_WAGES_2,
      SLUG__INCOME_W2_STATE_INCOME_TAX_2,
      SLUG__INCOME_W2_LOCAL_WAGES_2,
      SLUG__INCOME_W2_LOCAL_TAXES_2,
      SLUG__INCOME_W2_LOCAL_NAME_2
    ].includes(question.slug)
  ) {
    if (_.get(answer, ['value', SLUG__INCOME_W2_HAS_EXTRA_STATE, 'value']) !== '1') {
      return false;
    }
  }
  if (
    [
      SLUG__INCOME_W2_ADDRESS_COUNTRY,
      SLUG__INCOME_W2_ADDRESS_FOREIGN_POSTAL_CODE,
      SLUG__INCOME_W2_ADDRESS_PROVINCE
    ].includes(question.slug)
  ) {
    if (_.get(answer, ['value', SLUG__INCOME_W2_FOREIGN_ADDRESS, 'value']) !== '1') {
      return false;
    }
  }
  if ([SLUG__INCOME_W2_EMPLOYER_ADDRESS_STATE, SLUG__INCOME_W2_EMPLOYER_ADDRESS_ZIP].includes(question.slug)) {
    if (_.get(answer, ['value', SLUG__INCOME_W2_FOREIGN_ADDRESS, 'value']) === '1') {
      return false;
    }
  }
  if (question.slug === SLUG__SELF_MARRIAGE_FILING_OPTION) {
    if (_.get(answer, ['value', SLUG__SELF_MARRIAGE_STATUS, 'value']) !== '1') {
      return false;
    }
  }

  if (
    _.get(currentQuestion, 'slug') !== SLUG__SUBMIT_BANK_NUMBERS &&
    [
      SLUG__SUBMIT_DEBIT_INSTITUTION,
      SLUG__SUBMIT_DEBIT_ROUTING,
      SLUG__SUBMIT_DEBIT_ACCOUNT,
      SLUG__SUBMIT_DEBIT_TYPE,
      SLUG__SUBMIT_DEBIT_MANUAL_HEADER
    ].includes(question.slug)
  ) {
    if (_.get(answer, ['value', SLUG__SUBMIT_DEBIT_MANUAL_OPTION, 'value']) !== 'manual') {
      return false;
    }
  }

  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_MULTI) {
    return _.get(question, 'sub_question', []).some((subQuestion) =>
      isQuestionPresent({
        currentQuestion: question,
        question: subQuestion,
        answer,
        status
      })
    );
  }

  return true;
};
