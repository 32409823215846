import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { setOrigin } from '@app/src/actions/pricingActions';
import { setIsPaymentModalOpen, setPremiumUpsellModalShow } from '@app/src/actions/taxFlowActions';
import ArrowLeft from '@app/src/assets/arrow-left.svg?react';
import IconCheckGreen from '@app/src/assets/icon-check-green-circle.svg?react';
import IconXRed from '@app/src/assets/icon-x-red-circle.svg?react';
import { pricingObjSelector } from '@app/src/selectors/pricingSelectors';
import { premiumUpsellModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { getSubscriptionFeatures } from '@app/src/utils/subscriptionUtils';
import '@app/src/Components/TaxFlow/Question/TaxFlowPremiumUpsellModal.scss';

const TaxFlowPremiumUpsellModal = ({
  premiumUpsellModalShow,
  setPremiumUpsellModalShow,
  setIsPaymentModalOpen,
  setOrigin,
  pricingObj
}) => {
  const onClose = () => {
    setPremiumUpsellModalShow(false);
  };

  const subscriptionFeatures = getSubscriptionFeatures(pricingObj?.isEligibleForReferralPricing);

  return (
    <Dialog
      sx={{ '.MuiPaper-root': { maxWidth: '100%', width: '1000px' } }}
      open={!!premiumUpsellModalShow}
      onClose={onClose}
    >
      <div style={{ padding: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ArrowLeft onClick={onClose} color='primary' style={{ cursor: 'pointer' }} />{' '}
          <div className='taxflow-premium-upsell-modal-header'>Your situation requires a Premium plan subscription</div>
        </div>
        <div className='taxflow-premium-upsell-modal-line' />
        <Grid container spacing={2}>
          {Object.entries(subscriptionFeatures).map(
            ([planKey, { title, subtitle, price, priceSubtitle, currency, frequency, features }], index) => (
              <Grid item xs={12} sm={4} key={index}>
                <div
                  className={
                    planKey === 'premium'
                      ? 'taxflow-premium-upsell-modal-subscription-type-selected'
                      : 'taxflow-premium-upsell-modal-subscription-type'
                  }
                >
                  <div className='taxflow-premium-upsell-modal-subscription-type-element'>
                    <div className='taxflow-premium-upsell-modal-content'>
                      <div className='taxflow-premium-upsell-modal-content-item-left'>
                        <div className='taxflow-premium-upsell-modal-price-title'>{title}</div>
                      </div>
                      <div className='taxflow-premium-upsell-modal-content-item-right'>
                        <div className='taxflow-premium-upsell-modal-price-container'>
                          <div className='taxflow-premium-upsell-modal-currency'>{currency}</div>
                          <div className='taxflow-premium-upsell-modal-price'>{price}</div>
                          <div className='taxflow-premium-upsell-modal-frequency'>{frequency}</div>
                        </div>
                      </div>
                    </div>
                    <div className='taxflow-premium-upsell-modal-content'>
                      <div className='taxflow-premium-upsell-modal-content-item-left'>
                        <div className='taxflow-premium-upsell-modal-price-subtitle'>{subtitle}</div>
                      </div>
                      <div className='taxflow-premium-upsell-modal-content-item-right'>
                        {priceSubtitle && (
                          <div className='taxflow-premium-upsell-modal-pill'>
                            <div className='taxflow-premium-upsell-modal-pill-text'>{priceSubtitle}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='taxflow-premium-upsell-modal-line' />
                  </div>
                  {features.map((feature, featureIndex) => (
                    <div key={featureIndex} className='taxflow-premium-upsell-modal-feature'>
                      <div className='taxflow-premium-upsell-modal-icon-container'>
                        {feature.included ? <IconCheckGreen /> : <IconXRed />}
                      </div>
                      <div className='taxflow-premium-upsell-modal-feature-text'>{feature.text}</div>
                    </div>
                  ))}
                </div>
              </Grid>
            )
          )}
        </Grid>
        <div className='taxflow-premium-upsell-modal-line' />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size='large'
            variant='contained'
            color='secondary'
            onClick={() => {
              setOrigin({ origin: 'premium-upsell-modal' });
              setPremiumUpsellModalShow(false);
              setIsPaymentModalOpen(true);
            }}
          >
            Continue with Premium
          </Button>
        </div>{' '}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  premiumUpsellModalShow: premiumUpsellModalShowSelector(state),
  pricingObj: pricingObjSelector(state)
});

const mapDispatchToProps = {
  setOrigin,
  setPremiumUpsellModalShow,
  setIsPaymentModalOpen
};

const ConnectedTaxFlowPremiumUpsellModal = connect(mapStateToProps, mapDispatchToProps)(TaxFlowPremiumUpsellModal);

export default ConnectedTaxFlowPremiumUpsellModal;
