import React from 'react';
import _ from 'lodash';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import { ArrowDown2 } from 'iconsax-react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';
import { isOnboardingQuestion } from '@app/src/services/taxFlow/isOnboardingQuestion';
import '@app/src/Components/TaxValidation/TaxValidationSelect.scss';

const TaxValidationSelect = ({ className, question, answer, hasError, value, ...props }) => {
  return (
    <div>
      <TaxValidationInputLabel question={question} htmlFor={question.slug} />
      <div className='tax-flow-input-wrap'>
        <Select
          fullWidth
          native
          error={hasError}
          value={value}
          name={question.slug}
          id={question.slug}
          className={classNames(
            'tax-validation-select',
            { onboarding: isOnboardingQuestion(question) },
            {
              'tax-validation-select-valid': !!value && !hasError
            },
            {
              'tax-validation-select-small': _.get(question, ['question_meta_ext', 'type']) === 'small'
            },
            {
              'grey-text': answer && _.get(answer, 'value') === ''
            },
            'form-control',
            'browser-default',
            className
          )}
          IconComponent={(props) => <ArrowDown2 {...props} />}
          {..._.omit(props, ['dispatch', 'className', 'hasError', 'question', 'answer', 'short', 'label'])}
        />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxValidationSelect = connect(mapStateToProps)(TaxValidationSelect);

export default ConnectedTaxValidationSelect;
