import React from 'react';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import AnimatedList from '@app/src/Components/HolisticOnboarding/components/AnimatedList/AnimatedList';
import { jobWriteOffsSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const OnboardingDeductions = ({ jobDeductions }) => {
  return (
    <Stack spacing={1}>
      <AnimatedList items={jobDeductions} />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  jobDeductions: jobWriteOffsSelector(state)
});

const ConnectedOnboardingDeductions = connect(mapStateToProps)(OnboardingDeductions);

export default ConnectedOnboardingDeductions;
