import {
  PRICING2__SET_BANK_LINKS,
  PRICING2__SET_ACCOUNT_DETAILS,
  PRICING2__SET_LOADING,
  PRICING2__SET_IS_CARD_VALID,
  PRICING2__SET_UPDATING,
  PRICING2__SET_BANK,
  PRICING2__SET_ERROR,
  PRICING2__SET_ORIGIN,
  PRICING2__SET_WALLET_TYPE,
  PRICING2__SET_SUBSCRIPTION_TYPE
} from '@app/src/constants/pricingConstants';

export const setBankLinks = ({ bankLinks }) => ({
  type: PRICING2__SET_BANK_LINKS,
  bankLinks
});

export const setAccountDetails = ({ accountDetails }) => ({
  type: PRICING2__SET_ACCOUNT_DETAILS,
  accountDetails
});

export const setLoading = ({ loading }) => ({
  type: PRICING2__SET_LOADING,
  loading
});

export const setUpdating = ({ updating }) => ({
  type: PRICING2__SET_UPDATING,
  updating
});

export const setError = ({ error }) => ({
  type: PRICING2__SET_ERROR,
  error
});

export const setWalletType = ({ walletType }) => ({
  type: PRICING2__SET_WALLET_TYPE,
  walletType
});

export const setIsCardValid = ({ isCardValid }) => ({
  type: PRICING2__SET_IS_CARD_VALID,
  isCardValid
});

export const setBank = ({ bank }) => ({
  type: PRICING2__SET_BANK,
  bank
});

export const setOrigin = ({ origin }) => ({
  type: PRICING2__SET_ORIGIN,
  origin
});

export const setSubscriptionType = (subscriptionType) => ({
  type: PRICING2__SET_SUBSCRIPTION_TYPE,
  subscriptionType
});
