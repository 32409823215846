import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import BaseAssistant from '@app/src/Components/Assistant/BaseAssistant';
import { sendStreamMessage, setAssistantMessages, setAssistantUnreadMessages } from '@app/src/actions/assistantActions';
import {
  assistantHasErrorSelector,
  assistantIsConnectedSelector,
  assistantIsLoadingSelector,
  assistantMessagesSelector,
  assistantTypingSelector
} from '@app/src/selectors/assistantSelectors';
import { generatePageSummary } from '@app/src/services/assistantService';
import '@app/src/Components/Assistant/Assistant.scss';

const StreamAssistant = ({ sendStreamMessage, messages, typing, isLoading, hasError, isConnected }) => {
  const submitMessage = useCallback(
    (text) => {
      sendStreamMessage({ text });
    },
    [sendStreamMessage]
  );

  return (
    <BaseAssistant
      submitMessage={submitMessage}
      typing={typing}
      messages={messages}
      isLoading={isLoading}
      error={(() => {
        if (hasError) {
          return 'Something went wrong! Please reload the page or try again later.';
        }

        if (!isConnected && !isLoading) {
          return 'Chat is disconnected.';
        }
      })()}
      disableSubmit={hasError || !isConnected}
    />
  );
};

const mapStateToProps = (state) => ({
  messages: assistantMessagesSelector(state),
  typing: assistantTypingSelector(state),
  isLoading: assistantIsLoadingSelector(state),
  hasError: assistantHasErrorSelector(state),
  isConnected: assistantIsConnectedSelector(state)
});

const mapDispatchToProps = {
  setMessages: setAssistantMessages,
  setAssistantUnreadMessages,
  generatePageSummary,
  sendStreamMessage
};

const ConnectedStreamAssistant = connect(mapStateToProps, mapDispatchToProps)(StreamAssistant);

export default ConnectedStreamAssistant;
