import _ from 'lodash';
import { SLUG__CREDIT_CHILD_CARE_DETAIL } from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__SELF,
  COLLECTION_TYPE__SPOUSE,
  PATH_COMPONENT__SELF_DETAIL,
  PATH_COMPONENT__SELF_EXIT_BOUNCE,
  PATH_COMPONENT__SPOUSE_DETAIL,
  PATH_COMPONENT__SPOUSE_EXIT_BOUNCE,
  PATH_COMPONENT__HOME_ADDRESS_DETAIL,
  PATH_COMPONENT__HOME_ADDRESS_EXIT_BOUNCE,
  SLUG__SELF_DETAIL,
  SLUG__SELF_EXIT_BOUNCE,
  SLUG__SPOUSE_DETAIL,
  SLUG__SPOUSE_EXIT_BOUNCE,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__HOME_ADDRESS_EXIT_BOUNCE,
  SLUG__DEPENDENT_DETAIL,
  SLUG__DEPENDENT_EXIT_BOUNCE,
  PATH_COMPONENT__DEPENDENT_EXIT_BOUNCE,
  COLLECTION_TYPE__DEPENDENT,
  PATH_COMPONENT__DEPENDENT_DETAIL,
  PATH_COMPONENT__DEPENDENT_RELATIONSHIP_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  PATH_COMPONENT__DEPENDENT_UNQUALIFIED,
  SLUG__DEPENDENT_UNQUALIFIED,
  SLUG__SELF_ID,
  PATH_COMPONENT__SELF_ID,
  PATH_COMPONENT__SELF_ID_INFO,
  SLUG__SELF_ID_INFO,
  PATH_COMPONENT__SPOUSE_ID,
  SLUG__SPOUSE_ID,
  PATH_COMPONENT__SPOUSE_ID_INFO,
  SLUG__SPOUSE_ID_INFO,
  ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME,
  PATH_COMPONENT__DEPENDENT_QUALIFIED,
  SLUG__DEPENDENT_QUALIFIED,
  SLUG__DEPENDENTS,
  PATH_COMPONENT__DEPENDENTS,
  ENDPOINT_ATTRIBUTE__SELF_SSN,
  ENDPOINT_ATTRIBUTE__SPOUSE_SSN,
  PATH_COMPONENT__SELF_DUE_DILIGENCE_INFO,
  SLUG__SELF_DUE_DILIGENCE_INFO,
  PATH_COMPONENT__SPOUSE_DUE_DILIGENCE_INFO,
  SLUG__SPOUSE_DUE_DILIGENCE_INFO,
  PATH_COMPONENT__SELF_MARRIAGE,
  SLUG__SELF_MARRIAGE,
  PATH_COMPONENT__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_TYPE,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { isItin } from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextQuery = ({ question, answer }) => {
  if (question.slug === SLUG__DEPENDENTS && _.get(answer, ['value']) === '1') {
    return {
      collectionId: '1'
    };
  } else {
    return {};
  }
};

export const getNextPathComponentMap = () => {
  return {
    [SLUG__SELF_DUE_DILIGENCE_INFO]: {
      nextPathComponent: PATH_COMPONENT__SELF_ID,
      questionnaireEarlyExit: false
    },
    [SLUG__SELF_ID_INFO]: {
      nextPathComponent: PATH_COMPONENT__SELF_EXIT_BOUNCE,
      questionnaireEarlyExit: true
    },
    [SLUG__SPOUSE_DUE_DILIGENCE_INFO]: {
      nextPathComponent: PATH_COMPONENT__SPOUSE_ID,
      questionnaireEarlyExit: false
    },
    [SLUG__SPOUSE_ID_INFO]: {
      nextPathComponent: PATH_COMPONENT__SPOUSE_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__SPOUSE_EXIT_BOUNCE]: {
      questionnaireEarlyExit: true
    },
    [SLUG__HOME_ADDRESS_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__HOME_ADDRESS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__HOME_ADDRESS_EXIT_BOUNCE]: {
      questionnaireEarlyExit: true
    },
    [SLUG__DEPENDENT_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__DEPENDENT_RELATIONSHIP_DETAIL,
      questionnaireEarlyExit: false
    },
    [SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__DEPENDENT_QUALIFIED,
      questionnaireEarlyExit: false
    },
    [SLUG__DEPENDENT_UNQUALIFIED]: {
      nextPathComponent: PATH_COMPONENT__DEPENDENT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    }
  };
};
export const getNextPathComponent = ({ question, answer }) => {
  if (question.slug === SLUG__SELF_DETAIL) {
    const selfItin = isItin(_.get(answer, ['value', ENDPOINT_ATTRIBUTE__SELF_SSN, 'value']));

    if (selfItin) {
      return { nextPathComponent: PATH_COMPONENT__SELF_DUE_DILIGENCE_INFO };
    }
    return { nextPathComponent: PATH_COMPONENT__SELF_ID };
  }
  if (question.slug === SLUG__SPOUSE_DETAIL) {
    const spouseUsingItin = isItin(_.get(answer, ['value', 'spouse-ssn', 'value']));
    if (spouseUsingItin) {
      return { nextPathComponent: PATH_COMPONENT__SPOUSE_DUE_DILIGENCE_INFO };
    }
    return { nextPathComponent: PATH_COMPONENT__SPOUSE_ID };
  }
  if (question.slug === SLUG__DEPENDENT_RELATIONSHIP_DETAIL) {
    const relationship = _.get(answer, ['value', SLUG__DEPENDENT_RELATIONSHIP_TYPE, 'value']);
    if (['daughter', 'son'].includes(relationship)) {
      return { nextPathComponent: PATH_COMPONENT__DEPENDENT_QUALIFIED };
    }
    return { nextPathComponent: PATH_COMPONENT__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL };
  }

  if (question.slug === SLUG__SELF_MARRIAGE) {
    return _.get(answer, ['value', ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS, 'value']) === '1'
      ? {
          nextPathComponent: PATH_COMPONENT__SPOUSE_DETAIL
        }
      : { nextPathComponent: PATH_COMPONENT__SELF_EXIT_BOUNCE };
  }
  return { nextPathComponent: null };
};

export const getOptionPathComponentMap = () => {
  return {
    [SLUG__SELF_ID]: {
      0: {
        nextPathComponent: PATH_COMPONENT__SELF_EXIT_BOUNCE,
        questionnaireEarlyExit: true
      },

      1: {
        nextPathComponent: PATH_COMPONENT__SELF_ID_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__SPOUSE_ID]: {
      0: {
        nextPathComponent: PATH_COMPONENT__SPOUSE_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__SPOUSE_ID_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__DEPENDENTS]: {
      0: {
        nextPathComponent: PATH_COMPONENT__DEPENDENT_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__DEPENDENT_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__DEPENDENT_QUALIFIED]: {
      0: {
        nextPathComponent: PATH_COMPONENT__DEPENDENT_UNQUALIFIED,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__DEPENDENT_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      }
    }
  };
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__SELF_DETAIL]: SLUG__SELF_DETAIL,
    [PATH_COMPONENT__SELF_MARRIAGE]: SLUG__SELF_MARRIAGE,
    [PATH_COMPONENT__SELF_ID]: SLUG__SELF_ID,
    [PATH_COMPONENT__SELF_ID_INFO]: SLUG__SELF_ID_INFO,
    [PATH_COMPONENT__SELF_DUE_DILIGENCE_INFO]: SLUG__SELF_DUE_DILIGENCE_INFO,
    [PATH_COMPONENT__SPOUSE_DUE_DILIGENCE_INFO]: SLUG__SPOUSE_DUE_DILIGENCE_INFO,
    [PATH_COMPONENT__SELF_EXIT_BOUNCE]: SLUG__SELF_EXIT_BOUNCE,
    [PATH_COMPONENT__SPOUSE_DETAIL]: SLUG__SPOUSE_DETAIL,
    [PATH_COMPONENT__SPOUSE_ID]: SLUG__SPOUSE_ID,
    [PATH_COMPONENT__SPOUSE_ID_INFO]: SLUG__SPOUSE_ID_INFO,
    [PATH_COMPONENT__SPOUSE_EXIT_BOUNCE]: SLUG__SPOUSE_EXIT_BOUNCE,
    [PATH_COMPONENT__HOME_ADDRESS_DETAIL]: SLUG__HOME_ADDRESS_DETAIL,
    [PATH_COMPONENT__HOME_ADDRESS_EXIT_BOUNCE]: SLUG__HOME_ADDRESS_EXIT_BOUNCE,
    [PATH_COMPONENT__DEPENDENTS]: SLUG__DEPENDENTS,
    [PATH_COMPONENT__DEPENDENT_DETAIL]: SLUG__DEPENDENT_DETAIL,
    [PATH_COMPONENT__DEPENDENT_RELATIONSHIP_DETAIL]: SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
    [PATH_COMPONENT__DEPENDENT_QUALIFIED]: SLUG__DEPENDENT_QUALIFIED,
    [PATH_COMPONENT__DEPENDENT_UNQUALIFIED]: SLUG__DEPENDENT_UNQUALIFIED,
    [PATH_COMPONENT__DEPENDENT_EXIT_BOUNCE]: SLUG__DEPENDENT_EXIT_BOUNCE,
    [PATH_COMPONENT__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL]: SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL
  };
};

export const getQuestionQueries = ({ question, collectionId }) => {
  if (
    [
      SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
      SLUG__DEPENDENT_QUALIFIED,
      SLUG__DEPENDENT_UNQUALIFIED,
      SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL
    ].includes(question.slug)
  ) {
    return [
      {
        coll_type: COLLECTION_TYPE__DEPENDENT,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
      }
    ];
  } else if (question.slug === SLUG__SPOUSE_ID || question.slug === SLUG__SPOUSE_ID_INFO) {
    return [
      {
        coll_type: COLLECTION_TYPE__SPOUSE,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
      }
    ];
  } else if (question.slug === SLUG__CREDIT_CHILD_CARE_DETAIL) {
    return [
      {
        coll_type: COLLECTION_TYPE__SELF,
        coll_id: '0',
        slug: ENDPOINT_ATTRIBUTE__SELF_SSN
      },
      {
        coll_type: COLLECTION_TYPE__SPOUSE,
        coll_id: '0',
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_SSN
      }
    ];
  } else {
    return [];
  }
};
