import React from 'react';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ResponseText from '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText';
import TaxSavingsEstimate from '@app/src/Components/HolisticOnboarding/components/TaxSavingsEstimate/TaxSavingsEstimate';
import { loadingSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const OnboardingDeductionEstimate = ({ loading }) => {
  const {
    location: { pathname }
  } = useHistory();

  return (
    <Stack spacing={1}>
      <TaxSavingsEstimate loading={loading} />
      <ResponseText pathname={pathname} visible />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  loading: loadingSelector(state)
});

const ConnectedOnboardingDeductionEstimate = connect(mapStateToProps)(OnboardingDeductionEstimate);

export default ConnectedOnboardingDeductionEstimate;
