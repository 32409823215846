import React from 'react';
import _ from 'lodash';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import NumberFormatCustom from '@app/src/Components/Common/NumberFormatCustom/NumberFormatCustom';
import {
  setHolisticOnboarding1099Income,
  setHolisticOnboardingW2Income,
  setHolisticOnboardingPartner1099Income,
  setHolisticOnboardingPartnerW2Income
} from '@app/src/actions/holisticOnboardingActions';
import {
  income1099Selector,
  incomeW2Selector,
  partner1099IncomeSelector,
  partnerW2IncomeSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { getLocalStorage, getStringSubstitutions } from '@app/src/utils/holisticOnboardingUtils';

const OnboardingIncomeEstimate = ({
  income1099amount,
  incomeW2amount,
  partnerIncome1099,
  partnerIncomeW2,
  setHolisticOnboarding1099Income,
  setHolisticOnboardingPartner1099Income,
  setHolisticOnboardingPartnerW2Income,
  setHolisticOnboardingW2Income,
  getStringSubstitutions
}) => {
  const handleUpdateIncome = ({ e, value, name }) => {
    const amount = Number(value ?? e.value);

    switch (name) {
      case 'W2':
        setHolisticOnboardingW2Income(amount);
        break;
      case '1099':
        setHolisticOnboarding1099Income(amount);
        break;
      case 'partnerW2':
        setHolisticOnboardingPartnerW2Income(amount);
        break;
      case 'partner1099':
        setHolisticOnboardingPartner1099Income(amount);
        break;
      default:
        break;
    }
  };

  const localStorage = JSON.parse(getLocalStorage('onboardingState'));

  const isMarried = _.get(localStorage, 'married', '') === 'married';
  const hasW2Income = _.get(localStorage, 'incomeType', []).includes('salaried');
  const has1099Income = _.get(localStorage, 'incomeType', []).includes('contractor');
  const isOwner = _.get(localStorage, 'incomeType', []).includes('owner');

  return (
    <Stack direction='column' spacing={2}>
      {hasW2Income && (
        <div>
          <label className='h-onboarding-content-question-label' htmlFor='incomeW2amount'>
            My employment income (W-2)
          </label>
          <OutlinedInput
            id='incomeW2amount'
            name='incomeW2amount'
            fullWidth
            value={incomeW2amount}
            onChange={(e, value) => handleUpdateIncome({ e, value, name: 'W2' })}
            inputComponent={NumberFormatCustom}
            startAdornment={incomeW2amount ? '$' : null}
            placeholder='e.g. $50,000'
          />
        </div>
      )}
      {(has1099Income || isOwner) && (
        <div>
          <label className='h-onboarding-content-question-label' htmlFor='income1099amount'>
            {getStringSubstitutions('My {work} income')}
          </label>
          <OutlinedInput
            id='income1099amount'
            name='income1099amount'
            fullWidth
            value={income1099amount}
            onChange={(e, value) => handleUpdateIncome({ e, value, name: '1099' })}
            inputComponent={NumberFormatCustom}
            startAdornment={income1099amount ? '$' : null}
            placeholder='e.g. $50,000'
          />
        </div>
      )}
      {isMarried && (
        <>
          <div>
            <label className='h-onboarding-content-question-label' htmlFor='partnerIncomeW2'>
              My spouse's employment income (W-2)
            </label>
            <OutlinedInput
              id='partnerIncomeW2'
              name='partnerIncomeW2'
              fullWidth
              value={partnerIncomeW2}
              onChange={(e, value) => handleUpdateIncome({ e, value, name: 'partnerW2' })}
              inputComponent={NumberFormatCustom}
              startAdornment={partnerIncomeW2 ? '$' : null}
              placeholder='e.g. $50,000'
            />
          </div>
          <div>
            <div>
              <div className='h-onboarding-content-question-label'>
                {getStringSubstitutions("My spouse's {work} income")}
              </div>
              <OutlinedInput
                fullWidth
                value={partnerIncome1099}
                onChange={(e, value) => handleUpdateIncome({ e, value, name: 'partner1099' })}
                inputComponent={NumberFormatCustom}
                startAdornment={partnerIncome1099 ? '$' : null}
                placeholder='e.g. $50,000'
              />
            </div>
          </div>
        </>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  income1099amount: income1099Selector(state),
  incomeW2amount: incomeW2Selector(state),
  partnerIncome1099: partner1099IncomeSelector(state),
  partnerIncomeW2: partnerW2IncomeSelector(state)
});

const mapDispatchToProps = {
  getStringSubstitutions,
  setHolisticOnboarding1099Income,
  setHolisticOnboardingW2Income,
  setHolisticOnboardingPartner1099Income,
  setHolisticOnboardingPartnerW2Income
};

const ConnectedOnboardingIncomeEstimate = connect(mapStateToProps, mapDispatchToProps)(OnboardingIncomeEstimate);

export default ConnectedOnboardingIncomeEstimate;
