import _ from 'lodash';

const getChargeableAccounts = ({ bankLinks }) => {
  return _.flatMap(
    bankLinks.filter((bankLink) => bankLink.linked),
    (bankLink) => {
      return bankLink.subAccounts
        .filter((subAccount) => {
          return !_.isNil(subAccount.cust_id) && subAccount.cust_id !== 'null' && subAccount.hasAccountNumbers;
        })
        .map((subAccount) => {
          const mask = _.get(subAccount, 'mask');

          return {
            account_id: subAccount.account_id,
            institution_name: bankLink.institution_name,
            institution_id: bankLink.institution_id,
            name: subAccount.name,
            charged_acct: subAccount.charged_acct,
            access_token: subAccount.access_token,
            mask: mask && mask.padStart(4, 0)
          };
        });
    }
  );
};

export default getChargeableAccounts;
