import {
  Url_ONBOARDING_DURATION,
  Url_ONBOARDING_ESTIMATE_REFUND,
  Url_ONBOARDING_HOME,
  Url_ONBOARDING_INCOME_TYPE,
  Url_ONBOARDING_JOB_SELECT,
  Url_ONBOARDING_MEAL,
  Url_ONBOARDING_TRANSPORT,
  Url_ONBOARDING_TRAVEL,
  Url_ONBOARDING_WRITE_OFFS,
  Url_SIGNUP
} from '@app/src/constants/onboardingConstants';

export const isOnboardingQuestion = (question) => {
  const { flow, slug } = question;
  const onboardingQuestionSlugs = [
    Url_ONBOARDING_DURATION,
    Url_ONBOARDING_ESTIMATE_REFUND,
    Url_ONBOARDING_INCOME_TYPE,
    Url_ONBOARDING_HOME,
    Url_ONBOARDING_JOB_SELECT,
    Url_ONBOARDING_MEAL,
    Url_ONBOARDING_TRANSPORT,
    Url_ONBOARDING_TRAVEL,
    Url_ONBOARDING_WRITE_OFFS,
    Url_SIGNUP
  ];

  return flow === 'onboarding' || onboardingQuestionSlugs.includes(slug);
};
