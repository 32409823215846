import _ from 'lodash';
import { useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import {
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { NO_INCOME_TAX_STATES } from '@app/src/taxflow/shared/constants/sharedConstants';

export const useSubstitutedText = ({ text }) => {
  const { data: stateTaxData, isLoading: isStateTaxDataLoading } = useGetTaxDataQuery({
    collectionType: COLLECTION_TYPE__STATE_RETURN
  });

  if (isStateTaxDataLoading) {
    return { isLoading: true, substitutedText: null };
  }

  const states = _.chain(stateTaxData).filter({ slug: ENDPOINT_ATTRIBUTE__STATE_RETURN }).map('value').value();
  const taxableStates = _.filter(states, (state) => !NO_INCOME_TAX_STATES.has(state));
  const taxEntities = ['the IRS', ...taxableStates];
  const irsAndState = _.isEmpty(taxableStates)
    ? 'the IRS'
    : `${_.dropRight(taxEntities).join(', ')} and ${_.last(taxEntities)}`;

  const substitutedText = _.replace(text, '{irsAndState}', irsAndState);
  return { isLoading: false, substitutedText };
};
