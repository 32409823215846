import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import OnboardingQuestion from '@app/src/Components/Onboarding/Question/OnboardingQuestion';
import { getVerify, updateAnswer } from '@app/src/services/onboardingService';

const OnboardingVerify = ({ history, location, getVerify, updateAnswer }) => {
  useEffect(() => {
    getVerify({ history, location });
  }, [getVerify, history, location]);

  const onNext = async () => {
    await updateAnswer({ history });
  };

  return <OnboardingQuestion onNext={onNext} />;
};

const mapDispatchToProps = {
  getVerify,
  updateAnswer
};

const ConnectedOnboardingVerify = connect(null, mapDispatchToProps)(OnboardingVerify);

export default ConnectedOnboardingVerify;
