import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';

const PhoneField = forwardRef(({ error, mask, name, onChange, InputProps, ...props }, ref) => (
  <TextField
    fullWidth
    required
    error={!!error}
    InputProps={{
      inputComponent: NumberFormatCustom,
      inputProps: {
        ref,
        className: 'dd-privacy-mask-user-input',
        'data-testid': name,
        format: mask,
        allowEmptyFormatting: true,
        mask: '_',
        onValueChange: (values) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }
      },
      ...InputProps
    }}
    {...props}
  />
));

const NumberFormatCustom = forwardRef((props, ref) => {
  return <NumberFormat getInputRef={ref} {...props} />;
});

export default PhoneField;
