import _ from 'lodash';
import capitalize from 'capitalize';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_TAXFLOW_SYSTEM,
  CATEGORY_TYPE_NAVIGATION,
  CATEGORY_TYPE_SUMMARY,
  CATEGORY_TYPE_BOUNCE,
  CATEGORY_TYPE_TEXT,
  CATEGORY_TYPE_TAXFLOW_FORM_EMAIL,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TAXFLOW_FORM_SSN,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_INFO,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_INITIAL,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_YEARS,
  CATEGORY_TYPE_PERCENT,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_EIN,
  CATEGORY_TYPE_SSN_OR_EIN,
  CATEGORY_TYPE_ZIP,
  CATEGORY_TYPE_INT,
  CATEGORY_TYPE_TEMPLATE,
  DEFAULT_WORK,
  CATEGORY_TYPE_BUSINESS_CODE,
  CATEGORY_TYPE_TAXFLOW_FORM_MULTI,
  WHO_1099,
  CATEGORY_TYPE_TAXFLOW_ADDRESS
} from '@app/src/constants/constants';
import { currencyWith0DecimalPlaces, isReactNative } from '@app/src/global/Helpers';
import { setUserWithObj } from '@app/src/services/analyticsService';
import { getFormUploadEnabledMap } from '@app/src/taxflow/collection/utils/collectionUtils';
import { deserializeQuestionAnswer, serializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import { getQuestionQueries as getQuestionQueriesNavigation } from '@app/src/taxflow/navigation/utils/navigationUtils';
import { CAR_ENDPOINT_ATTRIBUTES, CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import {
  getSlugMap as getSlugMapCar,
  getQuestionQueries as getQuestionQueriesCar
} from '@app/src/taxflow/sections/car/carUtils';
import {
  SLUG__CREDIT_HSA_DETAIL,
  SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  SLUG__CREDIT_STUDENT_LOAN_DETAIL,
  SLUG__CREDIT_CHILD_CARE_DETAIL,
  COLLECTION_TYPE__CREDIT_HEALTHCARE,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
  SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_DETAIL,
  COLLECTION_TYPE__CREDIT_STANDARD,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_HEALTH_INSURANCE,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MEDICAL,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MORTGAGE_INTEREST,
  COLLECTION_TYPE__CREDIT_CHARITY,
  SLUG__CREDIT_STANDARD_ITEMIZED,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_COLLECTION_TYPE_STARTED,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_STARTED,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_DONE,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_STANDARD_RESULT,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_DISALLOWED_NAMES,
  SLUG__CREDIT_DISALLOWED,
  COLLECTION_TYPE__CREDIT_DISALLOWED,
  SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_MONTHLY_PREMIUM,
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_STATE_REAL_ESTATE,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_GAMBLING_LOSSES,
  SLUG__CREDIT_HEALTH_PLAN,
  COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
  SLUG__CREDIT_HSA_DETAIL_FAMILY
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  getSlugMap as getSlugMapCredit,
  getQuestionQueries as getQuestionQueriesCredit
} from '@app/src/taxflow/sections/credit/utils/creditUtils';
import {
  HOME_SLUGS,
  COLLECTION_TYPE__HOME,
  HOME_ENDPOINT_ATTRIBUTES
} from '@app/src/taxflow/sections/home/homeConstants';
import {
  getSlugMap as getSlugMapHome,
  getQuestionQueries as getQuestionQueriesHome
} from '@app/src/taxflow/sections/home/homeUtils';
import {
  SLUG__INCOME_FREELANCE_AGI,
  SLUG__INCOME_FREELANCE_1099_NEC,
  SLUG__INCOME_FREELANCE_1099_MISC,
  SLUG__INCOME_W2_INFO,
  SLUG__INCOME_UNEMPLOYMENT_INFO,
  SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD,
  SLUG__INCOME_INTEREST_INFO,
  SLUG__INCOME_INTEREST_FORM_UPLOAD,
  SLUG__INCOME_DIV_INFO,
  SLUG__INCOME_DIV_FORM_UPLOAD,
  SLUG__INCOME_INVEST_INFO,
  SLUG__INCOME_INVEST_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO,
  COLLECTION_TYPE__INCOME_FREELANCE,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_JOB_NAME,
  SLUG__INCOME_W2_TWELVE_A,
  SLUG__INCOME_W2_TWELVE_B,
  SLUG__INCOME_W2_TWELVE_C,
  SLUG__INCOME_W2_TWELVE_D,
  SLUG__INCOME_W2_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_SSA_INFO,
  SLUG__INCOME_RETIREMENT_TYPE,
  SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_PENSION_INFO,
  SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
  COLLECTION_TYPE__INCOME_INVEST,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME,
  SLUG__INCOME_W2_PREFILL_LOADING,
  SLUG__INCOME_INVEST_MORE_INFO,
  SLUG__INCOME_INVEST_UNIFICATION,
  PATH_COMPONENT__INCOME_INVEST_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
  SLUG__INCOME_FREELANCE_1099K_INFO,
  SLUG__INCOME_FREELANCE_PAYERS_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED,
  SLUG__INCOME_INVEST_CATEGORY,
  SLUG__INCOME_RETIREMENT_PENSION_ROTH,
  SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR,
  SLUG__INCOME_FREELANCE_1099_TYPE,
  SLUG__INCOME_FREELANCE_JOB,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_INDUSTRY,
  SLUG__INCOME_FREELANCE_WHO
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  getSlugMap as getSlugMapIncome,
  getQuestionQueries as getQuestionQueriesIncome
} from '@app/src/taxflow/sections/income/utils/incomeUtils';
import {
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
  SLUG__DEPENDENT_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
  COLLECTION_TYPE__HOME_ADDRESS,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE,
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  SLUG__DEPENDENT_UNQUALIFIED,
  COLLECTION_TYPE__SPOUSE,
  ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME,
  SLUG__DEPENDENT_QUALIFIED,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__SELF_FIRST_NAME,
  SLUG__SELF_LAST_NAME,
  SLUG__SELF_MARRIAGE,
  SLUG__SELF_MARRIAGE_STATUS,
  SLUG__SELF_MARRIAGE_FILING_OPTION,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION,
  ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED,
  ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED,
  SLUG__SELF_TAX_STATUS,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL,
  SLUG__DEPENDENTS,
  SLUG__HOME_ADDRESS_STATE_MULTISTATE,
  SLUG__HOME_ADDRESS_STATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  getSlugMap as getSlugMapPersonal,
  getQuestionQueries as getQuestionQueriesPersonal
} from '@app/src/taxflow/sections/personal/utils/personalUtils';
import {
  BULK_UPLOAD_FORM_PREDICT_QUESTIONS,
  SLUG__BULK_UPLOAD_CHARITY_OPTION,
  SLUG__BULK_UPLOAD_HAS_CHARITY,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  getSlugMap as getSlugMapSpecial,
  getQuestionQueries as getQuestionQueriesSpecial
} from '@app/src/taxflow/sections/special/utils/specialUtils';
import {
  getQuestionQueries as getQuestionQueriesState,
  getSlugMap as getSlugMapState
} from '@app/src/taxflow/sections/state';
import {
  SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
  COLLECTION_TYPE__STATE_INCOME,
  COLLECTION_TYPE__STATE_RESIDENCY,
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_INCOME,
  SLUG__STATE_RESIDENCY,
  SLUG__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED,
  SLUG__STATE_DEFAULT,
  SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
  SLUG__DC_RENT_CREDIT_OPTIONS,
  SLUG__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_EXPENSES,
  SLUG__PA_USE_TAX_PURCHASES,
  ENDPOINT_ATTRIBUTE__STATE_RETURN_STARTED,
  SLUG__TEMPLATE_STATE_DONE,
  ENDPOINT_ATTRIBUTE__STATE_RETURN_DONE,
  SLUG__STATE_NO_INCOME_TAX,
  SLUG__NY_COUNTY,
  SLUG__NY_SCHOOL_DISTRICT
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { getStateReturnCollId } from '@app/src/taxflow/sections/state/utils/stateUtils';
import { getSlugMap as getSlugMapSubmit } from '@app/src/taxflow/sections/submit';
import {
  SLUG__SUBMIT_DEBIT,
  COLLECTION_TYPE__SUBMIT_DEBIT,
  ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_CONFIRM_ID
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { getQuestionQueries as getQuestionQueriesSubmit } from '@app/src/taxflow/sections/submit/utils/submitUtils';
import {
  DEFAULT_COLLECTION_ID,
  COLLECTION_TYPE__REVIEW,
  ENDPOINT_ATTRIBUTE__REVIEW_STARTED,
  TAX_FILING_YEAR,
  STATE_NAME_MAP,
  SLUG__SETTINGS,
  PATH_COMPONENT__CONTACT_SUPPORT,
  SLUG__CONTACT_SUPPORT,
  PATH_COMPONENT__PAST_RETURNS,
  SLUG__PAST_RETURNS,
  PATH_COMPONENT__SETTINGS,
  NO_INCOME_TAX_STATES,
  PATH_COMPONENT__SWITCH_TO_DESKTOP,
  SLUG__SWITCH_TO_DESKTOP,
  PATH_COMPONENT__LINKED_ACCOUNTS,
  SLUG__LINKED_ACCOUNTS,
  LAST_TAX_FILING_YEAR
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  getKnownJobInfo,
  getFilingStatus,
  getQueryResultByEndpointAttribute,
  getQueryResultValueByEndpointAttribute,
  getQueryResultsByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';
import { capitalizeFirstLetter } from '@app/src/utils/commonUtils';

const getSlugMapNavigation = () => {
  return {
    [PATH_COMPONENT__CONTACT_SUPPORT]: SLUG__CONTACT_SUPPORT,
    [PATH_COMPONENT__PAST_RETURNS]: SLUG__PAST_RETURNS,
    [PATH_COMPONENT__SWITCH_TO_DESKTOP]: SLUG__SWITCH_TO_DESKTOP
  };
};

export const getSlugByLocation = (location) => {
  if (_.get(location, 'pathname') === `/${PATH_COMPONENT__SETTINGS}`) {
    return SLUG__SETTINGS;
  } else if (_.get(location, 'pathname') === `/${PATH_COMPONENT__LINKED_ACCOUNTS}`) {
    return SLUG__LINKED_ACCOUNTS;
  }

  const pathComponents = location.pathname.split('/');
  const primaryPathComponent = pathComponents[2];

  const pathComponentSlugMap = {
    ...getSlugMapCar(),
    ...getSlugMapCredit(),
    ...getSlugMapHome(),
    ...getSlugMapIncome(),
    ...getSlugMapPersonal(),
    ...getSlugMapSpecial(),
    ...getSlugMapSubmit(),
    ...getSlugMapState(),
    ...getSlugMapNavigation()
  };
  return _.get(pathComponentSlugMap, [primaryPathComponent], null);
};

export const getPathComponentBySlug = (slug) => {
  const map = {
    ...getSlugMapCar(),
    ...getSlugMapCredit(),
    ...getSlugMapHome(),
    ...getSlugMapIncome(),
    ...getSlugMapPersonal(),
    ...getSlugMapSpecial(),
    ...getSlugMapSubmit(),
    ...getSlugMapState(),
    ...getSlugMapNavigation()
  };
  const inverted = _.invert(map);
  return _.get(inverted, slug, null);
};

const getSummaryQueries = ({ question }) => {
  if (question.question_type !== CATEGORY_TYPE_SUMMARY || !_.get(question, ['question_meta', 'collectionTypes'])) {
    return [];
  }

  const collectionTypes = question.question_meta.collectionTypes;

  return _.flatMap(collectionTypes, (collectionType) => {
    return [
      {
        coll_type: collectionType.type,
        slug: collectionType.startedEndpointAttribute
      },
      ...(collectionType.displayNameEndpointAttribute
        ? [
            {
              coll_type: collectionType.type,
              slug: collectionType.displayNameEndpointAttribute
            }
          ]
        : []),
      {
        coll_type: collectionType.type,
        slug: collectionType.collectionTypeStartedEndpointAttribute
      }
    ];
  });
};

export const getQuestionQueries = ({ question, collectionId, allCollectionTypes }) => {
  let newQueries = [];
  if (question.slug === SLUG__STATE_RESIDENCY) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__SELF,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
      },
      {
        coll_type: COLLECTION_TYPE__STATE_RESIDENCY,
        coll_id: DEFAULT_COLLECTION_ID
      }
    ];
  } else if (question.slug === SLUG__STATE_INCOME) {
    const jobTypes = [
      'income-freelance',
      'income-1099g',
      'income-interest',
      'income-div',
      'income-invest',
      'income-retirement'
    ];
    newQueries = jobTypes.reduce(
      (queries, jobType) => {
        let nameSlug, incomeSlug;
        if (jobType === 'income-freelance') {
          nameSlug = 'job-name';
          incomeSlug = 'income';
        } else if (jobType === 'income-1099g') {
          nameSlug = 'payer-name';
          incomeSlug = 'compensation';
        } else if (jobType === 'income-interest') {
          nameSlug = 'payer-name';
          incomeSlug = 'amount';
        } else if (jobType === 'income-div') {
          nameSlug = 'payer-name';
          incomeSlug = 'ordinary';
        } else if (jobType === 'income-invest') {
          return [
            ...queries,
            ...[
              'short-term-sale-proceeds',
              'short-term-cost-basis',
              'long-term-sale-proceeds',
              'long-term-cost-basis',
              'unknown-term-sale-proceeds',
              'unknown-term-cost-basis',
              'description',
              'unification'
            ].map((slugSuffix) => ({
              coll_type: jobType,
              slug: `${jobType}-${slugSuffix}`
            }))
          ];
        } else {
          return [
            ...queries,
            {
              coll_type: jobType,
              slug: `${jobType}-type`
            },
            {
              coll_type: jobType,
              slug: `${jobType}-ssa-net-benefits`
            },
            {
              coll_type: jobType,
              slug: `${jobType}-pension-gross-distribution`
            }
          ];
        }

        return [
          ...queries,
          {
            coll_type: jobType,
            slug: `${jobType}-${nameSlug}`
          },
          {
            coll_type: jobType,
            slug: `${jobType}-${incomeSlug}`
          }
        ];
      },
      [
        {
          coll_type: COLLECTION_TYPE__STATE_INCOME
        }
      ]
    );
  } else if (question.slug === SLUG__STATE_EXPENSES) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__STATE_INCOME
      },
      {
        coll_type: COLLECTION_TYPE__STATE_EXPENSES
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME
      }
    ];
  } else if (question.slug === SLUG__NY_COUNTY) {
    newQueries = [
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr
      },
      { slug: SLUG__NY_SCHOOL_DISTRICT }
    ];
  } else if (question.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_INDUSTRY
      }
    ];
  } else if (question.slug === SLUG__CREDIT_HEALTH_PLAN) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED
      },
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr
      }
    ];
  } else if (
    question.question_type === CATEGORY_TYPE_TAXFLOW_INFO ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_SYSTEM ||
    question.question_type === CATEGORY_TYPE_SUMMARY
  ) {
    newQueries = [];
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    newQueries = question.sub_question.map((question) => ({
      coll_type: question.collectionType,
      coll_id: collectionId,
      slug: question.endpoint_attr
    }));
    if (question.slug === SLUG__HOME_ADDRESS_DETAIL) {
      newQueries = [
        ...newQueries,
        {
          coll_type: COLLECTION_TYPE__STATE_RETURN,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED
        }
      ];
    } else if (question.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
      newQueries = [
        ...newQueries,
        {
          coll_type: COLLECTION_TYPE__CREDIT_HEALTHCARE,
          slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_MONTHLY_PREMIUM
        },
        {
          coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
          slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
        },
        {
          coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
          slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT
        }
      ];
    }
  } else if (question.slug === SLUG__PA_USE_TAX_PURCHASES) {
    newQueries = [
      ...newQueries,
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME
      }
    ];
  } else if (question.question_type !== CATEGORY_TYPE_NAVIGATION) {
    newQueries = [
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr
      }
    ];
  }

  newQueries = [
    ...newQueries,
    // For tax status
    {
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS
    },
    {
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION
    },
    {
      coll_type: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED
    },
    {
      coll_type: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED
    },
    {
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
    },
    {
      coll_type: COLLECTION_TYPE__HOME_ADDRESS,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE
    },
    {
      coll_type: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    },
    {
      coll_type: COLLECTION_TYPE__REVIEW,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__REVIEW_STARTED
    },
    // Used for common string replacements (e.g. dynamic 1099 variables)
    {
      coll_type: COLLECTION_TYPE__SPOUSE,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
    },
    {
      coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
      slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME
    },
    {
      coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
      slug: SLUG__INCOME_FREELANCE_WHO
    },
    ...getSummaryQueries({ question, allCollectionTypes }),
    ...getQuestionQueriesNavigation({ question }),
    ...getQuestionQueriesCar({ question, collectionId }),
    ...getQuestionQueriesCredit({ question, collectionId }),
    ...getQuestionQueriesHome({ question, collectionId }),
    ...getQuestionQueriesIncome({ question, collectionId }),
    ...getQuestionQueriesPersonal({ question, collectionId }),
    ...getQuestionQueriesSpecial({ question }),
    ...getQuestionQueriesState({ question }),
    ...getQuestionQueriesSubmit({ question })
  ];

  return newQueries;
};

export const getQuestionQueryResults = ({ allQuestions, items }) => {
  return _.flatMap(items, (item) => {
    if (item.coll_type === COLLECTION_TYPE__STATE_INCOME || item.coll_type === COLLECTION_TYPE__STATE_EXPENSES) {
      if (item.slug.endsWith('started') || item.slug.endsWith('done')) {
        return {
          coll_type: item.coll_type,
          coll_id: item.coll_id,
          slug: item.slug,
          answer: {
            value: item.value
          }
        };
      } else {
        return {
          coll_type: item.coll_type,
          coll_id: item.coll_id,
          slug: item.slug,
          answer: {
            value: parseFloat(item.value)
          }
        };
      }
    }
    if (item.slug === SLUG__INCOME_INVEST_UNIFICATION) {
      return {
        coll_type: item.coll_type,
        coll_id: item.coll_id,
        slug: item.slug,
        answer: {
          value: JSON.parse(item.value)
        }
      };
    }
    if (item.coll_type === COLLECTION_TYPE__CAR && item.slug.startsWith(CAR_SLUGS.MILEAGE_SPECIFIC)) {
      return {
        coll_type: item.coll_type,
        coll_id: item.coll_id,
        slug: item.slug,
        answer: {
          value: parseFloat(item.value)
        }
      };
    }
    const questionForItem = getQuestionByEndpointAttribute({
      allQuestions,
      endpointAttribute: item.slug
    });
    if (!questionForItem) {
      return [];
    }
    return [
      {
        coll_type: item.coll_type,
        coll_id: item.coll_id,
        slug: item.slug,
        answer: deserializeQuestionAnswer({
          question: questionForItem,
          value: item.value
        })
      }
    ];
  });
};

// certain questions should be reformatted or don't get sent into database at all. that happens here
export const getQuestionUpdates = ({
  question,
  answer,
  queryResults,
  collectionType,
  collectionId,
  user,
  jobCategories,
  businessCode,
  bulkUploadItems
}) => {
  if (question.slug === CAR_SLUGS.NAV_START && Number(_.get(answer, 'value')) === 0) {
    return [];
  }

  if (question.slug === HOME_SLUGS.NAV_START && Number(_.get(answer, 'value')) === 0) {
    return [];
  }

  if (question.slug === HOME_SLUGS.PRESELECT_ADDRESS) {
    // If selecting a preselect address option, populate the street address and state if available
    const value = _.get(answer, 'value');
    if (value !== '0' && !_.isEmpty(value)) {
      const addressData = queryResults.filter(({ coll_type }) => coll_type === COLLECTION_TYPE__HOME_ADDRESS);
      const street = addressData.find((r) => _.get(r, 'answer.value') === value);
      const isMultistateAddress = street.slug.includes('multistate');
      const state = addressData.find(
        ({ slug }) => slug === (isMultistateAddress ? SLUG__HOME_ADDRESS_STATE_MULTISTATE : SLUG__HOME_ADDRESS_STATE)
      );
      return [
        {
          coll_type: COLLECTION_TYPE__HOME,
          coll_id: collectionId,
          slug: HOME_ENDPOINT_ATTRIBUTES.ADDRESS_STREET,
          value
        },
        {
          coll_type: COLLECTION_TYPE__HOME,
          coll_id: collectionId,
          slug: HOME_ENDPOINT_ATTRIBUTES.ADDRESS_STATE,
          value: _.get(state, 'answer.value')
        }
      ];
    }
    // Otherwise, ignore it
    return [];
  }

  if (question.slug === SLUG__SUBMIT_DEBIT) {
    return [
      {
        coll_type: COLLECTION_TYPE__SUBMIT_DEBIT,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD,
        value: _.get(answer, 'value')
      }
    ];
  }

  if (question.slug === SLUG__STATE_INCOME || question.slug === SLUG__STATE_EXPENSES) {
    const answerVal = _.get(answer, ['value']);
    const collectionType =
      question.slug === SLUG__STATE_INCOME ? COLLECTION_TYPE__STATE_INCOME : COLLECTION_TYPE__STATE_EXPENSES;
    const taxData = Object.keys(answerVal).map((subAnswer) => {
      return {
        coll_type: collectionType,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: _.get(answerVal, [subAnswer, 'slug']),
        value: _.get(answerVal, [subAnswer, 'value'])
      };
    });
    return taxData;
  }

  if (question.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
    return [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE,
        value: _.get(answer, ['value', SLUG__INCOME_FREELANCE_BUSINESS_CODE, 'value'])
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_INDUSTRY,
        value: _.get(answer, ['value', SLUG__INCOME_FREELANCE_INDUSTRY, 'value'])
      }
    ];
  }

  let updates = [];
  if (question.slug === SLUG__INCOME_INVEST_INFO) {
    const collectionIds = Object.keys(_.get(answer, 'value'));
    collectionIds.forEach((collId) => {
      updates = [
        ...updates,
        {
          coll_type: COLLECTION_TYPE__INCOME_INVEST,
          coll_id: collId,
          slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED,
          value: '1'
        },
        ..._.flatMap(question.sub_question, (subQuestion) => {
          const subAnswer = _.get(answer, ['value', collId, subQuestion.slug]);
          const serializedSubAnswer = serializeQuestionAnswer({ question: subQuestion, answer: subAnswer });

          if (_.isNil(serializedSubAnswer)) {
            return [
              {
                coll_type: subQuestion.collectionType,
                coll_id: collId,
                slug: subQuestion.endpoint_attr,
                value: '',
                error: true
              }
            ];
          } else {
            return [
              {
                coll_type: subQuestion.collectionType,
                coll_id: collId,
                slug: subQuestion.endpoint_attr,
                value: serializedSubAnswer
              }
            ];
          }
        })
      ];
    });

    const collIdsNumeric = collectionIds.map((collId) => Number(collId));
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__INCOME_INVEST,
        coll_id: Math.min(...collIdsNumeric),
        slug: SLUG__INCOME_INVEST_UNIFICATION,
        value: JSON.stringify(collIdsNumeric)
      }
    ];
    return updates;
  }

  if (question.question_type === CATEGORY_TYPE_BOUNCE) {
    updates = [
      {
        coll_type: question.collectionType,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: question.endpoint_attr,
        value: '1'
      }
    ];
  } else if (
    question.question_type === CATEGORY_TYPE_TAXFLOW_INFO ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_SYSTEM ||
    question.question_type === CATEGORY_TYPE_SUMMARY ||
    question.question_type === CATEGORY_TYPE_NAVIGATION
  ) {
    updates = [];
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    updates = _.flatMap(question.sub_question, (subQuestion) => {
      if (subQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_MULTI) {
        const subAnswerKeys = Object.keys(answer.value);
        return subAnswerKeys.map((key) => {
          return {
            coll_type: subQuestion.collectionType,
            coll_id: collectionId,
            slug: key,
            value: answer.value[key]?.value
          };
        });
      } else {
        const subAnswer = _.get(answer, ['value', subQuestion.slug]);
        const serializedSubAnswer = serializeQuestionAnswer({ question: subQuestion, answer: subAnswer });
        if (_.isNil(serializedSubAnswer)) {
          return [
            {
              coll_type: subQuestion.collectionType,
              coll_id: collectionId,
              slug: subQuestion.endpoint_attr,
              value: '',
              error: true
            }
          ];
        } else {
          return [
            {
              coll_type: subQuestion.collectionType,
              coll_id: collectionId,
              slug: subQuestion.endpoint_attr,
              value: serializedSubAnswer
            }
          ];
        }
      }
    });
  } else {
    updates = [
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr,
        value: serializeQuestionAnswer({ question, answer })
      }
    ];
  }

  // manually add state-return-started flags when user clicks next on state-return select screen
  if (question.slug === SLUG__STATE_RETURN) {
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_STARTED,
        value: '1'
      }
    ];
  }

  // if we have a business code associated with the job, overwrite business code
  if (question.slug === SLUG__INCOME_FREELANCE_JOB) {
    const jobName = _.get(answer, ['value', SLUG__INCOME_FREELANCE_JOB_NAME, 'value']);
    const jobCategory = jobCategories.find((j) => j.slug === jobName);
    const jobBusinessCode = (jobCategory && jobCategory.businessCode) || businessCode;
    if (jobBusinessCode) {
      updates = [
        ...updates,
        {
          coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
          coll_id: collectionId,
          slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE,
          value: businessCode
        }
      ];
    }
  }

  if (question.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_COLLECTION_TYPE_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT,
        value: _.get(answer, ['value', ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY, 'value'])
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_DONE,
        value: '1'
      }
    ];
  }

  if (question.slug === SLUG__CREDIT_STANDARD_RESULT) {
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_DEDUCTION,
        value: 'standard'
      }
    ];
  }

  const subType = _.get(
    {
      [SLUG__INCOME_FREELANCE_1099_MISC]: 'misc',
      [SLUG__INCOME_FREELANCE_1099_NEC]: 'nec',
      [SLUG__INCOME_FREELANCE_1099K_INFO]: 'k',
      [SLUG__INCOME_RETIREMENT_PENSION_INFO]: 'pension',
      [SLUG__INCOME_RETIREMENT_SSA_INFO]: 'ssa'
    },
    question.slug
  );

  if (!_.isNil(subType)) {
    const bulkUploadItem = _.find(bulkUploadItems, { collectionType, subType });
    if (
      !_.isNil(bulkUploadItem.subTypeDifferentiationSlug) &&
      !_.isNil(bulkUploadItem.subTypeDifferentiationSlugValue)
    ) {
      updates = [
        ...updates,
        {
          coll_type: collectionType,
          coll_id: collectionId,
          slug: bulkUploadItem.subTypeDifferentiationSlug,
          value: bulkUploadItem.subTypeDifferentiationSlugValue
        }
      ];
    }
  }

  const filingStatusSlugs = new Set([
    ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS,
    ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION,
    ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED
  ]);

  if (updates.some((update) => filingStatusSlugs.has(_.get(update, 'slug')))) {
    const filingStatus = getFilingStatus({ updates, queryResults });

    if (filingStatus) {
      updates = [
        ...updates,
        {
          coll_type: COLLECTION_TYPE__SELF,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
          value: filingStatus
        }
      ];
      setUserWithObj(user.phone, { tax_filing_status: filingStatus });
    }
  }

  return updates;
};

export const getQuestionDelete = ({ question, collectionId, answer, updates, queryResults, businessCode }) => {
  const deletions = [];

  if (BULK_UPLOAD_FORM_PREDICT_QUESTIONS.includes(question.slug)) {
    const hasCollection =
      question.slug === SLUG__BULK_UPLOAD_HAS_CHARITY
        ? _.get(answer, ['value', SLUG__BULK_UPLOAD_CHARITY_OPTION, 'value'])
        : _.get(answer, 'value');
    if (hasCollection === '0') {
      deletions.push(
        {
          coll_type: _.get(question, ['collectionTypeStarted', 'fields', 'collectionType']),
          coll_id: collectionId
        },
        {
          coll_type: _.get(question, ['collectionTypeStarted', 'fields', 'collectionType']),
          coll_id: DEFAULT_COLLECTION_ID,
          slug: _.get(question, ['collectionTypeStarted', 'fields', 'endpointAttribute'])
        }
      );
    }
  }

  if (question.slug === HOME_SLUGS.OPTIONS && _.get(answer, 'value') === 'free_housing') {
    deletions.push({
      coll_type: COLLECTION_TYPE__HOME,
      coll_id: collectionId
    });
  }

  if (question.slug === SLUG__DEPENDENT_UNQUALIFIED) {
    deletions.push({
      coll_type: COLLECTION_TYPE__DEPENDENT,
      coll_id: collectionId
    });
  }

  if (question.slug === SLUG__DEPENDENTS && _.get(answer, 'value') === '0') {
    deletions.push({
      coll_type: COLLECTION_TYPE__DEPENDENT,
      coll_id: '1'
    });
  }

  if (question.slug === SLUG__CREDIT_DISALLOWED && _.get(answer, 'value') === '0') {
    deletions.push({
      coll_type: COLLECTION_TYPE__CREDIT_DISALLOWED,
      coll_id: collectionId,
      slug: SLUG__CREDIT_DISALLOWED_NAMES
    });
  }

  if (question.slug === SLUG__SELF_MARRIAGE && _.get(answer, ['value', SLUG__SELF_MARRIAGE_STATUS, 'value']) === '0') {
    deletions.push({
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: collectionId,
      slug: SLUG__SELF_MARRIAGE_FILING_OPTION
    });

    deletions.push({
      coll_type: COLLECTION_TYPE__SPOUSE
    });
  }

  if (question.slug === SLUG__INCOME_FREELANCE_JOB) {
    if (!businessCode) {
      deletions.push({
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE
      });
      deletions.push({
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_INDUSTRY
      });
    }
  }
  const lastAnswer = getQueryResultValueByEndpointAttribute({
    queryResults,
    collectionType: question.collectionType,
    collectionId,
    slug: question.slug
  });

  if (
    question.slug === SLUG__CREDIT_STANDARD_RESULT ||
    (question.slug === SLUG__CREDIT_STANDARD_DEDUCTION && _.get(answer, 'value') !== lastAnswer)
  ) {
    deletions.push(
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_HEALTH_INSURANCE
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MEDICAL
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MORTGAGE_INTEREST
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_STATE_REAL_ESTATE
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_GAMBLING_LOSSES
      }
    );
  }

  const filingStatus = getFilingStatus({ updates, queryResults });

  if (_.isNil(filingStatus)) {
    deletions.push({
      coll_type: COLLECTION_TYPE__SELF,
      slug: SLUG__SELF_TAX_STATUS
    });
  }

  return deletions;
};

export const getStartedUpdates = ({ question, collectionId, stateTaxSlugs, queryResults }) => {
  // if screen is the first screen in a state return tax flow, then get the coll id of the state return
  // then manually add the state-return-started flag w the correct coll id
  const stateCollId = getStateReturnCollId({
    firstScreenSlug: question.slug,
    collectionId,
    queryResults,
    stateTaxSlugs
  });
  if (stateCollId) {
    return [
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: stateCollId,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED,
        value: '1'
      }
    ];
  }

  const incomeInvestUpdateSlug = getFormUploadEnabledMap()[PATH_COMPONENT__INCOME_INVEST_FORM_UPLOAD]
    ? SLUG__INCOME_INVEST_FORM_UPLOAD
    : SLUG__INCOME_INVEST_INFO;

  const updates = [
    ...(question.started
      ? [
          {
            coll_type: _.get(question, ['started', 'fields', 'collectionType']),
            coll_id: collectionId,
            slug: _.get(question, ['started', 'fields', 'endpointAttribute']),
            value: '1'
          }
        ]
      : []),
    ...(question.started && question.slug === incomeInvestUpdateSlug
      ? [
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: collectionId,
            slug: SLUG__INCOME_INVEST_CATEGORY,
            value: 'S'
          },
          {
            coll_type: _.get(question, ['started', 'fields', 'collectionType']),
            coll_id: `${Number(collectionId) + 1}`,
            slug: _.get(question, ['started', 'fields', 'endpointAttribute']),
            value: '1'
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: `${Number(collectionId) + 1}`,
            slug: SLUG__INCOME_INVEST_CATEGORY,
            value: 'L'
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: `${Number(collectionId) + 2}`,
            slug: SLUG__INCOME_INVEST_CATEGORY,
            value: 'U'
          },
          {
            coll_type: _.get(question, ['started', 'fields', 'collectionType']),
            coll_id: `${Number(collectionId) + 2}`,
            slug: _.get(question, ['started', 'fields', 'endpointAttribute']),
            value: '1'
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: collectionId,
            slug: SLUG__INCOME_INVEST_UNIFICATION,
            value: JSON.stringify([Number(collectionId), Number(collectionId) + 1, Number(collectionId) + 2])
          }
        ]
      : []),
    ...(question.collectionTypeStarted
      ? [
          {
            coll_type: _.get(question, ['collectionTypeStarted', 'fields', 'collectionType']),
            coll_id: DEFAULT_COLLECTION_ID,
            slug: _.get(question, ['collectionTypeStarted', 'fields', 'endpointAttribute']),
            value: '1'
          }
        ]
      : [])
  ];

  if (_.find(updates, { slug: ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED })) {
    const filingStatus = getFilingStatus({ updates, queryResults });

    return [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__SELF,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
        value: filingStatus
      }
    ];
  }

  return updates;
};

export const getDoneUpdates = ({ question, collectionId }) => {
  // for state returns that have specific flows
  if (_.get(question, ['template', 'slug']) === SLUG__TEMPLATE_STATE_DONE) {
    return [
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_DONE,
        value: '1'
      }
    ];
  }

  if (!question.done) {
    return [];
  }

  const collectionType = _.get(question, ['done', 'fields', 'collectionType']);

  // for state returns with no specific flows
  // (i.e. state returns that go to state-default / state-no-income-tax)
  if (collectionType === COLLECTION_TYPE__STATE_RETURN) {
    return [
      {
        coll_type: collectionType,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: _.get(question, ['done', 'fields', 'endpointAttribute']),
        value: '1'
      }
    ];
  }

  return [
    {
      coll_type: collectionType,
      coll_id: collectionId,
      slug: _.get(question, ['done', 'fields', 'endpointAttribute']),
      value: '1'
    }
  ];
};

export const getQuestionById = ({ allQuestions, slug }) => {
  return allQuestions.find((question) => {
    return question.slug === slug;
  });
};

export const getDerivedQuestion = ({ question, jobOptions }) => {
  if (question.slug === SLUG__INCOME_FREELANCE_JOB_NAME) {
    return {
      ...question,
      question_meta: jobOptions
    };
  }

  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM && question?.sub_question?.map) {
    return {
      ...question,
      sub_question: _.map(question.sub_question, (q) => getDerivedQuestion({ question: q, jobOptions }))
    };
  }

  return question;
};

const getQuestionByEndpointAttribute = ({ allQuestions, endpointAttribute }) => {
  return allQuestions.find((question) => question.endpoint_attr === endpointAttribute);
};

export const isQuestionAnswerEmpty = ({ question, answer }) => {
  const value = _.get(answer, ['value']);
  if (
    question.question_type === CATEGORY_TYPE_OPTIONS ||
    question.question_type === CATEGORY_TYPE_TEXT ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SLIDER ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_EMAIL ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PHONE ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SSN ||
    question.question_type === CATEGORY_TYPE_INITIAL ||
    question.question_type === CATEGORY_TYPE_MILES ||
    question.question_type === CATEGORY_TYPE_YEARS ||
    question.question_type === CATEGORY_TYPE_PERCENT ||
    question.question_type === CATEGORY_TYPE_SQUARE_FOOTAGE ||
    question.question_type === CATEGORY_TYPE_EIN ||
    question.question_type === CATEGORY_TYPE_SSN_OR_EIN ||
    question.question_type === CATEGORY_TYPE_ZIP ||
    question.question_type === CATEGORY_TYPE_INT ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_NUMBER ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN ||
    question.question_type === CATEGORY_TYPE_STATE ||
    question.question_type === CATEGORY_TYPE_MONEY ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_ADDRESS
  ) {
    if (_.isNumber(value) && !_.isNaN(value)) {
      return false;
    } else {
      return _.isEmpty(value);
    }
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR) {
    if (_.isEmpty(question.question_meta)) {
      return false;
    }

    return _.isEmpty(value);
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    return question.sub_question.every((subQuestion) => {
      const subAnswer = _.get(answer, ['value', subQuestion.slug]);
      return isQuestionAnswerEmpty({ question: subQuestion, answer: subAnswer });
    });
  } else if (question.slug === SLUG__SUBMIT_DEBIT) {
    return _.isEmpty(value);
  } else {
    return false;
  }
};

export const isTaxfilingNonskippable = (question) => {
  return [
    SLUG__SUBMIT_EMAIL_INFO,
    SLUG__STATE_RETURN,
    SLUG__SUBMIT_DEBIT,
    SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
    SLUG__DC_RENT_CREDIT_OPTIONS,
    SLUG__SUBMIT_SIGNATURE,
    SLUG__CREDIT_DISALLOWED_NAMES,
    SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
    SLUG__CREDIT_STUDENT_TUITION_QUALIFY
  ].includes(question.slug);
};

// if false then hide back button
export const isPrev = ({ slug }) => {
  if (slug === SLUG__SETTINGS || slug === SLUG__LINKED_ACCOUNTS) {
    return false;
  }

  return true;
};

export const showNextBtn = ({ slug }) => {
  if (slug === SLUG__SETTINGS || slug === SLUG__LINKED_ACCOUNTS) {
    return false;
  }

  return true;
};

export const getNextButtonLabel = ({ question }) => {
  return _.get(question, ['nextButtonLabel'], 'Next');
};

export const getAllowedParams = ({ slug, currentSlug }) => {
  if (
    currentSlug === SLUG__BULK_UPLOAD_MANUAL_ENTRY ||
    [
      CAR_SLUGS.BRAND,
      CAR_SLUGS.COST,
      CAR_SLUGS.DEPRECIATION_AMOUNT_SPECIFIC,
      CAR_SLUGS.DEPRECIATION_AMOUNT_ESTIMATE,
      CAR_SLUGS.DEPRECIATION_STRAIGHT_LINE,
      CAR_SLUGS.PAST_DEPRECIATION_KNOWN,
      CAR_SLUGS.LEASE,
      CAR_SLUGS.LOAN,
      CAR_SLUGS.NEW_USED,
      CAR_SLUGS.OPTIONS,
      CAR_SLUGS.PAST_DEPRECIATION,
      CAR_SLUGS.PURCHASE_DATA,
      CAR_SLUGS.SERVICE_DATE,
      CAR_SLUGS.YEARS_DEPRECIATION,
      HOME_SLUGS.ACQUIRED,
      HOME_SLUGS.ADDRESS,
      HOME_SLUGS.DEPRECIATION,
      HOME_SLUGS.PRIOR_DEPRECIATION,
      HOME_SLUGS.YEARS_DEPRECIATION,
      HOME_SLUGS.DATES,
      HOME_SLUGS.DONE,
      HOME_SLUGS.EXPENSES_MORTGAGE_INTEREST,
      HOME_SLUGS.EXPENSES_RENT,
      HOME_SLUGS.OFFICE_SIZE,
      HOME_SLUGS.OPTIONS,
      HOME_SLUGS.PAST_DEPRECIATION,
      HOME_SLUGS.PRESELECT_ADDRESS,
      HOME_SLUGS.TYPE,
      HOME_SLUGS.VALUE,
      SLUG__DEPENDENT_DETAIL,
      SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
      SLUG__DEPENDENT_QUALIFIED,
      SLUG__DEPENDENT_UNQUALIFIED,
      SLUG__INCOME_FREELANCE_1099_TYPE,
      SLUG__INCOME_FREELANCE_JOB,
      SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO,
      SLUG__INCOME_FREELANCE_AGI,
      SLUG__INCOME_FREELANCE_1099_NEC,
      SLUG__INCOME_FREELANCE_1099_MISC,
      SLUG__INCOME_W2_INFO,
      SLUG__INCOME_W2_FORM_UPLOAD,
      SLUG__INCOME_W2_PREFILL_LOADING,
      SLUG__INCOME_W2_TWELVE_A,
      SLUG__INCOME_W2_TWELVE_B,
      SLUG__INCOME_W2_TWELVE_C,
      SLUG__INCOME_W2_TWELVE_D,
      SLUG__INCOME_UNEMPLOYMENT_INFO,
      SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD,
      SLUG__INCOME_INTEREST_INFO,
      SLUG__INCOME_INTEREST_FORM_UPLOAD,
      SLUG__INCOME_DIV_INFO,
      SLUG__INCOME_DIV_FORM_UPLOAD,
      SLUG__INCOME_INVEST_INFO,
      SLUG__INCOME_INVEST_MORE_INFO,
      SLUG__INCOME_INVEST_FORM_UPLOAD,
      SLUG__CREDIT_HSA_DETAIL,
      SLUG__CREDIT_HSA_DETAIL_FAMILY,
      SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
      SLUG__CREDIT_STUDENT_LOAN_DETAIL,
      SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
      SLUG__CREDIT_STUDENT_TUITION_DETAIL,
      SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
      SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
      SLUG__CREDIT_CHILD_CARE_DETAIL,
      SLUG__CREDIT_HOMEOWNER_DETAIL,
      SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
      SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
      SLUG__STATE_RETURN,
      SLUG__STATE_DEFAULT,
      SLUG__STATE_NO_INCOME_TAX,
      SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__INCOME_RETIREMENT_TYPE,
      SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
      SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
      SLUG__INCOME_RETIREMENT_SSA_INFO,
      SLUG__INCOME_RETIREMENT_PENSION_INFO,
      SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
      SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
      SLUG__INCOME_FREELANCE_1099K_INFO,
      SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
      SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL
    ].indexOf(slug) !== -1
  ) {
    return ['collectionId', 'origin'];
  } else {
    return ['origin'];
  }
};

export const getTemplateQuestions = (questions) => {
  return questions.map((question) => {
    if (question.question_type !== CATEGORY_TYPE_TEMPLATE) {
      return question;
    }

    return {
      ...question,
      ..._.pick(question.template, ['title', 'summary', 'examples', 'question_type', 'question_meta'])
    };
  });
};

const getWho1099 = ({ queryResults }) => {
  const jobs = getJobData({ queryResults });
  const filingStatus = getFilingStatus({ queryResults });
  const uniqueEarners = _.uniq(jobs.map((j) => j.who));

  if (uniqueEarners.length > 1 && filingStatus === 'married') {
    return WHO_1099.BOTH;
  } else if (uniqueEarners.length === 1 && uniqueEarners[0] === 'my_spouse') {
    return WHO_1099.SPOUSE;
  } else {
    return WHO_1099.FILER;
  }
};

const getDynamicYou1099Or = ({ who1099, spouseName }) => {
  if (who1099 === WHO_1099.BOTH) {
    return spouseName ? `you or ${spouseName}` : 'you or your spouse';
  } else if (who1099 === WHO_1099.SPOUSE) {
    return spouseName ? `${spouseName}` : 'your spouse';
  }
  return 'you';
};

const getDynamicYou1099And = ({ who1099, spouseName }) => {
  if (who1099 === WHO_1099.BOTH) {
    return spouseName ? `you and ${spouseName}` : 'you and your spouse';
  } else if (who1099 === WHO_1099.SPOUSE) {
    return spouseName ? `${spouseName}` : 'your spouse';
  }
  return 'you';
};

const getDynamicI1099And = ({ who1099, spouseName }) => {
  if (who1099 === WHO_1099.BOTH) {
    return spouseName ? `${spouseName} and I` : 'my spouse and I';
  } else if (who1099 === WHO_1099.SPOUSE) {
    return spouseName ? `${spouseName}` : 'my spouse';
  }
  return 'I';
};

const getDynamicI1099Or = ({ who1099, spouseName }) => {
  if (who1099 === WHO_1099.BOTH) {
    return spouseName ? `${spouseName} or I` : 'my spouse or I';
  } else if (who1099 === WHO_1099.SPOUSE) {
    return spouseName ? `${spouseName}` : 'my spouse';
  }
  return 'I';
};

const getDynamicYou1099PossessiveOr = ({ who1099, spouseName }) => {
  if (who1099 === WHO_1099.BOTH) {
    return spouseName ? `your or ${spouseName}'s` : "your or your spouse's";
  } else if (who1099 === WHO_1099.SPOUSE) {
    return spouseName ? `${spouseName}'s` : "your spouse's";
  }
  return 'your';
};

const getDynamicYou1099PossessiveAnd = ({ who1099, spouseName }) => {
  if (who1099 === WHO_1099.BOTH) {
    return spouseName ? `your and ${spouseName}'s` : "your and your spouse's";
  } else if (who1099 === WHO_1099.SPOUSE) {
    return spouseName ? `${spouseName}'s` : "your spouse's";
  }
  return 'your';
};

const getDynamicYouOr = ({ filingStatus, spouseName }) => {
  if (filingStatus === 'married') {
    return spouseName ? `you or ${spouseName}` : 'you or your spouse';
  }
  return 'you';
};

const getDynamicYouAnd = ({ filingStatus, spouseName }) => {
  if (filingStatus === 'married') {
    return spouseName ? `you and ${spouseName}` : 'you and your spouse';
  }
  return 'you';
};

const getDynamicCar = ({ collectionId, queryResults }) => {
  const brand = getQueryResultValueByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId,
    slug: CAR_ENDPOINT_ATTRIBUTES.BRAND
  });
  return brand || 'car';
};

const getDynamicIOr = ({ filingStatus, spouseName }) => {
  if (filingStatus === 'married') {
    return spouseName ? `${spouseName} or I` : 'My spouse or I';
  }
  return 'I';
};

const getDynamicIAnd = ({ filingStatus, spouseName }) => {
  if (filingStatus === 'married') {
    return spouseName ? `${spouseName} and I` : 'My spouse and I';
  }
  return 'I';
};

const getDynamicYouPossessiveOr = ({ filingStatus, spouseName }) => {
  if (filingStatus === 'married') {
    return spouseName ? `Your or ${spouseName}'s` : "Your or your spouse's";
  }
  return 'your';
};

const getDynamicYouPossessiveAnd = ({ filingStatus, spouseName }) => {
  if (filingStatus === 'married') {
    return spouseName ? `Your and ${spouseName}'s` : "Your and your spouse's";
  }
  return 'your';
};

const getDynamicAddress = ({ collectionId, queryResults }) => {
  const street = getQueryResultValueByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__HOME,
    collectionId,
    slug: HOME_ENDPOINT_ATTRIBUTES.ADDRESS_STREET
  });
  return street || 'your home';
};

export const getSubstitutions = ({
  question,
  queryResults,
  collectionId,
  taxFilePrice,
  workDetails,
  taxAmounts,
  jobCategories,
  deductions,
  taxState,
  savings,
  submitEnabled
}) => {
  const status = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__SELF,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
  });

  const states = _.chain(
    getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    })
  )
    .map('answer.value')
    .value();

  const taxableStates = _.filter(states, (state) => !NO_INCOME_TAX_STATES.has(state));

  const state1Code = _.get(states, 0);
  const state1 = STATE_NAME_MAP[state1Code];
  const state2Code = _.get(states, 1);
  const state2 = STATE_NAME_MAP[state2Code];

  const stateCode = taxState;
  const state = STATE_NAME_MAP[stateCode];
  const specialState = STATE_NAME_MAP[workDetails.state_residence];

  const dependent = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__DEPENDENT,
    collectionId,
    slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
  });

  const healthcareOptions = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CREDIT_HEALTHCARE,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS
  });
  const healthcareOptionsValue = _.get(healthcareOptions, ['answer', 'value']);
  let coverage;
  if (healthcareOptionsValue === '0') {
    coverage = `Since your household didn’t have insurance, we’ll calculate the amount and apply it to your ${stateCode} return.`;
  } else if (healthcareOptionsValue === '1') {
    coverage = 'Since your entire household had healthcare, you’re good to go!';
  } else if (healthcareOptionsValue === '2') {
    coverage =
      'Since some members of your household had insurance, we need more information to determine if the penalty applies to you.';
  } else if (!healthcareOptionsValue) {
    coverage = `Complete healthcare information in credits section to continue your ${state} taxes.`;
  }

  const standardDeduction = _.get(deductions, 'standardDeduction');
  const itemizedDeduction = _.get(deductions, 'itemizedDeduction');

  const mobilePrice = '89.99';
  const webPrice = taxFilePrice ? taxFilePrice : '89';

  const isBill =
    taxAmounts &&
    (_.get(taxAmounts, 'federal_amount') >= 0 || _.get(taxAmounts, 'state_amounts', []).some((amount) => amount >= 0));

  const currentJob = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
    collectionId,
    slug: SLUG__INCOME_FREELANCE_JOB_NAME
  });
  const knownJobCategory = getKnownJobInfo(jobCategories, _.get(currentJob, ['answer', 'value']));
  const knownJobName = _.get(knownJobCategory, ['name'], '');

  const spouseNameResult = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__SPOUSE,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
  });
  const spouseName = !_.isEmpty(_.get(spouseNameResult, ['answer', 'value'], ''))
    ? capitalize(_.get(spouseNameResult, ['answer', 'value']))
    : null;

  const payersName = _.get(
    getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
      collectionId,
      slug: SLUG__INCOME_FREELANCE_PAYERS_NAME
    }),
    ['answer', 'value']
  );

  const [selfFirstName, selfLastName] = [SLUG__SELF_FIRST_NAME, SLUG__SELF_LAST_NAME].map((slug) =>
    _.get(
      getQueryResultByEndpointAttribute({
        queryResults,
        collectionType: 'self',
        collectionId: DEFAULT_COLLECTION_ID,
        slug
      }),
      ['answer', 'value']
    )
  );
  const selfFullName = `${selfFirstName} ${selfLastName}`;

  const freelanceIncomeQueries = getQueryResultsByEndpointAttribute({
    queryResults,
    collectionType: 'income-freelance',
    slug: 'income-freelance-income'
  });

  const freelanceIncomeSum = _.sumBy(freelanceIncomeQueries, (query) => {
    const value = _.get(query, ['answer', 'value'], 0);
    return parseInt(value, 10);
  });
  const qbiSavings = 0.2 * freelanceIncomeSum;

  const filingStatus = getFilingStatus({ queryResults });

  const who1099 = getWho1099({ queryResults });

  return [
    {
      name: '{work}',
      value: DEFAULT_WORK
    },
    {
      name: '{currentJob}',
      value: knownJobName ? knownJobName.toLowerCase() : _.get(currentJob, ['answer', 'value'])
    },
    {
      name: '%s',
      value: _.get(status, ['answer', 'value']) === 'married' ? '(or your spouse)' : ''
    },
    {
      name: '{household}',
      value: _.get(status, ['answer', 'value']) !== 'single' ? 'you' : 'your household'
    },
    {
      name: '{taxFilingYear}',
      value: TAX_FILING_YEAR
    },
    {
      name: '{lastTaxFilingYear}',
      value: LAST_TAX_FILING_YEAR
    },
    {
      name: '{price}',
      value: isReactNative() ? mobilePrice : webPrice
    },
    {
      name: '{stateCode}',
      value: stateCode ? stateCode : ''
    },
    {
      name: '{stateName}',
      value: state || ''
    },
    {
      name: '{state1}',
      value: state1 || ''
    },
    {
      name: '{state2}',
      value: state2 || ''
    },
    {
      name: '{state1Code}',
      value: state1Code ? state1Code : ''
    },
    {
      name: '{state2Code}',
      value: state2Code ? state2Code : ''
    },
    {
      name: '{specialState}',
      value: specialState || ''
    },
    {
      name: '{dependent}',
      value: !_.isEmpty(_.get(dependent, ['answer', 'value'], ''))
        ? capitalize(_.get(dependent, ['answer', 'value']))
        : 'this person'
    },
    {
      name: '{standardType}',
      value: `${_.get(deductions, 'itemized') ? 'Itemized' : 'Standard'} deduction`
    },
    {
      name: '{standardDeduction}',
      value: currencyWith0DecimalPlaces(standardDeduction)
    },
    {
      name: '{itemizedDeduction}',
      value: currencyWith0DecimalPlaces(itemizedDeduction)
    },
    {
      name: '{standardOrItemizedAmount}',
      value: `${_.get(deductions, 'itemized') ? currencyWith0DecimalPlaces(itemizedDeduction) : currencyWith0DecimalPlaces(standardDeduction)}`
    },
    {
      name: '{fileButtonLabel}',
      value: 'File my Return'
    },
    {
      name: '{coverage}',
      value: coverage
    },
    {
      name: '{spouseName}',
      value: spouseName || 'your spouse'
    },
    {
      name: '{billOrRefund}',
      value: isBill ? 'bill' : 'refund'
    },
    {
      name: '{irsAndState}',
      value: (() => {
        const taxEntities = ['the IRS', ...taxableStates];

        if (taxEntities.length === 1) {
          return taxEntities[0];
        }

        return `${_.dropRight(taxEntities).join(', ')} and ${_.last(taxEntities)}`;
      })()
    },
    {
      name: '{savings}',
      value: !!savings ? `(${currencyWith0DecimalPlaces(savings)})` : ''
    },
    {
      name: '{payersName}',
      value: payersName
    },
    {
      name: '{selfFullName}',
      value: selfFullName
    },
    { name: '{qbiSavings}', value: currencyWith0DecimalPlaces(qbiSavings) },
    { name: '{dynamicIOr}', value: getDynamicIOr({ filingStatus, spouseName }) },
    { name: '{dynamicIAnd}', value: getDynamicIAnd({ filingStatus, spouseName }) },
    { name: '{dynamicYouOr}', value: getDynamicYouOr({ filingStatus, spouseName }) },
    { name: '{dynamicYouAnd}', value: getDynamicYouAnd({ filingStatus, spouseName }) },
    { name: '{dynamicYouPossessiveOr}', value: getDynamicYouPossessiveOr({ filingStatus, spouseName }) },
    { name: '{dynamicYouPossessiveAnd}', value: getDynamicYouPossessiveAnd({ filingStatus, spouseName }) },
    { name: '{my/our}', value: filingStatus === 'married' ? 'our' : 'my' },
    {
      name: '{dynamicYou1099PossessiveOr}',
      value: getDynamicYou1099PossessiveOr({ who1099, spouseName })
    },
    {
      name: '{dynamicYou1099PossessiveAnd}',
      value: getDynamicYou1099PossessiveAnd({ who1099, spouseName })
    },
    {
      name: '{dynamicYou1099Or}',
      value: getDynamicYou1099Or({ who1099, spouseName })
    },
    {
      name: '{dynamicYou1099And}',
      value: getDynamicYou1099And({ who1099, spouseName })
    },
    {
      name: '{dynamicI1099Or}',
      value: getDynamicI1099Or({ who1099, spouseName })
    },
    {
      name: '{dynamicI1099And}',
      value: getDynamicI1099And({ who1099, spouseName })
    },
    {
      name: '{dynamicCar}',
      value: getDynamicCar({ collectionId, queryResults })
    },
    {
      name: '{nextButtonLabel}',
      value: submitEnabled && _.get(question, 'slug') === SLUG__SUBMIT_CONFIRM_ID ? 'Send to review!' : 'Next'
    },
    { name: '{dynamicAddress}', value: getDynamicAddress({ collectionId, queryResults }) }
  ];
};

export const getStringWithSubstitutions = ({ str, substitutions }) => {
  return substitutions.reduce(
    (result, substitution) => result.replace(new RegExp(substitution.name, 'g'), substitution.value),
    str
  );
};

export const getQuestionWithSubstitutions = ({ question, substitutions }) => {
  let examples = question.examples;
  if (question.examples) {
    examples = question.examples.map((example) => getStringWithSubstitutions({ str: example, substitutions }));
  }

  let question_meta = question.question_meta;
  if (question.question_type === CATEGORY_TYPE_TEXT || question.question_type === CATEGORY_TYPE_MONEY) {
    const keys = [
      'default',
      'goodDescription',
      'badDescription',
      'defaultStateDescription',
      'startStateDescription',
      'noIncomeTaxDescription'
    ];
    const intersectingKeys = _.intersection(Object.keys(question.question_meta), keys);

    question_meta = {
      ...question_meta,
      ...intersectingKeys.reduce(
        (result, key) => ({
          ...result,
          [key]: getStringWithSubstitutions({ str: _.get(question, ['question_meta', key]), substitutions })
        }),
        {}
      )
    };
  } else if (
    (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE ||
      question.slug === SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR ||
      question.slug === SLUG__INCOME_RETIREMENT_PENSION_ROTH) &&
    _.get(question_meta, 'warningMessage')
  ) {
    question_meta = {
      ...question_meta,
      warningMessage: getStringWithSubstitutions({ str: question_meta.warningMessage, substitutions })
    };
  } else if (
    [CATEGORY_TYPE_OPTIONS, CATEGORY_TYPE_TAXFLOW_CALENDAR].includes(question.question_type) &&
    Array.isArray(question.question_meta)
  ) {
    question_meta = question.question_meta.map((option) => {
      return {
        ...option,
        text: capitalizeFirstLetter(getStringWithSubstitutions({ str: option.text, substitutions }))
      };
    });
  } else if (question.question_type === CATEGORY_TYPE_SUMMARY) {
    question_meta = {
      ...question.question_meta,
      collectionTypes: question.question_meta.collectionTypes.map((collectionType) => ({
        ...collectionType,
        listName: getStringWithSubstitutions({ str: collectionType.listName, substitutions })
      }))
    };
  }

  let sub_question = question.sub_question;
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    sub_question = question.sub_question.map((subQuestion) =>
      getQuestionWithSubstitutions({ question: subQuestion, substitutions })
    );
  }

  let nextButtonLabel = question.nextButtonLabel;
  if (question.nextButtonLabel) {
    nextButtonLabel = getStringWithSubstitutions({ str: question.nextButtonLabel, substitutions });
  }

  return {
    ...question,
    title: getStringWithSubstitutions({ str: question.title, substitutions }),
    summary: getStringWithSubstitutions({ str: question.summary, substitutions }),
    learnMoreInfo: !question.learnMoreInfo?.fields?.description
      ? question.learnMoreInfo
      : {
          ...question.learnMoreInfo,
          fields: {
            ...question.learnMoreInfo.fields,
            description: getStringWithSubstitutions({ str: question.learnMoreInfo.fields.description, substitutions })
          }
        },
    info: getInfoWithSubstitutions({ info: question.info, substitutions }),
    examples,
    question_meta,
    sub_question,
    nextButtonLabel
  };
};

const getInfoWithSubstitutions = ({ info, substitutions }) => {
  if (info?.default?.fields) {
    info.default.fields.description = getStringWithSubstitutions({
      str: info.default.fields.description,
      substitutions
    });
    info.default.fields.title = getStringWithSubstitutions({ str: info.default.fields.title, substitutions });
  }
  return info;
};

export const getJobData = ({ queryResults }) => {
  const allJobData = _.uniq(
    queryResults.filter((item) => item.coll_type === COLLECTION_TYPE__INCOME_FREELANCE && Number(item.coll_id) > 0)
  );
  const jobCollectionIds = _.uniq(allJobData.map(({ coll_id }) => coll_id)).sort();
  const filingStatus = getFilingStatus({ queryResults });

  // Remap the job data
  const jobs = _.uniqWith(
    _.flatMap(jobCollectionIds, (collectionId) => {
      const currentJobData = allJobData.filter((jd) => jd.coll_id === collectionId);
      const who =
        filingStatus === 'married'
          ? currentJobData.find((jd) => jd.slug === SLUG__INCOME_FREELANCE_WHO)?.answer?.value
          : 'me';
      const jobName = currentJobData.find((jd) => jd.slug === SLUG__INCOME_FREELANCE_JOB_NAME)?.answer?.value;
      return {
        who,
        jobName
      };
    }).filter((item) => item.who != null && item.jobName != null), // If a 1099 form was left incomplete, exclude it from the job list
    (a, b) => a.who === b.who && a.jobName === b.jobName
  );

  return jobs;
};
