import CanceledImage from '@app/src/assets/keeper-subscription.svg';
import DelinquentImage from '@app/src/assets/update-payment.svg';

export const SUBSCRIPTION_STATUS_MAP = {
  canceled: { title: 'Re-subscribe to continue' },
  delinquent: { title: 'Re-subscribe to continue' },
  'pre free trial': { title: 'Start your 7-day free trial' }
};

export const PAYWALLED_STATES_MAP = {
  canceled: {
    title: 'Please re-subscribe to continue',
    message:
      "We've noticed that your Keeper subscription has expired. To pick up where you left off, and continue enjoying full access to all Keeper has to offer, please re-activate your subscription.",
    image: CanceledImage,

    cta: 'Continue'
  },
  delinquent: {
    title: 'Please update your payment info',
    message:
      'Our records indicate that the payment method linked to your Keeper account has expired. In order to avoid any disruptions in service, please update your payment information.',
    image: DelinquentImage,
    cta: 'Continue'
  },
  'pre free trial': {
    title: 'Try Keeper free for 7 days',
    cta: 'Continue with free trial'
  }
};
