import React from 'react';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import TaxSavingsEstimate from '@app/src/Components/HolisticOnboarding/components/TaxSavingsEstimate/TaxSavingsEstimate';
import { loadingSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const OnboardingDeductionEstimateW2 = ({ loading }) => (
  <Stack spacing={1}>
    <TaxSavingsEstimate loading={loading} w2 />
  </Stack>
);

const mapStateToProps = (state) => ({
  loading: loadingSelector(state)
});

const ConnectedOnboardingDeductionEstimateW2 = connect(mapStateToProps)(OnboardingDeductionEstimateW2);

export default ConnectedOnboardingDeductionEstimateW2;
