import React, { useEffect } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import {
  useDismissSubmitWarningMutation,
  useGetSubmitIssuesQuery,
  useGetSubmitWarningsQuery,
  useUpdateSubmitIssuesMutation
} from '@app/src/api/taxDataApi';
import WarningIcon from '@app/src/assets/warning.svg?react';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { TAXFLOW_BASE_URL, UI_STAGE__CONFIRM_AMOUNTS } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitWarnings.scss';

const TaxFlowSubmitWarnings = ({ setLoading = _.noop }) => {
  const { data: submitWarnings, isLoading: submitWarningsLoading } = useGetSubmitWarningsQuery();
  const { data: submitIssues, isLoading: submitIssuesLoading } = useGetSubmitIssuesQuery();
  const { data: campaign, isLoading: campaignLoading } = useGetCampaignQuery();
  const [dismissSubmitWarning] = useDismissSubmitWarningMutation();
  const [updateSubmitIssues] = useUpdateSubmitIssuesMutation();

  const isLoading = submitWarningsLoading || submitIssuesLoading || campaignLoading;

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  if (isLoading) {
    return null;
  }

  const hideButtons = campaign?.ui_stage === UI_STAGE__CONFIRM_AMOUNTS;
  const navigateMakeChanges = ({ link }) => {
    history.push(`/${TAXFLOW_BASE_URL}${link}`);
  };

  const resolveSubmitWarning = ({ slug, title }) => {
    trackActivity('dismiss submit warning', { slug, title });
    dismissSubmitWarning({ slug });
  };

  const resolveSubmitIssue = ({ slug, title, body }) => {
    trackActivity('dismiss submit warning', { slug, title });
    const updatedIssues = _.map(submitIssues, (submitIssue) =>
      _.isMatch(submitIssue, { header: title, body }) ? { ...submitIssue, isChecked: true } : submitIssue
    );
    updateSubmitIssues({ updatedIssues });
  };

  if (_.isEmpty(submitWarnings) && _.isEmpty(submitIssues)) {
    return null;
  }

  return (
    <div className='taxflow-submit-warnings'>
      {_.chain(submitIssues)
        .filter({ isChecked: false })
        .map((submitIssue) => (
          <TaxFlowSubmitWarning
            key={submitIssue.header}
            title={submitIssue.header}
            body={submitIssue.body}
            blocking={hideButtons}
            link={!hideButtons && submitIssue.link}
            linkText={'Make edits'}
            dismissText={submitIssue.blocking ? 'I’ve resolved' : 'Dismiss'}
            onLinkClick={() => navigateMakeChanges({ link: submitIssue.link })}
            onDismiss={() =>
              resolveSubmitIssue({ slug: submitIssue.slug, title: submitIssue.header, body: submitIssue.body })
            }
          />
        ))
        .value()}
      {_.map(submitWarnings, (submitWarning) => (
        <TaxFlowSubmitWarning
          key={submitWarning.slug}
          title={submitWarning.title}
          body={submitWarning.body}
          blocking={hideButtons || submitWarning.blocking}
          link={!hideButtons && submitWarning.link}
          linkText={submitWarning.link_text}
          dismissText={'Dismiss'}
          onLinkClick={() => navigateMakeChanges({ link: submitWarning.link })}
          onDismiss={() => resolveSubmitWarning({ slug: submitWarning.slug, title: submitWarning.title })}
        />
      ))}
    </div>
  );
};

export const TaxFlowSubmitWarning = ({
  title,
  body,
  blocking,
  link,
  linkText,
  dismissText,
  onLinkClick,
  onDismiss
}) => {
  return (
    <div className='taxflow-submit-warning'>
      <div className='taxflow-submit-warning-title'>
        <WarningIcon width={20} height={20} />
        <div>{title}</div>
      </div>
      <div className='taxflow-submit-warning-body'>{body}</div>
      <div className='taxflow-submit-warning-footer' style={{ justifyContent: link ? 'start' : 'end' }}>
        {link && (
          <Button fullWidth variant='outlined' className='taxflow-submit-warning-footer-button' onClick={onLinkClick}>
            <span className='taxflow-submit-warning-footer-button-text'>{linkText}</span>
          </Button>
        )}
        {!blocking && (
          <Button fullWidth variant='outlined' className='taxflow-submit-warning-footer-button' onClick={onDismiss}>
            <span className='taxflow-submit-warning-footer-button-text'>{dismissText}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default TaxFlowSubmitWarnings;
