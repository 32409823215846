import React, { forwardRef } from 'react';
import classNames from 'classnames';
import '@app/src/Components/Assistant/AssistantMessage.scss';

const AssistantSystemMessage = forwardRef(({ children, error, ...props }, ref) => {
  return (
    <div className={classNames('assistant-system-message', { 'assistant-error': error })} ref={ref} {...props}>
      {children}
    </div>
  );
});

export default AssistantSystemMessage;
