import React from 'react';
import _ from 'lodash';
import TaxFlowBillContainer from '@app/src/Components/TaxFlow/Question/TaxFlowBillContainer';
import TaxFlowBillRow from '@app/src/Components/TaxFlow/Question/TaxFlowBillRow';

const TaxFlowDetailedBillElement = ({ className, amounts }) => {
  return (
    <TaxFlowBillContainer className={className} title='Your tax bill'>
      {amounts.pdf && (
        <>
          <TaxFlowBillRow
            label='Adjusted gross income'
            amount={amounts.pdf.adjusted_gross_income}
            color={false}
            decimalPlaces={0}
          />
          <TaxFlowBillRow
            label='Standard or itemized deduction'
            amount={amounts.pdf.standard_or_itemized_deduction}
            color='refund'
            negativeSign
            decimalPlaces={0}
          />
          <TaxFlowBillRow
            label='Business income deduction'
            amount={amounts.pdf.business_income_deduction}
            color='refund'
            negativeSign
            decimalPlaces={0}
          />
          <TaxFlowBillRow label='Taxable income' amount={amounts.pdf.taxable_income} color={false} decimalPlaces={0} />
          <TaxFlowBillRow
            label='Non refundable credits'
            amount={amounts.pdf.non_refundable_credits}
            color='refund'
            negativeSign
            decimalPlaces={0}
          />
          <TaxFlowBillRow label='Gross taxes' amount={amounts.pdf.total_tax_owed} color={false} decimalPlaces={0} />
          <TaxFlowBillRow
            label='Taxes withheld and refundable credits'
            amount={amounts.pdf.total_payments_made}
            color='refund'
            negativeSign
            decimalPlaces={0}
          />
          <hr />
        </>
      )}
      <TaxFlowBillRow
        label={(billDue) => (billDue ? 'Federal tax bill due' : 'Federal tax refund')}
        amount={amounts.drake.federal_amount}
        decimalPlaces={0}
      />
      {!_.isEmpty(amounts.drake.multistate) &&
        amounts.drake.multistate.map(({ state, amount }) => (
          <TaxFlowBillRow
            key={state}
            label={(billDue) => (billDue ? `${state} State tax bill due` : `${state} State tax refund`)}
            amount={amount}
            decimalPlaces={0}
          />
        ))}
    </TaxFlowBillContainer>
  );
};

export default TaxFlowDetailedBillElement;
