import React, { useState } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { PulseLoader } from 'react-spinners';
import SendIcon from '@app/src/assets/send.svg?react';
import { colorFontLight } from '@app/src/theme';
import '@app/src/Components/Assistant/Assistant.scss';

const AssistantFooter = ({ submitMessage, disableSubmit, typing, isLoading = false }) => {
  const [userMessage, setUserMessage] = useState('');

  return (
    <div className='assistant-footer'>
      <Box
        component='form'
        onSubmit={(e) => {
          e.preventDefault();

          if (disableSubmit) return;

          submitMessage(userMessage);
          setUserMessage('');
        }}
      >
        {typing && (
          <div className='assistant-typing-indicator'>
            <strong>{typing}</strong> is typing
            <div className='assistant-typing-animation'>
              <PulseLoader size={3} color={colorFontLight} speedMultiplier={0.6} />
            </div>
          </div>
        )}
        <TextField
          fullWidth
          variant='outlined'
          onChange={(event) => {
            setUserMessage(event.target.value);
          }}
          placeholder='Message assistant'
          value={userMessage}
          maxRows={5}
          autoComplete='off'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton type='submit' disabled={isLoading || disableSubmit || _.isEmpty(userMessage.trim())}>
                  {isLoading ? <PulseLoader color='#90949E' speedMultiplier={0.5} size={5} /> : <SendIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </div>
  );
};

export default AssistantFooter;
