import React from 'react';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import TaxFlowLearnMoreElement from '@app/src/Components/TaxFlow/Common/TaxFlowLearnMoreElement';
import {
  SLUG__INCOME_INVEST_LONG_TERM_HEADER,
  SLUG__INCOME_INVEST_MANUAL_UPLOAD,
  SLUG__INCOME_INVEST_SHORT_TERM_HEADER
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { SLUG__SUBMIT_DEBIT_MANUAL_HEADER } from '@app/src/taxflow/sections/submit/constants/submitConstants';

class TaxFlowFormInfoItem extends React.Component {
  render() {
    const { question } = this.props;

    const accordionInfo = _.get(question, ['learnMoreInfo', 'fields', 'accordionInfo']);
    const contentType = _.get(question, ['learnMoreInfo', 'fields', 'contentType']);
    const type = _.get(question, ['learnMoreInfo', 'fields', 'type']);
    const slug = _.get(question, ['learnMoreInfo', 'fields', 'slug']);
    const title = _.get(question, ['learnMoreInfo', 'fields', 'title']);
    const description = _.get(question, ['learnMoreInfo', 'fields', 'description']);
    const url = _.get(question, ['learnMoreInfo', 'fields', 'url']);
    const image = _.get(question, ['learnMoreInfo', 'fields', 'image', 'fields']);
    const disableChatMessaging = _.get(question, ['learnMoreInfo', 'fields', 'disableChatMessaging']);
    const buttonText = _.get(question, 'learnMoreTitle');
    return (
      <div className='form-group'>
        <label className={question.header_style}>{this.props.replaceStrings(question.title)}</label>
        <ReactMarkdown className='step-summary' source={question.summary} />
        {[
          SLUG__INCOME_INVEST_SHORT_TERM_HEADER,
          SLUG__INCOME_INVEST_LONG_TERM_HEADER,
          SLUG__INCOME_INVEST_MANUAL_UPLOAD,
          SLUG__SUBMIT_DEBIT_MANUAL_HEADER
        ].includes(question.slug) && (
          <TaxFlowLearnMoreElement
            question={question}
            slug={slug}
            type={type}
            buttonText={buttonText}
            modalTitle={title}
            description={description}
            url={url}
            image={image}
            accordionInfo={accordionInfo}
            contentType={contentType}
            disableChatMessaging={disableChatMessaging}
          />
        )}
      </div>
    );
  }
}

export default TaxFlowFormInfoItem;
