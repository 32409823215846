import React from 'react';
import _ from 'lodash';
import TaxFlowMilesItem from '@app/src/Components/TaxFlow/Question/TaxFlowMilesItem';

const TaxFlowFormMilesItem = ({ question, answer, onChange, onFocus = _.noop, ...props }) => {
  const handleChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  return (
    <div className='form-group'>
      <TaxFlowMilesItem
        {...props}
        isChildQuestion
        currentQuestion={question}
        currentAnswer={answer}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    </div>
  );
};

export default TaxFlowFormMilesItem;
