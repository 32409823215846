import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import { setSettingsModalType } from '@app/src/actions/settingsActions';
import { isEligibleForRefundSelector } from '@app/src/selectors/settingsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { deleteSubscription } from '@app/src/services/pricingService';
import { cancelMembership } from '@app/src/services/settingsService';
import '@app/src/Components/Settings/Modals/Churn/ChurnInitial.scss';

const ChurnInitial = ({ isEligibleForRefund, setSettingsModalType, cancelMembership }) => {
  const [checkedOption, setCheckedOption] = useState(null);

  const onCancelMembership = async ({ refund }) => {
    setSettingsModalType(null);
    await cancelMembership({ refund });
  };

  const onOptionClick = ({ option, reason, showChurnExplanation, refund }) => {
    setCheckedOption(option);
    trackActivity('churn reason picked', { reason });

    if (showChurnExplanation) {
      setSettingsModalType('churn-explanation');
      return;
    }

    setSettingsModalType('');
    onCancelMembership({ refund });
  };

  return (
    <Stack spacing={1}>
      <DialogTitle>Oh no! What's wrong?</DialogTitle>
      <ToggleButton
        label=''
        name={'option_1'}
        checked={checkedOption === 1}
        onClick={() =>
          onOptionClick({
            option: 1,
            reason: 'no longer freelancing'
          })
        }
        onChange={() => {}}
        control={<Radio />}
      >
        I no longer do 1099 work
      </ToggleButton>
      <ToggleButton
        label=''
        name={'option_2'}
        checked={checkedOption === 2}
        onClick={() =>
          onOptionClick({
            option: 2,
            reason: 'bank link not working'
          })
        }
        onChange={() => {}}
        control={<Radio />}
      >
        Bank link not working
      </ToggleButton>
      <ToggleButton
        label=''
        name={'option_3'}
        checked={checkedOption === 3}
        onClick={() =>
          onOptionClick({
            option: 3,
            reason: 'need more sophistication',
            showChurnExplanation: true
          })
        }
        onChange={() => {}}
        control={<Radio />}
      >
        Needs more sophistication
      </ToggleButton>
      <ToggleButton
        label=''
        name={'option_4'}
        checked={checkedOption === 4}
        onClick={() =>
          onOptionClick({
            option: 4,
            reason: 'did not enjoy service',
            showChurnExplanation: true
          })
        }
        onChange={() => {}}
        control={<Radio />}
      >
        I didn't enjoy Keeper
      </ToggleButton>
      {isEligibleForRefund && (
        <ToggleButton
          label=''
          name={'option_5'}
          checked={checkedOption === 5}
          onClick={() =>
            onOptionClick({
              option: 5,
              reason: 'did not mean to subscribe',
              refund: true
            })
          }
          onChange={() => {}}
          control={<Radio />}
        >
          I didn't mean to subscribe
        </ToggleButton>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  isEligibleForRefund: isEligibleForRefundSelector(state)
});

const mapDispatchToProps = { setSettingsModalType, cancelMembership, deleteSubscription };

const ConnectedChurnInitial = connect(mapStateToProps, mapDispatchToProps)(ChurnInitial);

export default ConnectedChurnInitial;
