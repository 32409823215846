import _ from 'lodash';
import { useGetAccountDetailsQuery } from '@app/src/api/profileApi';

export const useIsPremiumSubscriber = () => {
  const { data: accountDetails, isLoading: accountDetailsLoading } = useGetAccountDetailsQuery();

  if (accountDetailsLoading) {
    return { isLoading: true };
  }

  return { data: _.get(accountDetails, 'isPremiumSubscriber', false), isLoading: false };
};
