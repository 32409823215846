import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { Controller, useFormContext } from 'react-hook-form';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';
import ManageJobsPanel from '@app/src/Components/TaxProfile/ManageJobsPanel';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import TaxProfileSlider from '@app/src/Components/TaxProfile/TaxProfileSlider';
import { colorFontDark } from '@app/src/theme';

const CategoryUsageSection = () => {
  const [jobPanelOpen, setJobPanelOpen] = useState(false);
  const { jobType, allJobs, topJobCategories, onSubmit } = useTaxProfileFormContext();

  const jobsBySlug = useMemo(() => _.keyBy(allJobs, 'slug'), [allJobs]);

  const { watch, handleSubmit, setValue } = useFormContext();

  const jobs = watch('jobs');
  const car = watch('car');
  const home = watch('home');
  const publicTransport = watch('public_transport');

  return (
    <TaxProfileFormSection header={`${_.capitalize(jobType)} details`}>
      <div
        style={{
          border: '1px solid #AAAAAB',
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 16,
          paddingLeft: 16,
          paddingRight: 16
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8
          }}
        >
          {jobs.map((jobSlug) => {
            const job = jobsBySlug[jobSlug];

            return (
              <TaxFlowPill
                key={job.slug}
                text={job.name}
                onClick={() => {
                  setJobPanelOpen(true);
                }}
                icon={<img src={job.icon_url} alt={`${job.name}-icon`} />}
              />
            );
          })}
        </div>
        <Button
          onClick={() => {
            setJobPanelOpen(true);
          }}
        >
          Edit
        </Button>
      </div>
      <Controller
        name='meals'
        render={({ field }) => {
          return (
            <FormControl
              fullWidth
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <InputLabel
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: colorFontDark
                }}
                htmlFor='meals'
                id='mealsLabel'
              >
                {_.capitalize(jobType)} meals
              </InputLabel>
              <Switch
                id='meals'
                {...field}
                checked={field.value === 1}
                onChange={(e) => {
                  field.onChange(e.target.checked ? 1 : 0);
                  handleSubmit(onSubmit(field.name))();
                }}
              />
            </FormControl>
          );
        }}
      />
      <Controller
        name='travel'
        render={({ field }) => {
          return (
            <FormControl
              fullWidth
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <InputLabel
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: colorFontDark
                }}
                htmlFor='travel'
                id='travelLabel'
              >
                {_.capitalize(jobType)} travel
              </InputLabel>
              <Switch
                id='travel'
                {...field}
                checked={field.value === 1}
                onChange={(e) => {
                  field.onChange(e.target.checked ? 1 : 0);
                  handleSubmit(onSubmit(field.name))();
                }}
              />
            </FormControl>
          );
        }}
      />
      <Controller
        name='percent_car'
        rules={{
          min: 0,
          max: 100
        }}
        render={({ field: { value, onChange, name } }) => {
          return (
            <TaxProfileSlider
              label={`Percent of driving for ${jobType}`}
              id='percent_car'
              value={car ? value : 0}
              onChange={(value) => {
                const parsedValue = parseInt(value);

                setValue('car', parsedValue > 0 ? 1 : 0);

                onChange(parsedValue);
              }}
              onBlur={handleSubmit(onSubmit(name))}
            />
          );
        }}
      />
      <Controller
        name='percent_home'
        rules={{
          min: 0,
          max: 100
        }}
        render={({ field: { value, onChange, name } }) => {
          return (
            <TaxProfileSlider
              label='Percent of home used for home office'
              id='percent_home'
              value={home ? value : 0}
              onChange={(value) => {
                const parsedValue = parseInt(value);

                setValue('home', parsedValue > 0 ? 1 : 0);

                onChange(parsedValue);
              }}
              onBlur={handleSubmit(onSubmit(name))}
            />
          );
        }}
      />
      <Controller
        name='percent_phone'
        rules={{
          min: 0,
          max: 100
        }}
        render={({ field: { value, onChange, name } }) => (
          <TaxProfileSlider
            label={`Percent of phone usage for ${jobType}`}
            id='percent_phone'
            value={value}
            onChange={(value) => onChange(parseInt(value))}
            onBlur={handleSubmit(onSubmit(name))}
          />
        )}
      />
      <Controller
        name='percent_public_transport'
        rules={{
          min: 0,
          max: 100
        }}
        render={({ field: { value, onChange, name } }) => (
          <TaxProfileSlider
            label={`Percent of public transport for ${jobType}`}
            id='percent_public_transport'
            value={publicTransport ? value : 0}
            onChange={(value) => {
              const parsedValue = parseInt(value);

              setValue('public_transport', parsedValue > 0 ? 1 : 0);

              onChange(parsedValue);
            }}
            onBlur={handleSubmit(onSubmit(name))}
          />
        )}
      />
      <Controller
        name='jobs'
        render={({ field: { value, onChange, name } }) => {
          return (
            <ManageJobsPanel
              open={jobPanelOpen}
              onClose={() => setJobPanelOpen(false)}
              allJobs={allJobs}
              topJobCategories={topJobCategories}
              onSave={(jobs) => {
                onChange(jobs);
                handleSubmit(onSubmit(name))();
              }}
              value={value}
            />
          );
        }}
      />
    </TaxProfileFormSection>
  );
};

export default CategoryUsageSection;
