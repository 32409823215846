import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import OnboardingQuestion from '@app/src/Components/Onboarding/Question/OnboardingQuestion';
import { MAIN_BUTTON, SKIP_BUTTON } from '@app/src/constants/bankLinkConstants';
import { bankLinkSkippedSelector } from '@app/src/selectors/bankSelectors';
import { getLink, updateLink } from '@app/src/services/onboardingService';

class OnboardingLink extends React.Component {
  async componentDidMount() {
    await this.props.getLink({ history: this.props.history });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.bankLinkSkipped && !prevProps.bankLinkSkipped) {
      this.onNext();
    }
  }

  async onNext() {
    const buttonClicked = _.isEmpty(this.props.bank.bankList) ? SKIP_BUTTON : MAIN_BUTTON;

    await this.props.updateLink({ history: this.props.history, buttonClicked });

    this.props.history.push('/onboarding/loading-initial');
  }

  render() {
    return <OnboardingQuestion isPrev={false} onNext={() => this.onNext()} />;
  }
}

const mapStateToProps = (state) => ({
  bank: state.bank,
  bankLinkSkipped: bankLinkSkippedSelector(state)
});

const mapDispatchToProps = {
  getLink,
  updateLink
};

const ConnectedOnboardingLink = connect(mapStateToProps, mapDispatchToProps)(OnboardingLink);

export default ConnectedOnboardingLink;
