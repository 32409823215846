import React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import {
  referralsHowItWorksSelector,
  referralsTermsAndConditionsSelector
} from '@app/src/selectors/referralsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/Referrals/ReferralsInfoSidebar.scss';

const ReferralsInfoSidebar = ({ component, setSidebarComponent, howItWorksContent, termsAndConditionsContent }) => {
  const contentMap = {
    TERMS_AND_CONDITIONS: (
      <div className='sidebar-content'>
        <h2 className='sidebar-header'>{termsAndConditionsContent?.title}</h2>
        <Divider />
        <ReactMarkdown className='sidebar-text'>{termsAndConditionsContent?.summary || ''}</ReactMarkdown>
      </div>
    ),
    HOW_IT_WORKS: (
      <div className='sidebar-content'>
        <h2 className='sidebar-header'>{howItWorksContent?.title}</h2>
        <Divider />
        <ReactMarkdown className='sidebar-text'>{howItWorksContent?.summary || ''}</ReactMarkdown>
        <Button
          onClick={() => {
            trackActivity('navigation: referral tab terms and conditions');
            setSidebarComponent('TERMS_AND_CONDITIONS');
          }}
          className='sidebar-terms-Button'
        >
          Terms, conditions, and limits apply.
        </Button>
      </div>
    )
  };

  return component ? (
    <Drawer
      anchor='right'
      open={Boolean(component)}
      onClose={() => setSidebarComponent(null)}
      variant='persistent'
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{
        style: {
          padding: '16px'
        }
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
        onClick={() => setSidebarComponent(null)}
      >
        <CloseIcon />
      </IconButton>
      <div className='base-sidebar-panel-content'>{contentMap[component] || contentMap['HOW_IT_WORKS']}</div>
    </Drawer>
  ) : null;
};

const mapStateToProps = (state) => ({
  howItWorksContent: referralsHowItWorksSelector(state),
  termsAndConditionsContent: referralsTermsAndConditionsSelector(state)
});

const ConnectedReferralsInfoSidebar = connect(mapStateToProps)(ReferralsInfoSidebar);

export default ConnectedReferralsInfoSidebar;
