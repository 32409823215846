import _ from 'lodash';

function parseNumberParam({ numberParam, numberParamCount }) {
  const numberInt = parseInt(numberParam);
  if (_.isNaN(numberInt)) {
    return 0;
  }
  return _.clamp(numberInt - 1, 0, numberParamCount);
}

export default parseNumberParam;
