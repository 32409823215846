import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Oauth from '@app/src/Components/Common/Oauth/Oauth';
import OnboardingAppDownload from '@app/src/Components/Onboarding/OnboardingAppDownload';
import OnboardingCard from '@app/src/Components/Onboarding/OnboardingCard';
import OnboardingAccount from '@app/src/Components/Onboarding/pages/OnboardingAccount';
import OnboardingAccountPhoneOnly from '@app/src/Components/Onboarding/pages/OnboardingAccountPhoneOnly';
import OnboardingHome from '@app/src/Components/Onboarding/pages/OnboardingHome';
import OnboardingIncomeType from '@app/src/Components/Onboarding/pages/OnboardingIncomeType';
import OnboardingInit from '@app/src/Components/Onboarding/pages/OnboardingInit';
import OnboardingJobDuration from '@app/src/Components/Onboarding/pages/OnboardingJobDuration';
import OnboardingJobSelection from '@app/src/Components/Onboarding/pages/OnboardingJobSelection';
import OnboardingLink from '@app/src/Components/Onboarding/pages/OnboardingLink';
import OnboardingLoadingInitial from '@app/src/Components/Onboarding/pages/OnboardingLoadingInitial';
import OnboardingMeal from '@app/src/Components/Onboarding/pages/OnboardingMeal';
import OnboardingRedirect from '@app/src/Components/Onboarding/pages/OnboardingRedirect';
import OnboardingTransport from '@app/src/Components/Onboarding/pages/OnboardingTransport';
import OnboardingTravel from '@app/src/Components/Onboarding/pages/OnboardingTravel';
import OnboardingVerify from '@app/src/Components/Onboarding/pages/OnboardingVerify';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import TaxFlowInfoModalWindow from '@app/src/Components/TaxFlow/Common/TaxFlowInfoModalWindow';
import { getOnboardingPrerequisites } from '@app/src/services/onboardingService';

const routes = [
  { path: '/onboarding/account', Component: OnboardingAccount },
  { path: '/onboarding/account-phone-only', Component: OnboardingAccountPhoneOnly },
  { path: '/onboarding/verify', Component: OnboardingVerify },
  { path: '/onboarding/home', Component: OnboardingHome },
  { path: '/onboarding/income-type', Component: OnboardingIncomeType },
  { path: '/onboarding/job-duration/:jobNumber', Component: OnboardingJobDuration },
  { path: '/onboarding/job-select', Component: OnboardingJobSelection },
  { path: '/onboarding/link', Component: OnboardingLink },
  { path: '/onboarding/loading-initial', Component: OnboardingLoadingInitial },
  { path: '/onboarding/meal', Component: OnboardingMeal },
  { path: '/onboarding/oauth', Component: (props) => <Oauth {...props} defaultRoute='/onboarding/link' /> },
  { path: '/onboarding/transport', Component: OnboardingTransport },
  { path: '/onboarding/travel', Component: OnboardingTravel },
  { path: '/onboarding/app-download', Component: OnboardingAppDownload },
  { path: '/onboarding', Component: OnboardingInit },
  { path: '*', Component: OnboardingRedirect }
];

const OnboardingApp = ({ bankList, location, getOnboardingPrerequisites }) => {
  useEffect(() => {
    getOnboardingPrerequisites();
  }, [getOnboardingPrerequisites]);

  const renderRoute = useCallback(() => {
    return (
      <StripeProvider>
        <div
          className={classNames('taxflow-wrapper', {
            'taxflow-wrapper-link': location.pathname === '/onboarding/link' && bankList.length > 0,
            'taxflow-wrapper-app-download': location.pathname === '/onboarding/app-download',
            'taxflow-wrapper-white':
              location.pathname === '/onboarding/loading-initial' || location.pathname === '/onboarding/loading-final'
          })}
        >
          <div className='taxflow-content'>
            <div className='taxflow-content-center'>
              <OnboardingCard location={location}>
                <div className='taxflow-content-routes'>
                  <Switch location={location}>
                    {routes.map(({ path, Component }) => (
                      <Route key={path} exact path={path} component={Component} />
                    ))}
                  </Switch>
                </div>
                <TaxFlowInfoModalWindow />
              </OnboardingCard>
            </div>
          </div>
        </div>
      </StripeProvider>
    );
  }, [bankList.length, location]);

  return <Route render={renderRoute} />;
};

const mapStateToProps = (state) => ({
  bankList: state.bank.bankList
});

const mapDispatchToProps = {
  getOnboardingPrerequisites
};

const ConnectedOnboardingApp = connect(mapStateToProps, mapDispatchToProps)(OnboardingApp);

export default ConnectedOnboardingApp;
