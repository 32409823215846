import _ from 'lodash';
import axios from 'axios';
import { DASHBOARD2__SET_EXPENSES, DASHBOARD2__SET_CATEGORIES } from '@app/src/constants/dashboardConstants';
import { serverUrl } from '@app/src/global/Environment';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';

const baseUrl = serverUrl();

const setExpenses = ({ expenses }) => ({
  type: DASHBOARD2__SET_EXPENSES,
  expenses
});

export const getExpenses = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/expense/writeoffs`, {
    params: {
      start: `${TAX_FILING_YEAR}-01-01`,
      end: `${TAX_FILING_YEAR}-12-31`
    }
  });
  let expenses = _.get(res, ['data', 'data']);
  if (_.isEmpty(expenses)) {
    expenses = [];
  }
  dispatch(setExpenses({ expenses }));
};

const setCategories = ({ categories }) => ({
  type: DASHBOARD2__SET_CATEGORIES,
  categories
});

export const getCategories = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/expense/load-cat-map`);
  const categories = _.get(res, ['data', 'status']) === 'error' ? [] : _.get(res, ['data', 'data']);
  dispatch(setCategories({ categories }));
};
