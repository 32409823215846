import {
  SET_ASSISTANT_MESSAGES,
  SET_ASSISTANT_OPEN,
  INCREMENT_ASSISTANT_UNREAD_MESSAGES,
  TOGGLE_ASSISTANT_OPEN,
  SET_ASSISTANT_UNREAD_MESSAGES,
  SET_ASSISTANT_TYPING,
  SET_ASSISTANT_LOADING,
  SET_ASSISTANT_HAS_ERROR,
  SET_ASSISTANT_CONNECTED,
  SEND_STREAM_MESSAGE,
  ADD_TO_SIGNED_URL_CACHE
} from '@app/src/actions/types';

export const setAssistantMessages = (payload) => {
  return {
    type: SET_ASSISTANT_MESSAGES,
    payload
  };
};

export const setAssistantOpen = (payload) => {
  return {
    type: SET_ASSISTANT_OPEN,
    payload
  };
};

export const toggleAssistantOpen = () => {
  return {
    type: TOGGLE_ASSISTANT_OPEN
  };
};

export const setAssistantUnreadMessages = (payload) => {
  return {
    type: SET_ASSISTANT_UNREAD_MESSAGES,
    payload
  };
};

export const incrementAssistantUnreadMessages = (payload) => {
  return {
    type: INCREMENT_ASSISTANT_UNREAD_MESSAGES,
    payload
  };
};

export const setAssistantTyping = (payload) => {
  return {
    type: SET_ASSISTANT_TYPING,
    payload
  };
};

export const setAssistantLoading = (payload) => {
  return {
    type: SET_ASSISTANT_LOADING,
    payload
  };
};

export const setAssistantHasError = (payload) => {
  return {
    type: SET_ASSISTANT_HAS_ERROR,
    payload
  };
};

export const setAssistantConnected = (payload) => {
  return {
    type: SET_ASSISTANT_CONNECTED,
    payload
  };
};

export const sendStreamMessage = (payload) => {
  return {
    type: SEND_STREAM_MESSAGE,
    payload
  };
};

export const addToSignedUrlCache = (payload) => {
  return {
    type: ADD_TO_SIGNED_URL_CACHE,
    payload
  };
};
