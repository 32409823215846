import React from 'react';
import { connect } from 'react-redux';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import MailIcon from '@app/src/assets/mail.svg?react';
import TaxFilingHomeImage from '@app/src/assets/tax-filing-home.png';
import { userSelector } from '@app/src/selectors/userSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowSwitchtoDesktop.scss';

const TaxFlowSwitchtoDesktop = ({ user }) => {
  return (
    <div className='tax-flow-switch-to-desktop'>
      <TaxFlowPill
        text={user.email}
        icon={
          <MailIcon>
            width={16} height={16}
          </MailIcon>
        }
      />
      <img className='tax-flow-switch-to-desktop-image' src={TaxFilingHomeImage} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state)
});

const ConnectedTaxFlowSwitchtoDesktop = connect(mapStateToProps)(TaxFlowSwitchtoDesktop);

export default ConnectedTaxFlowSwitchtoDesktop;
