import { SET_REFERRALS, SET_PAYPAL_EMAIL, SET_REFERRALS_CONTENT } from '@app/src/actions/types.js';

export const setReferrals = (referrals) => ({
  type: SET_REFERRALS,
  payload: referrals
});

export const setPayPalEmail = (paypalEmail) => ({
  type: SET_PAYPAL_EMAIL,
  payload: paypalEmail
});

export const setReferralsContent = (content) => ({
  type: SET_REFERRALS_CONTENT,
  payload: content
});
