import React from 'react';
import Helmet from 'react-helmet';
import { isReactNative } from '@app/src/global/Helpers';

const KeeperHelmet = () => {
  return (
    <Helmet>
      <meta
        name='viewport'
        content={
          isReactNative()
            ? 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
            : 'width=device-width, initial-scale=1, viewport-fit=cover'
        }
      />
    </Helmet>
  );
};

export default KeeperHelmet;
