import { CATEGORY_TYPE_NAVIGATION } from '@app/src/constants/constants';
import {
  COLLECTION_TYPE__INCOME_1099G,
  COLLECTION_TYPE__INCOME_DIV,
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INTEREST,
  COLLECTION_TYPE__INCOME_INVEST,
  COLLECTION_TYPE__INCOME_PERSONAL_ITEMS,
  COLLECTION_TYPE__INCOME_RETIREMENT,
  COLLECTION_TYPE__INCOME_W2,
  COLLECTION_TYPE__INCOME_W2G,
  ENDPOINT_ATTRIBUTE__INCOME_1099G_COMPENSATION,
  ENDPOINT_ATTRIBUTE__INCOME_DIV_ORDINARY,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_MISC_BOX_2,
  ENDPOINT_ATTRIBUTE__INCOME_INTEREST_AMOUNT,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_SALE_PROCEEDS,
  ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_AMOUNT,
  ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_PENSION_GROSS,
  ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_SSA_NET,
  ENDPOINT_ATTRIBUTE__INCOME_W2G_REPORTABLE_WINNINGS,
  ENDPOINT_ATTRIBUTE__INCOME_W2_WAGES
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
  ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';

export const getQuestionQueries = ({ question }) => {
  if (question.question_type === CATEGORY_TYPE_NAVIGATION) {
    return [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_MISC_BOX_2
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_W2,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2_WAGES
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_INTEREST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INTEREST_AMOUNT
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_DIV,
        slug: ENDPOINT_ATTRIBUTE__INCOME_DIV_ORDINARY
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_INVEST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_SALE_PROCEEDS
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_1099G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_1099G_COMPENSATION
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_W2G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2G_REPORTABLE_WINNINGS
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_RETIREMENT,
        slug: ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_PENSION_GROSS
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_RETIREMENT,
        slug: ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_SSA_NET
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_PERSONAL_ITEMS,
        slug: ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_AMOUNT
      },
      {
        coll_type: COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT
      }
    ];
  }

  return [];
};

export const getOriginParam = ({ location }) => {
  return new URLSearchParams(location.search).get('origin');
};
