import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DelayedLoader from '@app/src/Components/Common/DelayedLoader/DelayedLoader';
import OnboardingFoot from '@app/src/Components/Onboarding/Question/OnboardingFoot';
import OnboardingFormItem from '@app/src/Components/Onboarding/Question/OnboardingFormItem';
import OnboardingHead from '@app/src/Components/Onboarding/Question/OnboardingHead';
import OnboardingJobCategoryItem from '@app/src/Components/Onboarding/Question/OnboardingJobCategoryItem';
import OnboardingOptionItem from '@app/src/Components/Onboarding/Question/OnboardingOptionItem';
import OnboardingSystemTypeItem from '@app/src/Components/Onboarding/Question/OnboardingSystemTypeItem';
import OnboardingVerifyTypeItem from '@app/src/Components/Onboarding/Question/OnboardingVerifyTypeItem';
import TaxFlowResponseText from '@app/src/Components/TaxFlow/Common/TaxFlowResponseText';
import { setCurrentAnswer } from '@app/src/actions/onboardingActions';
import {
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_SYSTEM,
  CATEGORY_TYPE_VERIFY,
  TAXFLOW_SYSTEM_QUESTION_TYPE_CONNECT_BANK
} from '@app/src/constants/constants';
import { Url_ONBOARDING_INCOME_TYPE, Url_SIGNUP_LINK } from '@app/src/constants/onboardingConstants';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import {
  isOnboardingSkipEnabledSelector,
  loadingSelector,
  responseTextsSelector,
  updatingSelector
} from '@app/src/selectors/onboardingSelectors';

class OnboardingQuestionItem extends Component {
  render() {
    const { currentQuestion, replaceStrings, updating, loading, responseTexts, bankList, history, onNext } = this.props;

    if (!currentQuestion || _.isEmpty(currentQuestion)) {
      return (
        <div className='wrapper-view'>
          <div className='steps-content-wrapper'>
            <div className='taxflow-wrapper-view-centered'>
              <DelayedLoader />
            </div>
          </div>
        </div>
      );
    }

    const learnMoreInfo = _.get(currentQuestion, 'learnMoreInfo');
    const title = replaceStrings(currentQuestion.titleOrig);
    const summary = replaceStrings(currentQuestion.summary);
    const isFormType = currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM;
    const isSystemType = currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_SYSTEM;
    const isVerifyType = currentQuestion.question_type === CATEGORY_TYPE_VERIFY;
    const displayHeader = !isVerifyType;
    const hasBanks = bankList && bankList.length > 0;

    return (
      <div className='wrapper-view'>
        {loading ? (
          <div className='steps-content-wrapper'>
            <div className='taxflow-wrapper-view-centered'>
              <DelayedLoader />
            </div>
          </div>
        ) : (
          <>
            <div
              className={classNames('steps-content-wrapper', 'steps-wrapper', {
                'steps-content-bank': currentQuestion.sysId === TAXFLOW_SYSTEM_QUESTION_TYPE_CONNECT_BANK && !hasBanks
              })}
            >
              <div className='steps-content'>
                {displayHeader && (
                  <OnboardingHead
                    question={currentQuestion}
                    title={title}
                    summary={summary}
                    learnMoreInfo={learnMoreInfo}
                    learnMoreTitle={currentQuestion.learnMoreTitle}
                  />
                )}
                <div className='steps-body-wrapper'>
                  {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY ? (
                    <OnboardingJobCategoryItem {...this.props} resultLoading={loading} />
                  ) : null}
                  {currentQuestion.question_type === CATEGORY_TYPE_OPTIONS && currentQuestion.question_meta ? (
                    <OnboardingOptionItem {...this.props} resultLoading={loading} />
                  ) : null}
                  {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION ? (
                    <OnboardingOptionItem multiple {...this.props} resultLoading={loading} />
                  ) : null}
                  {isFormType ? <OnboardingFormItem {...this.props} onNext={onNext} /> : null}
                  <TaxFlowResponseText responseTexts={responseTexts} replaceStrings={replaceStrings} />
                  {isSystemType && <OnboardingSystemTypeItem {...this.props} isFeatureOpened isStep onNext={onNext} />}
                  {isVerifyType && <OnboardingVerifyTypeItem history={history} currentQuestion={currentQuestion} />}
                </div>
              </div>
            </div>
          </>
        )}
        <OnboardingFoot
          isPrev={currentQuestion.slug !== Url_ONBOARDING_INCOME_TYPE && currentQuestion.slug !== Url_SIGNUP_LINK}
          isNext
          isDisabled={this.props.isNextDisabled}
          isNextButtonLoading={loading}
          isUpdating={updating}
          isSkipEnabled={this.props.isSkipEnabled}
          buttonLabel={currentQuestion.nextButtonLabel}
          onPrev={this.props.onPrev}
          onNext={this.props.onNext}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  replaceStrings: props.replaceStrings || _.identity,
  updating: updatingSelector(state),
  loading: loadingSelector(state),
  responseTexts: responseTextsSelector(state),
  bankList: bankListSelector(state),
  isSkipEnabled: isOnboardingSkipEnabledSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedOnboardingQuestionItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnboardingQuestionItem));

export default ConnectedOnboardingQuestionItem;
