import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import OauthRedirect from '@app/src/Components/Common/OauthRedirect';
import { parseLinkToken } from '@app/src/services/bankLinkService';
import '@app/src/Components/Common/Oauth/Oauth.scss';

const Oauth = ({
  className,
  linkToken,
  history,
  parseLinkToken,
  defaultRoute,
  successRoute,
  getSuccessParams,
  loadOnSuccess
}) => {
  useEffect(() => {
    parseLinkToken();
  }, [parseLinkToken]);

  if (!linkToken) {
    return (
      <div className={classNames('oauth', className)}>
        <Loader />
      </div>
    );
  }

  return (
    <OauthRedirect
      loadOnSuccess={loadOnSuccess}
      history={history}
      defaultRoute={defaultRoute}
      successRoute={successRoute}
      getSuccessParams={getSuccessParams}
    />
  );
};

const mapStateToProps = (state) => ({
  linkToken: _.get(state, ['bank', 'linkToken'])
});

const mapDispatchToProps = {
  parseLinkToken
};

const ConnectedOauth = connect(mapStateToProps, mapDispatchToProps)(Oauth);

export default ConnectedOauth;
