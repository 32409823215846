import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import KeeperSlider from '@app/src/Components/Common/KeeperSlider/KeeperSlider';
import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import { bulkEditExpensesSelector } from '@app/src/selectors/expenseReviewSelectors';
import { bulkEditBase } from '@app/src/services/bulkEditService';

const ExpenseReviewModalBulkSplit = ({ selectedRows, bulkEditBase, setExpenseReviewModalType }) => {
  const [personalAmount, setPersonalAmount] = useState(0);
  const [workAmount, setWorkAmount] = useState(100);

  const handlePersonalChange = (value) => {
    setPersonalAmount(value);
    setWorkAmount(100 - value);
  };

  const handleWorkChange = (value) => {
    setWorkAmount(value);
    setPersonalAmount(100 - value);
  };

  const handleSave = async () => {
    await bulkEditBase({ type: 'allocation', value: workAmount });
    setExpenseReviewModalType(null);
  };

  return (
    <div>
      <div className='expense-review-modal-add-header'>Edit {selectedRows.length} selected transactions</div>
      <div className='expense-review-modal-add-line' />
      <Stack spacing={2} style={{ minWidth: '480px', width: '50%' }}>
        <KeeperSlider
          label='Personal'
          showInput
          value={personalAmount}
          onSliderChange={handlePersonalChange}
          onInputChange={handlePersonalChange}
        />
        <KeeperSlider
          label='Work'
          showInput
          value={workAmount}
          onSliderChange={handleWorkChange}
          onInputChange={handleWorkChange}
        />
        <Button variant='contained' color='secondary' fullWidth onClick={handleSave}>
          Save
        </Button>
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedRows: bulkEditExpensesSelector(state)
});

const mapDispatchToProps = {
  bulkEditBase,
  setExpenseReviewModalType
};

const ConnectedExpenseReviewModalBulkSplit = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalBulkSplit);

export default ConnectedExpenseReviewModalBulkSplit;
