import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxFlowFormSubQuestionItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSubQuestionItem';
import { setAuthResponseError } from '@app/src/actions/authActions';
import { updateCurrentAnswer } from '@app/src/actions/taxFlowActions';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { taxAmountsSelector } from '@app/src/api/taxDataApi';
import { renderWideDesktopContentSelector } from '@app/src/selectors/taxFlowSelectors';
import { errorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { dismissOcrError, dismissUncommonOcrErrors } from '@app/src/services/taxFlowService';
import { currentCollectionIdSelector, statusSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getCollectionOcrError, getQuestionOcrError } from '@app/src/taxflow/shared/utils/sharedUtils';
import { isQuestionPresent } from '@app/src/utils/taxValidationUtils';

class TaxFlowFormItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  onFocus = (result) => {
    const ocrError = getQuestionOcrError({
      errors: this.props.errors,
      question: { endpoint_attr: result.endpoint_attr, collectionType: this.props.currentQuestion.collectionType },
      collectionId: this.props.collectionId
    });
    if (ocrError) {
      const newErrors = this.props.errors.filter(
        (error) =>
          !(
            error.slug === result.endpoint_attr &&
            error.coll_type === this.props.currentQuestion.collectionType &&
            error.coll_id === this.props.collectionId &&
            error.code === 'ocr_error'
          )
      );
      this.props.setErrors(newErrors);
      this.props.dismissOcrError({ error: ocrError });
    }

    const endpointAttr = _.get(result, 'endpoint_attr');
    if (endpointAttr && endpointAttr.endsWith('other-fields')) {
      const ocrErrors = getCollectionOcrError({
        errors: this.props.errors,
        collectionType: this.props.currentQuestion.collectionType,
        collectionId: this.props.collectionId
      });
      if (ocrErrors) {
        this.props.dismissUncommonOcrErrors({
          slug: result.endpoint_attr,
          collType: this.props.currentQuestion.collectionType,
          collId: this.props.collectionId
        });
      }
    }

    this.props.onFocus &&
      this.props.onFocus({
        endpoint_attr: result.endpoint_attr,
        collectionType: this.props.currentQuestion.collectionType
      });
  };

  /**
   * @desc save user input in question answer and set answer for analytics and signup
   */
  onChange = (result) => {
    this.props.updateCurrentAnswer({
      slug: result.slug,
      answer: result
    });
  };

  onSubmit = (event) => {
    try {
      event.preventDefault();
      this.onNext();
      trackActivity('question: submit', { question: _.get(this, ['props', 'currentQuestion', 'slug']) });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  onNext = () => {
    if (this.props.onNext) this.props.onNext();
  };

  render() {
    const { currentQuestion, resultLoading } = this.props;

    if (resultLoading) return null;

    return (
      <div>
        <form className='steps-signup-form' onSubmit={this.onSubmit}>
          <div className={classNames('steps-body', { 'steps-body-wide': this.props.renderWideDesktopContent })}>
            {currentQuestion.sub_question &&
              currentQuestion.sub_question.map((question) => {
                return <div key={question.slug}>{this.renderQuestionInputType(question)}</div>;
              })}
          </div>
        </form>
      </div>
    );
  }

  renderQuestionInputType = (question) => {
    const answer =
      this.props.currentAnswer && this.props.currentAnswer.value ? this.props.currentAnswer.value[question.slug] : null;

    if (
      !isQuestionPresent({
        currentQuestion: this.props.currentQuestion,
        question,
        answer: this.props.currentAnswer,
        status: this.props.status
      })
    ) {
      return null;
    }

    return (
      <TaxFlowFormSubQuestionItem
        {...this.props}
        onChange={this.onChange}
        onFocus={this.onFocus}
        question={question}
        answer={answer}
        fullAnswer={this.props.currentAnswer}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  taxFlow: state.taxFlow,
  currentAnswer: state.taxFlow.currentAnswer,
  resultLoading: state.taxFlow.resultLoading,
  status: statusSelector(state),
  taxAmounts: taxAmountsSelector(state),
  errors: errorsSelector(state),
  collectionId: currentCollectionIdSelector(state),
  renderWideDesktopContent: renderWideDesktopContentSelector(state)
});

const mapDispatchToProps = {
  setAuthResponseError,
  updateCurrentAnswer,
  setErrors,
  dismissOcrError,
  dismissUncommonOcrErrors
};

const ConnectedTaxFlowFormItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormItem);

export default ConnectedTaxFlowFormItem;
