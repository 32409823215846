// @ts-check

import React from 'react';
import classNames from 'classnames';

/**
 * @typedef {Object} TaxFlowRefundStatusStepProps
 * @property {boolean} success
 * @property {React.ReactNode} icon
 * @property {string} summary
 * @property {string} details?
 */

/**
 * @type {React.FC<any>}
 */
const TaxFlowRefundStatusStep = ({ success, icon, summary, details }) => {
  return (
    <div
      className={classNames('step', {
        success
      })}
    >
      <div className='icon-container'>{icon}</div>
      <h4>{summary}</h4>
      <div className='connector' />
      <p>{details}</p>
    </div>
  );
};

export default TaxFlowRefundStatusStep;
