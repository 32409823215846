import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Icon from '@mui/material/Icon';
import moment from 'moment';
import { connect } from 'react-redux';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import { useGetSubmitTimestampQuery } from '@app/src/api/taxDataApi';
import TimerIcon from '@app/src/assets/timer.svg?react';
import {
  UI_STAGE__OPS_REVIEW,
  UI_STAGE__PURGATORY,
  UI_STAGE__UPLOAD_ESC
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressTimer.scss';

const TaxFlowSubmitProgressTimer = () => {
  const timerTarget = useCountdown();

  // calculate time left
  const timeLeft = timerTarget?.valueOf() - moment().valueOf();
  const [timer, setTimer] = useState(timeLeft > 0 ? timeLeft : 0);
  const timerDone = timer === 0;

  // convert time left from ms to hours / minutes / seconds
  const hours = Math.floor(timer / (1000 * 60 * 60));
  const minutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timer % (1000 * 60)) / 1000);

  // refresh timer
  useEffect(() => {
    const interval = setInterval(() => {
      if (_.isNil(timerTarget)) {
        return;
      }
      const timeLeft = timerTarget.valueOf() - moment().valueOf();
      setTimer(timeLeft > 0 ? timeLeft : 0);
    }, 1000);

    return () => clearInterval(interval);
  }, [timerTarget, timerDone]);

  return (
    <div>
      <div className='taxflow-submit-progress-timer'>
        <Icon className='taxflow-submit-progress-timer-icon'>
          <TimerIcon width={12} height={12} />
        </Icon>
        <span className='taxflow-submit-progress-timer-label'>
          {timerDone ? 'Any moment now...' : 'Estimated finish: '}
        </span>

        {!timerDone && (
          <span className='taxflow-submit-progress-timer-countdown'>
            {hours > 0
              ? hours +
                ' hr ' +
                minutes.toString().padStart(2, '0') +
                ' min ' +
                seconds.toString().padStart(2, '0') +
                ' sec'
              : minutes + ' min ' + seconds.toString().padStart(2, '0') + ' sec'}
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state)
});

const useCountdown = () => {
  const { data: campaign, isLoading: campaignLoading } = useGetCampaignQuery();
  const { data: submitTimestamp, isLoading: isSubmitTimestampLoading } = useGetSubmitTimestampQuery();

  if (campaignLoading || isSubmitTimestampLoading) {
    return null;
  }

  const uiStage = campaign?.ui_stage;
  const engEscReviewTime = {
    days: 0,
    hours: 72,
    minutes: 0,
    seconds: 0
  };
  const manualReviewTime = {
    days: 0,
    hours: 24,
    minutes: 0,
    seconds: 0
  };
  const instantReviewTime = {
    minutes: 5
  };

  const manualReviewFinish = moment(submitTimestamp).add(manualReviewTime);
  const instantReviewFinish = moment(submitTimestamp).add(instantReviewTime);
  const engEscReviewFinish = moment(submitTimestamp).add(engEscReviewTime);

  if (uiStage === UI_STAGE__PURGATORY) {
    return instantReviewFinish;
  } else if (uiStage === UI_STAGE__OPS_REVIEW) {
    return manualReviewFinish;
  } else if (uiStage === UI_STAGE__UPLOAD_ESC) {
    return engEscReviewFinish;
  } else {
    return null;
  }
};
const ConnectedTaxFlowSubmitProgressTimer = connect(mapStateToProps, {})(TaxFlowSubmitProgressTimer);

export default ConnectedTaxFlowSubmitProgressTimer;
