import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import PaymentElements from '@app/src/Components/Subscription/PaymentModal/PaymentElements';
import { chargeableAccountSelector } from '@app/src/selectors/bankSelectors';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import '@app/src/Components/Settings/Modals/PaymentMethodModal.scss';

const PaymentMethodModal = ({ history, chargeableAccount }) => (
  <>
    <DialogTitle>Edit payment method</DialogTitle>
    <div>Your current payment method is {chargeableAccount?.displayName}.</div>
    <PaymentElements origin='settings' history={history} buttonCopy='Update' />
  </>
);

const mapStateToProps = (state) => ({
  accountDetails: accountDetailsSelector(state),
  chargeableAccount: chargeableAccountSelector(state)
});

const mapDispatchToProps = {};

const ConnectedPaymentMethodModal = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodModal);

export default ConnectedPaymentMethodModal;
