export const PRICING2__SET_SETTINGS = 'PRICING2__SET_SETTINGS';
export const PRICING2__SET_BANK_LINKS = 'PRICING2__SET_BANK_LINKS';
export const PRICING2__SET_ACCOUNT_DETAILS = 'PRICING2__SET_ACCOUNT_DETAILS';
export const PRICING2__SET_LOADING = 'PRICING2__SET_LOADING';
export const PRICING2__SET_UPDATING = 'PRICING2__SET_UPDATING';
export const PRICING2__SET_ERROR = 'PRICING2__SET_ERROR';
export const PRICING2__SET_WALLET_TYPE = 'PRICING2__SET_WALLET_TYPE';
export const PRICING2__SET_IS_CARD_VALID = 'PRICING2__SET_IS_CARD_VALID';
export const PRICING2__SET_BANK = 'PRICING2__SET_BANK';
export const PRICING2__SET_ORIGIN = 'PRICING2__SET_ORIGIN';
export const PRICING2__SET_SUBSCRIPTION_TYPE = 'PRICING2__SET_SUBSCRIPTION_TYPE';

export const PAYMENT_ERROR_MESSAGE =
  'There was an error processing the payment. Please try again or switch to a new payment method.';
