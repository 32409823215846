import React from 'react';
import _ from 'lodash';
import Link from '@mui/material/Link';
import capitalize from 'capitalize';
import { connect } from 'react-redux';
import MobileRedirectLink from '@app/src/Components/MobileRedirect/MobileRedirectLink';
import { setInfoModalItem, setInfoModalShow } from '@app/src/actions/taxFlowActions';
import DashboardSummaryIcon from '@app/src/assets/dashboard-summary.svg?react';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import { categoriesSelector, expensesSelector, savingsSelector } from '@app/src/selectors/dashboardSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import getImageUrl from '@app/src/utils/getImageUrl';
import '@app/src/Components/TaxFlow/Question/TaxFlowWriteOffsElement.scss';

const TaxFlowWriteOffsElement = ({
  currentQuestion,
  expenses,
  categories,
  savings,
  setInfoModalItem,
  setInfoModalShow
}) => {
  const groupedExpenses = _.groupBy(expenses, 'keeper_category_id');
  const displayCategories = Object.keys(groupedExpenses);

  const onClick = () => {
    trackActivity('find write offs button clicked');

    setInfoModalItem({
      title: 'What are deductions?',
      source: `Deductions are expenses that you’ve paid while you’re conducting business that can be subtracted from your taxable income.

A person who makes more money also pays more taxes, so be sure to find as many deductions as you can!

Head over to the Keeper app to review all of your other expenses we’ve detected that may be deductions!`,
      origin: 'taxFlowFindWriteOffsElement'
    });

    setInfoModalShow(true);
  };

  return (
    <>
      <div className='taxflow-write-offs-element'>
        <div className='taxflow-write-offs-element-title'>
          <DashboardSummaryIcon width={48} height={48} />
          <h1>{currentQuestion.title}</h1>
        </div>
        <div className='taxflow-write-offs-element-description'>
          If you have your own business or freelance, make sure you deduct your business expenses to reduce your tax
          bill!{' '}
          <Link className='taxflow-write-offs-element-description-learn-more' onClick={onClick} color='info.light'>
            Learn more
          </Link>
        </div>
        <div className='taxflow-write-offs-element-item taxflow-write-offs-element-item-1'>
          <div className='taxflow-write-offs-element-subtitle'>Your deductions</div>
          {displayCategories.map((id) => {
            const category = categories.find((c) => c.id === Number(id));
            const categoryExpenses = groupedExpenses[id];
            const categoryExpensesTotal = categoryExpenses.length;

            if (!category) return null;

            return (
              <div key={id} className='taxflow-write-offs-element-category'>
                <div className='taxflow-write-offs-element-category-wrapper'>
                  <div className='taxflow-write-offs-element-category-emoji'>{category.emoji}</div>
                  <div className='taxflow-write-offs-element-category-title'>{capitalize(category.plural_name)}</div>
                </div>
                <div className='taxflow-write-offs-element-category-total'>{categoryExpensesTotal}</div>
              </div>
            );
          })}
          <div className='taxflow-write-offs-element-border' />
          <div className='taxflow-write-offs-element-savings'>
            <div className='taxflow-write-offs-element-savings-text'>
              Savings from
              <strong>{expenses.length}</strong>
              deductions
            </div>
            <div className='taxflow-write-offs-element-savings-number'>{currencyWith0DecimalPlaces(savings)}</div>
          </div>
        </div>
        <div className='taxflow-write-offs-element-item taxflow-write-offs-element-item-2'>
          <div className='taxflow-write-offs-element-preview'>
            <img
              src={getImageUrl('home-dash-example-writeoffs.svg')}
              alt='App preview'
              className='taxflow-write-offs-element-preview-img'
            />
            <div className='taxflow-write-offs-element-preview-text'>
              <div className='taxflow-write-offs-element-preview-title'>Find more deductions in app</div>
              <div className='taxflow-write-offs-element-preview-subtitle'>
                Review expenses, edit deductions, and so much more — directly in the app
              </div>
              <div className='taxflow-write-offs-element-preview-button'>
                <MobileRedirectLink
                  className='btn-block'
                  linkType='write-offs-link'
                  clickEvent='find write-offs more: click'
                  buttonColor='secondary'
                  mobileText='Go to app'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  expenses: expensesSelector(state),
  categories: categoriesSelector(state),
  savings: savingsSelector(state)
});

const mapDispatchToProps = {
  setInfoModalItem,
  setInfoModalShow
};

const ConnectedTaxFlowWriteOffsElement = connect(mapStateToProps, mapDispatchToProps)(TaxFlowWriteOffsElement);

export default ConnectedTaxFlowWriteOffsElement;
