import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxFlowLearnMoreElement from '@app/src/Components/TaxFlow/Common/TaxFlowLearnMoreElement';
import TaxFlowOptionItem from '@app/src/Components/TaxFlow/Question/TaxFlowOptionItem';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarning from '@app/src/Components/TaxValidation/TaxValidationWarning';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

const TaxFlowFormOptionItem = (props) => {
  const { question, answer, hasError } = props;

  if (!question) {
    return null;
  }

  const onChange = (value) => {
    props.onChange({ slug: props.question.slug, value, endpoint_attr: props.question.endpoint_attr });
  };

  const onFocus = () => {
    if (props.onFocus) {
      props.onFocus({ endpoint_attr: props.question.endpoint_attr });
    }
  };

  const accordionInfo = _.get(question, ['learnMoreInfo', 'fields', 'accordionInfo']);
  const contentType = _.get(question, ['learnMoreInfo', 'fields', 'contentType']);
  const type = _.get(question, ['learnMoreInfo', 'fields', 'type']);
  const learnMoreInfoSlug = _.get(question, ['learnMoreInfo', 'fields', 'slug']);
  const title = _.get(question, ['learnMoreInfo', 'fields', 'title']);
  const description = _.get(question, ['learnMoreInfo', 'fields', 'description']);
  const url = _.get(question, ['learnMoreInfo', 'fields', 'url']);
  const buttonText = _.get(question, 'learnMoreTitle');
  const disableChatMessaging = _.get(question, ['learnMoreInfo', 'fields', 'disableChatMessaging']);

  return (
    <div className='form-group child-options'>
      <TaxValidationLabel question={question} answer={answer} title={props.replaceStrings(question.title)} />
      {learnMoreInfoSlug && (
        <div className='learn-more-option'>
          <TaxFlowLearnMoreElement
            question={question}
            slug={learnMoreInfoSlug}
            type={type}
            buttonText={buttonText}
            modalTitle={title}
            description={description}
            url={url}
            accordionInfo={accordionInfo}
            contentType={contentType}
            disableChatMessaging={disableChatMessaging}
          />
        </div>
      )}
      {hasError && <TaxValidationWarning question={question} answer={answer} blueWarningMessage />}
      <TaxFlowOptionItem
        {...props}
        isChildQuestion
        currentQuestion={question}
        currentAnswer={answer}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  hasError: hasFieldErrorSelector(state, props)
});

const ConnectedTaxFlowFormOptionItem = connect(mapStateToProps)(TaxFlowFormOptionItem);

export default ConnectedTaxFlowFormOptionItem;
