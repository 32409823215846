import {
  SET_IS_EMAIL_LOADING,
  SET_IS_PHONE_LOADING,
  SET_IS_FIRSTNAME_LOADING,
  SET_IS_LASTNAME_LOADING,
  SET_NEXT_CHARGE_DATE,
  SET_SETTINGS_MODAL_TYPE,
  SET_IS_CANCELING_SUBSCRIPTION,
  SET_SETTINGS_LOADING,
  SET_SETTINGS_ERROR
} from '@app/src/actions/types';

const initialState = {
  modalType: '',
  isEmailLoading: false,
  isPhoneLoading: false,
  isFirstnameLoading: false,
  isLastnameLoading: false,
  nextChargeDate: null,
  isCancelingSubscription: false,
  loading: false,
  error: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload
      };
    case SET_IS_EMAIL_LOADING:
      return {
        ...state,
        isEmailLoading: action.payload
      };
    case SET_IS_PHONE_LOADING:
      return {
        ...state,
        isPhoneLoading: action.payload
      };
    case SET_IS_FIRSTNAME_LOADING:
      return {
        ...state,
        isFirstnameLoading: action.payload
      };
    case SET_IS_LASTNAME_LOADING:
      return {
        ...state,
        isLastnameLoading: action.payload
      };
    case SET_NEXT_CHARGE_DATE:
      return {
        ...state,
        nextChargeDate: action.payload
      };
    case SET_IS_CANCELING_SUBSCRIPTION:
      return {
        ...state,
        isCancelingSubscription: action.payload
      };
    case SET_SETTINGS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default: {
      return state;
    }
  }
}
