import React from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { walletTypeSelector, updatingSelector } from '@app/src/selectors/pricingSelectors';
import '@app/src/Components/Subscription/PaymentModal/PaymentWallet.scss';

const PaymentWallet = ({ className, walletType, updating, paymentRequest }) => {
  if (!walletType || !paymentRequest) {
    return null;
  }

  return (
    <div className={classNames('payment-wallet', className)}>
      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: '50px'
            }
          }
        }}
      />
      {updating && <div className='payment-wallet-cover' />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  walletType: walletTypeSelector(state),
  updating: updatingSelector(state)
});

const ConnectedPaymentWallet = connect(mapStateToProps)(PaymentWallet);

export default ConnectedPaymentWallet;
