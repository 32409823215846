import React from 'react';
import DropDown from '@app/src/Components/Common/DropDown/DropDown';
import { colorAccent, colorFontDark } from '@app/src/theme';

const ExpenseReviewDropdown = ({ value, onChange }) => {
  const options = [
    { text: 'Deduction', value: 'yes' },
    { text: 'Not deductible', value: 'no' }
  ];

  return (
    <DropDown
      value={value || ''}
      options={options}
      inputProps={{
        style: {
          height: '30px'
        }
      }}
      style={{
        height: '24px',
        paddingRight: '0px',
        fontSize: '14px',
        paddingTop: '4px',
        paddingBottom: '4px',
        color: colorFontDark
      }}
      sx={{
        '& .MuiNativeSelect-icon': { height: 14, width: 14, right: 14, top: 'auto' },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: colorAccent }
      }}
      onChange={onChange}
      showDefault={true}
      defaultText='Select'
    />
  );
};

export default ExpenseReviewDropdown;
