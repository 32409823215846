export const LOADING_BANNER_TITLE = 'Scanning for deductions...';
export const BANK_SKIP_LOADING_BANNER_TITLE = 'Finding relevant deductions...';
export const BANK_LINK_LOADING_LIST_ITEMS = [
  'Connecting to your bank...',
  'Finding potential deductions...',
  'Calculating your tax savings...',
  'Preparing your results in the app...'
];

export const BANK_SKIP_LOADING_LIST_ITEMS = [
  'Analyzing your job profile...',
  'Finding relevant deductions...',
  'Estimating potential tax savings...',
  'Preparing your results in the app...'
];
export const BANK_LINK_SKIP_TITLE = 'In order to continue, please download the app';
