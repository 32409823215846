import React from 'react';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import TaxProfileSelect from '@app/src/Components/TaxProfile/TaxProfileSelect';
import { STATES } from '@app/src/taxflow/shared/constants/sharedConstants';
import { colorFontDark } from '@app/src/theme';

/**
 * @typedef HouseholdDetailsSectionProps
 * @property {import('react-hook-form').UseFormReturn['control']} control
 * @property {import('react-hook-form').UseFormReturn['handleSubmit']} handleSubmit
 * @property {string} filingStatus
 */

/** @type {React.FC<HouseholdDetailsSectionProps>} */
const HouseholdDetailsSection = () => {
  const { handleSubmit, watch, setValue } = useFormContext();

  const filingStatus = watch('filing_status');

  const { onSubmit } = useTaxProfileFormContext();

  return (
    <TaxProfileFormSection
      header='Household details'
      headerStyle={{
        marginTop: 0
      }}
    >
      <Controller
        name='state_residence'
        defaultValue=''
        render={({ field, fieldState: { error } }) => (
          <TaxProfileSelect
            {...field}
            id='state'
            label='State'
            error={error}
            placeholder='Select a state'
            value={_.isNil(field.value) ? '' : field.value}
            onChange={(event) => {
              field.onChange(event);

              handleSubmit(onSubmit(field.name))();
            }}
            options={STATES.map(({ code, name }) => {
              return {
                value: code,
                label: name
              };
            })}
          />
        )}
      />
      <Controller
        name='filing_status'
        defaultValue=''
        rules={{
          required: 'Filing status is required'
        }}
        render={({ field, fieldState: { error } }) => (
          <TaxProfileSelect
            {...field}
            id='filingStatus'
            error={error}
            label='Filing status'
            placeholder='Select a status'
            onChange={(event) => {
              field.onChange(event);

              if (event.target.value === 'single') {
                setValue('tax_calc_credits.numDependents', null);
              }

              handleSubmit(onSubmit(field.name))();
            }}
            options={[
              {
                value: 'single',
                label: 'Single'
              },
              {
                value: 'married',
                label: 'Married filing jointly'
              },
              {
                value: 'married_separate',
                label: 'Married filing separately'
              },
              {
                value: 'head',
                label: 'Head of household'
              }
            ]}
          />
        )}
      />
      {filingStatus !== 'single' && filingStatus !== '' && (
        <Controller
          name='tax_calc_credits.numDependents'
          defaultValue={''}
          rules={{
            min: {
              value: 0,
              message: 'Number of dependents cannot be negative'
            },
            max: {
              value: 10,
              message: 'Number of dependents cannot exceed 10'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <InputLabel
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: colorFontDark
                }}
                htmlFor='numDependents'
                id='numDependentsLabel'
              >
                Number of dependents
              </InputLabel>
              <TextField
                {...field}
                value={_.isNil(field.value) ? '' : field.value}
                error={!!error}
                helperText={error?.message}
                onChange={(e) => {
                  const value = parseInt(e.target.value);

                  if (isNaN(value)) {
                    field.onChange(null);
                    return;
                  }

                  field.onChange(value);
                }}
                onBlur={handleSubmit(onSubmit(field.name))}
                id='numDependents'
                type='number'
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 10
                  }
                }}
                placeholder='e.g. 1'
                style={{
                  width: '100%'
                }}
              />
            </FormControl>
          )}
        />
      )}
    </TaxProfileFormSection>
  );
};

export default HouseholdDetailsSection;
