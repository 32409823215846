import _ from 'lodash';
import moment from 'moment';
import { DEFAULT_WORK, REFERRER_ID_STORAGE_KEY, REFERRER_STORAGE_KEY } from '@app/src/constants/constants';
import {
  Url_FACEBOOK_CLICK_ID_STORAGE_KEY,
  Url_GOOGLE_CLICK_ID_STORAGE_KEY,
  Url_TIKTOK_STORAGE_KEY
} from '@app/src/constants/onboardingConstants';
import { currencyWith0DecimalPlaces, getCookie } from '@app/src/global/Helpers';
import { jobCategoriesSelector, taxEstimateSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { generateClientDedupId, getAnalyticsTrait, trackActivity } from '@app/src/services/analyticsService';
import { parseExperimentProperties, parseSignupExperiments } from '@app/src/services/onboardingExperimentService';

export const getMonths = () => {
  const months = [];

  for (let i = 1; i <= 12; i++) {
    months.push(
      moment()
        .month(i - 1)
        .format('M')
    );
  }

  return months;
};

export const getPreviousTwoYears = () => {
  return [moment().year(), moment().year() - 1];
};

export const autoAdvance = ({ f, pathname, push }) =>
  setTimeout(() => {
    f({ pathname, push });
  }, 400);

export const consolidateContentfulValues = (contentful) => {
  const { items } = contentful;

  return items.reduce((acc, { fields }) => ({ ...acc, [fields.url]: fields }), {});
};

export const getStringSubstitutions = (value) => (dispatch, getState) => {
  const localStorage = JSON.parse(getLocalStorage('onboardingState'));

  let updatedValue = value;

  if (updatedValue.includes('{work}')) {
    const incomeType = _.get(localStorage, 'incomeType', '');

    if (incomeType?.includes('contractor')) {
      updatedValue = updatedValue.replace('{work}', 'contracting work');
    } else if (incomeType?.includes('owner')) {
      updatedValue = updatedValue.replace('{work}', 'business');
    } else {
      updatedValue = updatedValue.replace('{work}', '1099 work');
    }
  }

  if (updatedValue.includes('{1099 work}')) {
    const job = _.get(localStorage, 'jobs[0].name', DEFAULT_WORK);

    const jobInfo = jobCategoriesSelector(getState()).find((category) => category.slug === job);
    const jobAsVerb = _.get(jobInfo, 'jobAsVerb', 'work');

    updatedValue = updatedValue.replace('{1099 work}', jobAsVerb);
  }

  if (updatedValue.includes('{jobNameWithArticle}')) {
    const jobs = _.get(localStorage, 'jobs');

    if (jobs && jobs.length === 1) {
      const job = _.get(jobs, '[0].name');
      const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

      updatedValue = updatedValue.replace('{jobNameWithArticle}', _.get(jobInfo, 'jobNameWithArticle', 'a freelancer'));
    } else {
      updatedValue = updatedValue.replace('{jobNameWithArticle}', 'a freelancer');
    }
  }

  if (updatedValue.includes('{you}')) {
    const isMarried = _.get(localStorage, 'married', '') === 'married';
    updatedValue = updatedValue.replace('{you}', isMarried ? 'you and your spouse' : 'you');
  }

  if (updatedValue.includes('{jobName}')) {
    const jobs = _.get(localStorage, 'jobs');
    const job = jobs.length > 1 ? DEFAULT_WORK : jobs;

    if (jobs.length === 1) {
      const jobInfo = jobCategoriesSelector(getState()).find((category) => category.slug === job);

      updatedValue = updatedValue.replace('{jobName}', _.get(jobInfo, 'name'));
    } else {
      updatedValue = updatedValue.replace('{jobName}', 'freelancers');
    }
  }

  if (updatedValue.includes('{carRelatedDescription}')) {
    const job = _.get(localStorage, 'jobs[0].name');
    const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

    updatedValue = updatedValue.replace('{carRelatedDescription}', _.get(jobInfo, 'carRelatedDescription'));
  }

  if (updatedValue.includes('{homeOfficeDescription}')) {
    const job = _.get(localStorage, 'jobs[0].name');
    const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

    updatedValue = updatedValue.replace('{homeOfficeDescription}', _.get(jobInfo, 'homeOfficeDescription'));
  }

  if (updatedValue.includes('{travelHelpText}')) {
    const job = _.get(localStorage, 'jobs[0].name');
    const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

    updatedValue = updatedValue.replace('{travelHelpText}', _.get(jobInfo, 'travelHelpText'));
  }

  if (updatedValue.includes('{whyMightThisPersonGetABusinessMeal}')) {
    const job = _.get(localStorage, 'jobs[0].name');
    const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

    updatedValue = updatedValue.replace(
      '{whyMightThisPersonGetABusinessMeal}',
      _.get(jobInfo, 'whyMightThisPersonGetABusinessMeal')
    );
  }

  if (updatedValue.includes('{estimatedSavings}')) {
    const income1099 = _.get(localStorage, 'income1099', 0);

    if (income1099 < 20000) {
      updatedValue = updatedValue.replace('{estimatedSavings}', '$788');
    } else if (income1099 < 60000) {
      updatedValue = updatedValue.replace('{estimatedSavings}', '$2,075');
    } else if (income1099 < 100000) {
      updatedValue = updatedValue.replace('{estimatedSavings}', '$5,328');
    } else {
      updatedValue = updatedValue.replace('{estimatedSavings}', '$8,168');
    }
  }

  if (updatedValue.includes('{billOrRefund}')) {
    const estimatedRefund = _.get(taxEstimateSelector(getState()), 'netBalance', 0);

    updatedValue = updatedValue.replace('{billOrRefund}', estimatedRefund > 0 ? 'bill' : 'refund');
  }

  if (updatedValue.includes('{estimatedRefund}')) {
    const estimatedRefund = _.get(taxEstimateSelector(getState()), 'netBalance', 0);

    updatedValue = updatedValue.replace('{estimatedRefund}', currencyWith0DecimalPlaces(Math.abs(estimatedRefund)));
  }

  if (updatedValue.includes('{contentfulTypeJobs}')) {
    updatedValue = updatedValue.replace('{contentfulTypeJobs}', userSelector(getState()).contentfulTypeJobs);
  }

  return updatedValue;
};

export const getLocalStorage = (key) => localStorage.getItem(key);
const setLocalStorage = (key, value) => localStorage.setItem(key, value);
export const clearOnboardingState = () => localStorage.removeItem('onboardingState');
export const getOnboardingState = () => JSON.parse(getLocalStorage('onboardingState'));

export const preserveProgress = (answer, analyticsEvent) => {
  const onboardingState = JSON.parse(getLocalStorage('onboardingState')) || {};

  setLocalStorage('onboardingState', JSON.stringify({ ...onboardingState, ...answer }));

  trackActivity('question: answer', {
    flow: 'onboarding',
    type: 'holistic-onboarding',
    answer,
    ...analyticsEvent
  });
};

export const getExperimentProperties = (experiments) => {
  const experimentProperties = parseExperimentProperties(experiments);
  const signupExperiments = parseSignupExperiments(experimentProperties.experiments);

  return {
    experiments: signupExperiments
  };
};

export const getMarketingData = () => {
  const referrer = getCookie(REFERRER_STORAGE_KEY);
  const referrer_id = getCookie(REFERRER_ID_STORAGE_KEY);
  const fbc = getAnalyticsTrait(Url_FACEBOOK_CLICK_ID_STORAGE_KEY);
  const gclid = getAnalyticsTrait(Url_GOOGLE_CLICK_ID_STORAGE_KEY);
  const ttclid = getAnalyticsTrait(Url_TIKTOK_STORAGE_KEY);
  const client_dedup_id = generateClientDedupId();

  return {
    referrer,
    referrer_id,
    client_dedup_id,
    ...(fbc && { fbc }),
    ...(ttclid && { ttclid }),
    ...(gclid && { gclid })
  };
};
