import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxFlowMultiOptionListItem from '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionListItem';
import { onboardingEstimateTimeSelector, onboardingExperimentsSelector } from '@app/src/selectors/onboardingSelectors';
import { collectionInfoModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { multiOptionExplanationSelector } from '@app/src/selectors/taxFlowSelectors';
import TaxFlowCollectionInfoModal from '@app/src/taxflow/collection/components/TaxFlowCollectionInfoModal';
import { collectionTypeItemsWithSubstitutionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionElement.scss';

class TaxFlowMultiOptionElement extends Component {
  render() {
    const {
      collectionTypeItems,
      currentQuestion,
      resultLoading,
      collectionInfoModalShow,
      isChildQuestion,
      explanation
    } = this.props;

    if (resultLoading) {
      return null;
    }

    return (
      <div className={classNames({ 'steps-body': !isChildQuestion })}>
        <div className='checkbox-list'>
          {currentQuestion.question_meta &&
            currentQuestion.question_meta.map((item) => {
              const collectionTypeItem = item.aboutYou
                ? collectionTypeItems.find((collectionTypeItem) => collectionTypeItem.fields.slug === item.value)
                : null;

              return (
                <TaxFlowMultiOptionListItem
                  currentQuestion={currentQuestion}
                  collectionTypeItem={collectionTypeItem}
                  item={item}
                  key={item.value}
                  showRightIcon={
                    (item.aboutYou && !!_.get(collectionTypeItem, ['fields', 'modalTitle'])) || _.get(item, 'info')
                  }
                  {...this.props}
                />
              );
            })}
          {collectionInfoModalShow && <TaxFlowCollectionInfoModal />}
        </div>
        {explanation && <div className='tax-flow-multi-option-element-info'>{explanation}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  collectionTypeItems: collectionTypeItemsWithSubstitutionsSelector(state),
  currentQuestion: props.currentQuestion || state.taxFlow.currentQuestion,
  resultLoading: !_.isNil(props.resultLoading) ? props.resultLoading : state.taxFlow.resultLoading,
  collectionInfoModalShow: collectionInfoModalShowSelector(state),
  estimateTime: onboardingEstimateTimeSelector(state),
  experiments: onboardingExperimentsSelector(state),
  explanation: multiOptionExplanationSelector(state)
});

const ConnectedTaxFlowMultiOptionElement = connect(mapStateToProps)(TaxFlowMultiOptionElement);

export default ConnectedTaxFlowMultiOptionElement;
